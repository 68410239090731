import React from 'react'
import { Flex, Text, PseudoBox, Image, Button } from '@builtbypixel/plasma'

/**
 * The card that shows the resource item.
 *
 * @param {Object} item The item to be shown
 * @param {Function} setSelected The function to be called when an item is selected
 * @param {Boolean} selected Indicates whether an item is selected
 */
function ResourceCard({ item, setSelected, selected }) {
   return (
      <PseudoBox
         cursor='pointer'
         bg='white'
         rounded={['0.5rem']}
         transition='0.3s'
         _hover={{
            transform: 'scale(1.02, 1.02)',
            boxShadow: '5px 5px 20px grey',
            textDecoration: 'none'
         }}
         overflow='hidden'
         display='flex'
         flexDirection='column'
         onClick={!selected ? () => setSelected(item) : null}
         as={selected ? 'a' : null}
         href={selected ? item?.pdf_link : null}
         target={selected ? '_blank' : null}
      >
         <Flex overflow='hidden' h='100%'>
            <Image
               src={selected ? item?.url : item?.resources?.[0]?.url}
               m='auto'
            />
         </Flex>

         <Flex p='1rem' direction='column'>
            <Text fontWeight='bold'>
               {selected ? item?.original_filename : item?.name}
            </Text>
            {selected ? (
               <a
                  href={'data:application/pdf,' + item?.pdf_link}
                  target='_blank'
                  download={item?.original_filename}
               >
                  <Button mt='1rem' w='100%'>
                     Download
                  </Button>
               </a>
            ) : (
               <Button mt='1rem'>View All</Button>
            )}
         </Flex>
      </PseudoBox>
   )
}

export default ResourceCard

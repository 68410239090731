import React, { Suspense, useEffect } from 'react'
import {
   Box,
   Grid,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   Flex,
   Button,
   Stack,
   useToast
} from '@builtbypixel/plasma'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import TableLoader from '../../components/common/TableLoader'
import LinkButton from '../../components/LinkButton'
import Card from '../../components/common/Card'

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import ViewTitle from '../../components/common/ViewTitle'
import { tableAtom, globalAtom } from '../../state/global'
import { useHttp } from '../../hooks'
import { useRecoilValue } from 'recoil'

const GoLocalAudits = React.lazy(() => import('./Tabs/GoLocalAudits'))
const SupplierAudits = React.lazy(() => import('./Tabs/Supplier'))
const FileDownload = require('js-file-download')

const Planner = () => {
   const Http = useHttp()
   let { path, url } = useRouteMatch()
   const users = useRecoilValue(tableAtom)
   const { user } = useRecoilValue(globalAtom)
   const toast = useToast()

   const routes = [
      {
         name: 'Go Local Audits',
         component: <GoLocalAudits />,
         path: 'go-local',
         exact: true,
         permissions: ['Admin', 'RDA']
      }
      // },
      // {
      //    name: 'Supplier Tracker',
      //    component: <SupplierAudits />,
      //    path: 'supplier',
      //    exact: true,
      //    permissions: ['Admin', 'RDA']
      // }
   ]

   const getActiveTab = () => {
      const item = routes.find(
         (r) => r.path === history.location.pathname.split('/').pop()
      )
      return routes.indexOf(item)
   }
   const history = useHistory()

   const downloadCSV = () => {
      Http.post(
         '/download-audits-csv',
         { data: users?.selected },
         {
            responseType: 'blob',
            headers: {
               'Access-Control-Allow-Headers': '*',
               'Access-Control-Allow-Origin': '*'
            }
         }
      )
         .then((res) => {
            FileDownload(res.data, `customer_leads_export.xlsx`)

            toast({
               title: 'Download Audit Successful',
               status: 'success',
               position: 'top',
               variant: 'plain'
            })
         })
         .catch((err) => {
            toast({
               title: 'Error Downloading Audit, please try again.',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
         })
   }

   return (
      <>
         <Flex align='center'>
            <ViewTitle title='Audits' />
            <Flex ml='auto'>
               {users?.selected.length !== 0 &&
                  user.type.name === 'Administrator' && (
                     <Button
                        onClick={() => downloadCSV()}
                        variantColor='parfettsGreen'
                        color='white'
                        size='lg'
                        mr={5}
                     >
                        Download CSV
                     </Button>
                  )}
               <LinkButton to={`/audits/create`}>
                  <Button variantColor='parfettsRed' color='white' size='lg'>
                     Add New Audit
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Grid w='100%' templateColumns='repeat(2, 1fr)'>
            <Box gridColumn={{ xs: 'span 12' }}>
               <Tabs
                  mb={10}
                  orientation='horizontal'
                  size='lg'
                  index={getActiveTab()}
               >
                  <TabList>
                     {routes.map((route, i) => (
                        <Tab
                           key={`tab-${i}`}
                           position='relative'
                           onClick={() => history.push(`${url}/${route.path}`)}
                        >
                           {route.name}
                        </Tab>
                     ))}
                  </TabList>

                  <TabPanels>
                     <Switch>
                        <Card borderTopLeftRadius={0}>
                           {routes.map((route, i) => (
                              <TabPanel key={`planner-route-${i}`}>
                                 <Route
                                    key={`panel-${i}`}
                                    exact={route.exact}
                                    path={`${path}/${route.path}`}
                                 >
                                    <ErrorBoundary>
                                       <Suspense fallback={<TableLoader />}>
                                          {route.component}
                                       </Suspense>
                                    </ErrorBoundary>
                                 </Route>
                              </TabPanel>
                           ))}
                        </Card>
                     </Switch>
                  </TabPanels>
               </Tabs>
            </Box>
         </Grid>
      </>
   )
}

export default Planner

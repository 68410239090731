import React from 'react'
import {
    Box,
    Button,
    Flex,
    Text,
    IconButton,
    Tooltip,
    Modal,
    ModalButton,
    PopConfirm
} from '@builtbypixel/plasma'

import { FiFolderPlus, FiFolderMinus, FiEdit2 } from 'react-icons/fi'
import { FiUploadCloud } from 'react-icons/fi'

import { useHttp, useToast } from '../../hooks'

import AddFolderModal from './AddFolderModal'
import EditFile from './AddEditFile'




const AddNewFolder = ({ selectedFolder, refreshData, retailer }) => {

    return (
        <Tooltip title={selectedFolder?.id ? 'Add subfolder' : 'Create Folder'}>
            <Modal>
                <ModalButton>
                    <IconButton
                        isFullWidth
                        opacity={0.6}
                        variant='ghost'
                        icon={<FiFolderPlus fontSize='18px' />}
                    ></IconButton>
                </ModalButton>
                <AddFolderModal folder={selectedFolder} refreshData={refreshData} retailer={retailer} />
            </Modal>
        </Tooltip>
    )
}

const EditFolder = ({ selectedFolder, refreshData, retailer }) => {
    return (
        <Tooltip title='Edit Folder'>
            <Modal>
                {selectedFolder.id ?
                    <ModalButton >
                        <IconButton

                            isFullWidth
                            opacity={0.6}
                            variant='ghost'
                            icon={<FiEdit2 fontSize='18px' />}
                        ></IconButton>
                    </ModalButton>
                    :
                    <IconButton
                        disabled
                        isFullWidth
                        opacity={0.6}
                        variant='ghost'
                        icon={<FiEdit2 fontSize='18px' />}
                    ></IconButton>
                }
                <AddFolderModal edit folder={selectedFolder} refreshData={refreshData} retailer={retailer} />
            </Modal>
        </Tooltip>
    )
}

const UploadFile = ({ selectedFolder }) => {


    return (
        selectedFolder.id ?
            <Tooltip title='Upload File'>
                <Modal>
                    <ModalButton>
                        <IconButton
                            isFullWidth
                            opacity={0.6}
                            variant='ghost'
                            icon={<FiUploadCloud fontSize='18px' />}
                        ></IconButton>
                    </ModalButton>
                    <EditFile folder={selectedFolder} />
                </Modal>
            </Tooltip>

            :
            <IconButton
                disabled
                isFullWidth
                opacity={0.6}
                variant='ghost'
                icon={<FiUploadCloud fontSize='18px' />}
            ></IconButton>



    )
}
const CurrentFolder = ({ selectedFolder }) => {
    return (
        <Tooltip title='Current Folder'>
            <Button
                isFullWidth
                opacity={0.6}
                variant='ghost'>{selectedFolder?.name ? selectedFolder.name : "All Files"}</Button>

        </Tooltip>

    )
}
const RemoveFolder = ({ selectedFolder }) => {
    const Http = useHttp()
    const toast = useToast()

    const handleDelete = () => {

        Http.delete(`/media-library/folder`, { data: { id: [selectedFolder.id] } })
            .then(() => {
                toast({
                    title: 'Folder Deleted',
                    status: 'success',
                    variant: 'plain'
                })
            })
            .catch((err) => {
                toast({
                    title: 'Error Deleting Folder',
                    status: 'error',
                    variant: 'plain'
                })
            })
    }


    return (
        selectedFolder?.id ?
            <Tooltip title='Delete Folder'>
                <PopConfirm
                    title={
                        <>
                            <Text>Are you sure?</Text>{' '}
                            <Text fontWeight='semibold'>
                                This will delete all files associated with this folder and
                                cannot be undone.
                  </Text>
                        </>
                    }
                    onConfirm={handleDelete}
                >
                    <IconButton
                        isFullWidth
                        opacity={0.6}
                        variant='ghost'
                        icon={<FiFolderMinus fontSize='18px' />}
                    ></IconButton>
                </PopConfirm>
            </Tooltip>
            :
            <IconButton
                disabled
                isFullWidth
                opacity={0.6}
                variant='ghost'
                icon={<FiFolderMinus fontSize='18px' />}
            ></IconButton>
    )
}

const FolderNavigation = ({ selectedFolder, refreshData, refreshFiles, retailer }) => {

    return (
        <>

            <Flex w='100%' borderBottom='1px' borderColor='global.borderColour'>

                <Box flex={1} borderRight='1px' borderColor='global.borderColour'>
                    <CurrentFolder selectedFolder={selectedFolder} />
                </Box>
                <Box flex={1} borderRight='1px' borderColor='global.borderColour'>
                    <AddNewFolder selectedFolder={selectedFolder} refreshData={refreshData} retailer={retailer} />
                </Box>
                <Box flex={1} borderRight='1px' borderColor='global.borderColour'>
                    <UploadFile selectedFolder={selectedFolder} refreshFiles={refreshFiles} />
                </Box>
                <Box flex={1} borderRight='1px' borderColor='global.borderColour'>
                    <EditFolder selectedFolder={selectedFolder} refreshData={refreshData} retailer={retailer} />
                </Box>
                <Box flex={1}>
                    <RemoveFolder selectedFolder={selectedFolder} refreshData={refreshData} />
                </Box>
            </Flex>
        </>
    )
}


export default FolderNavigation

import React, { useContext, useEffect, useState } from 'react'
import {
   Box,
   Button,
   ModalContent,
   ModalContext,
   ModalFooter,
   ModalHeader,
   TextArea
} from '@builtbypixel/plasma'

const Content = ({ handleSubmit, notes, isLoading, isDisabled }) => {
   const { close } = useContext(ModalContext)

   const [newNotes, setNewNotes] = useState(notes)

   useEffect(() => {
      setNewNotes(notes)
   }, [notes])

   return (
      <ModalContent p='25px'>
         <ModalHeader>Submit Remote Audit</ModalHeader>
         <Box m='1rem'>
            <TextArea
               value={newNotes}
               onChange={(value) => setNewNotes(value.target.value)}
               placeholder='Enter additional information here... (Optional)'
               rows={4}
               size='md'
               variant='outline'
            />
         </Box>

         <ModalFooter showCloseIcon={false}>
            <Button
               variantColor='parfettsGreen'
               color='white'
               onClick={() => {
                  handleSubmit(newNotes)
                  close()
               }}
               loadingText='Submitting...'
               isLoading={isLoading}
               isDisabled={isDisabled}
            >
               Confirm
            </Button>
         </ModalFooter>
      </ModalContent>
   )
}

export default Content

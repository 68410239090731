import React, { memo } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import { Flex, Text, Box, Image, Grid } from '@builtbypixel/plasma'

const PendingLeads = memo(() => {
   const { data } = useSWR('/customer-leads-rda', useGet, {
      suspense: true
   })

   const cellPadding = '8px 12px'

   return (
      <>
         <Grid templateColumns='repeat(4, 1fr)' gap='5px 0px'>
            <Text>RDA Name</Text>
            <Box bg='parfettsRed' color='white' p={cellPadding}>
               High
            </Box>
            <Box bg='parfettsOrange' color='white' p={cellPadding}>
               Medium
            </Box>
            <Box bg='parfettsGreen' color='white' p={cellPadding}>
               Low
            </Box>
         </Grid>
         {data.data.length > 0 &&
            data.data.slice(0, 8).map((rda, i) => (
               <Grid
                  templateColumns='repeat(4, 1fr)'
                  gap='5px 0px'
                  key={`pending-rda-container-${i}`}
               >
                  <Flex
                     bg={i % 2 === 0 && 'grayRow'}
                     align='center'
                     p={cellPadding}
                  >
                     <Image
                        src={rda?.profile_image?.[0]?.url}
                        size='30px'
                        objectFit='cover'
                        rounded='full'
                        mr='5px'
                     />
                     {rda?.first_name} {rda.last_name}
                  </Flex>
                  <Flex
                     align='center'
                     bg={i % 2 === 0 && 'grayRow'}
                     p={cellPadding}
                     fontSize='1.2rem'
                  >
                     {rda.customer_leads[0].customer_lead.length}
                  </Flex>
                  <Flex
                     align='center'
                     bg={i % 2 === 0 && 'grayRow'}
                     p={cellPadding}
                     fontSize='1.2rem'
                  >
                     {rda.customer_leads[1].customer_lead.length}
                  </Flex>
                  <Flex
                     align='center'
                     bg={i % 2 === 0 && 'grayRow'}
                     p={cellPadding}
                     fontSize='1.2rem'
                  >
                     {rda.customer_leads[2].customer_lead.length}
                  </Flex>
               </Grid>
            ))}
      </>
   )
})

export default PendingLeads

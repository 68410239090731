import React, { memo, Suspense, useEffect, useState } from 'react'
import {
   Text,
   ScrollContainer,
   NumberInput,
   Box,
   PopConfirm,
   IconButton
} from '@builtbypixel/plasma'
import Table from './Table'
import { FiTrash } from 'react-icons/fi'

import TableLoader from '../../../../components/common/TableLoader'
import ErrorBoundary from '../../../../components/common/ErrorBoundary'
import { useParams } from 'react-router-dom'
import { usePost } from '../../../../hooks'

const Trolley = memo(() => {
   const { retailer, branchId } = useParams()
   const [fetch, setFetch] = useState(0)
   const { post, response } = usePost()

   const handleEditQuantity = (qtt, vals) => {
      post('/trolley', {
         product: vals.id,
         pack: vals.pack_id,
         quantity: qtt,
         retailer: {
            id: retailer,
            branch_id: branchId
         }
      })
   }

   useEffect(() => {
      if (response) {
         setFetch(fetch + 1)
      }
   }, [response])

   const columns = [
      {
         Header: 'Product',
         accessor: 'pack.product.name',
         Cell: ({ value }) => <Text fontWeight='semibold'>{value}</Text>
      },
      {
         Header: 'Product ID',
         accessor: 'pack.product.id'
      },
      {
         Header: 'Pack',
         accessor: 'pack',
         Cell: ({ row }) => (
            <Text>
               {row.original.pack.quantity} x {row.original.pack.uos}
            </Text>
         )
      },

      {
         Header: 'VAT (%)',
         accessor: 'pack.product.vat_rate',
         Cell: ({ value }) => <Text>{value}%</Text>
      },
      {
         Header: 'RRP',
         accessor: 'rrp'
      },
      {
         Header: 'POR',
         accessor: 'por'
      },
      {
         Header: 'Price',
         accessor: 'price',
         Cell: ({ value }) => <Text>£ {value}</Text>
      },
      {
         Header: 'Qty',
         accessor: 'quantity',
         Cell: ({ row }) => (
            <Box width='122px'>
               <NumberInput
                  min={1}
                  max={1000}
                  onChange={(value) => handleEditQuantity(value, row.original)}
                  value={row.original.quantity}
                  precision={1}
                  textAlign='center'
                  width='36px'
                  m={0}
                  step={1}
                  size='sm'
               />
            </Box>
         )
      },
      {
         Header: 'Remove',
         accessor: 'ts_id',
         Cell: ({ row }) => (
            <PopConfirm
               okText='Remove'
               onConfirm={(value) => handleEditQuantity(0, row.original)}
               okButtonProps={{ variantColor: 'error' }}
               cancelButtonProps={{ variantColor: 'gray' }}
               title='Are you sure you want to remove item from the trolley?'
            >
               <IconButton
                  variant='solid'
                  size='sm'
                  variantColor='error'
                  ml='5px'
               >
                  <FiTrash />
               </IconButton>
            </PopConfirm>
         )
      }
   ]

   return (
      <>
         <ScrollContainer>
            <Suspense fallback={<TableLoader />}>
               <Table
                  id={retailer}
                  fetch={fetch}
                  hidePagination={false}
                  size='sm'
                  columns={columns}
                  handleEditQuantity={handleEditQuantity}
                  retailer={retailer}
                  setup={{
                     endpoint: `/trolley/${retailer}/${branchId}`,
                     canSearch: false,
                     canDelete: true,
                     canSelect: true,
                     dataKey: 'products'
                  }}
               />
            </Suspense>
         </ScrollContainer>
      </>
   )
})

const SuspenseTrolley = () => {
   return (
      <ErrorBoundary>
         <Trolley />
      </ErrorBoundary>
   )
}

export default SuspenseTrolley

import React, { useState } from 'react'
import { useWatch, useFormContext } from 'react-hook-form'
import { Text, Button, Box, Spinner } from '@builtbypixel/plasma'
import { Select } from '@builtbypixel/plasma'
import { useAppRetailers } from '../../hooks'
import { useHttp } from '../../hooks'

const TestNotification = () => {
   const [testRetailers, setTestRetailers] = useState([])
   const { watch } = useFormContext()
   const Http = useHttp()
   const formFields = watch({})
   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);

   const formatRetailerLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.customer?.name}</span>{' '}
         {' - '} {option.branch?.name}
      </Text>
   )
   const handleSubmit = () => {

      setLoading(true);
      setSuccess(false);

      Http.post('/app-notification-test', {
         formFields,

         testRetailers: testRetailers
      }).then((res) => {
         setLoading(false);
         setSuccess(true);
      })
      .catch((err) => {
         setLoading(false);
      })
   }

   const formatFields = () => {
    
      return (
         Object.keys(formFields).map((field) => {
            
            const value = () => {
               switch (field) {
                  case 'name':
                     return (
                        <>
                           <Text mb='1' textTransform='capitalize'>Selected {field}</Text>
                           <Text mb='3'>{formFields[field]}</Text>
                        </>
                     )
                  break;
                  case 'description':
                     return (
                        <>
                           <Text mb='1' textTransform='capitalize'>Selected {field}</Text>
                           <Text mb='3'>{formFields[field]}</Text>
                        </>
                     )
                  break;
                  // case 'branches':
                  //    const branches = formFields?.branches;

                  //    return <Select
                  //       mb='3'
                  //       name={field}
                  //       isDisabled
                  //       value={branches}
                  //       options={branches}
                  //       labelKey='name'
                  //       valueKey='id'
                  //       isMulti
                  //    />
                  // break;
                  // case 'price_schemes':
                  //    const price_schemes = formFields?.price_schemes;

                  //    return <Select
                  //       mb='10'
                  //       name={field}
                  //       isDisabled
                  //       value={price_schemes}
                  //       options={price_schemes}
                  //       labelKey='scheme'
                  //       valueKey='id'
                  //       isMulti
                  //    />
                  // break;
                  case 'products':
                     const products = formFields?.products;

                     return (
                        <>
                           <Text mb='1' textTransform='capitalize'>Selected {field}</Text>
                           <Select
                              mb='10'
                              name={field}
                              isDisabled
                              value={products}
                              options={products}
                              labelKey='description'
                              valueKey='id'
                              isMulti
                           />
                        </>
                     )
                  break;
                  default:
                  break;
               }
            }
            
            return (
               <>
               <Box mb='5'>
                  {value()}
               </Box>
               </>
            )

         })
      )
   }

   return (
      <Box p='2rem'>
         {formatFields()}
         <Text mb='1' textTransform='capitalize'>Test Retailers</Text>
         <Select
            onChange={(e) => setTestRetailers(e)}
            name='retailers'
            placeholder='Retailer'
            isAsync
            loadOptions={useAppRetailers}
            labelKey='business_name'
            valueKey='id'
            formatOptionLabel={formatRetailerLabel}
            isMulti
            value={testRetailers}
         />

         {loading ? (
            <Spinner mt='10'/>
         ) : (
         <Button mt='10' onClick={() => handleSubmit()}>Send</Button>)}

         {success && (
            <Text color='green' mt='5'>Test Notification Sent Successfully</Text>
         )}
      </Box>
   )
}

export default TestNotification

import React from 'react'
import { Flex, Skeleton, Grid } from '@builtbypixel/plasma'
import Card from '../common/Card'

const StoreDevelopmentLoader = () => {
   return (
      <>
         <Grid
            isInline
            spacing='10px'
            templateColumns='repeat(12, 1fr)'
            gap='15px 15px'
         >

            <Card
               flex={2}
               title={<Skeleton
                  width='300px'
                  variant='rect'
                  h='25px'
                  mr='10px'
                  animation='pulse'
               />}
               gridColumn={{ xs: 'span 12', sm: 'span 6' }}
               justifyContent="center"
               align='center'

            >
               <Skeleton
                  width='100%'
                  variant='rect'
                  h='50px'
                  mr='10px'
                  animation='pulse'
               />
            </Card>
            <Card
               flex={2}
               title={<Skeleton
                  width='300px'
                  variant='rect'
                  h='25px'
                  mr='10px'
                  animation='pulse'
               />}
               gridColumn={{ xs: 'span 12', sm: 'span 6' }}
               justifyContent="center"
               align='center'
            >
               <Skeleton
                  width='100%'
                  variant='rect'
                  h='50px'
                  mr='10px'
                  animation='pulse'
               />
            </Card>
            <Card
               flex={2}
               title={<Skeleton
                  width='300px'
                  variant='rect'
                  h='25px'
                  mr='10px'
                  animation='pulse'
               />}
               gridColumn={{ xs: 'span 12', sm: 'span 6' }}
               justifyContent="center"
               align='center'
            >
               <Flex align='flex-end' h='300px' justify='stretch'>
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='300px'
                     mr='10px'
                     animation='pulse'
                  />

                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='100px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='50px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='300px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='200px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='90px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='140px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='100px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='270px'
                     mr='10px'
                     animation='pulse'
                  />
                  <Skeleton
                     width='100%'
                     variant='rect'
                     h='200px'
                     mr='10px'
                     animation='pulse'
                  />
               </Flex>
            </Card>
            <Card
               flex={2}
               title={<Skeleton
                  width='300px'
                  variant='rect'
                  h='25px'
                  mr='10px'
                  animation='pulse'
               />}
               gridColumn={{ xs: 'span 12', sm: 'span 6' }}
               justifyContent="center"
               align='center'
            >
               <Flex h='300px' justify='center' align='center'>
                  <Skeleton
                     width='100%'
                     variant="circle"
                     h='160px'
                     w='160px'
                     mr='10px'
                     animation='pulse'
                  />

               </Flex>
               <Skeleton
                  width='300px'
                  variant='rect'
                  h='25px'
                  m='0 auto'
                  animation='pulse'
               />
            </Card>
         </Grid>



      </>
   )
}

export default StoreDevelopmentLoader

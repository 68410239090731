import React from 'react'
import { Box } from '@builtbypixel/plasma'
import calendarState from '../utils/calendarState'
import { weekdays } from '../constants/dates'
import { useRecoilValue } from 'recoil'
import styled from '@emotion/styled'
const dayjs = require('dayjs')

const Day = styled(Box)`
   grid-column: ${(props) =>
      !props.showFirstColumn ? props.index + 1 : props.index + 2};
   grid-row: 1;
   font-weight: 600;
`

const WeekdayIndicator = ({ showFirstColumn }) => {
   const calendar = useRecoilValue(calendarState)

   const weekdayIcons = weekdays.map((day, key) => {
      return (
         <Day
            key={`weekday-${key}`}
            index={key}
            p='4px'
            showFirstColumn={showFirstColumn}
         >
            {day}
         </Day>
      )
   })

   return calendar.mode === 'day'
      ? dayjs(calendar.selectedDate).format('dddd')
      : weekdayIcons
}

export default WeekdayIndicator

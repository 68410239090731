export const theme = {
   colors: {
      primary: '#18191a',
      secondary: '#f1675c',
      calendar_hover_text: 'black',
      global: {
         bg: '#f0f2f5',
         lightBg: '#ffffff'
      },
      grayBg: '#edf2f7',
      darkBorder: 'rgba(0,0,0,0.5)',
      richText: {
         editorBg: '#ffffff'
      },
      parfettsRed: '#f1675c',
      parfettsRedSubtle: 'rgba(241,103,92,0.1)',
      parfettsOrange: '#f3b973',
      parfettsOrangeHighlight: '#fcebd7',
      parfettsGreen: '#85c96f',
      parfettsGreenSublte: 'rgba(133,201,111, 0.3)',
      parfettsGrayText: 'rgba(0,0,0,0.4)',
      parfettsBlueHighlight: '#E3F4FC',
      parfettsBlue: '#88c2ed',
      redLight: '#fde9e7',
      grayRow: 'rgba(0,0,0,0.05)',
      menuIconColour: 'rgba(0,0,0,0.4)',
      chartBlue: '#3182bd',
      chartPurple: '#7b68ee',
      tabs: {
         unSelectedBackground: 'rgba(0,0,0,0.06)',
         hoverBackground: 'rgba(0,0,0,0.1)'
      },
      calendar: {
         dayBg: 'rgba(0,0,0,0.03)'
      },
      modes: {
         dark: {
            primary: 'rgba(255,255,255,0.9)',
            secondary: '#f1675c',
            calendar_hover_text: 'black',
            parfettsRed: '#f1675c',
            parfettsOrangeHighlight: 'rgba(255,255,255,0.1)',
            parfettsGrayText: 'rgba(255,255,255,0.4)',
            parfettsBlueHighlight: '#76C9EE',
            darkBorder: 'rgba(255,255,255,0.5)',
            grayRow: 'rgba(255,255,255,0.1)',
            menuIconColour: 'rgba(255,255,255,0.4)',
            red: '#F5494A',
            global: {
               bg: '#0F0F12',
               lightBg: '#1A1A1E',
               elementBg: '#1A1A1E'
            },
            grayBg: '#45454D',
            richText: {
               editorBg: '#27272b'
            },
            tabs: {
               unSelectedBackground: 'rgba(0,0,0,0.3)',
               background: '#1a1a1e',
               hoverBackground: 'rgba(255,255,255,0.1)'
            },
            calendar: {
               dayBg: 'rgba(255,255,255,0.05)'
            }
         }
      }
   },
   fonts: {
      heading: `"Oxygen",-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      body: `"Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
   },
   shadows: {
      card: 'none'
   }
}

import React from 'react'
import {
    Box,
    useTheme,
} from '@builtbypixel/plasma'
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts'

const CompletedChart = ({ data }) => {

    const theme = useTheme()
    return (
        <Box>
            <ResponsiveContainer width='100%' height={300}>
                <BarChart height={300} data={data.current_year} margin={0}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='month' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey='current_year'
                        fill={theme.colors.parfettsOrange}
                        name="Current Year"
                    />
                    <Bar
                        dataKey='last_year'
                        fill={theme.colors.parfettsGreen}
                        name="Last Year" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default CompletedChart

import Developments from './'

const routes = [
   {
      path: '/report/execution',
      title: 'Report',
      exact: true,
      auth: true,
      component: Developments,
      permissions: ['Admin']
   }
]

export default routes

import React from 'react'
import { Flex, Icon } from '@builtbypixel/plasma'
import { RiSignalWifiErrorLine } from 'react-icons/ri'

class ErrorBoundary extends React.Component {
   state = { hasError: false, error: null }
   static getDerivedStateFromError(error) {
      return {
         hasError: true,
         error
      }
   }
   render() {
      if (this.state.hasError) {
         return (
            <Flex
               direction='column'
               w='100%'
               h='100%'
               {...this.props}
               align='center'
               justify='center'
               p='20px'
               opacity={0.5}
            >
               <Icon fontSize='22px' mb='10px'>
                  <RiSignalWifiErrorLine />
               </Icon>
               Error fetching data
            </Flex>
         )
      }
      return this.props.children
   }
}

export default ErrorBoundary

import React, { useEffect, useState } from 'react'
import {
   Box,
   Icon,
   Popover,
   PopoverBody,
   PopoverTrigger,
   PopoverContent,
   PseudoBox,
   Image,
   Text,
   Flex
} from '@builtbypixel/plasma'
import LinkButton from '../LinkButton'
import ToggleReadButton from '../../containers/Notifications/ToggleReadButton'
import { RiNotification3Line } from 'react-icons/ri'

const Notifications = ({ notifications }) => {
   const [unreadCount, setUnreadCount] = useState(0)

   useEffect(() => {
      if (notifications.length > 0) {
         const reduceUnread = notifications.reduce((acc, val) => {
            if (!val.read) {
               return acc + 1
            }
         }, 0)
         setUnreadCount(reduceUnread)
      }
   }, [notifications])

   return (
      <Box position='relative' mr='25px' mt='2px'>
         {notifications.length > 0 && (
            <Box
               rounded='full'
               size='20px'
               bg='red'
               position='absolute'
               top={0}
               right={-10}
               color='white'
               fontSize='13px'
               fontWeight='bold'
               textAlign='center'
               transition='all 0.3s ease'
               //   transform='scale(1)'
            >
               {unreadCount}
            </Box>
         )}
         <Popover size='sm'>
            <PopoverTrigger>
               <Icon
                  fontSize='26px'
                  mt='7px'
                  color={
                     notifications.length > 0 ? 'global.text' : 'blackAlpha.500'
                  }
               >
                  <RiNotification3Line />
               </Icon>
            </PopoverTrigger>

            <PopoverContent>
               <PopoverBody p={0}>
                  {notifications.length > 0 &&
                     notifications.map((notification, i) => (
                        <PseudoBox
                           _hover={{
                              bg: 'parfettsOrangeHighlight'
                           }}
                           key={`notification-${notification.id}`}
                           role='group'
                        >
                           <Flex
                              align='center'
                              borderColor='global.borderColour'
                              cursor='pointer'
                              p='8px'
                           >
                              <Box
                                 position='relative'
                                 overflow='hidden'
                                 width='2.5rem'
                                 height='2.5rem'
                              >
                                 <PseudoBox
                                    transition='transform 0.5s'
                                    overflow='hidden'
                                    _groupHover={{
                                       transform: 'translate(-50px, 0)',
                                       transition: 'transform 0.3s'
                                    }}
                                 >
                                    {notification?.created_by
                                       ?.profile_image?.[0]?.url ? (
                                       <Image
                                          src={
                                             notification?.created_by
                                                ?.profile_image?.[0]?.url
                                          }
                                          objectFit='cover'
                                          size='32px'
                                          rounded='full'
                                       />
                                    ) : (
                                       <Box
                                          p='6px'
                                          border='1px'
                                          borderColor='gray.200'
                                          borderRadius='5em'
                                       >
                                          <RiNotification3Line size='18px' />
                                       </Box>
                                    )}
                                 </PseudoBox>

                                 <PseudoBox
                                    position='absolute'
                                    top='0'
                                    left='0'
                                    transition='transform 0.3s'
                                    transform='translate(-50px, 0)'
                                    overflow='hidden'
                                    _groupHover={{
                                       transform: 'translate(0, 0)'
                                    }}
                                 >
                                    <ToggleReadButton
                                       size='md'
                                       notification={notification}
                                    />
                                 </PseudoBox>
                              </Box>
                              <LinkButton
                                 to={
                                    notification.type !== 'General' &&
                                    `/${notification.type}/edit/${notification.model_id}`
                                 }
                              >
                                 <Box pl='10px'>
                                    <Text
                                       fontSize='14px'
                                       color='primary'
                                       isTruncated
                                       maxWidth='250px'
                                    >
                                       {notification.message}
                                    </Text>
                                 </Box>
                              </LinkButton>
                           </Flex>
                        </PseudoBox>
                     ))}
               </PopoverBody>
            </PopoverContent>
         </Popover>
      </Box>
   )
}

export default Notifications

import React from 'react';
import { Select } from '@builtbypixel/plasma';

const SelectField = (props) => {
  const {
    setActive,
    onChange,
    onBlur,
    value,
    labelKey,
    valueKey,
    options,
    ...rest
  } = props;

  return (
    <Select
      onChange={(e) => onChange(e)}
      isFullWidth
      onBlur={() => {
        onBlur && onBlur();
        setActive && setActive(false);
      }}
      onFocus={() => setActive && setActive(true)}
      value={value}
      options={options}
      labelKey={labelKey}
      valueKey={valueKey}
      {...rest}
    />
  );
};

export default SelectField;

import React, { Suspense, useMemo, useEffect, useState } from 'react'
import {
   Text,
   Box,
   Flex,
   Button,
   Image,
   Modal,
   ModalButton,
   ModalContent
} from '@builtbypixel/plasma'
import { useRDAS, useRetailers } from '../../../hooks'
import { BiX } from 'react-icons/bi'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import BlockLoader from '../../../components/common/BlockLoader'
import ErrorBoundary from '../../../components/common/ErrorBoundary'
import Table from '../../../components/ListView'
import { useRecoilState } from 'recoil'
import { tableAtom } from '../../../state/global'
import dayjs from 'dayjs'

import ImageModal from '../components/ImageModal'

const LinesTable = ({ id }) => {
   const [noFetch, setNoFetch] = useState(true)
   const [tableState, setTableState] = useRecoilState(tableAtom)
   const [viewToday, setViewToday] = useState(false)

   useEffect(() => {
      setTableState((old) => ({
         ...old,
         params: {
            ...old.params,
            relations: {
               execution: id
            }
         }
      }))
      setNoFetch(false)
   }, [setTableState, id])

   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name
   const columns = [
      {
         Header: 'Retailer',
         accessor: 'retailer.business_name',
         Cell: ({ value, row }) => (
            <Box>
               <Text as='strong'>{value}</Text>
               <Text>
                  by {row.original.rda.first_name} {row.original.rda.last_name}
               </Text>
            </Box>
         )
      },
      {
         Header: 'Images',
         accessor: 'images',
         Cell: ({ value }) => (
            <Modal>
               <ModalButton>
                  <Flex position='relative' cursor='pointer' align='flex-end'>
                     {value.length > 0 &&
                        value
                           ?.slice(0, 4)
                           ?.map((image) => (
                              <Image src={image.url} w='64px' mx='5px' />
                           ))}
                     {value.length > 4 && <Text>...</Text>}
                  </Flex>
               </ModalButton>
               <ModalContent>
                  <ImageModal images={value} />
               </ModalContent>
            </Modal>
         )
      },
      {
         Header: 'Products',
         accessor: 'execution.products',
         Cell: ({ value }) =>
            value && value.length > 0 ? (
               value?.map((product) => (
                  <Text pr='2px'>{product.description}</Text>
               ))
            ) : (
               <Text>n/a</Text>
            )
      }
   ]

   const filters = useMemo(
      () => [
         {
            name: 'user_id',
            component: 'select',
            placeholder: 'Filter by RDA',
            isAsync: true,
            loadOptions: useRDAS,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatOptionLabel
         },
         {
            name: 'retailer_id',
            component: 'select',
            placeholder: 'Type to search retailers',
            isAsync: true,
            loadOptions: useRetailers,
            labelKey: 'business_name',
            valueKey: 'id'
         },
         {
            name: 'from',
            component: 'date',
            placeholder: 'From',
            group: 'created_at'
         },
         {
            name: 'to',
            component: 'date',
            placeholder: 'To',
            group: 'created_at'
         }
      ],
      [useRDAS, useRetailers]
   )

   useEffect(() => {
      setTableState((old) => ({
         ...old,
         params: {
            ...old.params,
            date_range: {
               created_at: {
                  from: viewToday ? dayjs() : null,
                  to: viewToday ? dayjs() : null
               }
            }
         }
      }))
   }, [viewToday])

   const extraMenu = () => (
      <Button
         variantColor={viewToday ? 'parfettsRed' : 'parfettsGreen'}
         color='white'
         leftIcon={viewToday && <BiX />}
         onClick={() => setViewToday(!viewToday)}
      >
         {viewToday ? 'Clear Date' : "Today's Executions"}
      </Button>
   )

   return (
      <Box>
         <ErrorBoundary>
            <Suspense fallback={<BlockLoader columns={1} />}>
               <Table
                  columns={columns}
                  size='sm'
                  filters={filters}
                  setup={{
                     endpoint: `/single-execution-line`,
                     canSearch: false,
                     canDelete: false
                  }}
                  extraMenu={extraMenu}
                  noInitialFetch={noFetch}
               />
            </Suspense>
         </ErrorBoundary>
      </Box>
   )
}

export default LinesTable

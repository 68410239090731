import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import {
   IconButton,
   Flex,
   Text,
   Image,
   Button,
   Box
} from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'
import { useRDAS } from '../../hooks'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return (
      <LinkButton to={`/${setup.model}/create`}>
         <Button variantColor='parfettsGreen' color='white'>
            New Milestone
         </Button>
      </LinkButton>
   )
}

const List = () => {
   const columns = [
      {
         Header: () => 'Business ID',
         accessor: 'store_number',
         Cell: ({ value }) => (
            <Link to={`/retailers/view/${value?.id}/invoices`}>
               <Text>{value?.id}</Text>
            </Link>
         )
      },
      {
         Header: () => 'Description',
         id: 'name',
         Cell: ({ row }) => (
            <Link to={`/milestones/edit/${row.original.id}`}>
               <Text>{row?.original?.name}</Text>
            </Link>
         )
      },
      {
         Header: 'Responsible',
         accessor: 'assigned_to',
         Cell: ({ value }) => (
            <Flex align='center'>
               <Image
                  src={value?.profile_image?.[0]?.url}
                  objectFit='cover'
                  size='30px'
                  rounded='full'
                  mr='10px'
               />
               <Text>
                  {value?.first_name} {value?.last_name}
               </Text>
            </Flex>
         )
      },
      {
         Header: 'Added By',
         accessor: 'created_by',
         Cell: ({ value, row }) => (
            <Flex align='center'>
               <Image
                  src={value?.profile_image?.[0]?.url}
                  objectFit='cover'
                  size='30px'
                  rounded='full'
                  mr='10px'
               />
               <Box minWidth='250px'>
                  <Text>
                     {value?.first_name} {value?.last_name}
                  </Text>
                  <Text color='gray.400'>
                     {dayjs(row?.original?.created_at).format('DD/MM/YYYY')}
                  </Text>
               </Box>
            </Flex>
         )
      },
      {
         Header: 'Due Date',
         accessor: 'due_date',
         Cell: ({ value }) => (
            <Text>{dayjs(value).format('DD/MM/YYYY HH:mm')}</Text>
         )
      },
      {
         Header: () => null,
         id: 'edit',
         Cell: ({ row }) => (
            <Link to={`/milestones/edit/${row.original.id}`}>
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </Link>
         )
      }
   ]
   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name

   const filters = useMemo(
      () => [
         {
            name: 'assigned_user_id',
            component: 'relation_select',
            placeholder: 'Filter by RDA',
            isAsync: true,
            loadOptions: useRDAS,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatOptionLabel
         },
         {
            name: 'from',
            component: 'date',
            placeholder: 'From'
         },
         {
            name: 'to',
            component: 'date',
            placeholder: 'To'
         }
      ],
      []
   )

   return (
      <ListWrapper
         setup={setup}
         columns={columns}
         filters={filters}
         extraMenu={extraMenu}
      />
   )
}

export default List

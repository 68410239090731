import React from 'react'
import { useFormContext } from 'react-hook-form'
import { TiWarningOutline } from 'react-icons/ti'
import {
   Flex,
   Alert,
   Text,
   Card,
   Box,
   Tag,
   Icon,
   ScrollContainer
} from '@builtbypixel/plasma'

const InfoBar = (props) => {
   const { errors } = useFormContext()
   return (
      <Flex
         direction='column'
         w={errors ? '100%' : 0}
         maxWidth='300px'
         flexGrow={0}
         bg='grayBg'
         h='inherit'
         p='10px'
         display={
            Object.keys(errors).length === 0
               ? 'none'
               : { xs: 'none', md: 'none', lg: 'block' }
         }
         position='sticky'
         right={0}
         top={0}
      >
         <ScrollContainer>
            {Object.keys(errors).length !== 0 && (
               <Card title='Field Errors' mb={10}>
                  <Box p='10px'>
                     <Alert variant='subtle' status='error' hasIcon={false}>
                        <Text pb='15px' fontSize='sm'>
                           There were errors in the following fields. Please
                           correct before continuing.
                        </Text>

                        {Object.values(errors) &&
                           Object.entries(errors).map(([key, value], index) => (
                              <React.Fragment
                                 key={`fragment-fieldError-${index}`}
                              >
                                 <Text
                                    fontWeight='semibold'
                                    key={`fieldError-${index}`}
                                    mb='8px'
                                 >
                                    <Tag
                                       variantColor='error'
                                       variant='solid'
                                       size='xs'
                                       mr='10px'
                                    >
                                       Required
                                    </Tag>
                                    {key}
                                 </Text>
                                 <Text>
                                    {Array.isArray(value) &&
                                       value.map((key, i) =>
                                          Object.keys(key).map((k, idx) => (
                                             <Flex
                                                key={`sub-field-${i}-${idx}`}
                                                align='center'
                                                pl='20px'
                                             >
                                                <Icon mr='5px'>
                                                   <TiWarningOutline />{' '}
                                                </Icon>
                                                <Text fontSize='12px'>
                                                   Item {i + 1} - {k}
                                                </Text>
                                             </Flex>
                                          ))
                                       )}
                                 </Text>
                              </React.Fragment>
                           ))}
                     </Alert>
                  </Box>
               </Card>
            )}
         </ScrollContainer>
      </Flex>
   )
}

export default InfoBar

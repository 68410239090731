import React, { useEffect, useState } from 'react'
import { IconButton } from '@builtbypixel/plasma'
import { usePost } from '../../hooks'
import { HiMail, HiOutlineMailOpen } from 'react-icons/hi'
import { mutate } from 'swr'

const ToggleReadButton = ({ notification, size }) => {
   const { loading, put, response } = usePost()
   const [isRead, setIsRead] = useState(false)

   useEffect(() => {
      if (notification) {
         setIsRead(notification.read)
      }
   }, [notification])
   const toggleRead = (id) => {
      put(`/notifications/${id}`, { read: !isRead })
   }
   useEffect(() => {
      if (response) {
         mutate('/notifications')
         mutate('/notifications-latest')
         setIsRead(!isRead)
      }
   }, [response])

   return (
      <IconButton
         variant='ghost'
         variantColor='gray'
         size={size}
         isRound
         isLoading={loading}
         onClick={() => toggleRead(notification.id)}
      >
         {isRead === false ? (
            <HiMail fontSize='1.5em' />
         ) : (
            <HiOutlineMailOpen fontSize='1.5em' />
         )}
      </IconButton>
   )
}

export default ToggleReadButton

import React from 'react'
import { Skeleton, Flex } from '@builtbypixel/plasma'

const BlockLoader = () => {
   return (
      <Flex align='flex-end' h='300px' justify='stretch'>
         <Skeleton
            width='100%'
            variant='rect'
            h='300px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='200px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='100px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='50px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='300px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='200px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='90px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='140px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='100px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='270px'
            mr='10px'
            animation='pulse'
         />
         <Skeleton
            width='100%'
            variant='rect'
            h='200px'
            mr='10px'
            animation='pulse'
         />
      </Flex>
   )
}

export default BlockLoader

import React, { useState } from 'react'
import {
   Flex,
   Card,
   Select,
   Box,
   PseudoBox,
   Icon,
   Text,
   Button
} from '@builtbypixel/plasma'
import { useHttp, useRetailersSearch } from '../../hooks'
import { VscGraph } from 'react-icons/vsc'
import LinkButton from '../../components/LinkButton'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const New = () => {
   const Http = useHttp();
   const [retailer, setRetailer] = useState(null);
   const [type, setType] = useState();
   const [auditTemplates, setAuditTemplates] = useState([]);
   const [supplierAuditTemplates, setSupplierAuditTemplates] = useState([]);
   const [checkAuditStatus, setCheckAuditStatus] = useState(null);

   const [glPeriodName, setGlPeriodName] = useState(null);
   const { retailerId } = useParams();

   useEffect(() => {
      Http.get('/current-go-local-period').then((res) => {
         setGlPeriodName(res.data.data.name)
      })
   }, [Http]);

   useEffect(() => {
      if (retailer && glPeriodName) {
         console.log(retailer, 'retailer');
         if(retailer?.groups?.length !== 0) {
            Http.post('/audit-templates', {
               filters: {
                  gl_period: glPeriodName,
                  scheme: { name: retailer?.groups?.[0]?.scheme }
               }
            }).then((res) => {
               setAuditTemplates(res.data.data);
               if(res.data.data.length === 0 && supplierAuditTemplates.length === 0) {
                  setCheckAuditStatus(true);
               } else {
                  setCheckAuditStatus(false);
               }
            })
         } else {
            setAuditTemplates([]);
         }
      }
   }, [Http, retailer, glPeriodName, supplierAuditTemplates]);

   useEffect(() => {
      if (retailer && glPeriodName) {
         Http.post('/audit-templates', {
            filters: {
               gl_period: glPeriodName,
               audit_template_type_id: 1
            }
         }).then((res) => {
            setTimeout(() => {
               setSupplierAuditTemplates(res.data.data)
            }, 500);
         })
      }
   }, [Http, glPeriodName, retailer]);

   useEffect(() => {
      if (retailerId) {
         Http.post(`/retailers`, { search: retailerId }).then((res) =>
            setRetailer(res.data.data[0])
         )
      }
   }, [retailerId]);

   return (
      <Flex align='center' justify='center'>
         <Card maxWidth='600px' title='New Audit'>
            <Box p='0 2rem 2rem'>
               <Box my='2rem'>
                  <Text fontWeight='bold' mb='0.5rem'>
                     Please select retailer (required)
                  </Text>
                  <Select
                     isAsync
                     size='lg'
                     loadOptions={useRetailersSearch}
                     placeholder='Type to search retailers...'
                     labelKey='business_name'
                     valueKey='id'
                     onChange={(value) => setRetailer(value)}
                     value={retailer}
                  />
               </Box>

               {auditTemplates.length > 0 && (
                  <>
                     <Flex w='100%' direction='column' my='25px'>
                        {auditTemplates?.map((template, i) => (
                           <LinkButton
                              to={`/audits/create/compliance/${retailer?.id}/${template.id}/${retailer?.branch.id}`}
                           >
                              <PseudoBox
                                 key={`type-${i}`}
                                 rounded='lg'
                                 border='2px'
                                 borderColor='darkBorder'
                                 display='flex'
                                 alignItems='center'
                                 justifyCenter='center'
                                 flexDirection='column'
                                 p='20px'
                                 w='100%'
                                 mb='20px'
                                 _hover={{
                                    borderColor: 'parfettsRed'
                                 }}
                                 cursor='pointer'
                                 onClick={() => setType(template.id)}
                                 bg={type === template.id && 'parfettsRed'}
                                 color={type === template.id && 'white'}
                              >
                                 <Icon
                                    fontSize='30px'
                                    mb='10px'
                                    color={
                                       type === template.id
                                          ? 'white'
                                          : 'parfettsRed'
                                    }
                                 >
                                    <VscGraph />
                                 </Icon>
                                 <Text fontWeight='semibold' fontSize='1.2rem'>
                                    {template.name}
                                 </Text>
                              </PseudoBox>
                           </LinkButton>
                        ))}
                     </Flex>
                  </>
               )}

               {supplierAuditTemplates.length > 0 && (
                  <>
                     <Flex w='100%' direction='column' my='25px'>
                        {supplierAuditTemplates.map((template, i) => (
                           <LinkButton
                              to={`/audits/create/supplier/${retailer?.id}/${template.id}/${retailer?.branch?.id}`}
                           >
                              <PseudoBox
                                 key={`type-${i}`}
                                 rounded='lg'
                                 border='2px'
                                 borderColor='darkBorder'
                                 display='flex'
                                 alignItems='center'
                                 justifyCenter='center'
                                 flexDirection='column'
                                 p='20px'
                                 w='100%'
                                 mb='20px'
                                 _hover={{
                                    borderColor: 'parfettsRed'
                                 }}
                                 cursor='pointer'
                                 // onClick={() => setType(template.id)}
                                 bg={type === template.id && 'parfettsRed'}
                                 color={type === template.id && 'white'}
                              >
                                 <Icon
                                    fontSize='30px'
                                    mb='10px'
                                    color={
                                       type === template.id
                                          ? 'white'
                                          : 'parfettsRed'
                                    }
                                 >
                                    <VscGraph />
                                 </Icon>
                                 <Text fontWeight='semibold' fontSize='1.2rem'>
                                    {template.name}
                                 </Text>
                              </PseudoBox>
                           </LinkButton>
                        ))}
                     </Flex>
                  </>
               )}

               {checkAuditStatus && (
                  <Flex w='100%' direction='column' my='25px'>
                     <Text fontSize={16} fontWeight="500">No audit templates are available for this retailer.</Text>
                  </Flex>
               )}

            </Box>
         </Card>
      </Flex>
   )
}

export default New

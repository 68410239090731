import React from 'react'
import { Text, Flex, Box } from '@builtbypixel/plasma'

const BlockData = (props) => {
   const { title, content, secondaryContent, thirdinaryContent } = props
   return (
      <Flex direction='column'>
         <Text color='parfettsGrayText' mb='5px'>
            {title}
         </Text>
         <Box fontWeight='semibold'>{content}</Box>
         {secondaryContent && (
            <Box fontWeight='semibold'>{secondaryContent}</Box>
         )}
         {thirdinaryContent && (
            <Box fontWeight='semibold'>{thirdinaryContent}</Box>
         )}
      </Flex>
   )
}

export default BlockData

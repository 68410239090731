import React, { useState } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import { useTheme, Stack, Box, Text, Checkbox } from '@builtbypixel/plasma'
import {
   BarChart,
   Bar,
   ResponsiveContainer,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend
} from 'recharts'

import dayjs from 'dayjs'
import renderToolTip from '../../components/common/ChartElements/RenderCurrencyToolTip'

const SalesFigures = ({ sop, mode, branch, rda, fascia }) => {
   const { data } = useSWR(
      `/sales-figures?view=${mode}${rda ? '&rda=' + rda : ''}${
         branch ? '&branch=' + branch : ''
      }${fascia ? '&fascia=' + fascia : ''}
      ${sop ? `&sop=${sop?.value}` : ``}
      `,
      useGet,
      {
         suspense: true
      }
   )
   const theme = useTheme()

   const [showPreviousTwoYear, setShowPreviousTwoYear] = useState(true)
   const [showPreviousYear, setShowPreviousYear] = useState(true)
   const [showCurrentYear, setShowCurrentYear] = useState(true)

   const today = dayjs()
   const currentYear = today.format('YYYY')
   const previousYear = today.subtract(1, 'year').format('YYYY')
   const previousTwoYear = today.subtract(2, 'year').format('YYYY')

   let currentTotal = data?.data?.currentYear

   let previousTotal = data?.data?.previousYear

   let previousPreviousTotal = data?.data?.previousTwoYear

   const AxisLabel = ({ axisType, x, y, width, height, stroke, children }) => {
      const isVert = axisType === 'yAxis'
      const cx = isVert ? x : x + width / 2
      const cy = isVert ? height / 2 + y : y + height + 10
      const rot = isVert ? `270 ${cx} ${cy}` : 0
      return (
         <text
            x={cx}
            y={cy}
            transform={`rotate(${rot})`}
            textAnchor='middle'
            stroke={stroke}
         >
            {children}
         </text>
      )
   }
   return (
      <>
         <Box px='15px' mb='20px' fontWeight='bold' fontSize='1.15rem'>
            <Stack spacing='15px' isInline p='5px' rounded='md' py='6px'>
               <Box color={theme.colors.parfettsBlue}>
                  <Checkbox
                     label={`Total ${previousTwoYear}: £${previousPreviousTotal?.toLocaleString()}`}
                     checked={showPreviousTwoYear}
                     onChange={(e) => setShowPreviousTwoYear(e.target.checked)}
                  />
               </Box>
               <Box color={theme.colors.parfettsGreen}>
                  <Checkbox
                     label={`Total ${previousYear}: £${previousTotal?.toLocaleString()}`}
                     checked={showPreviousYear}
                     onChange={(e) => setShowPreviousYear(e.target.checked)}
                  />
               </Box>
               <Box color={theme.colors.parfettsOrange}>
                  <Checkbox
                     label={`${currentYear} to date: £${currentTotal?.toLocaleString()}`}
                     checked={showCurrentYear}
                     onChange={(e) => setShowCurrentYear(e.target.checked)}
                  />
               </Box>
            </Stack>
         </Box>
         <Text ml='15px' color='#666666' fontSize='12px' mb-3px>
            x 1,000,000
         </Text>

         <ResponsiveContainer width='100%' height={300}>
            <BarChart height={300} data={data.data.periods}>
               <CartesianGrid strokeDasharray='3 3' />
               <XAxis dataKey='month' />
               <YAxis
                  tickFormatter={(tick) =>
                     '£' + (tick / 1000000).toLocaleString() + ' M'
                  }
               />
               <Tooltip content={renderToolTip} />
               <Legend />
               {showPreviousTwoYear && (
                  <Bar
                     dataKey={previousTwoYear}
                     fill={theme.colors.parfettsBlue}
                  />
               )}
               {showPreviousYear && (
                  <Bar
                     dataKey={previousYear}
                     fill={theme.colors.parfettsGreen}
                  />
               )}
               {showCurrentYear && (
                  <Bar
                     dataKey={currentYear}
                     fill={theme.colors.parfettsOrange}
                  />
               )}
            </BarChart>
         </ResponsiveContainer>
      </>
   )
}

export default SalesFigures

import { useHttp } from './useHttp'

const Http = useHttp()

export const useGet = (url, params) =>
   Http.get(url, { data: params }).then((res) => res.data)


export const useSWRPost = (url, params) =>
   Http.post(url,  params ).then((res) => res.data)

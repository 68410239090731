import React, { useEffect, useState } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { useHttp } from '../../hooks'
import { useFBPages } from '../../components/common/Facebook/useFBPages.jsx'
import { useRDAS } from '../../hooks'
import { useHasAdminPrivileges } from '../../hooks'

const EditForm = () => {
   const [retailerCategories, setRetailerCategories] = useState([])
   const [fascias, setFascias] = useState([])

   const pages = useFBPages()
   const Http = useHttp()
   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name

   useEffect(() => {
      Http.get(`/retailer-categories`).then((res) =>
         setRetailerCategories(res.data.data)
      )
      Http.get(`/fascias`).then((res) => setFascias(res.data.data))
   }, [Http])
   const isAdmin = useHasAdminPrivileges()

   const fields = [
      {
         group: 'Details',
         tab: 'Details',
         fields: [
            {
               name: 'attributes.user',
               component: 'select',
               placeholder: 'Search RDAs...',
               isAsync: true,
               label: 'RDA',
               loadOptions: useRDAS,
               labelKey: 'first_name',
               valueKey: 'id',
               formatOptionLabel: formatOptionLabel
            },
            {
               name: 'name',
               component: 'text',
               type: 'text',
               placeholder: 'Retailer name',
               label: 'Retailer',
               disabled: true,
               isHidden: true
            },
            {
               name: 'category',
               component: 'select',
               placeholder: 'Select categories',
               label: 'Category',
               isRequired: true,
               labelKey: 'business_category',
               valueKey: 'id',
               isMulti: true,
               options: retailerCategories
            },
            {
               name: 'attributes.max_spend_target',
               component: 'text',
               type: 'number',
               placeholder: '600',
               label: 'Green Spend Target',
               help: 'Maximum',
               disabled: !isAdmin
            },
            {
               name: 'attributes.spend_target',
               component: 'text',
               type: 'number',
               placeholder: '400',
               label: 'Amber Spend Target',
               help: 'Median',
               disabled: !isAdmin
            },
            {
               name: 'attributes.min_spend_target',
               component: 'text',
               type: 'number',
               placeholder: '200',
               label: 'Red Spend Target',
               help: 'Minimum',

               disabled: !isAdmin
            },
            {
               name: 'attributes.content.contract_start',
               component: 'date',
               placeholder: 'Contract Start',
               label: 'Contract Start'
            },
            {
               name: 'attributes.content.contract_renew',
               component: 'date',
               type: 'text',
               placeholder: 'Contract Renew',
               label: 'Contract Renew'
            },
            {
               name: 'attributes.content.contract_end',
               component: 'date',
               type: 'text',
               placeholder: 'Contract End',
               label: 'Contract End'
            },
            {
               name: 'attributes.content.loyalty_points',
               component: 'text',
               type: 'number',
               placeholder: 'Loyalty Points',
               label: 'Loyalty Points'
            },

            {
               name: 'attributes.content.fascia',
               component: 'select',
               placeholder: 'Fascia Type',
               label: 'Fascia Type',
               isRequired: true,
               labelKey: 'name',
               valueKey: 'id',
               options: fascias
            },
            {
               name: 'attributes.content.fascia.installation_date',
               component: 'date',
               placeholder: 'Installation Date',
               label: 'Installation Date'
            },
            {
               name: 'attributes.photo_id',
               component: 'file',
               placeholder: 'Photo ID',
               label: 'Photo ID'
            }
         ]
      },
      {
         group: 'Preferences',
         tab: 'Preferences',
         fields: [
             {
               name: 'attributes.email_marketing',
               component: 'switch',
               placeholder: 'Email Marketing',
               label: 'Email Marketing'
             },
             {
                 name: 'attributes.sms_marketing',
                 component: 'switch',
                 placeholder: 'SMS Marketing',
                 label: 'SMS Marketing'
             },
             {
                 name: 'attributes.mail_marketing',
                 component: 'switch',
                 placeholder: 'Mail Marketing',
                 label: 'Mail Marketing'
             }
         ]
      },
      {
         group: 'Facebook',
         tab: 'Facebook',
         fields: [
            {
               name: 'attributes.facebook',
               component: 'facebookSelect',
               placeholder: 'Select Facebook page',
               label: 'Facebook page',
               labelKey: 'name',
               valueKey: 'id',
               options: pages
            }
         ]
      },
      {
         group: 'Other Social Media',
         tab: 'Other Social Media',
         fields: [
            {
               name: 'social',
               component: 'repeater',
               placeholder: 'Other platforms',
               label: 'Other platforms',
               isRequired: false,
               fieldArray: [
                  {
                     name: 'platform',
                     component: 'select',
                     placeholder: 'Select platform',
                     label: 'Platform',
                     isRequired: true,
                     labelKey: 'name',
                     valueKey: 'id',
                     options: [
                        {
                           id: 1,
                           name: 'Twitter'
                        },
                        {
                           id: 2,
                           name: 'Instagram'
                        },
                        {
                           id: 3,
                           name: 'Other'
                        }
                     ]
                  },
                  {
                     name: 'name',
                     component: 'text',
                     placeholder: 'Enter name of social profile',
                     label: 'Profile Name',
                     rules: { required: true }
                  },
                  {
                     name: 'url',
                     component: 'text',
                     placeholder: 'https://...',
                     label: 'Link',
                     rules: { required: true }
                  }
               ]
            }
         ]
      }
   ]
   return (
      <EditView fields={fields} setup={setup} noPadding redirectBackOnSave>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

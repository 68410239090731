import React, { useState } from 'react'
import { Box } from '@builtbypixel/plasma'

import ParfettsAccordionButton from './ParfettsAccordionButton'
import ParfettsAccordionPanel from './ParfettsAccordionPanel'
import TodoItem from '../../../../../components/TaskItem'

const ParfettsAccordion = ({ day, tasks, getTasks }) => {
   const [open, setOpen] = useState(true)

   return (
      <Box my='1rem' key={day}>
         <ParfettsAccordionButton
            text={day}
            tasks={tasks}
            open={open}
            setOpen={setOpen}
         />
         <ParfettsAccordionPanel px={0} open={open}>
            {tasks &&
               tasks.map((task, i) => (
                  <TodoItem
                     item={task}
                     key={`tasks-${i}`}
                     index={i}
                     short
                     successCallback={getTasks}
                  />
               ))}
         </ParfettsAccordionPanel>
      </Box>
   )
}

export default ParfettsAccordion

import React, { useEffect, useState, memo } from 'react'
import {
   Flex,
   Stack,
   Text,
   IconButton,
   Tooltip,
   Icon,
   useColorMode
} from '@builtbypixel/plasma'
import { RiMenuUnfoldLine } from 'react-icons/ri'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { FiSun, FiMoon } from 'react-icons/fi'
import { globalAtom, menuAtom } from '../../state/global'
import AccountDropdown from './AccountDropdown'
import Notifications from './Notifications'
import dayjs from 'dayjs'
import Pusher from 'pusher-js'
import useSWR, { mutate } from 'swr'
import { useGet } from '../../hooks'
import { notificationsAtom } from '../../state/global'
import { useRecoilState } from 'recoil'

const pusher = new Pusher(process.env.REACT_APP_KEY, {
   cluster: process.env.REACT_APP_CLUSTER
})

var channel = pusher.subscribe('parfetts-crm')

const HeaderBar = memo(() => {
   const { user } = useRecoilValue(globalAtom)
   const setMenuState = useSetRecoilState(menuAtom)
   const { colorMode, toggleColorMode } = useColorMode()
   const [notifications, setNotifications] = useRecoilState(notificationsAtom)

   const [dbNotifications, setDbNotifications] = useState([])
   const { data } = useSWR('/notifications-latest', useGet)
   const { data: allNotifications } = useSWR(`/notifications`, useGet)

   useEffect(() => {
      if (data) {
         setDbNotifications(data.data)
      }
   }, [data, setDbNotifications])

   useEffect(() => {
      if (user?.id) {
         channel.bind(`notifications-${user.id}`, function (data) {
            mutate('/notifications-latest')
         })
      }
      channel.bind('notifications', function (data) {
         mutate('/notifications-latest')
         mutate('/notifications')
      })
   }, [user, channel])

   useEffect(() => {
      if (allNotifications) {
         setNotifications(allNotifications)
      }
   }, [allNotifications])
   return (
      <Flex
         bg='global.elementBg'
         w='100%'
         h='60px'
         borderBottom='1px'
         borderColor='global.borderColour'
         align='center'
         px='20px'
         pr='35px'
         position='sticky'
         top={0}
      >
         <Icon
            fontSize='24px'
            variant='ghost'
            rounded='md'
            onClick={() => setMenuState({ menu: true })}
            display={{ xs: 'inline-block', md: 'none' }}
         >
            <RiMenuUnfoldLine />
         </Icon>
         <Text fontSize='16px' display={{ xs: 'none', md: 'inline-block' }}>
            {dayjs().format('dddd MMMM DD YYYY')}
         </Text>
         <Stack ml='auto' isInline spacing='25px' align='center'>
            <IconButton
               rounded='full'
               size='lg'
               variant='ghost'
               onClick={() => toggleColorMode()}
            >
               <Tooltip
                  title={colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
                  placement='right'
               >
                  {colorMode === 'dark' ? (
                     <FiSun fontSize={20} />
                  ) : (
                     <FiMoon fontSize={20} />
                  )}
               </Tooltip>
            </IconButton>
            <Notifications notifications={dbNotifications} />

            <Text
               flexShrink={0}
               fontWeight='semibold'
               display={{ xs: 'none', md: 'inline-block' }}
            >
               Hello {user?.first_name}
            </Text>
            <AccountDropdown user={user} />
         </Stack>
      </Flex>
   )
})

export default HeaderBar

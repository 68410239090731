import React, { Suspense, useEffect, useState } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Grid,
   Box,
   Flex,
   Button
} from '@builtbypixel/plasma'
import { FiPlus } from 'react-icons/fi'
import StoreDevelopmentLoader from '../../components/StoreDevelopmentLoader'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import { usePost } from '../../hooks'
import Card from '../../components/common/Card'
import LinkButton from '../../components/LinkButton'
import CompletedChart from './Charts/Completed'
import UpcomingChart from './Charts/Upcoming'


const Comp = () => {
   const [data, setData] = useState(null);
   const [rda, setRDA] = useState(null);
   const { post, response } = usePost()


   useEffect(() => {
      post(`/developments`,
         { filters: { user_id: rda } }
      )
   }, [])
   useEffect(() => {
      if (response) {
         setData(response.data)
      }
   }, [response])
   return (
      <Grid
         isInline
         spacing='10px'
         templateColumns='repeat(12, 1fr)'
         gap='15px 15px'
      >
         <Card
            flex={2}
            title={`Total Completed Developments: ${data?.total}`}
            gridColumn={{ xs: 'span 12', sm: 'span 6' }}
            justifyContent="center"
            align='center'

         >

            <LinkButton to='/developments/list/1'>
               <Button
                  variant='solid'
                  color='white'
                  variantColor='parfettsGreen'
                  size='lg'
               >
                  View all completed
               </Button>
            </LinkButton>
         </Card>
         <Card
            flex={1}
            title={`Total Ongoing Developments: ${data?.ongoing}`}
            gridColumn={{ xs: 'span 12', sm: 'span 6' }}
         >

            <LinkButton to='/developments/list/0'>
               <Button
                  variant='solid'
                  color='white'
                  variantColor='parfettsGreen'
                  size='lg'
                  mb='10px'
               >
                  View all ongoing
         </Button>
            </LinkButton>
         </Card>
         {data?.completed &&
            <Card
               flex={1}
               title='Completed developments this year vs last'
               gridColumn={{ xs: 'span 12', md: 'span 6' }}
            >

               <CompletedChart data={data?.completed} />

            </Card>
         }
         {data?.fascias &&
            <Card
               flex={1}
               title='Fascias'
               gridColumn={{ xs: 'span 12', md: 'span 6' }}
            >

               <UpcomingChart data={data?.fascias} />

            </Card>
         }
      </Grid>
   )
}

const StoreDevelopments = () => {
   return (
      <Box>
         <Flex justifyContent='space-between'>
            <Box w='100%' mb='10px'>
               <ViewTitle title='Store Developments' />
            </Box>
            <Flex>
               <LinkButton to='/developments/create'>
                  <Button
                     variant='solid'
                     variantColor='parfettsRed'
                     color='white'
                     leftIcon={<FiPlus />}
                     rounded='md'
                  >
                     Add New
                  </Button>
               </LinkButton>

            </Flex>
         </Flex>

         <ErrorBoundary>
            <Suspense fallback={<StoreDevelopmentLoader />}>
               <Comp />
            </Suspense>

         </ErrorBoundary>

      </Box>
   )
}

export default StoreDevelopments

import React, { useEffect, useState } from 'react'
import {
   Flex,
   Box,
   Button,
   Icon
} from '@builtbypixel/plasma'
import FolderMenu from './FolderMenu'
import { usePost } from '../../hooks'
import ViewTitle from '../../components/common/ViewTitle'
import { ImUndo2 } from 'react-icons/im'

import List from './LibraryList'
import FolderNavigation from './FolderNavigation'
const MediaLibrary = ({ retailer, hideTitle, selectedCallback }) => {
   const [data, setData] = useState(null)
   const [localReatiler, setLocalRetailer] = useState()
   const [selectedFolder, setSelectedFolder] = useState({ parent: { id: null } })
   const [navigation, setNavigation] = useState([]);
   const { post, response } = usePost()



   useEffect(() => {
      if (retailer) {
         setLocalRetailer(retailer)
      }
   }, [retailer, setLocalRetailer])

   useEffect(() => {
      if (localReatiler) {
         refreshData()
      }
   }, [localReatiler])

   const refreshData = () => {
      post('/media-library/folders',
         {
            filters: {
               parent_id: null
            },
            relations: {
               media_retailer: localReatiler?.id
            }
         })
   }

   useEffect(() => {
      if (response) {
         setData(response)
         let currentPage = {};
         let whatILookFor = response.data.data;
         if (navigation.length > 0) {
            for (let index = navigation.length - 1; index >= 0; index--) {
               const element = navigation[index];
               currentPage = whatILookFor.filter((object) => (object.id === element.id));
               whatILookFor = currentPage?.[0]?.child;
            }
         }
         if (Array.isArray(currentPage)) {
            setSelectedFolder(currentPage[0])
         }
      }
   }, [response])





   return (
      <>
         <Flex
            w='100%'
            p='10px 20px'
            bg='global.elementBg'
            borderBottom='1px'
            aign='center'
            borderColor='global.borderColour'
            justify='space-between'
            alignItems='centre'
         >

            <ViewTitle title={localReatiler?.name ? `${retailer.name}'s folders` : 'Media Library'} />

            {localReatiler?.name &&
               <Button variant='ghost'
                  mr='10px'
                  onClick={() => setLocalRetailer({ id: null })} cursor='pointer'>View all folders</Button>
            }
         </Flex>

         <Flex w='100%' h='100%'>
            <Box
               w='250px'
               h='100%'
               bg='global.elementBg'
               borderRight='1px'
               borderColor='global.borderColour'
            >  {data &&
               <FolderMenu
                  data={data.data}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  setNavigation={setNavigation}
                  navigation={navigation}
               />
               }
            </Box>
            <Box w='100%' h='100%' overflow='auto'>


               <FolderNavigation
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  navigation={navigation}
                  setNavigation={setNavigation}
                  refreshData={refreshData}
                  retailer={retailer}
               />
               <List selectedFolder={selectedFolder} retailer={retailer} retailerView={hideTitle} selectedCallback={selectedCallback} />


            </Box>
         </Flex>
      </>
   )
}

export default MediaLibrary

import React, { useEffect } from 'react'
import { Button, PopConfirm, useToast } from '@builtbypixel/plasma'

import { FiCheckCircle } from 'react-icons/fi'
import { usePost } from '../../hooks'

const InStoreNow = ({ id }) => {
   const { post, loading, response } = usePost()
   const toast = useToast()

   const handleInStoreNow = () => {
      post('/last-visited', {
         type: 'Customer Lead',
         customer_lead: {
            id: id
         }
      })
   }
   useEffect(() => {
      if (response) {
         toast({
            title: 'Visit Saved',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [response])

   return (
      <Button
         variant='solid'
         variantColor='parfettsGreen'
         color='white'
         leftIcon={<FiCheckCircle />}
         rounded='md'
         loading={loading}
         onClick={() => handleInStoreNow()}
         mr='10px'
      >
         In Store Now
      </Button>
   )
}

export default InStoreNow

import React, { useEffect, useState } from 'react'
import EditView from '../../components/EditView'
import { useHttp } from '../../hooks'
import setup from './setup'

import CreateTemplateForm from './components/CreateTemplateForm'
import { Box, Flex } from '@builtbypixel/plasma'
import TemplatePreview from './components/TemplateComponents/TemplatePreview'

const Http = useHttp()

const EditForm = () => {
   const [promotions, setPromotions] = useState([])

   useEffect(() => {
      const fetch = async () => {
         const res = await Http.get('all-price-promotions')
         setPromotions(res.data.data)
      }
      fetch()
   }, [])

   return (
      <>
         <EditView setup={setup} noPadding>
            {/* <AutoFields fields={fields} /> */}

            <Box w='100%'>
               <Flex style={{ justifyContent: 'space-around' }}>
                  <CreateTemplateForm />

                  <TemplatePreview />
               </Flex>
            </Box>

            {/* 
            <Field
               component='select'
               name='retailer'
               isAsync
               placeholder='Search by Name'
               label='Retailer'
               loadOptions={useRetailers}
               labelKey='business_name'
               valueKey='id'
               size='lg'
            /> */}
         </EditView>
      </>
   )
}

export default EditForm

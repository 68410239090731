import { Box, Flex, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import Title from './Title'

import { Field } from '../../../../components/Form'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus } from 'react-icons/fa'
import VariablesPanel from './VariablesPanel'

const TemplateFooter = () => {
   const { setValue, register, watch, control, getValues } = useFormContext()
   const content = useWatch({ name: 'content[2]' })
   const { type } = useParams()

   return (
      <Flex gap={10} w='100%' direction='column'>
         <Title text='Footer Details' />
         <Flex w='100%' direction='column'>
            <Box width='100%'>
               <Field
                  component='text'
                  name='content[2].text'
                  placeholder='Enter Footer Text (Max 60 Characters)'
                  maxLength={60}
                  help={`${content?.text ? content?.text?.length : 0} / 60`}
               />
            </Box>
         </Flex>
      </Flex>
   )
}

export default TemplateFooter

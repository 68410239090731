import React, {useEffect, useState} from 'react'
import { Field } from '../../../components/Form'
import { useHttp } from '../../../hooks'

const BranchSelect = () => {
    const Http = useHttp()
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        Http.get(`/branches`).then((res) =>
           setBranches(res.data.data)
        )
     }, [setBranches, Http])

    return (
        <Field
            name='branches'
            help='Please ignore this option if you wish to select all branches'
            component='select'
            placeholder='Branch'
            label='Branch'
            options={branches}
            labelKey='name'
            valueKey='id'
            isMulti={true}
        />
    )
}

export default BranchSelect

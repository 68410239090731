const modelName = 'Visit Types'
const modelNameSingular = 'Visit Type'
const endpoint = '/visit-types'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: true,
   canAddNew: true,
   canSearch: true,
   endpoint,
   accessor: 'id',
   permissions: ['Admin','RDA']
}

export default setup

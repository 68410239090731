import React, { useEffect, useState } from 'react'
import { Select, Box, Table, Text } from '@builtbypixel/plasma'
import dayjs from 'dayjs'


const Product = ({ options, branches }) => {
    const [currentPack, setCurrentPack] = useState(options?.[0])

    useEffect(() => {
        setCurrentPack(options?.[0])
    }, [options])


    const columns = [

        {
            Header: 'Branch',
            accessor: 'branch_id',
            Cell: ({ value }) => {
                const branchName = branches?.find(branch => branch.id === value)

                return (
                    <Text>{branchName?.name ? branchName.name : "Branch"}</Text>
                )
            }
        },
        {
            Header: 'Price Scheme',
            accessor: 'price_scheme'
        },
        {
            Header: 'Price',
            accessor: 'price'
        }, {
            Header: 'Standard Price',
            accessor: 'standard_price'
        },
        {
            Header: 'RRP',
            accessor: 'rrp'
        },
        {
            Header: 'Prom ID',
            accessor: 'prom_id'
        },
        {
            Header: 'Promo End Date',
            accessor: 'promo_end_date',
            Cell: ({ value }) => (dayjs(value).format("DD/MM/YYYY"))
        },
    ]
    return (
        <Box>
            <Box mb="15px">
                <Select
                    placeholder="Select pack"
                    value={currentPack}
                    labelKey="pack"
                    idKey="id"
                    defaultValue={options?.[0]}
                    onChange={(val) => setCurrentPack(val)}
                    options={options}
                />
            </Box>

            { currentPack?.price &&
                <Table
                    columns={columns}
                    data={{ data: currentPack?.price }}

                    size='sm'
                />
            }

        </Box>
    )
}

export default Product

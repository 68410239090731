import React, { useEffect, useState } from 'react'
import { Field } from '../../../../components/Form'
import { Box } from 'victory'
import { Button, Flex, Grid, Input, Text } from '@builtbypixel/plasma'
import { FaMinus } from 'react-icons/fa'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import cloneDeep from 'lodash/cloneDeep'
const VariablesRepeater = ({ source = 'body', repeaterFuncs }) => {
   // const [showVariablesPanel, setShowVariablesPanel] = useState(false);
   const { setValue, register, watch, control, getValues } = useFormContext()
   const { fields, append, prepend, remove, swap, move, insert, replace } =
      repeaterFuncs || {}

   const allFields = useWatch({ control })
   const bodyText = allFields?.content?.[1]?.text
   const variablesBody = allFields?.content?.[1]?.variables

   //  useEffect(() => {
   //     setVariables(variablesBody)
   //  }, [variablesBody])

   const handleRemove = (index) => {
      //remove index from fieldarray

      //  <TODO>
      // Fix -> random spacing when var is is inserted after deleting one
      // </TODO>
      remove(index)
      let _body = allFields?.content?.[1]
      let _variablesBody = [...variablesBody]

      //remove var from index
      _body.variables = _variablesBody.filter((item, i) => i !== index)

      // set the new vars

      _body.text = bodyText.replaceAll(`{${index + 1}}`, '')

      //update indexes after the one that was removed

      for (let key in _body.variables) {
         if (Number(key) + 1 > index) {
            let text = _body.text.replaceAll(
               `{${Number(key) + 2}}`,
               `{${Number(key) + 1}}`
            )

            // console.log(
            //    index,
            //    'i ------> K',
            //    {
            //       key,
            //       keyadd1: Number(key) + 1,
            //       textLooking: `{${Number(key) + 2}}`,
            //       textREplace: `{${Number(key) + 1}}`,
            //       finalText: text
            //    },
            //    'azhar'
            // )
            _body.text = text
         }
      }

      // console.log(_body, 'asknfnsfnksnfsnk')

      setValue('content[1]', _body)
   }

   return (
      <Flex bg='white' direction='column' w='100%' h='100%' m='1em' p='1.5em'>
         {fields?.map((item, index) => {
            return (
               <Flex align='center' key={item.id}>
                  <Input
                     bg='#075E54'
                     fontWeight='bold'
                     color='white'
                     value={`{${index + 1}}`}
                     textAlign='center'
                     mr='1em'
                     mb='0.4em'
                     width='100px'
                  />
                  <Field
                     name={`content[1].variables[${index}]`}
                     component='text'
                     placeholder={`Enter variable for {${index + 1}}`}
                     width='400px'
                  />
                  <div
                     style={{ cursor: 'pointer' }}
                     onClick={() => handleRemove(index)}
                  >
                     <FaMinus />
                  </div>
               </Flex>
            )
         })}
         <section>
            <Button
               type='button'
               onClick={() => {
                  append('')
                  setValue(
                     'content[1].text',
                     `${getValues('content[1].text')} {${fields.length + 1}}`
                  )
               }}
            >
               Add Item
            </Button>
         </section>
      </Flex>
   )
}

export default VariablesRepeater

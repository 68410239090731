import React, { useEffect, useState } from 'react'
import { Text, Box, Flex, Icon, PseudoBox } from '@builtbypixel/plasma'
import { FiChevronDown } from 'react-icons/fi'

const ParfettsAccordionButton = ({ text, open, tasks, setOpen }) => {
   const [todoCount, setToDoCount] = useState(false)
   useEffect(() => {
      if (tasks) {
         let tmp = tasks.filter((item) => item.complete === false)
         setToDoCount(tmp.length)
      }
   }, [tasks])
   return (
      <PseudoBox
         _hover={{
            cursor: 'pointer'
         }}
         onClick={() => setOpen(!open)}
      >
         <Flex alignItems='center' marginTop='5px'>
            {todoCount > 0 && (
               <Box
                  rounded='full'
                  size='24px'
                  bg='parfettsGreen'
                  mr='2'
                  color='white'
                  fontSize='14px'
                  fontWeight='bold'
                  textAlign='center'
               >
                  {todoCount}
               </Box>
            )}

            <Box position='relative'>
               <Text fontWeight='bold' color='gray.500'>
                  {text}
               </Text>
            </Box>

            <Icon fontSize={16}>
               <Box
                  transform={!open && 'rotate(-90deg)'}
                  transition='transform 0.15s ease-out'
                  ml='2'
               >
                  <FiChevronDown color='gray' />
               </Box>
            </Icon>
         </Flex>
      </PseudoBox>
   )
}

export default ParfettsAccordionButton

const modelName = 'Task Categories'
const modelNameSingular = 'Task Category'
const endpoint = '/task-categories'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: true,
   canAddNew: true,
   canSearch: true,
   endpoint,
   accessor: 'id',
   permissions: ['Admin','RDA']
}

export default setup

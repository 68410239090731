import React from 'react'
import { Flex, Text } from '@builtbypixel/plasma'

const TotalsBlock = ({ label, value, icon }) => {
   return (
      <Flex
         mx='5px'
         bg='grayBg'
         flex={1}
         rounded='lg'
         align='center'
         justify='center'
         p='20px'
         direction='column'
      >
         <Text>{label}</Text>
         {icon}
         {value && (
            <Text fontWeight='bold' fontSize='1.5rem'>
               {value}
            </Text>
         )}
      </Flex>
   )
}

export default TotalsBlock

import React from 'react'
import { Flex } from '@builtbypixel/plasma'

import NavBackButton from '../components/NavBackButton'
import NavComponent from '../components/NavComponent'

/**
 * The navigation bar to navigate between the selectable options
 * @param {object} selectedGoLocalPeriod The selected GoLocalPeriod object
 * @param {function} setSelectedGoLocalPeriod The function to be called to make changes to the selectedGoLocalPeriod
 * @param {object} selectedExecution The selected Execution object
 * @param {function} setSelectedExecution The function to be called to make changes to the selectedExecution
 */
const NavBar = ({
   selectedGoLocalPeriod,
   setSelectedGoLocalPeriod,
   selectedExecution,
   setSelectedExecution
}) => {
   const handleBack = () =>
      selectedExecution
         ? setSelectedExecution(null)
         : setSelectedGoLocalPeriod(null)

   return selectedGoLocalPeriod ? (
      <Flex align='center'>
         <NavBackButton onClick={handleBack} />
         <NavComponent
            item={selectedGoLocalPeriod}
            color='parfettsGreen'
            onClick={() => setSelectedExecution(null)}
            text={selectedGoLocalPeriod?.price_promotion?.name}
         />
         <NavComponent
            item={selectedExecution}
            color='parfettsOrange'
            text={selectedExecution?.brand?.name}
         />
      </Flex>
   ) : null
}

export default NavBar

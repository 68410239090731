import React, { memo, useState, useEffect } from 'react'
import { Box, Button, Icon } from '@builtbypixel/plasma'
import useFBSDK from '../../../hooks/useFBSDK'
import useLoadFBApp from '../../../hooks/useLoadFBApp'
import { useRecoilState } from 'recoil'
import { facebookUserAtom } from '../../../state/facebook.js'

import { BiLogIn, BiLogOut } from 'react-icons/bi'

const LoginButton = memo(() => {
   const [fbUser, setFbUser] = useRecoilState(facebookUserAtom)
   const [windowLoaded, setWindowLoaded] = useState(false)
   const [loading, setLoading] = useState(false)
   useFBSDK()
   useEffect(() => {
      //init Facebook SDK

      useLoadFBApp()
      return () => {
         // unmount
      }
   }, [])

   useEffect(() => {
      window.addEventListener('load', function () {
         console.log('window loade')
         setWindowLoaded(true)
      })
      return () => {
         // unmount
      }
   }, [])
   useEffect(() => {
      if (windowLoaded) {
         setLoading(true)
         window.FB.getLoginStatus(function (response) {
            setLoading(false)
            if (response?.authResponse) {
               console.log('window has loaded')
               setFbUser(response.authResponse)
            }
         })
      }
      return () => {
         // unmount
      }
   }, [windowLoaded])

   const handleFBLogin = () => {
      setLoading(true)
      window.FB.login(
         function (response) {
            setLoading(false)
            if (response?.authResponse) {
               setFbUser(response.authResponse)
            }
         },
         {
            scope:
               'pages_show_list,email,pages_manage_posts,pages_read_user_content,publish_to_groups'
         }
      )
   }

   const handleFBLogout = () => {
      setLoading(true)
      window.FB.logout(function (response) {
         setLoading(false)
         // user is now logged out
         if (response && !response.error) {
            setFbUser({ userID: false })
         }
      })
   }

   return (
      <Box>
         {fbUser?.userID ? (
            <Button
               onClick={handleFBLogout}
               leftIcon={
                  <Icon color='gray.500'>
                     <BiLogOut fontSize='20px' />
                  </Icon>
               }
               isFullWidth
               variant='ghost'
               align='left'
               size='lg'
               isLoading={loading}
            >
               Logout of Facebook
            </Button>
         ) : (
            <Button
               onClick={handleFBLogin}
               leftIcon={
                  <Icon color='gray.500'>
                     <BiLogIn fontSize='20px' />
                  </Icon>
               }
               isFullWidth
               variant='ghost'
               align='left'
               size='lg'
               isLoading={loading}
            >
               Connect to Facebook for social features
            </Button>
         )}
      </Box>
   )
})

export default LoginButton

import React, { useMemo, useState, useEffect, useRef } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { useHttp, useRetailers } from '../../hooks'

import {
   Box,
   IconButton,
   Flex,
   Button,
   Text,
   useToast,
   Progress
} from '@builtbypixel/plasma'

import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import setup from './setup'

import { useRecoilState } from 'recoil'
import { tableAtom } from '../../state/global'
import { useHasAdminPrivileges } from '../../hooks'
import moment from 'moment'

const columns = [
   {
      Header: 'Notification',
      accessor: 'notification',
      Cell: ({ row }) => (
         row.original?.notification?.name
      )
   },
   {
      Header: 'Retailer',
      accessor: 'customer',
      Cell: ({ row }) => (
         row?.original?.customer ? row.original?.customer?.id : 'N/A'
      )
   },
   {
      Header: 'Branch',
      accessor: 'branch',
      Cell: ({ row }) => (
         row.original?.branch?.name
      )
   },
   {
      Header: 'Scheme',
      accessor: 'price_scheme',
      Cell: ({ row }) => (
         row.original?.price_scheme ? row.original?.price_scheme?.scheme : 'N/A'
      )
   },
   {
      Header: 'Ordering',
      accessor: 'device_id',
      Cell: ({ row }) => (
         row.original?.customer?.is_ordering == 1 ? <Text>Yes</Text> : <Text>No</Text>
      )
   },
   {
      Header: 'Delivered',
      accessor: 'delivered',
      Cell: ({ row }) => (
         row.original?.delivered == 1 ? <Box radius="100" bg='#85c96f' size='4' rounded={['full']}/> : <Box radius="100" bg='#f1675c' size='4' rounded={['full']}/>
      )
   },
   {
      Header: 'Opened',
      accessor: 'opened',
      Cell: ({ row }) => (
         row.original?.opened == 1 ? <Box radius="100" bg='#85c96f' size='4' rounded={['full']}/> : <Box radius="100" bg='#f1675c' size='4' rounded={['full']}/>
      )
   },
   {
      Header: 'Date Sent',
      accessor: 'created_at',
      Cell: ({ row }) => moment(row.original?.created_at).format('LLL')
   }
]

const Comp = () => {
   const Http = useHttp()
   const toast = useToast()

   const [noFetch, setNoFetch] = useState(true)
   const [branches, setBranches] = useState([])
   const [priceSchemes, setPriceSchemes] = useState([])
   const isAdmin = useHasAdminPrivileges()

   const formatRetailerLabel = (option) => 
      <Text><span style={{fontWeight: 'bold'}}>{option.id}</span> {' - ' + option.branch?.name}</Text>

   const formatBranchLabel = (option) => 
   <Text>{option.name}</Text>

   const formatPriceSchemeLabel = (option) => 
      <Text><span style={{fontWeight: 'bold'}}>{option.scheme}</span> {' - '} {option?.branch?.name}</Text>

   useEffect(() => {
      Http.get('/branches').then((res) => setBranches(res?.data?.data));
   }, [Http])

   useEffect(() => {
      Http.get('/price-schemes').then((res) => setPriceSchemes(res?.data?.data));
   }, [Http])

   const filters = useMemo(
      () => [
         {
            name: 'customer_id',
            component: 'select',
            placeholder: 'Filter by Retailer',
            isAsync: true,
            loadOptions: useRetailers,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatRetailerLabel
         },
         {
            name: 'branch_id',
            component: 'select',
            placeholder: 'Filter by Branch',
            options: branches,
            labelKey: 'name',
            valueKey: 'id',
            formatOptionLabel: formatBranchLabel
         },
         {
            name: 'is_ordering',
            component: 'select',
            placeholder: 'Retailer Type',
            isAsync: false,
            options: [
               {
                  name: 'Ordering',
                  value: true
               },
               {
                  name: 'Non-Ordering',
                  value: false
               }
            ],
            labelKey: 'name',
            valueKey: 'value'
         },
         {
            name: 'price_scheme',
            component: 'select',
            placeholder: 'Filter by Price Scheme',
            options: priceSchemes,
            labelKey: 'scheme',
            valueKey: 'id',
            formatOptionLabel: formatPriceSchemeLabel
         },
         {
            name: 'from_date',
            component: 'date',
            placeholder: 'From Date'
         },
         {
            name: 'to_date',
            component: 'date',
            placeholder: 'To Date'
         },
      ],
      [branches, priceSchemes]
   )

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='Push Notifications' />
            </Box>
            <Flex>
               <LinkButton to={`/${setup.model}/create`}>
                  <Button ml='5px' bg='parfettsRed' color='white'>
                     Add New Push Notification
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Flex justify='space-between'>
            <Box flex="6">
               <Table
                  columns={columns}
                  setup={setup}
                  onRowSelect={null}
                  filters={filters}
                  summary={true}
               />
            </Box>
         </Flex>
      </>
   )
}

export default Comp

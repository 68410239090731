import React, { useMemo, useState, useEffect } from 'react'
import { useHttp, useRDAS } from '../../hooks'
import ViewTitle from '../../components/common/ViewTitle'
import { Box, IconButton, Flex, Button, Text } from '@builtbypixel/plasma'
import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import moment from 'moment'
import { useRecoilState } from 'recoil'
import { tableAtom } from '../../state/global'
import dayjs from 'dayjs'

var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)
const FileDownload = require('js-file-download')

// Audit Score
const columns = [
   {
      Header: 'Customer Name',
      accessor: 'contact_name',
      Cell: ({ value, row }) => (
         <LinkButton to={`customer-leads/view/${row.original.id}`}>
            <Text cursor='pointer'>{value}</Text>
         </LinkButton>
      )
   },
   {
      Header: 'Contract End Date',
      accessor: 'contract',
      Cell: ({ value }) => (value ? dayjs(value).fromNow() : '-')
   },
   {
      Header: 'Address',
      accessor: 'address',
      Cell: ({ row }) =>
         row.original.address.first_line + ', ' + row.original.address.postcode
   },
   {
      Header: 'RDA',
      accessor: 'rda',
      Cell: ({ value }) => value?.first_name + ' ' + value?.last_name
   },
   {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => row.original.status
   },
   {
      Header: 'Last Visited',
      accessor: 'last_visited',
      Cell: ({ row }) =>
         row.original.last_visited &&
         dayjs(row.original.last_visited).format('DD/MM/YYYY')
   },
   {
      Header: 'Potential',
      accessor: 'potential',
      Cell: ({ value }) => (
         <Box
            bg={
               value?.name === 'Medium'
                  ? 'parfettsOrange'
                  : value?.name === 'High'
                  ? 'parfettsGreen'
                  : 'parfettsRed'
            }
            padding='2px 5px'
            textAlign='center'
            rounded='20px'
            width='100%'
            color='white'
         >
            {value?.name ? value?.name : 'Not Set'}
         </Box>
      )
   },
   {
      Header: 'Date Added',
      accessor: 'created_at',
      Cell: ({ row }) => moment(row.original.created_at).format('ll')
   },
   {
      Header: () => null,
      id: 'edit',
      Cell: ({ row }) => (
         <LinkButton to={`customer-leads/view/${row.original.id}`} w={50}>
            <IconButton variant='ghost' variantColor='primary' rounded='md'>
               <FiEdit fontSize={18} />
            </IconButton>
         </LinkButton>
      )
   }
]

const Comp = () => {
   const Http = useHttp()
   const [statuses, setStatuses] = useState([])
   const [noFetch, setNoFetch] = useState(true)
   const [tableState, setTableState] = useRecoilState(tableAtom)

   useEffect(() => {
      if (statuses.length > 0) {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: {
                  status_id: statuses[0].id
               },
               sort: 'contract',
               order: 'ASC',
               search: ''
            }
         }))
         setNoFetch(false)
      }
   }, [setTableState, statuses])

   useEffect(() => {
      Http.get(`/customer-leads-statuses`).then((res) =>
         setStatuses(res.data.data)
      )
   }, [Http])

   const downloadLeads = (id) => {
      // let exportIDs = []
      // id.map((each) => (
      //    exportIDs.push(each.original.id)
      // ))

      Http.get('/customer-leads-export', {
         responseType: 'blob',
         headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*'
         }
      })
         .then((res) => {
            FileDownload(res.data, `customer_leads_export.xlsx`)
         })
         .catch((err) => {
            // toast.warning('There was an error fetching your report, please try again');
         })
   }
   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name

   const filters = useMemo(
      () => [
         {
            name: 'user_id',
            component: 'select',
            placeholder: 'Search RDAs...',
            isAsync: true,
            loadOptions: useRDAS,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatOptionLabel
         },
         {
            name: 'status_id',
            component: 'select',
            placeholder: 'Filter by status',
            options: statuses,
            labelKey: 'name',
            valueKey: 'id',
            defaultValue: { id: 1, name: 'Active' }
         },

         {
            name: 'potential_id',
            component: 'select',
            placeholder: 'Filter by potential',
            options: [
               {
                  id: '1',
                  name: 'Low'
               },
               {
                  id: '2',
                  name: 'Medium'
               },
               {
                  id: '3',
                  name: 'High'
               }
            ],
            labelKey: 'name',
            valueKey: 'id'
         },
         {
            name: 'from',
            component: 'date',
            placeholder: 'From',
            group: 'last_visited'
         },
         {
            name: 'to',
            component: 'date',
            placeholder: 'To',
            group: 'last_visited'
         }
      ],
      [useRDAS, statuses]
   )
   return (
      <>
         <Flex mb='20px' align='center'>
            <ViewTitle title='Customer Leads' />
            <Button
               ml='auto'
               bg='parfettsRed'
               color='white'
               onClick={() => downloadLeads(tableState.selected)}
            >
               Export all
            </Button>
            <LinkButton to='/customer-leads/create'>
               <Button ml='5px' bg='parfettsRed' color='white'>
                  Add New Lead
               </Button>
            </LinkButton>
         </Flex>

         <Box>
            <Table
               columns={columns}
               setup={setup}
               filters={filters}
               noInitialFetch={noFetch}
            />
         </Box>
      </>
   )
}

export default Comp

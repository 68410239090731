import Audits from './'
import NewAudit from './New'
import ComplianceCheck from './ComplianceAudit'
import StockCheck from './StockCheck'

const routes = [
   {
      path: '/audits/list',
      title: 'Audits',
      exact: false,
      auth: true,
      component: Audits,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/audits/create',
      title: 'New Audit',
      exact: true,
      auth: true,
      component: NewAudit,
      permissions: ['Admin', 'RDA']
   },

   {
      path: '/audits/create/:retailerId',
      title: 'New Audit',
      exact: true,
      auth: true,
      component: NewAudit,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/audits/:type(create)/compliance/:retailer/:templateId/:branchId',
      title: 'New Compliance Audit',
      exact: false,
      auth: true,
      component: ComplianceCheck,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/audits/:type(create)/supplier/:retailer/:templateId/:branchId',
      title: 'New Supplier Audit',
      exact: false,
      auth: true,
      component: StockCheck,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/audits/:type(edit)/2/:id/:retailer/:branchId',
      title: 'Edit Compliance Audit',
      exact: false,
      auth: true,
      component: ComplianceCheck,
      permissions: ['Admin', 'RDA']
   }
]

export default routes

import { useEffect } from 'react';
const useFacebook = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    document.body.appendChild(script);
    console.log("fb connected");
return () => {
      document.body.removeChild(script);
    }
  }, []);
};
export default useFacebook;
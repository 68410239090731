import React, { memo } from 'react'
import { Box } from '@builtbypixel/plasma'
import { useFormContext, useWatch } from 'react-hook-form'
import ReactJson from 'react-json-view'

const DeveloperTools = memo(() => {
   const { control } = useFormContext()
   const values = useWatch({ control })

   return (
      <Box height='100%' maxHeight='100vh' overflowY='scroll'>
         <ReactJson
            src={values}
            displayDataTypes={false}
            collapseStringsAfterLength={100}
            style={{
               height: 'auto',
               fontSize: '12px',
               padding: '8px',
               paddingBottom: '100px'
            }}
            theme={{
               base00: '#1a1a1e',
               base01: '#4E4E4E',
               base02: 'rgba(255, 255, 230, 0.8)',
               base03: 'rgba(255, 255, 230, 0.5)',
               base04: '#B988FF',
               base05: '#444',
               base06: '#444',
               base07: '#88E8FF',
               base08: 'rgba(255, 255, 230, 1)',
               base09: 'rgba(255, 255, 230, 1)',
               base0A: 'rgba(255, 255, 230, 1)',
               base0B: 'rgba(255, 255, 230, 1)',
               base0C: 'rgba(255, 255, 230, 1)',
               base0D: 'rgba(255, 255, 230, 1)',
               base0E: 'rgba(255, 255, 230, 1)',
               base0F: 'rgba(89, 169, 226, 1)'
            }}
         />
      </Box>
   )
})

export default DeveloperTools

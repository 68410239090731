import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useHttp } from './useHttp'

export const useFetch = ({ url, ref, initialValue, params, withID }) => {
  const location = useLocation()
  const Http = useHttp()
  const [data, setData] = useState(initialValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  console.log(location)
  useEffect(() => {
    if (ref.current) {
      ;(async () => {
        try {
          const res = await Http.get(`${url}`, { params: params })
          const resJson = await res.data
          setData(resJson)
        } catch (err) {
          setError(err)
        } finally {
          setLoading(false)
        }
      })()
    }
    return () => {
      ref.current = false
    }
  }, [url, ref, Http, params])
  return { loading, data, error }
}

import React from 'react'
import {
   Box,
   Flex,
   Image,
   Modal,
   ModalButton,
   ModalContent,
   Table,
   Text
} from '@builtbypixel/plasma'
import useSWR from 'swr'
import dayjs from 'dayjs'

import { useGet } from '../../../hooks'
import ImageModal from '../components/ImageModal'

/**
 * A page rendering the execution report
 * @param {object} location The location object represents the current URL
 */
const Report = ({ location }) => {
   const { data: reports } = useSWR(`report${location.search}`, useGet)

   const columns = [
      {
         Header: 'Retailer',
         accessor: 'retailer.name',
         Cell: ({ value, row }) => (
            <Box>
               <Text fontWeight='bold'>{value}</Text>
               <Text color='grey'>{`on: ${dayjs(row?.original?.date).format(
                  'DD/MM/YYYY HH:mma'
               )}`}</Text>
            </Box>
         )
      },
      {
         Header: 'Images',
         accessor: 'images',
         Cell: ({ value }) => (
            <Modal>
               <ModalButton>
                  <Flex position='relative' cursor='pointer' align='flex-end'>
                     {value.length > 0 &&
                        value
                           ?.slice(0, 4)
                           ?.map((image) => (
                              <Image src={image.url} w='64px' mx='5px' />
                           ))}
                     {value.length > 4 && <Text>...</Text>}
                  </Flex>
               </ModalButton>
               <ModalContent>
                  <ImageModal images={value} />
               </ModalContent>
            </Modal>
         )
      }
   ]

   return (
      <>
         <Text
            fontSize='2rem'
            fontWeight='bold'
            mb='1rem'
         >{`Execution Report: ${
            reports?.data?.supplier?.name ?? 'Loading...'
         }`}</Text>

         <Box m='1rem' bg='white' p='1rem' borderRadius='0.5rem'>
            <Text fontWeight='bold'>Products:</Text>
            {reports?.data?.products?.map((product, index) => (
               <Text>{`${index + 1}. ${product?.description}`}</Text>
            ))}
         </Box>

         {reports && (
            <Table
               columns={columns}
               size='sm'
               data={{ data: reports?.data?.lines }}
            />
         )}
      </>
   )
}

export default Report

import React, { useEffect, useState, useCallback } from 'react'
import {
   Box,
   Flex,
   Button,
   Modal,
   ModalButton,
   Text
} from '@builtbypixel/plasma'
import TaskLoader from '../../../../components/common/TaskLoader'
import AddEditTask from '../../../../components/AddEditTask'
import {
   MdKeyboardArrowLeft,
   MdKeyboardArrowRight,
   MdAdd
} from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { globalAtom } from '../../../../state/global'
import _ from 'underscore'
import { useHttp } from '../../../../hooks'
import ParfettsAccordion from './ParfettsAccordion'

const Tasks = ({ limit, hideAddButton }) => {
   const { user } = useRecoilValue(globalAtom)

   const [params, setParams] = useState({
      page: 1,
      pagination: limit ? limit : 15,
      category: 1,
      user: 1
   })
   const [data, setData] = useState(false)
   const [tasks, setTasks] = useState([])
   const Http = useHttp()

   const getTasks = useCallback(() => {
      Http.post(`/tasks/`, {
         filters: { complete: 0 },
         relations: { user: user.id },
         pagination: params.pagination,
         page: params.page,
         sort: 'id',
         order: 'ASC'
      }).then((res) => {
         if (res.data.data.length !== 0) {
            setTasks(_.groupBy(res.data.data, 'day_header'))
         } else {
            setTasks([])
         }
         setData(res.data)
      })
   }, [Http, params, user])

   useEffect(() => {
      getTasks()
   }, [params, Http, getTasks])

   return (
      <Box>
         {tasks.length === 0 ? (
            <Flex align='center' bg='grayRow' rounded='5px' py='10px' px='10px'>
               <Text>You have no tasks to display.</Text>
               <NewTaskModal getTasks={getTasks} />
            </Flex>
         ) : (
            <>
               {!hideAddButton && (
                  <Flex m='20px 0' alignItems='center' justify='space-between'>
                     <NewTaskModal getTasks={getTasks} />
                  </Flex>
               )}
               {tasks.length !== 0 ? (
                  Object.keys(tasks).map((day) => {
                     return (
                        <ParfettsAccordion
                           day={day}
                           key={day + 'acc'}
                           tasks={tasks[day]}
                           getTasks={getTasks}
                        />
                     )
                  })
               ) : (
                  <TaskLoader />
               )}
               <Flex>
                  <Button
                     variant='outline'
                     roundedLeft='md'
                     size='sm'
                     onClick={() =>
                        setParams({
                           ...params,
                           page: params.page - 1
                        })
                     }
                     isDisabled={!data?.links?.prev}
                     leftIcon={<MdKeyboardArrowLeft />}
                  >
                     Prev
                  </Button>

                  <Button
                     variant='outline'
                     roundedRight='md'
                     size='sm'
                     onClick={() =>
                        setParams({
                           ...params,
                           page: params.page + 1
                        })
                     }
                     isDisabled={!data?.links?.next}
                     rightIcon={<MdKeyboardArrowRight />}
                     ml='15px'
                  >
                     Next
                  </Button>
               </Flex>
            </>
         )}
      </Box>
   )
}

const NewTaskModal = ({ getTasks }) => (
   <Box ml='auto'>
      <Modal>
         <ModalButton>
            <Button
               variant='outline'
               ml='auto'
               leftIcon={<MdAdd />}
               rounded='md'
            >
               Add New Task
            </Button>
         </ModalButton>
         <AddEditTask successCallback={getTasks} />
      </Modal>
   </Box>
)

export default Tasks

import React from 'react'
import { Select } from '@builtbypixel/plasma'
import FBLoginButton from '../components/common/Facebook/LoginButton.jsx'
import { facebookUserAtom } from '../state/facebook.js'

import {
    useRecoilValue,
} from 'recoil';

const SelectField = (props) => {
    const { userID } = useRecoilValue(facebookUserAtom);

    const {
        setActive,
        onChange,
        onBlur,
        value,
        labelKey,
        valueKey,
        options,
        ...rest
    } = props
    return (
        userID ?

            <Select
                onChange={(e) => onChange(e)}
                isFullWidth
                onBlur={() => {
                    onBlur && onBlur()
                    setActive && setActive(false)
                }}
                onFocus={() => setActive && setActive(true)}
                value={value}
                options={options}
                labelKey={labelKey}
                valueKey={valueKey}
                {...rest}
            />
            :
            <FBLoginButton />
    )
}

export default SelectField
import React from 'react'
import { Skeleton, Flex, Box, Stack } from '@builtbypixel/plasma'

const Item = () => {
   return (
      <Flex align='center'>
         <Skeleton w='50px' height='40px' variant='rect' mr='10px' mt='5px' />
         <Box w='100%'>
            <Skeleton width='100%' />
            <Skeleton variant='rect' width='100%' />
         </Box>
      </Flex>
   )
}

const FolderLoader = ({ number }) => {
   let items = []
   for (let index = 0; index < number; index++) {
      items.push(<Item key={`loader-${index}`} />)
   }

   return (
      <Stack spacing='15px' p='10px'>
         {items}
      </Stack>
   )
}

export default FolderLoader

import React from 'react'
import { Text, Flex, PseudoBox } from '@builtbypixel/plasma'

/**
 * Display the selectable options and is selectable
 * @param {string} backgroundColor the background color of the component
 * @param {function} onClick Function to be called when the component is clicked
 * @param {string} textLeft Text to render on the left hand side of the component
 * @param {string} textRight Text to render on the right hand side of the component
 */
const SelectableItem = ({ backgroundColor, onClick, textLeft, textRight }) => (
   <PseudoBox
      align='center'
      cursor='pointer'
      p='20px'
      w='100%'
      h='100%'
      bg={backgroundColor}
      color='white'
      rounded={['10px']}
      transition='transform 0.3s'
      _hover={{
         transform: 'scale(1.03, 1.03)'
      }}
      onClick={onClick}
   >
      <Flex justify='space-between'>
         <Text fontSize='1.3rem' fontWeight='700'>
            {textLeft}
         </Text>
         {textRight && (
            <Text fontSize='1.3rem' fontWeight='700' ml='1rem'>
               {textRight}
            </Text>
         )}
      </Flex>
   </PseudoBox>
)

export default SelectableItem

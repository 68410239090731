import Products from './'
import ProductsView from './View'

const routes = [
    {
        path: '/products',
        title: 'Products',
        exact: true,
        auth: true,
        component: Products,
        permissions: ['Admin','RDA']
      },
      {
        path: '/products/view/:id',
        title: 'View Lead',
        exact: false,
        auth: true,
        component: ProductsView,
        permissions: ['Admin','RDA']
      },
]

export default routes
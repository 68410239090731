import { useState } from 'react'
import { useHttp } from './useHttp'

export const useDelete = (url) => {
   const Http = useHttp()
   const [error, setError] = useState(null)
   const [loading, setLoading] = useState(false)
   const [response, setResponse] = useState(null)

   const handleDelete = (url) => {
      setLoading(true)
      Http.delete(url)
         .then((res) => {
            setLoading(false)
            setResponse(res)
         })
         .catch((err) => {
            setLoading(false)
            setResponse(null)
            setError(err)
         })
   }

   return { handleDelete, loading, error, response }
}

import React from 'react'
import { useTheme, Box, Flex, Text } from '@builtbypixel/plasma'

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts'
import ChartLoader from '../../../../components/common/ChartLoader'
import renderToolTip from '../../../../components/common/ChartElements/RenderCurrencyToolTip'

const Chart = ({ data }) => {
    const theme = useTheme()


    return (

        <ResponsiveContainer width='100%' height={300}>
            {data ?

                <BarChart width={730} height={250} data={data.data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={renderToolTip} />
                    <Legend />
                    <Bar
                        dataKey="ytd"
                        fill={theme.colors.parfettsOrange}
                        name='YTD'
                    />
                    <Bar
                        dataKey="last_ytd"
                        fill={theme.colors.parfettsGreen}
                        name='Last YTD'
                    />
                </BarChart>
                :
                <ChartLoader />
            }
        </ResponsiveContainer>

    )
}

export default Chart

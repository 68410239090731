import React, { useEffect, useState } from 'react'
import { Field } from '../../components/Form'
import {
   Text,
   Tabs,
   Tab,
   TabList,
   TabPanels,
   TabPanel,
   Input,
   Select
} from '@builtbypixel/plasma'

import { useRetailers, useRDAS, useHttp } from '../../hooks'

import Card from '../../components/common/Card'

import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TimePickerComponent from '../../Fields/TimePicker'
import EditMessageVariables from './editComponents/EditMessageVariables'
import EditMessageUploads from './editComponents/EditMessageUploads'
import { isEqual } from 'underscore'

// const debounce = require('lodash.debounce');

const CustomForm = ({ preview, setPreview }) => {
   const Http = useHttp()
   const { id } = useParams()

   //options
   const [templates, setTemplates] = useState(null)
   const [segments, setSegments] = useState(null)

   //base form vals from backend
   const [formValues, setFormValues] = useState(null)

   //form vars
   const [name, setName] = useState('')
   const [selectedTemplate, setSelectedTemplate] = useState()
   const [selectedSegments, setSelectedSegments] = useState(null)
   const [sendDate, setSendDate] = useState(null)
   const [messageSent, setMessageSent] = useState(false)

   const [contentHeader, setContentHeader] = useState(null)
   const [contentBody, setContentBody] = useState(null)
   const [contentFooter, setContentFooter] = useState(null)

   const [loading, setLoading] = useState(false)

   const headerType = selectedTemplate?.content?.[0]?.format

   const splitContent = (data) => {
      return data?.forEach((item) => {
         switch (item.type) {
            case 'HEADER':
               setContentHeader(item)
               break
            case 'BODY':
               setContentBody(item)
               break
            case 'FOOTER':
               setContentFooter(item)

               break

            default:
               break
         }
      })
   }

   useEffect(() => {
      let arr = []
      contentHeader && arr.push(contentHeader)
      contentBody && arr.push(contentBody)
      contentFooter && arr.push(contentFooter)

      let template = { ...selectedTemplate }
      template = { ...template, content: arr }

      const obj = {
         id: id,
         name: name,
         template: template,
         segments: selectedSegments,
         send_date: sendDate,
         message_sent: messageSent
      }

      setPreview(obj)
   }, [
      name,
      sendDate,
      selectedSegments,
      selectedTemplate,
      contentHeader,
      contentBody,
      contentFooter
   ])

   useEffect(() => {
      setLoading(true)
      const getData = () => {
         Http.get(`/whatsapp-messages/${id}`)
            .then((res) => {
               setFormValues(res.data.data)
               setName(res.data.data.name)
               setSelectedTemplate(res.data.data.template)
               setMessageSent(res.data.data.message_sent)
               setSelectedSegments(res.data.data.segments)
               setSendDate(res?.data?.data?.send_date)
               splitContent(res.data.data.template.content)
               setLoading(false)
            })
            .catch((err) => setLoading(false))
      }

      const getTemplates = () => {
         Http.get(`/all-whatsapp-templates`).then((res) => {
            setTemplates(res.data.data)
         })
      }
      const getSegments = () => {
         Http.post(`/whatsapp-segments`).then((res) => {
            setSegments(res.data.data)
         })
      }

      getData()
      getTemplates()
      getSegments()
   }, [Http])

   useEffect(() => {
      if (selectedTemplate?.content) splitContent(selectedTemplate?.content)
   }, [selectedTemplate])

   return (
      <>
         <Card title='Communication' gridColumn='span 4'>
            <Input
               disabled={messageSent}
               placeholder='Communication Name'
               onChange={(e) => setName(e.target.value)}
               value={name}
            />
         </Card>

         <Card title='Template' gridColumn='span 4'>
            <Select
               placeholder='Templates'
               labelKey='name'
               valueKey='id'
               isDisabled={messageSent}
               options={templates}
               onChange={(val) => setSelectedTemplate(val)}
               value={selectedTemplate}
            />
            <EditMessageVariables
               template={selectedTemplate}
               messageSent={messageSent}
               setContentHeader={setContentHeader}
               contentHeader={contentHeader}
               setContentBody={setContentBody}
               contentBody={contentBody}
               contentFooter={contentFooter}
               setContentFooter={setContentFooter}
            />
            {headerType && !(headerType === 'TEXT') && (
               <EditMessageUploads
                  media={
                     selectedTemplate?.content?.[0]?.example?.header_handle?.[0]
                  }
                  fileName={selectedTemplate?.content?.[0]?.example?.file_name}
                  setContentHeader={setContentHeader}
                  contentHeader={contentHeader}
                  messageSent={messageSent}
               />
            )}
         </Card>

         <Card title='Segment' gridColumn='span 4'>
            <Select
               placeholder='Segments'
               labelKey='name'
               valueKey='id'
               isDisabled={messageSent}
               options={segments}
               isMulti
               onChange={(val) => setSelectedSegments(val)}
               value={selectedSegments}
            />
         </Card>

         <Card title='Schedule' gridColumn='span 4'>
            <TimePickerComponent
               placeholder='Enter Send Date'
               disabled={messageSent}
               help='enter a date to send on OR leave blank to send immediately'
               onChange={(val) => setSendDate(val)}
               value={sendDate}
            />
         </Card>
      </>
   )
}

export default CustomForm

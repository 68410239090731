import { useHttp } from './'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useRetailers = debounce((inputValue, callback) => {
   Http.post(`/retailers`, { filters: { id: inputValue } }).then((res) =>
      callback(res.data.data)
   )
}, 500)

import React from 'react'
import { Modal, ModalButton, Button } from '@builtbypixel/plasma'
import NewNoteForm from './Notes/NoteForm'

import { FiPlus } from 'react-icons/fi'

const AddNewNote = ({ id, noteId }) => {
   return (
      <Modal>
         <ModalButton>
            <Button
               variant='solid'
               variantColor='parfettsRed'
               color='white'
               leftIcon={<FiPlus />}
               rounded='md'
            >
               Add New Note
            </Button>
         </ModalButton>
         <NewNoteForm noteId={noteId} />
      </Modal>
   )
}

export default AddNewNote

import React, { useMemo, useEffect, useState } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { Box, IconButton, Text } from '@builtbypixel/plasma'
import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import { useHttp } from '../../hooks'

// Audit Score
const columns = [
   {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ value }) => (
         <LinkButton to={`products/view/${value}/pack`}>
            <Text cursor='pointer'>{value}</Text>
         </LinkButton>
      )
   },
   {
      Header: 'Description',
      accessor: 'name',
      Cell: ({ row }) => row.original.description
   },
   {
      Header: 'Status',
      accessor: 'status'
   },
   {
      Header: 'Supplier',
      accessor: 'supplier_name',
      Cell: ({ row }) => row.original.supplier
   },
   {
      Header: () => null,
      id: 'edit',
      Cell: ({ row }) => (
         <LinkButton to={`products/view/${row.original.id}/pack`}>
            <IconButton variant='ghost' variantColor='primary' rounded='md'>
               <FiEdit fontSize={18} />
            </IconButton>
         </LinkButton>
      )
   }
]

const Comp = () => {
   const Http = useHttp()
   const [branches, setBranches] = useState([])

   useEffect(() => {
      Http.get(`/branches`).then((res) => {
         setBranches(res.data.data)
      })
   }, [])

   const filters = useMemo(
      () => [
         {
            name: 'product_branch',
            component: 'relation_select',
            placeholder: 'Filter by branch',
            options: branches,
            labelKey: 'name',
            valueKey: 'id',
            minWidth: '500px'
         }
      ],
      [branches]
   )
   return (
      <>
         <Box mb='20px'>
            <ViewTitle title='Products' />
         </Box>
         <Box>
            <Table
               isExpandable
               columns={columns}
               setup={setup}
               filters={filters}
            />
         </Box>
      </>
   )
}

export default Comp

import React, { useEffect, useState } from 'react'
import {
   Box,
   PseudoBox,
   Image,
   Flex,
   Text,
   Stack,
   Tooltip,
   PopConfirm,
   Button,
   useToast
} from '@builtbypixel/plasma'
import { useParams } from 'react-router-dom'
import { Field } from '../../components/Form'
import { BiTrashAlt } from 'react-icons/bi'
import { FiEdit3 } from 'react-icons/fi'
import { globalAtom } from '../../state/global'
import { useRecoilValue } from 'recoil'
import { TextArea } from '../../Fields'
import dayjs from 'dayjs'
import { usePost } from '../../hooks'
import { useWatch } from 'react-hook-form'

const RemoveButton = ({ id, removeItem }) => {
   return (
      <Tooltip title='Delete'>
         <PopConfirm
            onConfirm={() => removeItem(id)}
            title='Are you sure you want to remove this note?'
         >
            <Button size='sm' variant='link'>
               <BiTrashAlt />
            </Button>
         </PopConfirm>
      </Tooltip>
   )
}

const Item = ({ item, index, parentMutate }) => {
   const { user } = useRecoilValue(globalAtom)
   const toast = useToast()
   const { _delete, put, response, errors, loading } = usePost()
   const [stateItem, setStateItem] = useState(item)
   const [editMode, setEditMode] = useState(false)

   const retailer = useWatch({
      name: 'retailer'
   })
   const removeItem = (id) => {
      _delete(`/notes/`, { data: { id: [id] } })
      parentMutate()
   }
   const editItem = (i, value) => {
      let _item = { ...stateItem }
      let obj = { ..._item, description: value }

      _item = obj
      setStateItem(_item)
   }

   const saveEditedItem = () => {
      put(`/notes/${item.id}`, stateItem)
      parentMutate()
   }

   /* eslint-disable */
   useEffect(() => {
      if (response) {
         setEditMode(false)
         parentMutate()
      }
   }, [response])
   /* eslint-enable */

   useEffect(() => {
      if (errors) {
         toast({
            title: 'Action not completed',
            message: 'An error occurred',
            status: 'error',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [errors, toast])

   return (
      <Flex direction='column' key={`${item.id}-is-note-${index}`} mb='20px'>
         <Flex align='center'>
            <Flex align='center' mb='0px'>
               <Image
                  src={item?.user?.profile_image?.[0]?.url}
                  size='30px'
                  objectFit='cover'
                  rounded='full'
                  mr='10px'
                  fallbackSrc='/images/profile-placeholder.png'
               />
               <Text fontSize='sm' fontWeight='semibold' mr='10px'>
                  {item.user?.first_name} {item.user?.last_name}
               </Text>
               <Text fontSize='xs' opacity={0.5}>
                  {dayjs(item?.date).format('DD/MM/YYYY HH:mm')}
               </Text>
            </Flex>
            <Stack isInline ml='auto'>
               <RemoveButton id={item.id} removeItem={removeItem} />
               <Tooltip title='Edit'>
                  <Button
                     size='md'
                     variant='link'
                     onClick={() => setEditMode(!editMode)}
                  >
                     <FiEdit3 />
                  </Button>
               </Tooltip>
            </Stack>
         </Flex>
         <PseudoBox
            _before={{
               position: 'absolute',
               width: '20px',
               height: '20px',
               bg: 'grayBg',
               content: '" "',
               top: '-3px',
               transform: 'rotate(45deg)',
               left: '10px',
               zIndex: -1
            }}
            ml='40px'
            rounded='lg'
            p='10px'
            mt='5px'
            bg='grayBg'
            position='relative'
            zIndex={1}
         >
            {editMode ? (
               <Box w='100%' ml='15px'>
                  <TextArea
                     value={stateItem.description}
                     onChange={(value) => editItem(index, value)}
                     placeholder='Type your note'
                     mb='2px'
                     rows={2}
                     autoFocus
                  />
                  <Button
                     mt='5px'
                     size='sm'
                     onClick={() => saveEditedItem(index)}
                     isDisabled={!retailer || loading}
                     isLoading={loading}
                  >
                     Save
                  </Button>
               </Box>
            ) : (
               <Text
                  fontSize='md'
                  ml='10px'
                  onClick={() => user.id === item?.user_id && setEditMode(true)}
               >
                  {stateItem.description}
               </Text>
            )}
         </PseudoBox>
      </Flex>
   )
}

const Notes = ({ data, parentMutate }) => {
   const { user } = useRecoilValue(globalAtom)
   const { type, id } = useParams()
   const toast = useToast()

   const noteObj = {
      description: '',
      visit_id: parseInt(id),
      type: 'Visit',
      user
   }

   const [newItem, setNewItem] = useState(noteObj)
   const [canEdit, setEdit] = useState(null)
   const { post, response, errors, loading } = usePost()

   const retailer = useWatch({
      name: 'retailer'
   })

   const saveItem = () => {
      post('/notes/create', newItem)
      parentMutate()
   }

   /* eslint-disable */
   useEffect(() => {
      if (response) {
         console.log('i can respong')
         parentMutate()
         setNewItem({ ...newItem, description: '' })
         setEdit(null)
      }
   }, [response])
   /* eslint-enable */

   useEffect(() => {
      if (errors) {
         toast({
            title: 'Action not completed',
            message: 'An error occurred',
            status: 'error',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [errors, toast])

   return (
      <Box pt='25px' zIndex={0} position='relative'>
         {type === 'edit' ? (
            <>
               <Stack spacing='10px'>
                  {data.map((item, i) => (
                     <Item
                        item={item}
                        key={`${item.id}-notes-${i}`}
                        index={i}
                        parentMutate={parentMutate}
                     />
                  ))}
               </Stack>
               <Box mt='25px'>
                  <TextArea
                     value={newItem.description}
                     onChange={(value) =>
                        setNewItem({ ...newItem, description: value })
                     }
                     placeholder='Type your note'
                  />
                  {canEdit === null && (
                     <Button
                        mt='5px'
                        size='sm'
                        onClick={() => saveItem()}
                        isDisabled={loading}
                        isLoading={loading}
                        loadingText='Please Wait'
                     >
                        Save
                     </Button>
                  )}
               </Box>
               <Box></Box>
            </>
         ) : (
            <Flex>
               <Image
                  src={user?.profile_image?.[0]?.url}
                  size='35px'
                  objectFit='cover'
                  rounded='full'
                  mr='20px'
               />
               <Flex direction='column' w='100%' align='flex-start'>
                  <Field
                     component='textarea'
                     name='notes[0].description'
                     placeholder='Type your note'
                  />
               </Flex>
            </Flex>
         )}
      </Box>
   )
}

const SuspenseNotes = () => {
   const { id } = useParams()
   const { post, response } = usePost()
   const [data, setData] = useState(null)

   const getNotes = () => {
      //hacked mutate, swr was not playing ball
      post('/notes', { relations: { visit: parseInt(id) } })
   }
   useEffect(() => {
      getNotes()
   }, [])

   useEffect(() => {
      if (response) {
         setData(response.data.data)
      }
   }, [response])

   return data && <Notes data={data} parentMutate={getNotes} />
}

export default SuspenseNotes

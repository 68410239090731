import React from 'react'
import { useWatch } from 'react-hook-form'
import { Flex, Box, Text, Grid } from '@builtbypixel/plasma'
// import SearchBar from "./SearchBar";
import ProductCard from './ProductCard'
// import AddProductsModal from "./AddProductsModal";

const ProductsContainer = () => {
   // const { setValue } = useFormContext();
   const formProducts = useWatch({
      name: 'products'
   })

   // useEffect(() => {
   //   if (formProducts) {
   //     setProducts(formProducts);
   //   }
   // }, [formProducts]);

   return (
      <Box mt='25px'>
         <Flex justify='space-between'>
            {/* <SearchBar
          original={formProducts}
          products={products}
          setProducts={setProducts}
        /> */}
            {/* <AddProductsModal original={formProducts} /> */}
         </Flex>
         <Grid
            width='100%'
            templateColumns='120px 1fr 100px 100px'
            overflow='hidden'
            m='5px'
            position='relative'
            overflow='visible'
         >
            <Text as='strong' textAlign='center'>
               Image
            </Text>
            <Text as='strong'>Title</Text>
            <Text as='strong'>Retail 1</Text>
            <Text as='strong'>Retail 2</Text>
         </Grid>
         {formProducts && formProducts.length > 0 ? (
            <Box mt='20px' maxWidth='1200px'>
               {formProducts.map((product) => (
                  <ProductCard
                     product={product}
                     key={`product-${product.id}`}
                  />
               ))}
            </Box>
         ) : (
            'no products found'
         )}
      </Box>
   )
}

export default ProductsContainer

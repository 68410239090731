import React, { Suspense } from 'react'
import {
   Box,
   Button,
   Icon,
   Flex,
   Text,
   Stack
} from '@builtbypixel/plasma'

import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { FaFolder, FaFolderOpen } from 'react-icons/fa'
import FolderLoader from '../../../components/common/FolderLoader'
import ErrorBoundary from '../../../components/common/ErrorBoundary'

const Folder = ({ isCurrent, folder, setNavigation, setSelectedFolder }) => {

   return (
      <>
         <Button
            isFullWidth
            variant='ghost'
            justifyContent='space-between'
            mb='10px'
            display='flex'
            pr='8px'
            pl={isCurrent ? '8px' : '12px'}
            onClick={
               () => {
                  setSelectedFolder(folder)
                  setNavigation((oldNav) => [folder, ...oldNav]);
               }
            }
         >
            <Flex align='center'>
               <Icon mr='10px' color='#72CBF2'>
                  {isCurrent ?
                     <FaFolderOpen fontSize='24px' />
                     :
                     <FaFolder fontSize='22px' />
                  }
               </Icon>
               <Text>{folder?.name}</Text>
            </Flex>
            {isCurrent ?
               <MdKeyboardArrowDown fontSize='24px' ml='auto' />
               :
               <MdKeyboardArrowRight fontSize='24px' ml='auto' />
            }
         </Button>
      </>
   )
}


const FolderMenu = ({ data, selectedFolder, setSelectedFolder, navigation, setNavigation }) => {

   return (
      <>
         {
            navigation.length > 0 &&
            <>
               <Box borderBottom='1px' borderColor='global.borderColour'>
                  <Button
                     isFullWidth
                     border='0px'
                     align='center'
                     variant='ghost'
                     leftIcon={<RiArrowGoBackLine fontSize='17px' />}
                     onClick={() => {
                        if (navigation.length > 1) {
                           setSelectedFolder(navigation[1])
                        } else {
                           setSelectedFolder({ child: null })
                        }
                        let tmpNav = navigation
                        tmpNav.shift()
                        setNavigation(tmpNav)
                     }}
                  >
                     <Text fontWeight='semibold'>{selectedFolder?.parent?.name ? selectedFolder?.parent?.name : 'Back'}</Text>
                  </Button>
               </Box>
               <Box px='5px' mb='-10px'>
                  <Folder
                     folder={navigation[0]}
                     setSelectedFolder={() => { }}
                     setNavigation={() => { }}
                     isCurrent />
               </Box>

            </>
         }
         < Stack spacing='10px' p='15px 5px' >

            {
               selectedFolder.child ?
                  selectedFolder.child.map((folder, i) => (
                     <Folder
                        folder={folder}
                        key={`folder-${i}`}
                        setNavigation={setNavigation}
                        setSelectedFolder={setSelectedFolder}
                     />
                  ))
                  :
                  Array.isArray(data.data) &&

                  data.data?.map((folder, i) => (
                     <Folder
                        folder={folder}
                        key={`folder-${folder.id}-${i}`}
                        setSelectedFolder={setSelectedFolder}
                        setNavigation={setNavigation}
                     />
                  ))

            }
            {!selectedFolder && !data &&
               <Text>No folders set for this retailer</Text>
            }
         </Stack >
      </>
   )
}

const SuspenseFolders = (props) => (
   <ErrorBoundary>
      <Suspense fallback={<FolderLoader number={10} />}>
         <FolderMenu
            {...props}
         />
      </Suspense>
   </ErrorBoundary>
)

export default SuspenseFolders

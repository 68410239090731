import React, { useMemo, useState, useEffect, useRef } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { useHttp, useRDAS } from '../../hooks'

import {
   Box,
   IconButton,
   Flex,
   Button,
   Text,
   useToast
} from '@builtbypixel/plasma'

import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import TrafficLights from './TrafficLights'
import { FiUploadCloud } from 'react-icons/fi'
import { FaFileExport } from 'react-icons/fa'

import { useRecoilState } from 'recoil'
import { tableAtom } from '../../state/global'
import dayjs from 'dayjs'
import { useHasAdminPrivileges } from '../../hooks'

const FileDownload = require('js-file-download')
// Audit Score
const columns = [
   {
      Header: 'Customer',
      accessor: 'id',
      Cell: ({ row }) => (
         <LinkButton
            to={`retailers/view/${row?.original?.branch?.id}/${row?.original?.id}/invoices`}
         >
            <Text cursor='pointer'>
               {row?.original?.business_name} #{row.original?.id}
            </Text>
         </LinkButton>
      )
   },
   {
      Header: 'Branch',
      accessor: 'branch',
      Cell: ({ row }) => row?.original?.branch?.name
   },
   {
      Header: 'RDA',
      accessor: 'user',
      Cell: ({ row }) => (row?.original?.user ? row?.original?.user?.name : '-')
   },
   {
      Header: 'Fascia Type',
      accessor: 'groups',
      Cell: ({ row }) =>
         row?.original?.attributes?.content?.fascia?.name
            ? row?.original?.attributes?.content?.fascia?.name
            : '-'
   },
   {
      Header: 'Post Code',
      accessor: 'postcode',
      Cell: ({ row }) => row?.original?.customer?.address?.post_code
   },
   {
      Header: 'Score',
      accessor: 'overall',
      Cell: ({ value, row }) => {
         return (
            <TrafficLights
               title={`${value.toFixed(2)}%`}
               audit_compliance={row?.original?.audit_compliance}
               spend_target={row?.original?.spend_target}
               purchase_target={row?.original?.purchase_target}
            />
         )
      }
   },
   {
      Header: 'Last Visited',
      accessor: 'images',
      Cell: ({ row }) =>
         row?.original?.attributes?.last_visited
            ? dayjs(row.original?.attributes?.last_visited).format('DD/MM/YYYY')
            : '-'
   },
   {
      Header: 'Retailer Type',
      accessor: 'is_ordering',
      Cell: ({ row }) =>
         row.original.is_ordering === true ? 'Ordering' : 'Non-Ordering'
   },
   {
      Header: () => null,
      id: 'edit',
      Cell: ({ row }) => (
         <LinkButton
            to={`retailers/view/${row.original?.branch?.id}/${row.original?.id}/invoices`}
         >
            <IconButton variant='ghost' variantColor='primary' rounded='md'>
               <FiEdit fontSize={18} />
            </IconButton>
         </LinkButton>
      )
   }
]

const Comp = () => {
   const fileInput = useRef(null)
   const Http = useHttp()
   const toast = useToast()

   const [tableState, setTableState] = useRecoilState(tableAtom)
   const [noFetch, setNoFetch] = useState(true)
   const [loading, setLoading] = useState(null)

   const [fascias, setFascias] = useState([])
   const isAdmin = useHasAdminPrivileges()

   const formatOptionLabel = (option) =>
   (option?.rda_id) ? option.first_name + ' ' + option.last_name +' ('+ option.rda_id?.branch?.name+')' : option.first_name + ' ' + option.last_name 

   const formatValue = (option) => 
      console.log(option, 'opopopopopo')

   useEffect(() => {
      if (fascias.length > 0) {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: {
                  fascia: null,
                  status_id: null
               },
               search: ''
            }
         }))
         setNoFetch(false)
      }
   }, [fascias])

   useEffect(() => {
      Http.get(`/fascias`).then((res) => setFascias(res.data.data))
   }, [Http])

   const downloadRetailers = (id) => {
      // let exportIDs = []
      // id.map((each) => (
      //    exportIDs.push(each.original.id)
      // ))

      Http.get('/retailers-export', {
         responseType: 'blob',
         headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*'
         }
      })
         .then((res) => {
            FileDownload(res.data, `retailers_export.xlsx`)
            toast({
               title: 'Download Successful',
               status: 'success',
               position: 'top',
               variant: 'plain'
            })
         })
         .catch((err) => {
            toast({
               title: 'There was an error downloading retailers, please try again',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
         })
   }

   const handleUploadSpendTarget = (file) => {
      setLoading(true)
      const data = new FormData()
      data.append('file', file)

      Http.post('/file-upload', data).then((res) => {
         Http.post('/bulk-upload-spend-target', { file: res.data })
            .then((res) => {
               setLoading(false)
               toast({
                  title: 'Upload Successful',
                  status: 'success',
                  position: 'top',
                  variant: 'plain'
               })
            })
            .catch((err) => {
               toast({
                  title: 'There was an error uploading your spend target, please try again',
                  status: 'error',
                  position: 'top',
                  variant: 'plain'
               })
            })
      })
   }

   const filters = useMemo(
      () => [
         {
            name: 'id',
            component: 'text',
            placeholder: 'Search by id ...',
            labelKey: 'name',
            valueKey: 'id'
         },
         {
            name: 'rda_id',
            component: 'select',
            placeholder: 'Filter by RDA',
            isAsync: true,
            loadOptions: useRDAS,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatOptionLabel
         },
         {
            name: 'is_ordering',
            component: 'select',
            placeholder: 'Retailer Type',
            isAsync: false,
            options: [
               {
                  name: 'Ordering',
                  value: true
               },
               {
                  name: 'Non-Ordering',
                  value: false
               }
            ],
            labelKey: 'name',
            valueKey: 'value'
         },
         {
            name: 'fascia',
            component: 'switch',
            placeholder: 'Filter by Fascia Type',
            label: 'Fascia'
         }
      ],
      [fascias]
   )

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='Retailers' />
            </Box>
            <Flex>
               <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={fileInput}
                  accept='1'
                  onChange={(e) => handleUploadSpendTarget(e.target.files[0])}
               />
               {isAdmin && (
                  <Button
                     ml='auto'
                     bg='parfettsRed'
                     color='white'
                     leftIcon={<FiUploadCloud />}
                     onClick={() => fileInput.current.click()}
                     isLoading={loading}
                     mx='5px'
                  >
                     Upload Spend Target
                  </Button>
               )}

               <Button
                  ml='auto'
                  // bg='parfettsRed'
                  color='parfettsRed'
                  borderColor='parfettsRed'
                  variant='outline'
                  leftIcon={<FaFileExport />}
                  onClick={() => downloadRetailers(tableState.selected)}
                  mx='5px'
               >
                  Export all
               </Button>
            </Flex>
         </Flex>
         <Box>
            <Table
               columns={columns}
               setup={setup}
               onRowSelect={null}
               filters={filters}
               noInitialFetch={noFetch}
            />
         </Box>
      </>
   )
}

export default Comp

import React, { memo } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import { Stack, Flex, Button, Text, Box, useTheme } from '@builtbypixel/plasma'
import LinkButton from '../../components/LinkButton'

const PendingLeads = memo(() => {
   const theme = useTheme()

   const { data } = useSWR('/customer-leads-grouped', useGet, {
      suspense: true
   })

   const getColour = (priority) => {
      switch (priority) {
         case 'High':
            return theme.colors.parfettsRed
         case 'Medium':
            return theme.colors.parfettsOrange
         case 'Low':
            return theme.colors.parfettsGreen
         default:
            return '#000000'
      }
   }

   return (
      <>
         <Stack spacing='2px'>
            {data.data.length > 0 &&
               data.data.slice(0, 8).map((group, i) => (
                  <Flex
                     bg={i % 2 === 0 && 'grayRow'}
                     py='10px'
                     px='10px'
                     align='center'
                     key={`lead-${i}`}
                     rounded='md'
                  >
                     <Text
                        color={getColour(group.priority)}
                        fontWeight='semibold'
                     >
                        {group.priority}
                     </Text>
                     <Flex ml='auto' align='center'>
                        <Text mr='15px'>{group.number} leads</Text>
                        <Button size='sm' variant='outline'>
                           View
                        </Button>
                     </Flex>
                  </Flex>
               ))}
         </Stack>
      </>
   )
})

export default PendingLeads

import React, { memo, useMemo } from 'react'
import { AutoFields } from '../../components/Form'
import { Text } from '@builtbypixel/plasma'

import { useSWRPost, useRetailers } from '../../hooks'
import useSWR from 'swr'

const EditForm = memo(() => {
   const { data } = useSWR('users', (url) => useSWRPost(url))

   const formatUserLabel = (option) => {
      return (
         <Text>
            {option?.first_name} {option?.last_name}{' '}
         </Text>
      )
   }

   const fields = useMemo(
      () => [
         {
            group: '',
            fields: [
               {
                  name: 'completed',
                  component: 'switch',
                  size: 'lg',
                  placeholder: 'Milestone Completed',
                  label: 'Completed?'
               },
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  rules: { required: true }
               },
               {
                  name: 'due_date',
                  component: 'date',
                  placeholder: 'Due Date',
                  label: 'Due date',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'assigned_to',
                  component: 'select',
                  placeholder: 'Assigned to',
                  label: 'Assigned to',
                  options: data?.data,
                  valueKey: 'id',
                  labelKey: 'first_name',
                  formatOptionLabel: formatUserLabel
               },
               {
                  name: 'description',
                  component: 'textarea',
                  placeholder: 'Description',
                  label: 'Description'
               },
               {
                  component: 'select',
                  name: 'retailer',
                  isAsync: true,
                  placeholder: 'Type to search retailers',
                  label: 'Retailers',
                  loadOptions: useRetailers,
                  labelKey: 'business_name',
                  valueKey: 'id',
                  size: 'lg',
                  isRequired: true
               },

               {
                  component: 'text',
                  type: 'hidden',
                  name: 'processed',
                  defaultValue: '0'
               }
            ]
         }
      ],
      [data]
   )
   return <AutoFields fields={fields} />
})

export default EditForm

import React from 'react'
import { Alert } from '@builtbypixel/plasma'
const UnAuthorised = () => (
    <Alert
        status="error"
        title="Unauthorised"
    >
        You do not have permission to access this page
    </Alert>
)
export default UnAuthorised
import Dashboard from './'

const routes = [
   {
      path: '/dashboard',
      title: 'Dashboard',
      exact: true,
      auth: true,
      component: Dashboard,
      permissions: ['Admin', 'RDA']
   }
]

export default routes

import React, { memo, Suspense, useMemo, useEffect, useState } from 'react'
import {
   Text,
   ScrollContainer,
   Tag,
   Flex,
   Image,
   IconButton,
   Stack,
   Button
} from '@builtbypixel/plasma'
import Table from '../ListView'

import TableLoader from '../common/TableLoader'
import ErrorBoundary from '../common/ErrorBoundary'
import { FiEdit } from 'react-icons/fi'
import dayjs from 'dayjs'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { tableAtom } from '../../state/global'

const Visits = memo(({ type }) => {
   const { id } = useParams()
   const history = useHistory()
   const [noFetch, setNoFetch] = useState(true)
   const params = useParams()
   const [tableState, setTableState] = useRecoilState(tableAtom)

   const columns = [
      {
         Header: () => null,
         width: '40px',
         id: 'edit',
         Cell: ({ row }) => (
            <Link to={`/visits/edit/${row.original.id}`}>
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </Link>
         )
      },
      {
         Header: 'RDA',
         accessor: 'rda',
         Cell: ({ value }) => (
            <Flex align='center'>
               <Image
                  src={value?.profile_image?.[0]?.url}
                  objectFit='cover'
                  size='30px'
                  rounded='full'
                  mr='10px'
               />
               <Text minWidth='300px'>
                  {value?.first_name} {value?.last_name}
               </Text>
            </Flex>
         )
      },
      {
         Header: 'Status',
         accessor: '',
         Cell: ({ row }) =>
            !row.original.completed_date ? (
               <Tag variantColor='parfettsRed'>Scheduled</Tag>
            ) : (
               <Stack isInline spacing='10px'>
                  <Tag variantColor='parfettsGreen'>Completed</Tag>{' '}
                  <Text>
                     {dayjs(row.original.completed_date).format(
                        'DD/MM/YYYY HH:mm'
                     )}
                  </Text>
               </Stack>
            )
      },
      {
         Header: 'Due Date',
         accessor: 'due_date',
         Cell: ({ value }) => (
            <Text>{dayjs(value).format('DD/MM/YYYY HH:mm')}</Text>
         )
      }
   ]

   const filters = useMemo(
      () => [
         {
            name: 'from',
            component: 'date',
            placeholder: 'From'
         },
         {
            name: 'to',
            component: 'date',
            placeholder: 'To'
         }
      ],
      []
   )

   const extraMenu = () => (
      <Button
         variantColor='parfettsRed'
         color='white'
         onClick={() =>
            history.push(`/visits/create/${type}`, { type: type, id: id })
         }
      >
         Add New Visit
      </Button>
   )

   useEffect(() => {
      if (params && params.id) {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               relations: {
                  [type]: params.id
               }
            }
         }))
         setNoFetch(false)
      }
   }, [])

   return (
      <>
         <ScrollContainer>
            <Table
               hidePagination={true}
               size='sm'
               columns={columns}
               filters={filters}
               extraMenu={extraMenu}
               noInitialFetch={noFetch}
               setup={{
                  endpoint: `/visits?${type}=${id}`,
                  canSearch: true,
                  canDelete: false
               }}
            />
         </ScrollContainer>
      </>
   )
})

const SuspenseAudits = ({ type }) => {
   return (
      <ErrorBoundary>
         <Suspense fallback={<TableLoader />}>
            <Visits type={type} />
         </Suspense>
      </ErrorBoundary>
   )
}

export default SuspenseAudits

import { Box, Flex, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import Title from './Title'

import { Field } from '../../../../components/Form'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus } from 'react-icons/fa'
import VariablesPanel from './VariablesPanel'

const TemplateButtons = () => {
   const { setValue, register, watch, control, getValues } = useFormContext()
   const content = useWatch({ name: 'content[3]' })
   const { type } = useParams()

   return (
      <Flex gap={10} w='100%' direction='column'>
         <Title text='Button Details' />
         <Flex w='100%' direction='column'>
            <Flex w='100%'>
               <Box width='100%'>
                  <Flex direction='column'>
                     <Title text='Website Button' bg={false} />

                     <Field
                        component='text'
                        name='content[3].buttons[0].text'
                        placeholder='Enter Button Text'
                     />
                     <Field
                        component='text'
                        name='content[3].buttons[0].url'
                        placeholder='Enter URL (Max 2000 Characters)'
                        maxLength={2000}
                        help={`${
                           content?.buttons?.[0].url?.length
                              ? content?.buttons?.[0].url?.length
                              : 0
                        } / 2000`}
                     />
                  </Flex>
                  {/* <Flex direction='column' mt='1em'>
                     <Title text='Phone Button' bg={false} />

                     <Field
                        component='text'
                        name='content[3].buttons[1].text'
                        placeholder='Enter Button Text'
                        rules={{ required: true }}
                     />
                     <Field
                        component='text'
                        name='content[3].buttons[1].phone_number'
                        placeholder='Enter Phone Number'
                        rules={{ required: true }}
                        help='please include country code eg: +4412345678910'
                     />
                  </Flex> */}
               </Box>
            </Flex>
         </Flex>
      </Flex>
   )
}

export default TemplateButtons

import React from 'react'
import { TextArea } from '@builtbypixel/plasma'

const TextAreaComponent = (props) => {
   const { setActive, onChange, onBlur, value, ...rest } = props
   return (
      <TextArea
         onChange={(e) => onChange(e.target.value)}
         onBlur={() => {
            onBlur && onBlur()
            onBlur && setActive(false)
         }}
         onFocus={() => setActive && setActive(true)}
         value={value}
         {...rest}
      />
   )
}

export default TextAreaComponent

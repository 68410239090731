import { useState } from 'react'
import { useHttp } from './useHttp'

export const usePost = (url, data) => {
   const Http = useHttp()
   const [error, setError] = useState(null)
   const [loading, setLoading] = useState(false)
   const [response, setResponse] = useState(null)

   const post = (url, data) => {
      setLoading(true)
      Http.post(url, data)
         .then((res) => {
            setLoading(false)
            setResponse(res)
         })
         .catch((err) => {
            setLoading(false)
            setResponse(null)
            setError(err)
         })
   }

   const put = (url, data) => {
      setLoading(true)
      Http.put(url, data)
         .then((res) => {
            setLoading(false)
            setResponse(res)
         })
         .catch((err) => {
            setLoading(false)
            setResponse(null)
            setError(err)
         })
   }

   const _delete = (url,data) => {
      setLoading(true)
      Http.delete(url, data)
         .then((res) => {
            setLoading(false)
            setResponse(res)
         })
         .catch((err) => {
            setLoading(false)
            setResponse(null)
            setError(err)
         })
   }

   return { loading, post, put, _delete, error, response }
}

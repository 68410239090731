const modelName = 'Developments'
const modelNameSingular = 'Development'
const endpoint = '/developments/filtered'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: false,
  endpoint,
  accessor: 'id',
  permissions: ['Admin','RDA']
}

export default setup

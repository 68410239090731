import React from 'react'
import { Skeleton } from '@builtbypixel/plasma'

const CardLoader = () => {
   return (
      <>
         <Skeleton width='50%' />
         <Skeleton variant='rect' width='75%' />
         <Skeleton width='75%' />
         <Skeleton width='50%' />
         <Skeleton width='50%' />
         <Skeleton width='50%' />
      </>
   )
}

export default CardLoader

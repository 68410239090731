import React from 'react'
import { PseudoBox } from '@builtbypixel/plasma'
import { BiLeftArrowAlt } from 'react-icons/bi'

/**
 * The back button that brings the user back one page
 * @param {function} setSelected Function to be called when the user wants to go back a page
 */
function BackButton({ setSelected }) {
   return (
      <PseudoBox
         display='inline-block'
         mb='2rem'
         transition='color 0.15s'
         _hover={{
            color: 'parfettsRed',
            cursor: 'pointer'
         }}
         onClick={() => setSelected(null)}
      >
         <BiLeftArrowAlt fontSize='2rem' />
      </PseudoBox>
   )
}

export default BackButton

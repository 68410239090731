import React, { useState } from 'react'
import { Box, Grid, Text } from '@builtbypixel/plasma'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import BackButton from './components/BackButton'
import ResourceCard from './components/ResourceCard'

const Resources = () => {
   const [selected, setSelected] = useState()
   const { data } = useSWR(`/resources`, useGet)

   return (
      <Box>
         <Text fontSize='2rem' fontWeight='bold' mb='2rem'>
            Resources
         </Text>
         {selected && <BackButton setSelected={setSelected} />}
         <Grid
            templateColumns='repeat(3, 30%)'
            gap='2rem'
            justifyContent='center'
         >
            {!selected ? (
               <>
                  {data?.data?.map(
                     (item) =>
                        item.resources.length > 0 && (
                           <ResourceCard
                              item={item}
                              setSelected={setSelected}
                              key={item.name}
                           />
                        )
                  )}
               </>
            ) : (
               <>
                  {selected?.resources?.map((item, index) => (
                     <ResourceCard item={item} key={item.url} selected />
                  ))}
               </>
            )}
         </Grid>
      </Box>
   )
}

export default Resources

import { createCrudRoutes } from '../../hooks'
import List from './List'
import Entry from './'
import Profile from './Profile'
import setup from './setup'

const crud = createCrudRoutes({
   setup,
   name: setup.title,
   edit: Entry,
   list: List,
   view: null,
   permissions: ['Admin', 'RDA']
})

const otherRoutes = [
   {
      path: '/profile',
      title: 'Your Profile',
      exact: true,
      auth: false,
      component: Profile,
      permissions: ['Admin', 'RDA']
   }
]

const routes = [...crud, ...otherRoutes]

export default routes

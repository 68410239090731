import React from 'react'
import { Flex, Text, Spinner } from '@builtbypixel/plasma'

/**
 * A box that can be customised to display values.
 *
 * @param {String} title The title of the box
 * @param {String} value The value to be displayed
 * @param {String} bg The background color of the box
 */
const Summary = ({ title, value, bg }) => {
   return (
      <Flex
         align='center'
         justify='center'
         direction='column'
         borderRadius='1rem'
         p='1rem'
         bg={bg}
         color='white'
      >
         <Text fontWeight='bold'>{title}</Text>
         <Text fontSize='1.5rem' fontWeight='bold'>
            {value ?? <Spinner />}
         </Text>
      </Flex>
   )
}

export default Summary

import React, { useContext, useEffect, useState } from 'react'

import {
   Alert,
   Box,
   Button,
   Flex,
   Input,
   ModalContent,
   ModalContext,
   ModalFooter,
   ModalHeader,
   useToast
} from '@builtbypixel/plasma'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { useForm } from 'react-hook-form'

import { usePost } from '../../../../hooks'

/**
 * The content of the modal that handles Sending Execution Reports By Email
 * @param {Object} selectedExecution The selected execution object
 */
const Content = ({ selectedExecution }) => {
   const { handleSubmit } = useForm()

   const toast = useToast()
   const { close } = useContext(ModalContext)

   const { post, response, error, loading } = usePost()

   const [emails, setEmails] = useState([{}])

   const handleTyping = (index, value) => {
      const _emails = [...emails]
      _emails[index].value = value
      setEmails(_emails)
   }

   const handleRemove = (email) => {
      const _emails = [...emails]
      const filtered = _emails?.filter((e) => e !== email)
      setEmails(filtered)
   }

   const onSubmit = () => {
      const emailsArray = emails.map((email) => email.value)
      const payload = {
         execution: { id: selectedExecution.id },
         emails: emailsArray
      }
      post(`/send-supplier-link`, payload)
   }

   useEffect(() => {
      if (response) {
         toast({
            title: 'Email sent',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
         close()
      }
   }, [response])

   return (
      <ModalContent>
         <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Send All Reports To...</ModalHeader>
            <Box m='1rem'>
               {emails?.map((email, index) => (
                  <Flex align='center'>
                     <Input
                        value={email.value}
                        onChange={(e) => handleTyping(index, e.target.value)}
                        placeholder='Enter Email Address Here...'
                        m='0.5rem 0'
                        required
                        type='email'
                        key={email + index}
                     />
                     <Button
                        variant='ghost'
                        color='red'
                        leftIcon={<FiMinus />}
                        onClick={() => handleRemove(email)}
                     >
                        Remove
                     </Button>
                  </Flex>
               ))}
               <Button
                  mt='1rem'
                  leftIcon={<FiPlus />}
                  onClick={() => setEmails([...emails, {}])}
               >
                  Add
               </Button>
               {error && (
                  <Alert status='error' title='Error saving note' mt='20px'>
                     Failed to send email. Please try again.
                  </Alert>
               )}
            </Box>

            <ModalFooter showCloseIcon={false}>
               <Button
                  variantColor='parfettsGreen'
                  color='white'
                  type='submit'
                  isLoading={loading}
                  isDisabled={loading || emails?.length === 0}
                  loadingText='Sending...'
               >
                  Send
               </Button>
            </ModalFooter>
         </form>
      </ModalContent>
   )
}

export default Content

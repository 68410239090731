import React from 'react'
import Field from './Field'
import { Box, Text, FormLabel } from '@builtbypixel/plasma'

const AutoForm = (props) => {
   const { fields, isFullWidth } = props
   return fields.map((group, index) => (
      <Box
         key={`card=${index}`}
         p={5}
         mb={7}
         w='100%'
         maxWidth={isFullWidth ? 'none' : 600}
       
      >
         <Text mb={5} fontSize={20}>
            {group.group}
         </Text>

         {group?.help && (
            <FormLabel mb={5} mt={-3}>
               {group.help}
            </FormLabel>
         )}

         {group.fields.map(
            (field, i) =>
               !field.hidden && <Field key={`field-${i}`} {...field} auto />
         )}
      </Box>
   ))
}

export default AutoForm

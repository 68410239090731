import Report from '.'

const routes = [
   {
      path: '/dashboard/report',
      title: 'Report',
      exact: false,
      auth: false,
      component: Report
   }
]

export default routes

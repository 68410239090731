import React, { useState } from 'react'
import EditView from '../../components/EditView'
import setup from './setup'

import {
   Box,
   Flex,
   Modal,
   ModalButton,
   Button,
   ModalContent,
   useToast
} from '@builtbypixel/plasma'

import TestWhatsappMessage from './components/TestWhatsappMessage'
import CustomForm from './CustomForm'
import EditMessagePreview from './editComponents/EditMessagePreview'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHttp } from '../../hooks'
import { useHistory } from 'react-router-dom'

const EditForm = () => {
   const [preview, setPreview] = useState(null)

   const { id, type } = useParams()
   const Http = useHttp()
   const toast = useToast()
   const history = useHistory()

   const saveWhatsapp = () => {
      if (type === 'edit') {
         Http.put(`whatsapp-messages/${id}`, preview)
            .then((res) =>
               toast({
                  title: 'Message Edited Successfully',
                  status: 'success',
                  variant: 'plain'
               })
            )
            .catch((err) =>
               toast({
                  title: 'There was an error editing your message. Please try again',
                  status: 'error',
                  variant: 'plain'
               })
            )
      } else {
         Http.post(`whatsapp-messages/create`, preview)
            .then((res) => {
               toast({
                  title: 'Message Created Successfully',
                  status: 'success',
                  variant: 'plain'
               })

               history.goBack()
            })
            .catch((err) =>
               toast({
                  title: 'There was an error creating your message. Please try again',
                  status: 'error',
                  variant: 'plain'
               })
            )
      }
   }

   return (
      <EditView
         setup={setup}
         noPadding
         hideSave={true}
         extra={
            <>
               <Modal>
                  <ModalButton>
                     <Button variantColor='warning' color='white' mr='5'>
                        Test Whatsapp Message
                     </Button>
                  </ModalButton>

                  <ModalContent>
                     <TestWhatsappMessage data={preview} />
                  </ModalContent>
               </Modal>
               <Button
                  variantColor='success'
                  color='white'
                  mr='5'
                  onClick={() => saveWhatsapp()}
               >
                  Save Whatsapp Message
               </Button>
            </>
         }
      >
         <Flex p='20px' w='100%' justify='space-around'>
            <Flex direction='column' w='50%'>
               {/* <Form /> */}
               <CustomForm setPreview={setPreview} preview={preview} />
            </Flex>
            <EditMessagePreview
               source='messages'
               data={preview?.template?.content}
            />
         </Flex>
      </EditView>
   )
}

export default EditForm

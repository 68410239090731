const modelName = 'Push Notifications'
const modelNameSingular = 'Push Notification'
const endpoint = '/push-notifications'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: false,
   canAddNew: true,
   canSearch: true,
   endpoint,
   accessor: 'id',
   permissions: ['Admin']
}

export default setup

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import {
   IconButton,
   Flex,
   Text,
   Image,
   Tag,
   Button,
   Stack
} from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'
import { useRetailers } from '../../hooks'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return (
      <LinkButton to={`/${setup.model}/create`}>
         <Button variantColor='parfettsGreen' color='white'>
            New Visit
         </Button>
      </LinkButton>
   )
}

const List = () => {
   const columns = [
      {
         Header: () => null,
         id: 'edit',
         width: '50px',
         Cell: ({ row }) => {
            return (
               <Link to={`/visits/edit/${row.original.id}`}>
                  <IconButton
                     variant='ghost'
                     variantColor='primary'
                     rounded='md'
                  >
                     <FiEdit fontSize={18} />
                  </IconButton>
               </Link>
            )
         }
      },
      {
         Header: 'Business Name',
         accessor: 'retailer',
         Cell: ({ row }) =>
            row.original?.retailer?.business_name
               ? row.original?.retailer?.business_name
               : row.original?.customer_lead?.business_name
      },
      {
         Header: 'Type',
         accessor: 'customer_lead',
         Cell: ({ value }) => (value ? 'Customer Lead' : 'Retailer')
      },
      {
         Header: 'RDA',
         accessor: 'rda',
         Cell: ({ value }) => (
            <Flex align='center'>
               <Image
                  src={`${value?.profile_image?.[0]?.url}`}
                  objectFit='cover'
                  size='30px'
                  rounded='full'
                  mr='10px'
               />
               <Text minWidth='300px'>
                  {value?.first_name} {value?.last_name}
               </Text>
            </Flex>
         )
      },
      {
         Header: 'Status',
         accessor: 'completed_date',
         Cell: ({ row }) =>
            !row.original.completed_date ? (
               <Tag variantColor='parfettsRed'>Scheduled</Tag>
            ) : (
               <Stack isInline spacing='10px'>
                  <Tag variantColor='parfettsGreen'>Completed</Tag>{' '}
                  <Text>
                     {dayjs(row.original.completed_date).format(
                        'DD/MM/YYYY HH:mm'
                     )}
                  </Text>
               </Stack>
            )
      },
      {
         Header: 'Due Date',
         accessor: 'due_date',
         Cell: ({ value }) => (
            <Text>{dayjs(value).format('DD/MM/YYYY HH:mm')}</Text>
         )
      }
   ]

   const filters = useMemo(
      () => [
         {
            name: 'retailer',
            component: 'relation_select',
            placeholder: 'Type to search retailers',
            isAsync: true,
            loadOptions: useRetailers,
            labelKey: 'business_name',
            valueKey: 'id',
            minWidth: '500px'
         },
         {
            name: 'from',
            component: 'date',
            group: 'due_date',
            placeholder: 'From'
         },
         {
            name: 'to',
            group: 'due_date',
            component: 'date',
            placeholder: 'To'
         }
      ],
      [useRetailers]
   )

   return (
      <ListWrapper
         setup={setup}
         columns={columns}
         filters={filters}
         extraMenu={extraMenu}
      />
   )
}

export default List

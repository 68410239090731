import React from 'react'
import { Box, Table, ScrollContainer } from '@builtbypixel/plasma'

import TableLoader from '../../../../components/common/TableLoader'

const WeeklySales = ({ data }) => {
   const columns = [
      {
         Header: '',
         accessor: 'name'
      },
      {
         Header: 'YTD',
         accessor: 'ytd'
      },
      {
         Header: 'Last YTD',
         accessor: 'last_ytd'
      },
      {
         Header: 'Change &',
         accessor: 'change'
      },
      {
         Header: 'Total LY',
         accessor: 'total_ly'
      }
   ]


   return (
      <ScrollContainer>
         <Box>
            {data ? (
               <Table
                  data={{ data: data ? data.data : [] }}
                  hidePagination={true}
                  size='sm'
                  manualPagination={false}
                  columns={columns}
               />
            ) : (
                  <TableLoader />
               )}
         </Box>
      </ScrollContainer>
   )
}

export default WeeklySales

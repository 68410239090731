import { createCrudRoutes } from '../../hooks'
import Developments from './'
import Edit from './Edit'
import setup from './setup'

const crud = createCrudRoutes({
   setup,
   name: setup.title,
   edit: Edit,
   list: Developments,
   view: null,
   permissions: ['Admin']
})

const otherRoutes = [
   {
      path: '/executions/create',
      title: 'New Execution',
      exact: false,
      auth: true,
      component: Edit,
      permissions: ['Admin']
   }
]

const routes = [...crud, ...otherRoutes]

export default routes

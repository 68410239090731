import React from 'react'
import Entry from './'

const EntryWithProps = () =>(
   <Entry  retailer={{id:null}} />
)
const otherRoutes = [
   {
      path: '/media-library',
      title: 'Media Library',
      exact: true,
      auth: true,
      padding: false,
      component: EntryWithProps,
      permissions: ['Admin','RDA']
   },
   {
      path: '/media-library/:folder',
      title: 'Media Library',
      exact: false,
      auth: true,
      padding: false,
      component: Entry,
      permissions: ['Admin','RDA']
   }
]

const routes = [...otherRoutes]

export default routes

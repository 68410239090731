import { Text } from '@builtbypixel/plasma'
import React from 'react'

const Title = ({ text, bg = true }) => {
   const bgProps = bg
      ? {
           //   background: 'black',
           background: '#075E54',
           color: 'white',
           padding: '0.2em 1em',
           width: '200px'
        }
      : {}

   return (
      <Text fontWeight='bold' my='1em' textTransform='capitalize' {...bgProps}>
         {text}
      </Text>
   )
}

export default Title

import React from 'react'
import { Button } from '@builtbypixel/plasma'
import LinkButton from '../../LinkButton'
import { MdKeyboardArrowLeft } from 'react-icons/md'

const ReturnButton = ({ to, children }) => {
   return (
      <LinkButton to={to}>
         <Button
            variant='link'
            color='parfettsRed'
            size='lg'
            mb='10px'
            leftIcon={<MdKeyboardArrowLeft />}
         >
            {children}
         </Button>
      </LinkButton>
   )
}

export default ReturnButton

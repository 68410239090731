import React from 'react'

import { Flex, Grid, Image, Text, Box } from '@builtbypixel/plasma'

const ProductCard = ({
   product,
   removeItem,
   handleRemoveProduct,
   hideRemoveButton
}) => (
   <Grid
      width='100%'
      templateColumns='120px 1fr 100px 100px'
      overflow='hidden'
      m='5px'
      position='relative'
      overflow='visible'
      key={`product-${product.id}`}
   >
      {/* {!hideRemoveButton && (
      <IconButton
        colorScheme='orange'
        aria-label='Call Segun'
        size='sm'
        rounded='full'
        position='absolute'
        right='-10px'
        top='-10px'
        zIndex='15'
        icon={<FiMinus />}
        onClick={() =>
          handleRemoveProduct
            ? handleRemoveProduct(product)
            : removeItem(product)
        }
      />
    )} */}

      <Box>
         <Image
            height='100px'
            src={
               'https://api2.parfetts.co.uk/images/products/' +
               product?.product_id +
               '.jpg'
            }
            alt='x'
         />
      </Box>

      <Flex direction='column' justifyContent='center'>
         <Flex direction='row'>
            <Text
               color='gray.500'
               fontWeight='semibold'
               letterSpacing='wide'
               textTransform='uppercase'
               mr='1'
            >
               #{product.product_id}
            </Text>
            <Text
               borderRadius='full'
               px='2'
               colorScheme='teal'
               textAlign='left'
            >
               {product?.description}
            </Text>
         </Flex>

         <Text
            color='gray.500'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='sm'
         >
            Size: {product.case} x {product.size}
         </Text>
         <Text
            color='gray.500'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='sm'
         >
            Price Mark: {product.pricemark}
         </Text>
         {product.abv && (
            <Text
               color='gray.500'
               fontWeight='semibold'
               letterSpacing='wide'
               fontSize='sm'
            >
               ABV: {product.abv}
            </Text>
         )}
      </Flex>
      <Flex direction='column' justifyContent='center'>
         <Text>{product.retail_one}</Text>
      </Flex>
      <Flex direction='column' justifyContent='center'>
         <Text>{product.retail_two}</Text>
      </Flex>
   </Grid>
)

export default ProductCard

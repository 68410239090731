import React, { useState, memo } from 'react'
import Table from './Table'
import { Box, ScrollContainer } from '@builtbypixel/plasma'
import ChartToggle from '../../../../components/common/ChartToggle'
import Chart from './Chart'
import useSWR from 'swr'
import { useGet } from '../../../../hooks'
import { useParams } from 'react-router-dom'

const WeeklySales = memo(() => {
    const [mode, setMode] = useState('table')

    const { id } = useParams()
    const { data } = useSWR(`/products/sales/week/${id}`, useGet)

    return (
        <>
            <ChartToggle setMode={setMode} mode={mode} />
            {mode === 'table' ? (
                <Box
                    border='1px'
                    borderColor='global.borderColour'
                    rounded='lg'
                    p='10px'
                    w='100%'
                >
                    <ScrollContainer>
                        <Table data={data} />
                    </ScrollContainer>
                </Box>
            ) : (
                    <Chart data={data} />
                )}
        </>
    )
})

export default WeeklySales

import { atom } from 'recoil'

const dayjs = require('dayjs')


const calendarState = atom({
    key: 'calendarState',
    default: {
       selectedDate: dayjs(),
       mode: 'month',
       showTasks: true,
       showMilestones: true,
       showVisits: true,
       events:[]
    }
 })
 export const calendarEvents = atom({
   key: 'calendarEvents',
   default: {
      events:[]
   }
})
 
 export default calendarState
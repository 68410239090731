import React from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { Box, Flex, Button, Text, IconButton } from '@builtbypixel/plasma'
import { Link } from 'react-router-dom'
import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'

const columns = [
   {
      Header: 'Name',
      accessor: 'name'
   },
   {
      Header: 'Customer Total',
      accessor: 'to_be_sent'
   },
   {
      Header: 'Total Sent',
      accessor: 'total_sent'
   },
   {
      Header: 'Total Delivered',
      accessor: 'total_delivered'
   },
   {
      Header: 'Total Opened',
      accessor: 'total_opened'
   },
   {
      Header: 'Scheduled',
      accessor: 'scheduled',
      Cell: ({ row }) => {
         return row?.original?.scheduled == true ? 'Yes' : 'No'
      }
   },
   {
      Header: 'Send Date',
      accessor: 'send_date',
      Cell: ({ row }) => {
         return <>
            <Box>
               <Text>{row.original?.send_date}</Text>
               <Text>{row.original?.send_time}</Text>
            </Box>
         </>
      }
   },
   {
      Header: 'Edit',
      accessor: 'id',
      Cell: ({ row }) => {
         return (
            <Link to={`whatsapp-messages/edit/${row.original.id}`}>
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </Link>
         )
      }
   }
]

const Comp = () => {
   const filters = []

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='WhatsApp Messages' />
            </Box>
            <Flex>
               <LinkButton to={`/${setup.model}/create`}>
                  <Button ml='5px' bg='parfettsRed' color='white'>
                     Add New Message
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Flex justify='space-between'>
            <Box flex='6'>
               <Table
                  columns={columns}
                  setup={setup}
                  onRowSelect={null}
                  filters={filters}
               />
            </Box>
         </Flex>
      </>
   )
}

export default Comp

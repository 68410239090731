import React, { memo, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
   FormControl,
   FormLabel,
   PseudoBox,
   PopoverTrigger,
   PopoverContent,
   Popover,
   useColorMode,
   FormHelperText,
   FormErrorMessage
} from '@builtbypixel/plasma'
import { ErrorMessage } from '@hookform/error-message'

import Text from '../../Fields/Text'
import Date from '../../Fields/DatePicker'
import Time from '../../Fields/TimePicker'
import NativeSelect from '../../Fields/NativeSelect'
import Select from '../../Fields/Select'
import TextArea from '../../Fields/TextArea'
import RichText from '../../Fields/RichText'
import File from '../../Fields/File'
import Switch from '../../Fields/Switch'
import { Repeater } from '../../Fields/Repeater'
import Geoloc from '../../Fields/Geoloc'
import FacebookPagesSelect from '../../Fields/FacebookPagesSelect'
import Markdown from '../../Fields/Markdown'

const Field = memo((props) => {
   const {
      component,
      label,
      name,
      isRequired,
      defaultValue,
      guidance,
      help,
      rules,
      auto = false,
      isHidden,
      ...rest
   } = props
   const { errors, control } = useFormContext({ mode: 'onChange' })
   const { colorMode } = useColorMode()
   const [active, setActive] = useState(false)
   const [splitLabel, setSplitLabel] = useState(false)

   const borderColour =
      colorMode === 'dark' ? 'whiteAlpha.300' : 'blackAlpha.200'

   const getField = (props) => {
      switch (component) {
         case 'facebookSelect':
            return <FacebookPagesSelect setActive={setActive} {...props} />
         case 'geoloc':
            return <Geoloc setActive={setActive} {...props} />
         case 'text':
            return <Text setActive={setActive} {...props} />
         case 'date':
            return <Date setActive={setActive} {...props} />
         case 'time':
            return <Time setActive={setActive} {...props} />
         case 'nativeSelect':
            return <NativeSelect setActive={setActive} {...props} />
         case 'select':
            return <Select setActive={setActive} {...props} />
         case 'textarea':
            return <TextArea setActive={setActive} {...props} required={null} />
         case 'richtext':
            return <RichText setActive={setActive} active={active} {...props} />
         case 'markdown':
            return <Markdown setActive={setActive} active={active} {...props} />
         case 'switch':
            return <Switch setActive={setActive} active={active} {...props} />
         case 'file':
            return <File setActive={setActive} active={active} {...props} />
         case 'repeater':
            return (
               <Repeater
                  setActive={setActive}
                  active={active}
                  name={name}
                  {...props}
               />
            )
         default:
            const Comp = component
            return <Comp />
      }
   }

   return (
      <Controller
         name={name}
         defaultValue={defaultValue || ''}
         rules={rules}
         control={control}
         render={({ onChange, onBlur, value, ref }) => (
            <PseudoBox
               borderLeft={!auto ? 'none' : '2px'}
               transition='all 0.3s ease-in-out'
               borderColor={
                  errors[name] ? 'error' : active ? 'secondary' : borderColour
               }
               px={!auto ? 0 : 5}
               py={isHidden ? 0 : !auto ? 0 : 2}
               mb={!auto ? 0 : 3}
               w='100%'
               h={isHidden && 0}
               overflow={isHidden && 'hidden'}
            >
               <Popover
                  arrow
                  placement='bottom'
                  size='md'
                  trigger={guidance ? 'click' : null}
               >
                  <FormControl isRequired={isRequired} mb={9}>
                     {label && label.includes('\n') ? (
                        <>
                           {label.split('\n')[0] && (
                              <FormLabel htmlFor={name}>
                                 {label.split('\n')[0]}
                              </FormLabel>
                           )}
                           {label.split('\n')[1] && (
                              <FormLabel htmlFor={name}>
                                 {label.split('\n')[1]}
                              </FormLabel>
                           )}
                        </>
                     ) : (
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                     )}

                     <PopoverTrigger w='100%'>
                        {getField({ onChange, onBlur, value, ref, ...rest })}
                     </PopoverTrigger>

                     {help && <FormHelperText>{help}</FormHelperText>}

                     <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                           <FormErrorMessage>
                              {message ? message : 'Required'}
                           </FormErrorMessage>
                        )}
                     />
                  </FormControl>
                  <PopoverContent
                     bg='secondary'
                     color='white'
                     px={1}
                     py={1}
                     textAlign='center'
                     fontSize='sm'
                  >
                     {guidance}
                  </PopoverContent>
               </Popover>
            </PseudoBox>
         )}
      />
   )
})

export default Field

import { Flex, Image, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const TemplateMediaPreview = ({ headerFormat }) => {
   const [mediaToRender, setMediaToRender] = useState(null)
   const { control } = useFormContext()

   const allFields = useWatch({ control })

   const media = allFields?.content?.[0]?.example?.header_handle?.[0]

   useEffect(() => {
      setMediaToRender(media?.url ? media?.url : media)
   }, [media])

   return (
      <Flex background={'#ccd0d5'} width='100%' justify='center' py='1em'>
         <Image
            src={
               headerFormat?.value === 'IMAGE'
                  ? mediaToRender?.url
                     ? mediaToRender?.url
                     : mediaToRender
                     ? mediaToRender
                     : '/images/wa_img_placeholder.png'
                  : headerFormat?.value === 'VIDEO'
                  ? '/images/wa_vid_placeholder.png'
                  : '/images/wa_doc_placeholder.png'
            }
         />
      </Flex>
   )
}

export default TemplateMediaPreview

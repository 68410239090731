import React, { useEffect, useMemo, useState } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import { useHttp } from '../../hooks'
import setup from './setup'
import { useAppRetailers, useProducts } from '../../hooks'
import {
   Text,
   Button,
   Modal,
   ModalButton,
   ModalContent
} from '@builtbypixel/plasma'
import BranchSelect from './components/BranchSelect'
import PriceSchemeSelect from './components/PriceSchemeSelect'
import TestNotification from './TestNotification'

const Http = useHttp()

const EditForm = () => {
   const [promotions, setPromotions] = useState([])

   useEffect(() => {
      const fetch = async () => {
         const res = await Http.get('all-price-promotions')
         setPromotions(res.data.data)
      }
      fetch()
   }, [])

   const formatProductLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.id}</span> {' - '}{' '}
         {option.description}
      </Text>
   )

   const formatRetailerLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.customer?.name}</span>{' '}
         {' - '} {option.branch?.name}
      </Text>
   )

   const formatPricePromotionLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.id}</span> {' - '}{' '}
         {option.name}
      </Text>
   )

   const fields = useMemo(
      () => [
         {
            group: 'Notification Details',
            tab: 'Details',
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'description',
                  component: 'text',
                  placeholder: 'Description',
                  label: 'Description',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'site',
                  component: 'select',
                  placeholder: 'Site',
                  options: [
                     {
                        id: 1,
                        name: 'Parfetts'
                     },
                     {
                        id: 2,
                        name: 'Gold'
                     }
                  ],
                  labelKey: 'name',
                  valueKey: 'id',
                  label: 'Site',
                  help: 'Please ignore this option if you wish to select both sites'
               },
               {
                  name: 'branches',
                  component: BranchSelect
               },
               {
                  name: 'price_schemes',
                  component: PriceSchemeSelect
               },
               {
                  name: 'products',
                  component: 'select',
                  placeholder: 'Products',
                  label: 'Products',
                  isAsync: true,
                  loadOptions: useProducts,
                  labelKey: 'name',
                  valueKey: 'id',
                  formatOptionLabel: formatProductLabel,
                  isMulti: true
               },
               {
                  name: 'retailers',
                  component: 'select',
                  placeholder: 'Retailer',
                  help: 'Please ignore this option if you wish to select all retailers',
                  label: 'Retailer',
                  isAsync: true,
                  loadOptions: useAppRetailers,
                  labelKey: 'business_name',
                  valueKey: 'id',
                  formatOptionLabel: formatRetailerLabel,
                  isMulti: true
               },
               {
                  name: 'pdf',
                  component: 'file',
                  placeholder: 'Promotion PDF',
                  label: 'Promotion PDF'
               },
               {
                  name: 'price_promotion',
                  component: 'select',
                  placeholder: 'Price Promotion',
                  label: 'Price Promotion',
                  isAsync: false,
                  options: promotions,
                  labelKey: 'name',
                  valueKey: 'id'
               }
            ]
         }
      ],
      [promotions]
   )

   return (
      <>
         <EditView
            fields={fields}
            setup={setup}
            noPadding
            extra={
               <Modal>
                  <ModalButton>
                     <Button variantColor='warning' color='white' mr='5'>
                        Test Push Notification
                     </Button>
                  </ModalButton>

                  <ModalContent>
                     <TestNotification />
                  </ModalContent>
               </Modal>
            }
         >
            <AutoFields fields={fields} />
         </EditView>
      </>
   )
}

export default EditForm

import React, { useEffect } from 'react'
import { Field } from '../../components/Form'
import { Text, Flex, Box, Card } from '@builtbypixel/plasma'
import { useWatch, useFormContext } from 'react-hook-form'
import ImageGallery from '../../components/ImageGallery'
import { useGet, useRDAS, useRetailers } from '../../hooks'
import { useParams, useLocation } from 'react-router-dom'
import useSWR from 'swr'

const debounce = require('lodash.debounce')

const Form = () => {
   const { setValue, register } = useFormContext()
   const { data } = useSWR('/development-statuses', useGet)
   const { data: fascias } = useSWR('/fascias', useGet)
   const { type } = useParams()

   const { state } = useLocation()
   const rda = useWatch({ name: 'rda' })
   const customer = useWatch({ name: 'customer' })

   const formatRDAOptionLabel = (option) => (
      <Text>
         {option?.first_name} {option?.last_name}{' '}
      </Text>
   )
   useEffect(() => {
      // Lets set the retailer dropdown value if we pass a retailer id with the URL
      if (state) {
         register({ name: 'retailer' })
         setValue('retailer', state.retailer)
      }
   }, [register, setValue, state])

   return (
      <>
         {type === 'create' ? (
            <>
               <Field
                  component='select'
                  name='customer'
                  isAsync
                  placeholder='Search by ID, or Name'
                  label='Retailer'
                  loadOptions={useRetailers}
                  labelKey='business_name'
                  valueKey='id'
                  size='lg'
                  rules={{
                     required: true,
                     message: 'You must select a retailer'
                  }}
               />
            </>
         ) : (
            <Box mb='10px'>
               <Text>Retailer</Text>
               <Text fontWeight='bold'>{customer?.business_name}</Text>
            </Box>
         )}
         {type === 'create' ? (
            <>
               <Field
                  component='select'
                  name='rda'
                  isAsync
                  placeholder='Search RDA by Name'
                  label='RDA'
                  loadOptions={useRDAS}
                  labelKey='name'
                  valueKey='id'
                  size='lg'
                  rules={{
                     required: true,
                     message: 'You must select an RDA'
                  }}
                  formatOptionLabel={formatRDAOptionLabel}
               />
            </>
         ) : (
            <Box mb='10px'>
               <Text>RDA</Text>
               <Text fontWeight='bold'>
                  {rda?.first_name} {rda?.last_name}
               </Text>
            </Box>
         )}

         <Field
            component='select'
            name='fascia'
            placeholder='Select fascia type'
            label='Fascia'
            options={fascias?.data}
            labelKey='name'
            valueKey='id'
            size='lg'
            rules={{
               required: true,
               message: 'You must select a retailer'
            }}
         />
         <Text>Signage</Text>
         <Flex>
            <Field
               component='date'
               name='signage.date'
               placeholder='Visit Date'
               label='Due Date'
               rules={{ required: true }}
            />
            <Field
               component='select'
               name='signage.status'
               placeholder='Select status'
               label='Status'
               options={data?.data}
               labelKey='name'
               valueKey='id'
               size='lg'
               rules={{
                  required: true,
                  message: 'You must select a status'
               }}
            />
         </Flex>

         <Text>Fitting</Text>
         <Flex>
            <Field
               component='date'
               name='fitting.date'
               placeholder='Visit Date'
               label='Due Date'
               rules={{ required: true }}
            />
            <Field
               component='select'
               name='fitting.status'
               placeholder='Select status'
               label='Status'
               options={data?.data}
               labelKey='name'
               valueKey='id'
               size='lg'
               rules={{
                  required: true,
                  message: 'You must select a status'
               }}
            />
         </Flex>
         <Text>Artwork</Text>
         <Flex>
            <Field
               component='date'
               name='artwork.date'
               placeholder='Visit Date'
               label='Due Date'
               rules={{ required: true }}
            />
            <Field
               component='select'
               name='artwork.status'
               placeholder='Select status'
               label='Status'
               options={data?.data}
               labelKey='name'
               valueKey='id'
               size='lg'
               rules={{
                  required: true,
                  message: 'You must select a status'
               }}
            />
         </Flex>
         <Flex mt='24px'>
            <Card title='All Retailer Images'>
               <ImageGallery
                  id={customer?.id}
                  endpoint='retailers-images'
                  type='retailer'
                  noget
               />
            </Card>
         </Flex>
      </>
   )
}

export default Form

import React, { memo } from 'react'
import { Text, Skeleton } from '@builtbypixel/plasma'

const ViewTitle = memo(({ title }) => {
   return title ? (
      <Text as='h1' fontSize={{ sm: '1.5em', md: '2em' }} fontWeight='bold'>
         {title}
      </Text>
   ) : (
      <Skeleton
         reverse
         animation='pulse'
         maxWidth='300px'
         h='30px'
         variant='rect'
      />
   )
})

export default ViewTitle

import React from 'react'
import EditView from '../../components/EditView'
import setup from './setup'

import { Box } from '@builtbypixel/plasma'
import Form from './Form'

const EditForm = () => {
    return (
        <EditView setup={setup} noPadding>
            <Box p='20px' w='100%'>
                <Form />
            </Box>
        </EditView>
    )
}

export default EditForm
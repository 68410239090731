import React, {
   useCallback,
   memo,
   cloneElement,
   Children,
   isValidElement
} from 'react'

import { Form } from '../../components/Form'
import {
   Flex,
   Button,
   Text,
   Box,
   Skeleton,
   Stack,
   IconButton,
   ScrollContainer
} from '@builtbypixel/plasma'
import useSWR from 'swr'
import { useGet, useHttp } from '../../hooks'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useParams, useHistory } from 'react-router-dom'
import InfoBar from './InfoBar'
import DeveloperTools from './DeveloperTools'
import MoreOptions from './MoreOptions'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import { formAtom } from '../../state/form'
import { useSetRecoilState, useRecoilValue } from 'recoil'

const SubmitButton = memo(({ setup }) => {
   const params = useParams()
   const { loading } = useRecoilValue(formAtom)
   const isEdit = params.type === 'edit'
   const { submit } = useFormContext()

   return (
      <Button
         variantColor='success'
         color='white'
         onClick={() => submit()}
         isLoading={loading}
         loadingText='Submitting '
      >
         {isEdit ? `Save ${setup.singular}` : `Submit ${setup.singular}`}
      </Button>
   )
})

const FormColumn = memo((props) => {
   const { isEdit, data, children } = props
   return (
      <ScrollContainer>
         <Flex
            w='100%'
            h='calc(100vh - 200px)'
            pb='100px'
            flex={1}
            align='center'
            direction='column'
         >
            {isEdit ? (
               data ? (
                  children
               ) : (
                  <Box w='90%' h='100%' maxWidth={600} mt={10}>
                     <Skeleton variant='rect' h='100px' />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                     <Skeleton />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                  </Box>
               )
            ) : (
               Children.map(children, (child) => {
                  if (isValidElement(child)) {
                     return cloneElement(child, props)
                  }
                  return child
               })
            )}
         </Flex>
      </ScrollContainer>
   )
})

const EditView = memo((props) => {
   const {
      children,
      setup,
      isFullWidth,
      noPadding,
      extra,
      redirectBackOnSave,
      hideAddNew,
      autoHeight,
      hideSave
   } = props
   const params = useParams()
   const Http = useHttp()
   const isEdit = params.type === 'edit'
   const isCreate = params.type === 'create'

   const history = useHistory()
   const setFormState = useSetRecoilState(formAtom)

   const { data } = useSWR(
      params.type === 'edit'
         ? `${setup.endpoint}/${
              params?.branchId ? params?.branchId + '/' + params.id : params.id
           }`
         : null,
      useGet
   )

   React.useState(() => {
      setFormState((old) => ({ ...old, errors: null, success: false }))
   }, [])

   /* eslint-disable */
   const onSubmit = useCallback((values) => {
      setFormState((old) => ({ ...old, loading: true }))
      if (isEdit) {
         Http.put(
            `${setup.endpoint}/${
               params?.branchId ? params?.branchId + '/' + params.id : params.id
            }`,
            values
         )
            .then((res) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))
               if (redirectBackOnSave) {
                  history.goBack()
               }
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
      if (isCreate) {
         Http.post(setup.endpoint + '/create', values)
            .then((res) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))

               history.goBack()
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
   }, [])

   return (
      <Form data={data && data.data} onSubmit={onSubmit}>
         <Flex
            w={noPadding ? 'calc(100% + 40px)' : '100%'}
            bg='global.elementBg'
            h='calc(100vh - 58px)'
            overflow='hidden'
            align='center'
            direction='column'
            m={noPadding ? '-20px' : '0px'}
         >
            <Flex
               w='100%'
               align='center'
               px={2}
               h={50}
               flexShrink={0}
               flexGrow={0}
               borderBottom='1px'
               borderColor='global.borderColour'
            >
               <IconButton
                  variant='ghost'
                  rounded='full'
                  mr={2}
                  onClick={() => history.goBack()}
               >
                  <MdKeyboardArrowLeft fontSize='30px' />
               </IconButton>

               <Text
                  as='h1'
                  fontSize='lg'
                  fontWeight='semibold'
                  display={{ xs: 'none', md: 'inline-block' }}
               >
                  {isEdit ? 'Edit' : 'Create'} {setup.singular}
               </Text>
               <Stack ml='auto' isInline spacing='10px' mr='10px'>
                  <MoreOptions setup={setup} hideAddNew={hideAddNew} />
                  {extra}
                  {!hideSave && <SubmitButton setup={setup} />}
               </Stack>
            </Flex>

            <ErrorMessage />
            <SuccessMessage />

            <Flex w='100%' h={autoHeight ? 'auto' : '100%'} flex={1}>
               <FormColumn
                  data={data}
                  isEdit={isEdit}
                  children={children}
                  isFullWidth={isFullWidth}
               />
               <InfoBar />

               {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                  <Flex
                     direction='column'
                     w='100%'
                     h='100%'
                     overflowY='scroll'
                     maxWidth='300px'
                     flexGrow={0}
                     bg='grayBg'
                     position='relative'
                     right={0}
                     top={0}
                     display={{ xs: 'none', xl: 'block' }}
                     transform='translateX(0%)'
                  >
                     <DeveloperTools />
                  </Flex>
               )}
            </Flex>
         </Flex>
      </Form>
   )
})
export default EditView

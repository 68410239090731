import React, { useEffect, useState, memo } from 'react'
import {
   PseudoBox,
   Flex,
   Text,
   Tag,
   useToast,
   Checkbox,
   Modal,
   ModalButton,
   ModalContent
} from '@builtbypixel/plasma'

import AddEditTask from '../../components/AddEditTask'

import dayjs from 'dayjs'
import { usePost } from '../../hooks'

const TaskItem = memo(({ item, index, short, successCallback, retailer }) => {
   const toast = useToast()
   const { put, response, errors, loading } = usePost()
   const [stateItem, setStateItem] = useState(item)

   const toggleComplete = (index, value) => {
      let _item = { ...stateItem }
      let obj = { ..._item, complete: value }
      _item = obj
      setStateItem(_item)
      put(`/tasks/${item.id}`, _item)
   }

   useEffect(() => {
      if (errors) {
         toast({
            title: 'Action not completed',
            message: 'An error occurred',
            status: 'error',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [errors, toast])

   return (
      <Modal>
         <ModalButton>
            <Flex
               direction='column'
               key={`note-${index}`}
               mb='20px'
               cursor='pointer'
            >
               <Flex align='center'>
                  {!short && (
                     <Flex align='center' mb='0px'>
                        <Text fontSize='sm' fontWeight='semibold' mr='10px'>
                           {item.user?.first_name} {item.user?.last_name}
                        </Text>

                        <Text fontSize='xs' opacity={0.5}>
                           Due:{' '}
                           {dayjs(item.due_date).format('DD/MM/YYYY HH:mm')}
                        </Text>
                     </Flex>
                  )}
               </Flex>
               <PseudoBox
                  rounded='lg'
                  p='10px'
                  mt='5px'
                  bg='grayBg'
                  position='relative'
                  zIndex={1}
                  display='flex'
                  alignItems='center'
               >
                  <Checkbox
                     size='lg'
                     checked={stateItem.complete}
                     value={stateItem.complete}
                     onChange={(e) => toggleComplete(index, e.target.checked)}
                     isDisabled={loading}
                  />
                  <Flex justify='space-between' w='100%' alignItems='center'>
                     <Text
                        fontSize='md'
                        ml='10px'
                        textDecoration={stateItem.complete && 'line-through'}
                     >
                        {item.description}
                     </Text>
                     <Tag
                        variantColor={item?.category?.colour}
                        variant='solid'
                        size='xs'
                        mr='10px'
                     >
                        {item?.category?.name}
                     </Tag>
                  </Flex>
               </PseudoBox>
            </Flex>
         </ModalButton>
         <ModalContent>
            <AddEditTask edit data={item} successCallback={successCallback} />
         </ModalContent>
      </Modal>
   )
})

export default TaskItem

import React, { useEffect } from 'react'
import { Grid, Button, useToast } from '@builtbypixel/plasma'
import { Form, Field } from '../Form'
import Card from '../common/Card'
import useSWR, { mutate } from 'swr'
import { useGet, usePost, useHttp } from '../../hooks'
import { useFormContext } from 'react-hook-form'
import { useState } from 'react'

const ImageGallery = ({ id, endpoint, type }) => {
   const { data: images } = useSWR(`/${endpoint}/${id}`, useGet)
   const [formData, setFormData] = useState(null)

   const { post, put, loading, response } = usePost()
   const toast = useToast()
   const Http = useHttp()

   const handleSubmit = (data) => {
      console.log(data, 'FORM SUBMTI', typeof data, { outside: 'ne' })
      setFormData(data)
   }
   useEffect(() => {
      if (formData) {
         console.log(formData, 'handle submit', type)

         formData[type] = { id: id }
         if (images?.data?.id || response) {
            put(`/store-images/${images.data.id}`, formData)
         } else {
            console.log(formData, 'handle submit IIIIII ', type)

            Http.post('/store-images', formData)
         }
      }
   }, [formData])
   useEffect(() => {
      if (response) {
         toast({
            title: 'Success',
            message: 'Image uploaded',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
         mutate(`/${endpoint}/${id}`)
      }
   }, [response])
   return (
      <Card>
         <Form data={images?.data?.id && images.data} onSubmit={handleSubmit}>
            <Grid
               isInline
               spacing='10px'
               templateColumns='repeat(12, 1fr)'
               gap='15px 15px'
            >
               <Card gridColumn='span 4' title='Outside'>
                  <Field
                     component='file'
                     name='outside'
                     max={20}
                     placeholder='Add more images'
                     label={images ? 'Add more images' : 'Add images'}
                  />
               </Card>
               <Card gridColumn='span 4' title='Inside'>
                  <Field
                     component='file'
                     name='inside'
                     max={20}
                     placeholder='Add more images'
                     label={images ? 'Add more images' : 'Add images'}
                  />
               </Card>

               <Card gridColumn='span 4' title='Other'>
                  <Field
                     component='file'
                     name='general'
                     max={20}
                     placeholder='Add more images'
                     label={images ? 'Add more images' : 'Add images'}
                  />
               </Card>
            </Grid>

            <SubmitButton buttonLabel='Submit' loading={loading} />
         </Form>
      </Card>
   )
}

const SubmitButton = ({ loading, buttonLabel }) => {
   const { submit } = useFormContext()

   return (
      <Button
         mt='15px'
         p='15px'
         variantColor='parfettsGreen'
         color='white'
         maxWidth='100px'
         isLoading={loading}
         loadingText='Saving File'
         onClick={() => submit()}
      >
         {buttonLabel}
      </Button>
   )
}
export default ImageGallery

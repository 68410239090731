import { useEffect, useState } from 'react'

import {
    useRecoilState,
} from 'recoil';
import { facebookUserAtom } from '../../../state/facebook.js'


export const useFBPages = () => {
    //  const name = useWatch({ name: 'name' })
    const [pages, setPages] = useState([]);
    const [fbUser, setFbUser] = useRecoilState(facebookUserAtom);


    useEffect(() => {
        if (fbUser?.userID) {
            window.FB.api(`${fbUser.userID}/accounts`, function (response) {
                if (response && !response.error) {
                    setPages(response.data);
                }
            })
        }

        return () => {
            // unmount
        }
    }, [fbUser])

    return pages;


}


import React, { useEffect, useState } from 'react'
import { Field } from '../../components/Form'
import { useHttp } from '../../hooks'

const Form = () => {
   const Http = useHttp()

   return (
      <>
         <Field component='text' name='name' placeholder='Name' label='Name' />

         <Field
            component='textarea'
            name='customers'
            placeholder='Customers'
            label='Customers'
         />
      </>
   )
}

export default Form

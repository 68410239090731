import React from 'react'
import { Skeleton, Grid } from '@builtbypixel/plasma'
import CalendarHeader from './CalendarHeader'
import WeekdayIndicator from './WeekdayIndicator'

import calendarState from '../utils/calendarState'
import { useRecoilValue } from 'recoil'

const CardLoader = () => {
   const { mode } = useRecoilValue(calendarState)
   let fakeDays = []
   let max = mode === 'month' ? 42 : mode === 'week' ? 7 : 1
   for (let index = 0; index < max; index++) {
      fakeDays.push(
         <Skeleton
            variant='rect'
            key={`cal-day-${index}`}
            height={mode === 'month' ? '75px' : '600px'}
         />
      )
   }
   return (
      <>
         <CalendarHeader />
         <Grid templateColumns='repeat(7, minmax(0, 1fr))' gap='15px' w='100%'>
            <WeekdayIndicator />
         </Grid>
         <Grid
            templateColumns={
               mode !== 'day' ? 'repeat(7, minmax(auto, 1fr))' : 'auto'
            }
            gap='15px'
         >
            {fakeDays}
         </Grid>
      </>
   )
}

export default CardLoader

/* eslint-disable */

import React, { useEffect, useState } from 'react'

import {
   Button,
   Flex,
   Icon,
   Select,
   Switch,
   Tooltip
} from '@builtbypixel/plasma'

import { BiCalendar, BiGroup, BiNetworkChart, BiStore } from 'react-icons/bi'
import { GiCigarette } from 'react-icons/gi'
import { useGet, usePost } from '../../hooks'
import useSWR from 'swr'

const ChartFilters = ({
   fascia,
   setFinancial,
   setFascia,
   setSelectedUser,
   setSelectedBranch,
   selectedUser,
   selectedBranch,
   sop,
   setSop,
   financial,
   noEditRDA
}) => {
   const [users, setUsers] = useState([])
   //const [sop, setSop] = useState(null)

   const { post, response } = usePost()

   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name

   const handleSelectUser = (selected) => {
      setSelectedUser(selected)
   }
   const handleSelectBranch = (selected) => {
      setSelectedBranch(selected)
   }
   const handleSetSop = (selected) => {
      setSop(!sop)
   }
   const handleToggleFascia = () => {
      setFascia(!fascia)
   }
   const { data: branch } = useSWR('/branches', useGet)

   useEffect(() => {
      post('/users', {
         filters: {
            type_id: 2
         },
         pagination: 9999
      })
   }, [])

   useEffect(() => {
      if (response) {
         setUsers(response?.data?.data?.filter((el) => el.rda_id))
      }
   }, [response])
   return (
      <>
         <Flex>
            <Flex justify='center' align='center'>
               <Tooltip title='Fascia'>
                  <Flex justify='center' align='center'>
                     <BiStore />
                     <Switch
                        ml='5px'
                        onChange={handleToggleFascia}
                        value={fascia}
                     />
                  </Flex>
               </Tooltip>
            </Flex>
            <Flex justify='center' align='center' ml='5px'>
               <Icon mr='3px' fontSize='18px'>
                  <GiCigarette />
               </Icon>
               {/* <Select
                  labelKey='label'
                  options={[
                     { label: 'Only Cigarettes', value: true },
                     { label: 'Everything less Cigrarettes', value: false }
                  ]}
                  onChange={(item) => handleSetSop(item)}
                  value={sop}
                  valueKey='value'
                  placeholder='All'
               /> */}
               <Switch ml='5px' onChange={handleSetSop} value={sop} />
            </Flex>
            <Flex justify='center' align='center' ml='5px'>
               <Icon mr='3px' fontSize='18px'>
                  <BiGroup />
               </Icon>
               <Select
                  options={users}
                  value={selectedUser}
                  onChange={(el) => handleSelectUser(el)}
                  labelKey='first_name'
                  valueKey='id'
                  isDisabled={noEditRDA}
                  formatOptionLabel={formatOptionLabel}
               />
            </Flex>

            <Flex justify='center' align='center' ml='10px'>
               <Icon mr='3px' fontSize='18px'>
                  <BiNetworkChart />
               </Icon>
               <Select
                  options={branch?.data}
                  value={selectedBranch}
                  onChange={(el) => handleSelectBranch(el)}
                  labelKey='name'
                  valueKey='id'
                  ml='5px'
               />
            </Flex>

            <Button
               onClick={() => setFinancial(!financial)}
               leftIcon={<BiCalendar />}
               variant='ghost'
               ml='5px'
            >
               View {financial ? 'Calendar Year' : 'Financial Year'}
            </Button>
         </Flex>
      </>
   )
}

export default ChartFilters

import React, { Suspense, useState } from 'react'
import { Box, Flex, Input } from '@builtbypixel/plasma'

import BlockLoader from '../../../components/common/BlockLoader'
import ErrorBoundary from '../../../components/common/ErrorBoundary'
import ViewTitle from '../../../components/common/ViewTitle'

import Comp from './Comp'

const RetailerExecutions = () => {
   const [searchText, setSearchText] = useState('')

   const handleChange = (e) => {
      setSearchText(e.target.value)
   }
   return (
      <Box>
         <Flex justifyContent='space-between'>
            <Box w='100%' mb='10px'>
               <ViewTitle title='RDA Execution Report' />
            </Box>
            <Input
               placeholder='Filter by Go Local Period'
               value={searchText}
               onChange={(e) => handleChange(e)}
               mb='25px'
            />
         </Flex>

         <ErrorBoundary>
            <Suspense fallback={<BlockLoader columns={1} />}>
               <Comp searchText={searchText} />
            </Suspense>
         </ErrorBoundary>
      </Box>
   )
}

export default RetailerExecutions

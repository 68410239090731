import React, { useMemo } from 'react'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import { IconButton, Button, Flex, Tag, Text } from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import ViewTitle from '../../components/common/ViewTitle'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
      {
         Header: 'Name',
         accessor: 'name',
         Cell: ({ row }) => (
            <LinkButton
               to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
               <Button variant='link'>{row?.original?.name}</Button>
            </LinkButton>
         )
      },
      {
         Header: 'Go Local Period',
         accessor: 'gl_period'
      },
      {
         Header: 'Scheme',
         accessor: 'scheme'
      },
      {
         Header: 'Edit',
         Cell: ({ row }) => (
            <LinkButton
               to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </LinkButton>
         )
      }
   ]
   const filters = useMemo(
      () => [
         {
            component: 'text',
            name: 'gl_period',
            placeholder: 'Search by go local period',

            labelKey: 'scheme',
            valueKey: 'id'
         },
         {
            name: 'scheme',
            placeholder: 'Search by scheme',

            component: 'select',
            options: [
               { name: 'G1' },
               { name: 'G2' },
               { name: 'G3' },
               { name: 'TL' }
            ],
            labelKey: 'name',
            valueKey: 'name'
         }
      ],
      []
   )

   return (
      <>
         <Flex mb='20px' align='center'>
            <ViewTitle title={setup.title} />
            <LinkButton to={`/${setup.model}/create`}>
               <Button variantColor='success' color='white' ml='auto'>
                  Add New {setup.singular}
               </Button>
            </LinkButton>
         </Flex>
         <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            filters={filters}
         />
      </>
   )
}

export default List

import { Box, Flex, Input, Text } from '@builtbypixel/plasma'
import React, { useEffect } from 'react'
import { Field } from '../../../../components/Form'
import VariablesRepeater from './VariablesRepeater'
import Title from './Title'
import { FaMinus } from 'react-icons/fa'
import { useFormContext } from 'react-hook-form'

const VariablesPanel = ({ source = 'body', repeaterFuncs, handleRemove }) => {
   const { setValue, register, watch, control, getValues } = useFormContext()

   return (
      <Flex direction='column'>
         <Title text={`${source} Variables`} />
         {source === 'header' ? (
            <Flex align='center'>
               <Input
                  bg='#075E54'
                  fontWeight='bold'
                  color='white'
                  value='{1}'
                  textAlign='center'
                  mr='1em'
                  mb='0.4em'
                  width='100px'
               />

               <Field
                  name='content[0].variables'
                  component='text'
                  placeholder='Enter variable for {1}'
                  width='400px'
               />
               <Box cursor='pointer'>
                  <FaMinus onClick={() => handleRemove()} />
               </Box>
            </Flex>
         ) : (
            <VariablesRepeater source='body' repeaterFuncs={repeaterFuncs} />
         )}
      </Flex>
   )
}

export default VariablesPanel

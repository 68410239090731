import React, { useEffect, useState } from 'react'
import { Grid, Box, Flex } from '@builtbypixel/plasma'
import useSWR from 'swr'

import { useGet } from '../../../hooks'

import LinesTable from './LinesTable'
import SelectableItem from '../components/SelectableItem'
import NavBar from './NavBar'
import SendReports from '../components/SendReports/index'
import CopyLink from '../components/CopyLink'

/**
 * The page that renders the Supplier reports
 * @param {string} searchText The text searching for GoLocalPeriods
 */
const Comp = ({ searchText }) => {
   const [goLocalPeriods, setGoLocalPeriods] = useState([])
   const [selectedGoLocalPeriod, setSelectedGoLocalPeriod] = useState(null)
   const [executions, setExecutions] = useState(null)
   const [selectedExecution, setSelectedExecution] = useState(null)

   const { data } = useSWR(`/all-execution-lines`, useGet, {
      suspense: true
   })

   useEffect(() => {
      if (data) {
         setGoLocalPeriods(data.data)
      }
   }, [data])

   useEffect(() => {
      if (searchText) {
         setGoLocalPeriods(
            data.data.filter((item) =>
               item?.price_promotion?.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            )
         )
      } else {
         setGoLocalPeriods(data.data)
      }
   }, [searchText, data])

   return (
      <>
         <Flex justify='space-between'>
            <NavBar
               selectedGoLocalPeriod={selectedGoLocalPeriod}
               setSelectedGoLocalPeriod={setSelectedGoLocalPeriod}
               selectedExecution={selectedExecution}
               setSelectedExecution={setSelectedExecution}
            />
            {selectedExecution && (
               <Flex>
                  <CopyLink selectedExecution={selectedExecution} />
                  <SendReports selectedExecution={selectedExecution} />
               </Flex>
            )}
         </Flex>

         <Box mt='25px'>
            <Grid templateColumns='repeat(3,1fr)' gap='15px 15px'>
               {!selectedGoLocalPeriod
                  ? goLocalPeriods?.map((goLocalPeriod, index) => (
                       <SelectableItem
                          backgroundColor='parfettsGreen'
                          onClick={() => {
                             setSelectedGoLocalPeriod(goLocalPeriod)
                             setExecutions(goLocalPeriod.executions)
                          }}
                          textLeft={goLocalPeriod?.price_promotion?.name}
                          key={index}
                       />
                    ))
                  : !selectedExecution
                  ? executions?.map((execution, index) => (
                       <SelectableItem
                          backgroundColor='parfettsOrange'
                          onClick={() => {
                             setSelectedExecution(execution)
                          }}
                          textLeft={execution?.brand?.name}
                          textRight={execution?.lines?.length}
                          key={index}
                       />
                    ))
                  : null}
            </Grid>

            {selectedExecution && <LinesTable id={selectedExecution.id} />}
         </Box>
      </>
   )
}

export default Comp

import React from 'react'
import { ThemeProvider } from '@builtbypixel/plasma'
import Kernel from './Kernel'
import { theme } from './theme'
import { SWRConfig } from 'swr'
import { RecoilRoot } from 'recoil'

const NucleusCore = (props) => {
   const { routes, config, links } = props

   return (
      <RecoilRoot>
         <ThemeProvider override={theme}>
            <SWRConfig
               value={{
                  refreshInterval: 0,
                  revalidateOnFocus: false,
                  shouldRetryOnError: false
               }}
            >
               <Kernel config={config} routes={routes} links={links} />
            </SWRConfig>
         </ThemeProvider>
      </RecoilRoot>
   )
}

export default NucleusCore

import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { PseudoBox } from '@builtbypixel/plasma'

/**
 * The back button on the navigation bar
 * @param {function} onClick Function to be call when the button is clicked
 */
const NavBackButton = ({ onClick }) => (
   <PseudoBox
      transition='color 0.15s'
      _hover={{
         color: 'parfettsRed',
         cursor: 'pointer'
      }}
      onClick={onClick}
   >
      <BiLeftArrowAlt fontSize='2rem' />
   </PseudoBox>
)

export default NavBackButton

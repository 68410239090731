import { Box, Flex, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import Title from './Title'

import { Field } from '../../../../components/Form'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus } from 'react-icons/fa'
import VariablesPanel from './VariablesPanel'

const TemplateHeader = () => {
   const { setValue, register, watch, control, getValues } = useFormContext()

   const headerText = useWatch({ name: 'content[0].text' })
   const headerVariable = useWatch({ name: 'content[0].variables' })
   const headerFormat = useWatch({ name: 'content[0].format' })
   const { type } = useParams()
   const [showVariablesPanel, setShowVariablesPanel] = useState(false)
   const headerTypeOptions = [
      { id: 1, name: 'Text', value: 'TEXT' },
      { id: 2, name: 'Document', value: 'DOCUMENT' },
      { id: 3, name: 'Image', value: 'IMAGE' },
      { id: 4, name: 'Video', value: 'VIDEO' }
   ]
   useEffect(() => {
      if (headerVariable?.length === 0) {
         setShowVariablesPanel(false)
         setValue('content[0].text', headerText?.replaceAll('{1}', ''))
      } else if (headerVariable?.length > 0) setShowVariablesPanel(true)
   }, [headerVariable])

   useEffect(() => {
      if (type === 'edit' && typeof headerFormat === 'string') {
         const exists = headerTypeOptions.find(
            (el) => el.value === headerFormat
         )

         exists && setValue('content[0].format', exists)
      }
   }, [headerFormat])

   const handleChange = (selected) => {
      switch (selected?.value) {
         case 'IMAGE':
         case 'DOCUMENT':
         case 'VIDEO':
            // setValue('content[0].text', null)
            setValue('content[0].format', selected)

            break
         case 'TEXT':
            // setValue('content[0].example', null)
            setValue('content[0].format', selected)

            break

         default:
            break
      }
   }

   const appendToText = () => {
      setValue(
         'content[0].text',
         headerText ? headerText + ' ' + '{1}' + ' ' : '{1}' + ' '
      )
   }

   const handleRemove = () => {
      setValue('content[0].variables', '')
      setShowVariablesPanel(false)
      setValue('content[0].text', headerText?.replaceAll('{1}', ''))
   }

   return (
      <Flex gap={10} w='100%' direction='column'>
         <Title text='Header Details' />
         <Flex w='100%' direction='column'>
            <Flex align='center' w='100%'>
               <Box w='15em'>
                  <Field
                     name='content[0].format'
                     component='select'
                     placeholder='Select Header Type'
                     options={headerTypeOptions}
                     labelKey='name'
                     valueKey='value'
                     onChange={(val) => {
                        handleChange(val)
                     }}
                     help='Text | Image | Video | Document'
                  />
               </Box>
               {headerFormat?.value === 'TEXT' && (
                  <Flex w='100%' position='relative'>
                     <Box width='100%' ml='1em'>
                        <Field
                           component='text'
                           name='content[0].text'
                           placeholder='Enter Header Text'
                           maxLength={60}
                           help='Max 60 Characters'
                        />
                     </Box>
                     <Flex
                        maxWidth='150px'
                        align='center'
                        bg='#075E54'
                        px='1em'
                        h='25px'
                        justify='space-between'
                        top='-30px'
                        right='0'
                        position='absolute'
                        cursor='pointer'
                        onClick={() => {
                           setShowVariablesPanel(true)
                           !headerText?.includes('{1}') && appendToText()
                        }}
                     >
                        <FaPlus color='#25D366' />
                        <Text color='white' fontWeight='bold' ml='1em'>
                           Add Variable
                        </Text>
                     </Flex>
                  </Flex>
               )}
            </Flex>

            {showVariablesPanel && (
               <VariablesPanel source='header' handleRemove={handleRemove} />
            )}

            {headerFormat?.value && headerFormat?.value !== 'TEXT' && (
               <Box width='100%' ml='1em'>
                  <Field
                     component='file'
                     name='content[0].example.header_handle'
                     placeholder='Select a media file'
                  />
               </Box>
            )}
         </Flex>
      </Flex>
   )
}

export default TemplateHeader

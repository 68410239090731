import React from 'react'
import EditView from '../../components/EditView'
import setup from './setup'

import Form from './Form'

const EditForm = () => {
   return (
      <EditView setup={setup} noPadding>
         <Form />
      </EditView>
   )
}

export default EditForm

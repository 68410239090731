import React from 'react'
import { ModalBody, ModalContent, Box, Image } from '@builtbypixel/plasma'
import FileViewer from 'react-file-viewer'

const AddFolderModal = ({ file, type }) => {
   return (
      <ModalContent>
         <ModalBody>
            <Box p='20px' justify='center' mx='auto'>
               {type && type.substring(0, 5) === 'image' &&
                  <Image
                     src={file}
                     size="100%"
                     objectFit="cover" />
               }
               {type === 'application/pdf' &&
                  <FileViewer
                     style={{ width: '100%' }}
                     fileType='pdf'
                     filePath={file}
                  />
               }
            </Box>
         </ModalBody>
      </ModalContent>
   )
}

export default AddFolderModal

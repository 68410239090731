import React, { useEffect, useMemo } from 'react'
import { AutoFields, Field } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { useGet } from '../../hooks'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import useSWR from 'swr'
import { useHasAdminPrivileges } from '../../hooks'
import { Text } from '@builtbypixel/plasma'
import moment from 'moment'

const EditForm = () => {
   const { data } = useSWR('/user-types', useGet)
   const { data: rdaList } = useSWR('/rda-list', useGet)
   const { type } = useParams()

   const isAdmin = useHasAdminPrivileges()
   const formatOptionLabel = (option) => (
      <>
         <Text fontWeight='bold' display='inline'>
            {option?.name} -{' '}
         </Text>
         <Text display='inline'>{option?.branch?.name}</Text>
      </>
   )

   const fields = [
      {
         group: 'Personal Information',
         fields: [
            {
               name: 'first_name',
               component: 'text',
               placeholder: 'First Name',
               label: 'First Name',
               rules: { required: true }
            },
            {
               name: 'last_name',
               component: 'text',
               placeholder: 'Last Name',
               label: 'Last Name',
               isRequired: true,
               rules: { required: true }
            },
            {
               name: 'email',
               component: 'text',
               type: 'email',
               placeholder: 'Email Address',
               label: 'Email Address',
               isRequired: true,
               rules: { required: true }
            },
            {
               name: 'type',
               component: 'select',
               placeholder: 'Select Type',
               label: 'Account Type',
               options: data?.data,
               valueKey: 'id',
               labelKey: 'name',
               hidden: !isAdmin
            },
            {
               name: 'rda_id',
               component: 'select',
               placeholder: 'Select RDA ID',
               label: 'Internal RDA ID',
               help: 'This is the internal RDA id for this user',
               options: rdaList?.data,
               valueKey: 'id',
               labelKey: 'name',
               hidden: !isAdmin,
               formatOptionLabel: formatOptionLabel
            },
            {
               name: 'visit_target',
               component: 'text',
               type: 'number',
               placeholder: '10',
               label: 'Visit Target',
               hidden: isAdmin,
               help: 'Visit target per Go Local Period'
            },
            {
               name: 'audit_target',
               component: 'text',
               type: 'number',
               placeholder: '10',
               label: 'Audit Target',
               hidden: isAdmin,
               help: 'Audit target per Go Local Period'
            },
            {
               name: 'date_joined',
               component: 'date',
               placeholder: 'Joined Date',
               label: 'Joined Date',
               // isRequired: true,
               // rules: { required: true }
               hidden: type === 'create'
            },
            {
               name: 'profile_image',
               component: 'file',
               placeholder: 'Upload image',
               label: 'Profile Image',
               isMultiple: false,
               max: 1
            }
         ]
      }
   ]

   const SetJoinDate = () => {
      const { setValue } = useFormContext()

      useEffect(() => {
         if (type === 'create') {
            setValue('date_joined', moment().format('YYYY-MM-DD hh:mm:ss'))
         }
      }, [setValue])

      return <></>
   }

   return (
      <EditView setup={setup} noPadding>
         <AutoFields fields={fields} />
         <SetJoinDate />
      </EditView>
   )
}

export default EditForm

import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Text } from '@builtbypixel/plasma'
import { useDidMountEffect } from '../../../hooks'
import { usePost } from '../../../hooks'

/**
 * Filter the products by the starting Character of their names.
 *
 * @param {Boolean} readyFlag A state indicates whether the products are loaded from the response
 * @param {Array} products The products array
 * @param {Function} filterProducts Filter the products by the character specified
 * @param {String} selectedCategory The category of the product selected to filter by
 * @param {Function} selectAllProducts Get all products
 * @param {Boolean} selectAll A state to check if the select all filter option has been selected or not
 */
const FilterByCategory = ({
   readyFlag,
   products,
   filterProducts,
   selectAll,
   selectAllProducts,
   selectedCategory
}) => {
   const { post, response } = usePost()
   const [allCategories, setAllCategories] = useState([])

   useEffect(() => {
      post(`/product-categories`, {
         pagination: 99,
         filters: {
            parent_id: null
         }
      })
   }, [])

   useEffect(() => {
      if (response) {
         setAllCategories(response.data.data)
      }
   }, [response])

   /**
    * @returns an array containing the category of the products, no repeated patterns, acending order
    */
   const filteringOptions = () => {
      const categories = products?.map(
         (product) => product?.product?.category_product[0]?.category_id
      )
      return [...new Set(categories?.sort())]
   }

   // Calculates the number of packs with the specified category
   const numberOfItems = (category) => {
      const currentProducts = products?.filter(
         (product) =>
            category === product?.product?.category_product[0]?.category_id
      )
      return currentProducts.length
   }

   // Pre-populates the table with the first filtering option
   useDidMountEffect(() => {
      filterProducts(filteringOptions()[0])
   }, [readyFlag])

   return products?.length > 0 ? (
      <>
         <Text fontWeight='bold'>Product categories:</Text>
         <Grid
            templateColumns={'repeat(auto-fit, minmax(2rem, 15rem))'}
            gap='0.5rem'
            m='0.5rem 0'
         >
            <Button
               size='sm'
               variantColor={selectAll === true ? 'menuIconColour' : 'gray'}
               onClick={() => selectAllProducts(products)}
            >
               Select All ({products.length})
            </Button>
            {filteringOptions().map((category, index) => (
               <Button
                  size='sm'
                  variantColor={
                     category === selectedCategory && selectAll === false
                        ? 'menuIconColour'
                        : 'gray'
                  }
                  onClick={() => filterProducts(category)}
                  key={category + index}
               >
                  {`${
                     allCategories?.find((el) => el.id == category)?.name
                  } (${numberOfItems(category)})`}
               </Button>
            ))}
         </Grid>
      </>
   ) : null
}

export default FilterByCategory

import React, { memo, useContext, useEffect, useState } from 'react'
import {
   Flex,
   Grid,
   NumberInput,
   Text,
   useToast,
   Button,
   Spinner
} from '@builtbypixel/plasma'
import { usePost, useGet } from '../../../../hooks'
import useSWR, { mutate } from 'swr'

import { FiShoppingCart, FiXCircle } from 'react-icons/fi'

import TrolleyContext from '../../context'

/**
 * Component that shows the packs of a product. Handles the quantity to be oredered.
 *
 * @param {Object} product The product object from the data
 * @param {Function} onChange Function to be called when the switch changes state
 * @param {String} compliance true if it is 'compliance', false if it is 'stock check'
 */
const Pack = memo(({ product, onChange, compliance, scheme, branchid }) => {
   const templateColumns = compliance ? 'repeat(6, 1fr)' : 'repeat(5, 1fr)'
   const { post, response, error } = usePost()
   const { retailerTrolley, retailer } = useContext(TrolleyContext)
   const [showTrolley, setShowTrolly] = useState(false)

   const { data: productPackData, isLoading } = useSWR(
      showTrolley ? `/products/${product.product_id}/${retailer}` : null,
      useGet
   )

   /**
    * Get the number of packs that already added to the trolley
    * @param {object} pack The pack object
    * @returns number of packs that already added to the trolley
    */
   const defaultValue = (pack) => {
      const match = retailerTrolley?.data?.products?.find(
         (packInTrolley) => packInTrolley?.pack_id === pack.id
      )
      return match?.quantity ?? 0
   }

   /**
    * Add the pack to the trolley
    * @param {object} pack The pack object
    * @param {number} value The number of packs to be added to the trolley
    */
   const addToTrolley = (pack, value) => {
      const _pack = {
         pack: pack.id,
         product: pack.product.id,
         quantity: value,
         retailer: { id: retailer, branch_id: branchid }
      }
      post('/trolley', _pack)
   }

   const toast = useToast()
   // Render a toast after successful add to trolley
   useEffect(() => {
      if (response) {
         toast({
            title: 'Success',
            message: 'Cart updated',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
         mutate(`/trolley/${retailer}/${branchid}`)
      }
   }, [response])

   // Render a toast if add to trolley is unsuccessful
   useEffect(() => {
      if (error) {
         toast({
            title: 'Action not completed',
            message: 'An error occurred adding item to cart',
            status: 'error',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [error])
   return (
      <Flex direction='column' m='0 1rem'>
         <Button
            variant='ghost'
            onClick={() => setShowTrolly(!showTrolley)}
            leftIcon={
               productPackData ? (
                  <FiXCircle />
               ) : showTrolley && !productPackData ? (
                  <Spinner />
               ) : (
                  <FiShoppingCart />
               )
            }
            w='115px'
         />

         {showTrolley && productPackData && (
            <>
               <Grid
                  templateColumns={templateColumns}
                  gap='1rem'
                  m='0 0.5rem'
                  mt='1rem'
               >
                  <Text gridColumn='span 2'>Pack size</Text>
                  <Text gridColumn='span 2'>Price</Text>
                  <Text gridColumn='span 2'>Add to Trolley (Quantity)</Text>
               </Grid>
               {productPackData.data.subRows?.map((pack, index) => (
                  <Grid
                     templateColumns={templateColumns}
                     gap='1rem'
                     m='0.5rem'
                     key={pack.id.toString() + index}
                  >
                     <Text
                        gridColumn='span 2'
                        fontWeight='bold'
                        fontSize='1.2rem'
                     >
                        {pack?.pack}
                     </Text>
                     <Text
                        gridColumn='span 2'
                        fontWeight='bold'
                        fontSize='1.2rem'
                     >{`£${pack?.customer_price.toFixed(2)}`}</Text>
                     <Flex gridColumn='span 2' position='relative'>
                        <NumberInput
                           size='sm'
                           max={1000}
                           min={0}
                           defaultValue={() => defaultValue(pack)}
                           onChange={(value) => addToTrolley(pack, value)}
                        />
                     </Flex>
                     <Flex />
                  </Grid>
               ))}
            </>
         )}
      </Flex>
   )
})

export default Pack

import CustomerLeads from './'
import CustomerLeadView from './View'
import CustomerLeadEdit from './Edit'

const routes = [
   {
      path: '/customer-leads',
      title: 'Customers',
      exact: true,
      auth: true,
      component: CustomerLeads,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/customer-leads/view/:id',
      title: 'View Lead',
      exact: false,
      auth: true,
      component: CustomerLeadView,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/customer-leads/:type(edit)/:id',
      title: 'Edit Lead',
      exact: false,
      auth: true,
      component: CustomerLeadEdit,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/customer-leads/:type(create)',
      title: 'Create New Lead',
      exact: true,
      auth: true,
      component: CustomerLeadEdit,
      permissions: ['Admin', 'RDA']
   }
]

export default routes

import React from 'react'
import { Box, Table, ScrollContainer } from '@builtbypixel/plasma'

import TableLoader from '../../../../components/common/TableLoader'

const WeeklySales = ({ data }) => {
   const columns = [
      {
         Header: '',
         accessor: 'name',
         width: '100px'
      },
      {
         Header: 'Week 0',
         accessor: '0.week0',
         width: '70px'
      },
      {
         Header: 'Week 1',
         accessor: '0.week1',
         width: '70px'
      },
      {
         Header: 'Week 2',
         accessor: '0.week2',
         width: '70px'
      },
      {
         Header: 'Week 3',
         accessor: '0.week3',
         width: '70px'
      },
   ]


   return (
      <ScrollContainer>
         <Box>
            {data ? (
               <Table
                  data={{ data: data ? data.data : [] }}
                  onRowSelect={null}
                  isExpandable={false}
                  hidePagination={true}
                  size='sm'
                  manualPagination={true}
                  columns={columns}
               />
            ) : (
                  <TableLoader />
               )}
         </Box>
      </ScrollContainer>
   )
}

export default WeeklySales

import React, { useContext, useEffect, useState } from 'react'
import {
   Alert,
   Box,
   Button,
   ModalContent,
   ModalContext,
   ModalFooter,
   ModalHeader,
   TextArea,
   useToast
} from '@builtbypixel/plasma'
import { usePost } from '../../../hooks'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'

const Content = () => {
   const { id } = useParams()
   const toast = useToast()
   const { close } = useContext(ModalContext)
   const { post, loading, response, error } = usePost()

   const [description, setDescription] = useState('')

   const handleSubmit = () => {
      post('/last-visited', {
         type: 'Retailer',
         retailer: { id }
      })
      if (description) {
         post(`/notes/create`, {
            description: description,
            retailer_id: id
         })
      }
   }

   useEffect(() => {
      if (response) {
         close()
         mutate('/notes')
         toast({
            title: 'Visit Saved',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [response])

   return (
      <ModalContent>
         <ModalHeader>In Store Now</ModalHeader>

         <Box m='1rem'>
            <TextArea
               value={description}
               onChange={(value) => setDescription(value.target.value)}
               placeholder='Enter note text here... (Optional)'
               rows={4}
               size='md'
               variant='outline'
            />
            {error && (
               <Alert status='error' title='Error saving note' mt='20px'>
                  Your note was not saved. Please try again.
               </Alert>
            )}
         </Box>

         <ModalFooter showCloseIcon={false}>
            <Button
               variantColor='parfettsGreen'
               color='white'
               onClick={handleSubmit}
               isLoading={loading}
               isDisabled={loading}
               loadingText='Submitting...'
            >
               Confirm
            </Button>
         </ModalFooter>
      </ModalContent>
   )
}

export default Content

import React, {
   useCallback,
   memo,
   useMemo
} from 'react'

import { Form } from '../../components/Form'
import {
   Flex,
   Button,
   Box,
   Skeleton,
   Stack,
   ScrollContainer
} from '@builtbypixel/plasma'
import useSWR from 'swr'
import { useGet, useHttp } from '../../hooks'
import * as yup from 'yup'
import InfoBar from '../../components/EditView/InfoBar'
import { AutoFields } from '../../components/Form'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../../components/EditView/ErrorMessage'
import SuccessMessage from '../../components/EditView/SuccessMessage'
import { formAtom } from '../../state/form'
import { useSetRecoilState, useRecoilValue } from 'recoil'

const SubmitButton = memo(() => {
   const { loading } = useRecoilValue(formAtom)

   const { submit } = useFormContext()

   return (
      <Button
         variantColor='success'
         color='white'
         onClick={() => submit()}
         isLoading={loading}
         loadingText='Submitting '
      >
         Save Profile
      </Button>
   )
})

const FormColumn = memo((props) => {
   const { data, children } = props
   return (
      <ScrollContainer>
         <Flex
            w='100%'
            h='calc(100vh - 200px)'
            flex={1}
            align='center'
            direction='column'
         >
            {data ? (
               children
            ) : (
                  <Box w='90%' h='100%' maxWidth={600} mt={10}>
                     <Skeleton variant='rect' h='100px' />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                     <Skeleton />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                  </Box>
               )}
         </Flex>
      </ScrollContainer>
   )
})

const EditView = memo((props) => {
   const { children, isFullWidth } = props
   const Http = useHttp()

   const setFormState = useSetRecoilState(formAtom)

   const { data } = useSWR('auth/profile', useGet)

   React.useState(() => {
      setFormState((old) => ({ ...old, errors: null, success: false }))
   }, [])

   /* eslint-disable */
   const onSubmit = useCallback((values) => {
      setFormState((old) => ({ ...old, loading: true }))
      Http.put('auth/profile', values)
         .then((res) => {
            setFormState((old) => ({
               ...old,
               loading: false,
               errors: null,
               success: true
            }))
         })
         .catch((err) => {
            setFormState((old) => ({
               ...old,
               loading: false,
               errors: err,
               success: false
            }))
         })
   }, [])

   const fields = useMemo(
      () => [
         {
            group: 'Personal Information',
            fields: [
               {
                  name: 'profile_image',
                  component: 'file',
                  placeholder: 'Profile Image',
                  label: 'Profile Image'
               },
               {
                  name: 'first_name',
                  component: 'text',
                  placeholder: 'First Name',
                  label: 'First Name',
                  rules: { required: true }
               },
               {
                  name: 'last_name',
                  component: 'text',
                  placeholder: 'Last Name',
                  label: 'Last Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'email',
                  component: 'text',
                  type: 'email',
                  placeholder: 'Email Address',
                  label: 'Email Address',
                  isRequired: true,
                  rules: { required: true }
               }
            ]
         },
         {
            group: 'Change your password',
            fields: [
               {
                  name: 'password',
                  component: 'text',
                  type: 'password',
                  placeholder: 'Password',
                  label: 'Password'
               },
               {
                  name: 'password_confirm',
                  component: 'text',
                  type: 'password',
                  placeholder: 'Confirm Password',
                  label: 'Confirm Password'
               }
            ]
         }
      ],
      [data]
   )

   const validation = yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      email: yup.string().required().email(),
      password: yup.string(),
      password_confirm: yup
         .string()
         .oneOf([yup.ref('password'), null], 'Passwords must match')
   })

   return (
      <Form
         data={data && data.data}
         onSubmit={onSubmit}
         validationSchema={validation}
      >
         <Flex
            w={'calc(100% + 40px)'}
            bg='global.elementBg'
            h='calc(100vh - 58px)'
            overflow='hidden'
            align='center'
            direction='column'
            m={'-20px'}
         >
            <Flex
               w='100%'
               align='center'
               px={2}
               h={50}
               flexShrink={0}
               flexGrow={0}
               borderBottom='1px'
               borderColor='global.borderColour'
            >
               <Stack ml='auto' isInline spacing='10px' mr='10px'>
                  <SubmitButton />
               </Stack>
            </Flex>

            <ErrorMessage />
            <SuccessMessage />

            <Flex w='100%' h='100%' flex={1}>
               <FormColumn
                  data={data}
                  children={children}
                  isFullWidth={isFullWidth}
               >
                  <AutoFields fields={fields} />
               </FormColumn>
               <InfoBar />
            </Flex>
         </Flex>
      </Form>
   )
})
export default EditView

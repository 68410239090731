import React from 'react'
import {
   PseudoBox,
   Button,
   useColorMode,
   Box,
   PopConfirm,
   Stack
} from '@builtbypixel/plasma'
import Field from '../../components/Form/Field'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'

import { BiArrowToBottom, BiArrowToTop, BiTrashAlt } from 'react-icons/bi'

export const Repeater = (props) => {
   const { fieldArray, name, singleName, keyName } = props
   const { control } = useFormContext()
   const { colorMode } = useColorMode()

   const { fields, append, remove, move, insert } = useFieldArray({
      name: name,
      control,
      keyName: keyName ? keyName : 'id'
   })

   return (
      <PseudoBox w='100%'>
         {fields.map((field, index) => (
            <Box
               key={field[keyName ? keyName : 'id']}
               bg={colorMode === 'light' ? 'gray.50' : 'blackAlpha.300'}
               border='1px'
               borderColor={
                  colorMode === 'light' ? 'gray.200' : 'whiteAlpha.200'
               }
               p='10px'
               rounded='xl'
               mb='10px'
            >
               {fieldArray.map((_field, i) => (
                  <Box mb='15px' key={`field-${index}-${i}`}>
                     <Field
                        {..._field}
                        index={i}
                        name={`${name}[${index}].${_field.name}`}
                        component={_field.component}
                        size='sm'
                        defaultValue={field[_field.name]}
                     />
                  </Box>
               ))}
               <Stack isInline spacing='2px'>
                  <Button
                     leftIcon={<AiOutlineAppstoreAdd />}
                     variant='ghost'
                     variantColor='secondary'
                     size='sm'
                     onClick={() => insert(index + 1, {})}
                  >
                     Add {singleName ? singleName : 'item'} below
                  </Button>

                  <Button
                     leftIcon={<BiArrowToTop />}
                     variant='ghost'
                     variantColor='secondary'
                     size='sm'
                     isDisabled={index === 0}
                     onClick={() => move(index, index - 1)}
                  >
                     Move Up
                  </Button>

                  <Button
                     leftIcon={<BiArrowToBottom />}
                     variant='ghost'
                     variantColor='secondary'
                     size='sm'
                     isDisabled={index + 1 === fields.length}
                     onClick={() => move(index, index + 1)}
                  >
                     Move Down
                  </Button>

                  <Box>
                     <PopConfirm onConfirm={() => remove(index)}>
                        <Button
                           leftIcon={<BiTrashAlt />}
                           variant='ghost'
                           variantColor='error'
                           size='sm'
                        >
                           Remove
                        </Button>
                     </PopConfirm>
                  </Box>
               </Stack>
            </Box>
         ))}

         <Button
            onClick={() => append({})}
            leftIcon={<AiOutlineAppstoreAdd />}
            variant='ghost'
            variantColor='secondary'
         >
            Add {singleName ? singleName : 'item'}
         </Button>
      </PseudoBox>
   )
}

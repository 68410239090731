import React, { useEffect, useState } from 'react'
import { Field } from '../../components/Form'
import {
   Text,
   Tabs,
   Tab,
   TabList,
   TabPanels,
   TabPanel
} from '@builtbypixel/plasma'
import { Controller, useFormContext } from 'react-hook-form'
import Notes from './Notes'
import Tasks from './Tasks'
import { useRetailers, useRDAS, useHttp } from '../../hooks'
import { useParams, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { useGet } from '../../hooks'

const debounce = require('lodash.debounce')

const Form = () => {
   const Http = useHttp()
   const { setValue, register } = useFormContext()
   const { type, retailer } = useParams()
   const { state } = useLocation()
   const [selectType, setSelectType] = useState(null)

   const handleSetSelectType = (value) => {
      setSelectType(value)
   }
   const useCustomerLeads = debounce((inputValue, callback) => {
      Http.post(`/customer-leads`, { search: inputValue }).then((res) => {
         return callback(res.data.data)
      })
   }, 500)

   const formatVisitOptionLabel = (option) => (
      <Text>
         {option?.first_name} {option?.last_name}{' '}
      </Text>
   )
   const { data } = useSWR(
      `${state?.type?.replace(/_/g, '-')}s/${state?.id}`,
      useGet
   )

   useEffect(() => {
      if (state && data) {
         register({ name: state.type })
         setValue(state.type, data?.data)
      }
   }, [register, setValue, state, data])

   return (
      <>
         {type === 'create' ? (
            <>
               <Field
                  component='select'
                  name='select_type'
                  placeholder='Select Type'
                  label='Visit Type'
                  onChange={(value) => handleSetSelectType(value)}
                  options={[
                     {
                        id: 1,
                        name: 'Retailer'
                     },
                     {
                        id: 2,
                        name: 'Lead'
                     }
                  ]}
                  labelKey='name'
                  valueKey='id'
                  size='lg'
                  value={selectType}
               />
               {!state && (retailer === 'retailer' || selectType?.id === 1) && (
                  <Field
                     component='select'
                     name='retailer'
                     isAsync
                     placeholder='Search by Name'
                     label='Retailer'
                     loadOptions={useRetailers}
                     labelKey='business_name'
                     valueKey='id'
                     size='lg'
                  />
               )}
               {!state &&
                  (retailer === 'customer_lead' || selectType?.id === 2) && (
                     <Field
                        component='select'
                        name='customer_lead'
                        placeholder='Search by Name'
                        label='Customer Lead'
                        isAsync
                        loadOptions={useCustomerLeads}
                        labelKey='business_name'
                        valueKey='id'
                        size='lg'
                        rules={{
                           required: true,
                           message: 'You must select a Customer'
                        }}
                     />
                  )}
               {state && data && (
                  <Text my='10px'>
                     New visit for: {data?.data?.business_name}
                  </Text>
               )}
            </>
         ) : (
            <Field component='text' disabled name={'retailer.business_name'} />
         )}
         <Field
            component='date'
            name='due_date'
            placeholder='Visit Date'
            label='Due Date'
            rules={{ required: true }}
         />

         {type === 'edit' && (
            <Field
               component='date'
               name='date_completed'
               placeholder='Completed Date'
               label='Completed Date'
            />
         )}

         <Field
            component='select'
            isAsync={true}
            name='rda'
            placeholder='RDA'
            label='RDA'
            loadOptions={useRDAS}
            labelKey='first_name'
            valueKey='id'
            rules={{ required: true }}
            formatOptionLabel={formatVisitOptionLabel}
         />
         {type === 'edit' && (
            <Tabs orientation='horizontal' mt='25px'>
               <TabList>
                  <Tab>Notes</Tab>
                  <Tab>Tasks</Tab>
               </TabList>
               <TabPanels>
                  <TabPanel>
                     <Controller
                        name='notes'
                        defaultValue={[]}
                        render={(fieldProps) => <Notes {...fieldProps} />}
                     />
                  </TabPanel>
                  <TabPanel>
                     <Controller
                        name='tasks'
                        render={(fieldProps) => <Tasks {...fieldProps} />}
                     />
                  </TabPanel>
               </TabPanels>
            </Tabs>
         )}
      </>
   )
}

export default Form

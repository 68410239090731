import React from 'react'
import { Button, useToast } from '@builtbypixel/plasma'

/**
 * A button that copies the URL that shows the current report
 * @param {objet} selectedExecution The selected Execution object
 */
const CopyLink = ({ selectedExecution }) => {
   const toast = useToast()

   return (
      <Button
         mr='1rem'
         onClick={() => {
            navigator.clipboard.writeText(
               `${window?.location?.host}/dashboard/report?token=${selectedExecution?.link}`
            )
            toast({
               title: 'Link copied',
               message: `${window?.location?.host}/dashboard/report?token=${selectedExecution?.link}`,
               status: 'success',
               position: 'top',
               variant: 'plain'
            })
         }}
      >
         Copy Link
      </Button>
   )
}

export default CopyLink

import { checkPermissions } from '../helpers'
import { useRecoilValue } from 'recoil'
import { globalAtom } from '../state/global'


 export const useHasAdminPrivileges = () => {
    const { user } = useRecoilValue(globalAtom)

    return checkPermissions({
        has: user?.role?.name,
        required: ['Admin']
     })
  }
  
import { createCrudRoutes } from '../../hooks'
import List from './List'
import Entry from './'

import setup from './setup'

const crud = createCrudRoutes({
  setup,
  name: setup.title,
  edit: Entry,
  list: List,
  view: null,
  permissions: ['Admin','RDA']
})


const routes = [...crud]

export default routes

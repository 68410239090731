import React, { useMemo } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { useGet } from '../../hooks'
import useSWR from 'swr'

const EditForm = () => {
   const { data } = useSWR('https://jsonplaceholder.typicode.com/users', useGet)

   const fields = useMemo(
      () => [
         {
            group: '',
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  rules: { required: true }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

import React, { memo } from 'react'
import {
   Popover,
   PopoverTrigger,
   PopoverBody,
   PopoverContent,
   Image,
   Button,
   Flex,
   Stack,
   Icon
} from '@builtbypixel/plasma'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BiUser, BiCheckSquare, BiExit } from 'react-icons/bi'
import { logout } from '../../helpers'
import LinkButton from '../LinkButton'

const AccountDropdown = memo(({ user }) => {
   return (
      <Popover>
         <PopoverTrigger>
            <Flex align='center'>
               <Button
                  rounded='full'
                  variant='ghost'
                  pl='3px'
                  flexShrink={0}
                  leftIcon={
                     <Image
                        src={user?.profile_image && user?.profile_image[0]?.url}
                        fallbackSrc='/images/profile-placeholder.png'
                        objectFit='cover'
                        size='37px'
                        rounded='full'
                     />
                  }
               >
                  <MdKeyboardArrowDown fontSize='22px' />
               </Button>
            </Flex>
         </PopoverTrigger>

         <PopoverContent>
            <PopoverBody>
               <Stack>
                  <LinkButton to={`/administrators/edit/${user?.id}`}>
                     <Button
                        leftIcon={
                           <Icon color='gray.500'>
                              <BiUser fontSize='20px' />
                           </Icon>
                        }
                        isFullWidth
                        variant='ghost'
                        align='left'
                        size='lg'
                     >
                        My Account
                     </Button>
                  </LinkButton>
                  <LinkButton to='planner/tasks'>
                     <Button
                        leftIcon={
                           <Icon color='gray.500'>
                              <BiCheckSquare fontSize='20px' />
                           </Icon>
                        }
                        isFullWidth
                        variant='ghost'
                        align='left'
                        size='lg'
                     >
                        My Tasks
                     </Button>
                  </LinkButton>

                  <Button
                     leftIcon={
                        <Icon color='gray.500'>
                           <BiExit fontSize='20px' />
                        </Icon>
                     }
                     isFullWidth
                     variant='ghost'
                     align='left'
                     size='lg'
                     onClick={() => logout()}
                  >
                     Logout
                  </Button>
               </Stack>
            </PopoverBody>
         </PopoverContent>
      </Popover>
   )
})

export default AccountDropdown

import React, { useState, useCallback, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { useHttp } from '../../hooks'

import { Box, Input } from '@builtbypixel/plasma'

const DebouncedSearch = ({
   defaultSearch,
   onSearch,
   requestSource,
   setRequestSource,
   term,
   noInitialFetch,
   placeholder
}) => {
   const [searchInput, setSearchInput] = useState('')
   const [doFetch, setDoFetch] = useState(false)

   const Http = useHttp()

   const onChange = (term) => {
      setDoFetch(true)
      setSearchInput(term)
   }

   const search = async () => {
      onSearch(searchInput, term)
   }

   const _search = useCallback(debounce(search, 500), [searchInput])

   useEffect(() => {
      setDoFetch(!noInitialFetch)
   }, [noInitialFetch])
   useEffect(() => {
      requestSource &&
         requestSource.cancel('Operation canceled due to new request.')
      let NewRequest = Http.CancelToken
      setRequestSource(NewRequest.source())
      if (doFetch) {
         _search()
      }

      // Cancel the debounce on useEffect cleanup.
      return _search.cancel
   }, [searchInput, _search])

   return (
      <Box>
         <Input
            minWidth={140}
            value={searchInput}
            px='15px'
            onChange={(e) => onChange(e.target.value)}
            defaultValue={defaultSearch}
            placeholder={placeholder ? placeholder : 'Search...'}
         />
      </Box>
   )
}

export default DebouncedSearch

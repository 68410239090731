import React from 'react'
import { GrDocumentPdf, GrDocumentCsv, GrImage } from 'react-icons/gr'
var mime = require('mime-types')

const FileIcon = ({ fileType }) => {
   const type = mime.extension(fileType)

   switch (type) {
      case 'pdf':
         return <GrDocumentPdf fontSize='18px' />
      case 'csv':
         return <GrDocumentCsv fontSize='18px' />
      case 'png':
         return <GrImage fontSize='18px' />
      case 'jpeg':
         return <GrImage fontSize='18px' />
      default:
         return null
   }
}

export default FileIcon

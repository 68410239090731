import React, { useState, useEffect } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'

import setup from './setup'
import { useHttp, useRDAS } from '../../hooks'
import { Tag } from '@builtbypixel/plasma'

const EditForm = () => {
   const Http = useHttp()
   const [services, setServices] = useState([])
   const [potentials, setPotentials] = useState([])

   useEffect(() => {
      Http.get(`/customer-leads-potential`).then((res) =>
         setPotentials(res.data.data)
      )
   }, [setPotentials, Http])

   useEffect(() => {
      Http.post(`/services`).then((res) => setServices(res.data.data))
   }, [setServices, Http])

   const formatCategoryOptionLabel = (option) => {
      const colours = {
         1: 'parfettsGreen',
         2: 'parfettsOrange',
         3: 'parfettsRed'
      }
      return (
         <Tag
            variantColor={colours[option.id]}
            variant='solid'
            size='sm'
            mr='10px'
         >
            {option?.name}
         </Tag>
      )
   }
   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name
   const fields = [
      {
         group: 'RDA',
         tab: 'RDA',
         fields: [
            {
               name: 'rda',
               component: 'select',
               placeholder: 'Search RDAs...',
               isAsync: true,
               loadOptions: useRDAS,
               labelKey: 'first_name',
               valueKey: 'id',
               formatOptionLabel: formatOptionLabel
            }
         ]
      },
      {
         group: 'Customer Details',
         tab: 'Customer',
         fields: [
            {
               name: 'business_name',
               component: 'text',
               placeholder: 'Business Name',
               label: 'Business Name',
               rules: { required: true }
            },
            {
               name: 'contact_name',
               component: 'text',
               placeholder: 'Customer Name',
               label: 'Customer Name',
               rules: { required: true }
            },
            {
               name: 'email',
               component: 'text',
               type: 'email',
               placeholder: 'Customer Email',
               label: 'Customer Email',
               rules: { required: true }
            },
            {
               name: 'phone',
               component: 'text',
               type: 'number',
               placeholder: 'Customer Phone',
               label: 'Customer Phone',
               rules: { required: true }
            },
            {
               name: 'ownership',
               component: 'select',
               placeholder: 'Select ownership type',
               label: 'Ownership',
               isRequired: true,
               labelKey: 'name',
               valueKey: 'id',
               options: [
                  {
                     id: 1,
                     name: 'Sole Trader'
                  },
                  {
                     id: 2,
                     name: 'Limited Company'
                  },
                  {
                     id: 3,
                     name: 'Partnership'
                  },
                  {
                     id: 4,
                     name: 'Limited Liablity Partnership'
                  },
                  {
                     id: 5,
                     name: 'None'
                  }
               ]
            },

            {
               name: 'property_status',
               component: 'select',
               placeholder: 'Select property status',
               label: 'Property Status',
               isRequired: true,
               labelKey: 'name',
               valueKey: 'id',
               options: [
                  {
                     id: 1,
                     name: 'Leasehold'
                  },
                  {
                     id: 2,
                     name: 'Freehold'
                  },
                  {
                     id: 3,
                     name: 'No Property'
                  }
               ]
            },

            {
               name: 'store_photo',
               component: 'file',
               placeholder: 'Store Photo',
               label: 'Store Photo',
               isRequired: false
            }
         ]
      },
      {
         group: 'Address',
         tab: 'Address',
         fields: [
            {
               name: 'address.first_line',
               component: 'text',
               placeholder: 'Address Line 1',
               label: 'Address Line 1',
               isRequired: true
            },
            {
               name: 'address.second_line',
               component: 'text',
               placeholder: 'Address Line 2',
               label: 'Address Line 2',
               isRequired: false
            },
            {
               name: 'address.city',
               component: 'text',
               placeholder: 'City',
               label: 'City',
               isRequired: true,
               rules: { required: true }
            },
            {
               name: 'address.postcode',
               component: 'text',
               placeholder: 'Post Code',
               label: 'Post Code',
               isRequired: true
            },
            {
               name: 'geoloc',
               type: 'hidden',
               component: 'geoloc',
               placeholder: 'GeoLoc',
               label: null
            }
         ]
      },
      {
         group: 'Social Media',
         tab: 'Social Media',
         fields: [
            {
               name: 'social',
               component: 'repeater',
               placeholder: 'Social Meida',
               label: 'Social Media',
               isRequired: false,
               fieldArray: [
                  {
                     name: 'platform',
                     component: 'select',
                     placeholder: 'Select platform',
                     label: 'Platform',
                     isRequired: true,
                     labelKey: 'name',
                     valueKey: 'id',
                     options: [
                        {
                           id: 1,
                           name: 'Facebook'
                        },
                        {
                           id: 2,
                           name: 'Twitter'
                        },
                        {
                           id: 3,
                           name: 'Instagram'
                        },
                        {
                           id: 4,
                           name: 'Other'
                        }
                     ]
                  },
                  {
                     name: 'name',
                     component: 'text',
                     placeholder: 'Enter name of social profile',
                     label: 'Profile Name',
                     rules: { required: true }
                  },
                  {
                     name: 'url',
                     component: 'text',
                     placeholder: 'https://...',
                     label: 'Link',
                     rules: { required: true }
                  }
               ]
            }
         ]
      },
      {
         group: 'Additonal Info',
         fields: [
            {
               name: 'current_fascia',
               component: 'text',
               placeholder: 'Current Fascia',
               label: 'Current Fascia',
               isRequired: false
            },
            {
               name: 'contract',
               component: 'date',
               placeholder: 'Contract',
               label: 'Contract',
               isRequired: false
            },

            {
               name: 'opening_times',
               component: 'text',
               placeholder: 'Opening Times',
               label: 'Opening Times',
               isRequired: false
            },
            {
               name: 'service',
               component: 'select',
               placeholder: 'Services',
               label: 'Services',
               isRequired: true,
               labelKey: 'name',
               valueKey: 'id',
               options: services
            },
            {
               name: 'potential',
               component: 'select',
               placeholder: 'Potential',
               label: 'Potential',
               isRequired: true,
               labelKey: 'name',
               valueKey: 'id',
               options: potentials,
               formatOptionLabel: formatCategoryOptionLabel
            }
         ]
      },
      {
         group: 'Interests',
         tab: 'Interests',
         fields: [
            {
               name: 'interests.promotions',
               component: 'switch',
               size: 'lg',
               placeholder: 'Milestone Completed',
               label: 'Promotions'
            },
            {
               name: 'interests.fascia',
               component: 'switch',
               size: 'lg',
               placeholder: 'Milestone Completed',
               label: 'Fascia'
            },
            {
               name: 'interests.deliveries',
               component: 'switch',
               size: 'lg',
               placeholder: 'Milestone Completed',
               label: 'Deliveries'
            },
            {
               name: 'interests.social_media_support',
               component: 'switch',
               size: 'lg',
               placeholder: 'Milestone Completed',
               label: 'Social Media'
            }
         ]
      }
   ]
   return (
      <EditView fields={fields} setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

import { createCrudRoutes } from '../../hooks'
import index from './'

import setup from './setup'

const crud = createCrudRoutes({
   setup,
   name: setup.title,
   list: index,
   permissions: ['Admin', 'RDA']
})

const routes = [...crud]

export default routes

import Retailers from './'
import NewRetailer from './New'
import RetailerView from './View'
import RetailerEdit from './Edit'

const routes = [
   {
      path: '/retailers',
      title: 'Retailers',
      exact: true,
      auth: true,
      component: Retailers,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/retailers/:type(create)',
      title: 'New Retailer',
      exact: true,
      auth: true,
      component: NewRetailer,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/retailers/view/:branchId/:id',
      title: 'View Retailer',
      exact: false,
      auth: true,
      component: RetailerView,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/retailers/:type(edit)/:branchId/:id',
      title: 'Edit Retailer',
      exact: false,
      auth: true,
      component: RetailerEdit,
      permissions: ['Admin', 'RDA']
   }
]

export default routes

import React, { memo } from 'react'
import { Flex, Text, Image, Switch } from '@builtbypixel/plasma'

import Pack from './Pack'

/**
 * Display the product.
 *
 * @param {Object} product The product from the database
 * @param {Function} onSaleChange The function to be called when the switch has changed state
 * @param {String} compliance true if it is 'compliance', false if it is 'stock check'
 */
const Product = ({
   isEdit,
   product,
   productDetails,
   onChange,
   compliance,
   branchid,
   scheme,
   selectedCategory
}) => {
   const parfettsApi = process.env.REACT_APP_PARFETTS_API

   return (
      <Flex
         direction='column'
         bg='white'
         p='0.5rem'
         borderRadius='0.5rem'
         m='0.5rem 0'
      >
         <Flex w='100%' justifyContent='space-between'>
            <Flex>
               <Flex
                  h='10rem'
                  w='10rem'
                  bg='#eee'
                  justify='center'
                  align='center'
                  borderRadius='0.5rem'
               >
                  {productDetails.product_id ? (
                     <Image
                        src={`${parfettsApi}/images/products/${productDetails.product_id}.jpg`}
                     />
                  ) : (
                     <Text fontWeight='bold' color='#bbb'>
                        Coming soon!
                     </Text>
                  )}
               </Flex>

               <Flex direction='column' m='1rem'>
                  <Text fontSize='1.5rem'>{productDetails.description}</Text>
                  <Text fontWeight='bold' fontSize='1.5rem'>
                     {productDetails.retail_one} {productDetails.retail_two}
                  </Text>
                  <Flex m='0.5rem 0' direction='column'>
                     <Flex direction='row' mr='1rem'>
                        <Text>Price Mark:</Text>
                        <Text fontWeight='bold'>
                           {productDetails.pricemark}
                        </Text>
                     </Flex>
                     <Flex direction='row'>
                        <Text>Size:</Text>
                        <Text fontWeight='bold' ml='5px'>
                           {productDetails.case} x {productDetails.size}
                        </Text>
                     </Flex>
                     <Flex direction='row'>
                        <Text>POR:</Text>
                        <Text fontWeight='bold' ml='5px'>
                           {productDetails.dm_por}%
                        </Text>
                     </Flex>

                     {product?.product?.category_product[0]?.category_id ===
                        '02' && (
                        <Flex direction='row'>
                           <Text>Abv:</Text>
                           <Text fontWeight='bold' ml='5px'>
                              {productDetails.abv}
                           </Text>
                        </Flex>
                     )}
                  </Flex>
               </Flex>
            </Flex>

            <Flex
               direction='row'
               m='1rem'
               minWidth='250px'
               justifyContent='space-around'
            >
               <Flex direction='column' m='0.5'>
                  <Text>On Sale</Text>
                  <Switch
                     isChecked={product.on_sale}
                     onChange={(e) =>
                        onChange(
                           'on_sale',
                           product.id,
                           product.product_id,
                           e.target.checked
                        )
                     }
                  />
               </Flex>

               {compliance && (
                  <Flex direction='column' m='0.5'>
                     <Text>POS In Place</Text>

                     <Switch
                        isChecked={product.in_place}
                        onChange={(e) =>
                           onChange(
                              'in_place',
                              product.id,
                              product.product_id,
                              e.target.checked
                           )
                        }
                     />
                  </Flex>
               )}
            </Flex>
         </Flex>
         {!isEdit && (
            <Pack
               product={product}
               onChange={onChange}
               compliance={compliance}
               branchid={branchid}
               scheme={scheme}
            />
         )}
      </Flex>
   )
}

export default Product

import React, { memo } from 'react'
import { Stack, IconButton } from '@builtbypixel/plasma'
import { BiBarChart, BiListUl } from 'react-icons/bi'

const ChartToggle = memo(({ setMode, mode }) => {
   return (
      <Stack isInline mb='5px'>
         <IconButton
            size='sm'
            variant='ghost'
            onClick={() => setMode('table')}
            color={mode === 'table' && 'parfettsRed'}
         >
            <BiListUl fontSize='1.2rem' />
         </IconButton>
         <IconButton
            size='sm'
            variant='ghost'
            onClick={() => setMode('chart')}
            color={mode === 'chart' && 'parfettsRed'}
         >
            <BiBarChart fontSize='1.2rem' />
         </IconButton>
      </Stack>
   )
})

export default ChartToggle

import React from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { Box } from '@builtbypixel/plasma'
import HeadOfficeDashboard from './HeadOfficeDashboard'
import RDADashboard from './RDADashboard'

import { useRecoilValue } from 'recoil'
import { globalAtom } from '../../state/global'

const Dashboard = () => {
   const { user } = useRecoilValue(globalAtom)

   return (
      <>
         <Box mb='20px'>
            <ViewTitle title='Dashboard' />
         </Box>

         {user?.role?.name === 'Admin' ? (
            <HeadOfficeDashboard />

         ) : (
               <RDADashboard />

            )}
      </>
   )
}

export default Dashboard

import Planner from './'

const routes = [
   {
      path: '/planner',
      title: 'Planner',
      exact: false,
      auth: true,
      component: Planner,
      permissions: ['Admin','RDA']
   }
]

export default routes

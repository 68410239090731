import React, { useState, useEffect, useMemo } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import ProofOfID from './components/ProofOfID'
import SelectRDA from './components/SelectRDA'
import setup from './setup'
import { useHttp } from '../../hooks'

const EditForm = () => {
   const Http = useHttp()
   const [branches, setBranches] = useState([])
   const [rdas, setRdas] = useState([])
   const [fascias, setFascias] = useState([])
   const [currentRda, setCurrentRda] = useState(null)
   const [countries, setCountries] = useState([])
   const [businessCategories, setBusinessCategories] = useState([])
   const [currentAffiliations, setCurrentAffiliations] = useState([])

   useEffect(() => {
      Http.get(`/branches`).then((res) => setBranches(res.data?.data))
   }, [setBranches, Http])

   useEffect(() => {
      Http.get(`/rda-list`).then((res) => setRdas(res.data?.data))
   }, [setRdas, Http])

   useEffect(() => {
      Http.get(`/fascias`).then((res) => setFascias(res.data?.data))
   }, [setFascias, Http])

   useEffect(() => {
      Http.get(`/auth/current`).then((res) =>
         setCurrentRda(res?.data?.data?.rda_id)
      )
   }, [setCurrentRda, Http])

   useEffect(() => {
      Http.get(`/countries`).then((res) => setCountries(res?.data?.data))
   }, [setCountries, Http])

   useEffect(() => {
      Http.get(`/business-categories`).then((res) =>
         setBusinessCategories(res?.data?.data)
      )
   }, [setBusinessCategories, Http])

   useEffect(() => {
      Http.get(`/current-affiliations`).then((res) =>
         setCurrentAffiliations(res?.data?.data)
      )
   }, [setCurrentAffiliations, Http])

   const fields = useMemo(
      () => [
         {
            group: 'General',
            tab: 'Customer',
            fields: [
               {
                  name: 'business_name',
                  component: 'text',
                  placeholder: 'Business Name',
                  label: 'Business Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'contact_name',
                  component: 'text',
                  placeholder: 'Contact Name',
                  label: 'Contact Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'card_holder_one',
                  component: 'text',
                  placeholder: 'Card Holder One',
                  label: 'Card Holder 1'
               },
               {
                  name: 'card_holder_two',
                  component: 'text',
                  placeholder: 'Card Holder Two',
                  label: 'Card Holder 2'
               },
               {
                  name: 'card_holder_three',
                  component: 'text',
                  placeholder: 'Card Holder Three',
                  label: 'Card Holder 3'
               },
               {
                  name: 'branch',
                  component: 'select',
                  placeholder: 'Branch',
                  label: 'Branch / Depot',
                  options: branches,
                  labelKey: 'name',
                  valueKey: 'id',
                  isRequired: true,
                  rules: { required: true }
               },
               // {
               //    name: 'account_number',
               //    component: 'text',
               //    placeholder: 'Account Number',
               //    label: 'Account Number',
               //    isRequired: true
               // },
               {
                  name: 'business_category',
                  component: 'select',
                  placeholder: 'Business Category',
                  label: 'Business Category',
                  options: businessCategories,
                  labelKey: 'name',
                  valueKey: 'id',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'representative_rda',
                  component: () => (
                     <SelectRDA rdas={rdas} currentRda={currentRda} />
                  )
               },
               {
                  name: 'vat_number',
                  component: 'text',
                  placeholder: 'VAT Number',
                  label: 'VAT Number'
               },
               {
                  name: 'awrs_registration',
                  component: 'text',
                  placeholder: 'AWRS Registration',
                  label: 'AWRS Registration'
               },
               {
                  name: 'economic_operator_id',
                  component: 'text',
                  placeholder: 'Economic Operator ID',
                  label: 'Economic Operator ID'
               },
               {
                  name: 'facility_id',
                  component: 'text',
                  placeholder: 'Facility ID',
                  label: 'Facility ID'
               },
               {
                  name: 'current_affiliation',
                  component: 'select',
                  options: currentAffiliations,
                  labelKey: 'name',
                  valueKey: 'id',
                  placeholder: 'Current Affiliation',
                  label: 'Current Affiliation'
               },
               {
                  name: 'contract_end',
                  component: 'date',
                  placeholder: 'Contract End Date',
                  label: 'Contract End Date'
               },
               {
                  name: 'fascia',
                  component: 'select',
                  placeholder: 'Fascia',
                  label: 'Fascia',
                  options: fascias,
                  labelKey: 'name',
                  valueKey: 'id'
               },
               {
                  name: 'photo_id',
                  component: ProofOfID
               }
            ]
         },
         {
            group: 'Contact Details',
            tab: 'Contact Details',
            fields: [
               {
                  name: 'address.first_line',
                  component: 'text',
                  placeholder: 'Address Line 1',
                  label: 'Address Line 1',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'address.second_line',
                  component: 'text',
                  placeholder: 'Address Line 2',
                  label: 'Address Line 2'
               },
               {
                  name: 'address.third_line',
                  component: 'text',
                  placeholder: 'Address Line 3',
                  label: 'Address Line 3'
               },
               {
                  name: 'address.city',
                  component: 'text',
                  placeholder: 'Town / City',
                  label: 'Town / City'
               },
               {
                  name: 'address.county',
                  component: 'text',
                  placeholder: 'County',
                  label: 'County'
               },
               {
                  name: 'address.postcode',
                  component: 'text',
                  placeholder: 'Post Code',
                  label: 'Post Code',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'telephone',
                  component: 'text',
                  placeholder: 'Telephone Number',
                  label: 'Telephone Number',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'mobile_number',
                  component: 'text',
                  placeholder: '07123456789',
                  label: 'Mobile Number',
                  help: 'Phone number must be 07 format',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'email',
                  component: 'text',
                  placeholder: 'Email Address',
                  label: 'Email Address',
                  isRequired: true,
                  rules: { required: true }
               }
            ]
         },
         {
            group: 'Preferences',
            tab: 'Preferences',
            fields: [
               {
                  name: 'email_marketing',
                  component: 'switch',
                  placeholder: 'Email Marketing',
                  label: 'Email Marketing'
               },
               {
                  name: 'sms_marketing',
                  component: 'switch',
                  placeholder: 'SMS Marketing',
                  label: 'SMS Marketing'
               },
               {
                  name: 'mail_marketing',
                  component: 'switch',
                  placeholder: 'Mail Marketing',
                  label: 'Mail Marketing'
               }
            ]
         },
         {
            group: 'Consent',
            tab: 'Consent',
            help: 'Please hand the customer the device and ask them to read and sign the following consent form',
            fields: [
               {
                  name: 'consent_contact_agree',
                  component: 'switch',
                  label: 'I agree to be contacted by Parfetts with account information, news and updates'
               },
               {
                  name: 'consent_receive_agree',
                  component: 'switch',
                  label: 'I agree to receive deals, promotions and product information designed to drive margin and increase profits by email, post, SMS, WhatsApp and app notifications. \n These will be from Parfetts and selected third party partners (no contact data will be shared with these partners at any time).'
               }
            ]
         }
      ],
      [currentRda, businessCategories, countries, rdas, currentAffiliations]
   )

   return (
      <EditView fields={fields} setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

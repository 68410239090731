import React, { memo } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import dayjs from 'dayjs'
import { Text, Image, Flex, Box } from '@builtbypixel/plasma'
import { Carousel } from 'react-responsive-carousel'

const CurrentGoLocalPeriod = memo(() => {
   const { data: currentGoLocalPeriod } = useSWR(
      '/current-go-local-period',
      useGet,
      {
         suspense: true
      }
   )
   const { data: resources } = useSWR(`/resources`, useGet)

   return (
      <Flex direction='column'>
         <Box>
            <Text
               fontSize='1.5rem'
               fontWeight='semibold'
               mr='15px'
               color='parfettsRed'
            >
               {currentGoLocalPeriod?.data?.name}
            </Text>
            <Text fontSize='1.3rem'>
               {dayjs(currentGoLocalPeriod?.data?.period_start).format(
                  'DD MMM '
               )}
               -
               {dayjs(currentGoLocalPeriod?.data?.period_end).format(
                  ' DD MMM, YYYY'
               )}
            </Text>
            <Text fontSize='0.8rem'>in store dates</Text>
         </Box>
         <Flex direction='column' alignItems='start' justify='centre'>
            <Box>
               <Carousel
                  showArrows={true}
                  showStatus={false}
                  centerMode={false}
                  autoPlay={true}
                  swipeable={true}
                  renderIndicator={false}
               >
                  {resources?.data?.[1]?.resources?.map((image) => (
                     <Image
                        src={image.url}
                        width='auto !important'
                        height='300px'
                     />
                  ))}
               </Carousel>
            </Box>

            {/* <Flex direction='row' alignItems='space-between' justify='start'>
               <Button
                  variant='ghost'
                  variantColor='primary'
                  rounded='md'
                  leftIcon={<BsDownload fontSize={18} />}
                  onClick={() =>
                     window.location.assign(
                        'https://crmapi.parfetts.co.uk/resources/GL142021/G1.zip'
                     )
                  }
               >
                  G1
               </Button>
               <Button
                  variant='ghost'
                  variantColor='primary'
                  rounded='md'
                  leftIcon={<BsDownload fontSize={18} />}
                  onClick={() =>
                     window.location.assign(
                        'https://crmapi.parfetts.co.uk/resources/GL142021/G2.zip'
                     )
                  }
               >
                  G2
               </Button>
               <Button
                  variant='ghost'
                  variantColor='primary'
                  rounded='md'
                  leftIcon={<BsDownload fontSize={18} />}
                  onClick={() =>
                     window.location.assign(
                        'https://crmapi.parfetts.co.uk/resources/GL142021/G3.zip'
                     )
                  }
               >
                  G3
               </Button>
               <Button
                  variant='ghost'
                  variantColor='primary'
                  rounded='md'
                  leftIcon={<BsDownload fontSize={18} />}
                  onClick={() =>
                     window.location.assign(
                        'https://crmapi.parfetts.co.uk/resources/GL142021/TL.zip'
                     )
                  }
               >
                  TL
               </Button>
            </Flex> */}
         </Flex>
      </Flex>
   )
})

export default CurrentGoLocalPeriod

import React, { memo } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'
import { tableAtom } from '../../state/global'
import { useRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'

import {
   Box,
   Button,
   Flex,
   Grid,
   Text,
   Image,
   ScrollContainer
} from '@builtbypixel/plasma'

const CustomProgress = ({ value, bg, progressColor, height, label }) => {
   return (
      <Box
         position='relative'
         backgroundColor={bg}
         width='100%'
         height={height}
         border='1px solid #aeaeae'
         borderRadius='12px'
         boxSizing='content-box'
      >
         <Box
            position='absolute'
            backgroundColor={progressColor}
            top='0'
            left='0'
            bottom='0'
            borderRadius={value >= 100 ? '10px' : '10px 0px 0px 10px'}
            width={`${value >= 100 ? 100 : value}%`}
            height={height}
         ></Box>
         <Box
            position='absolute'
            top='0'
            left='0'
            bottom='0'
            width='100%'
            height={height}
         >
            <Text textAlign='center'>{label}</Text>
         </Box>
      </Box>
   )
}
const UpcomingVisits = memo(() => {
   const { data } = useSWR('/live-audit-results', useGet, {
      suspense: true
   })

   const [tableState, setTableState] = useRecoilState(tableAtom)
   const history = useHistory()

   const cellPadding = '8px 12px'

   const handleViewRdaAudits = (id, name) => {
      history.push(`/audits/list/go-local`)
      setTimeout(() => {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: {
                  type_id: 1,
                  user_id: id
               }
            },
            rda_name: name
         }))
      }, 500)
   }

   return (
      <ScrollContainer>
         <Grid templateColumns='repeat(2, 1fr)' gap='5px 0px'>
            <Text mb='5px' fontWeight='semibold' p={cellPadding}>
               RDA Name
            </Text>
            <Text mb='5px' fontWeight='semibold' p={cellPadding}>
               Audits
            </Text>
         </Grid>

         {data.data?.length > 0 &&
            data.data?.map((audit, i) => {
               return (
                  <Grid
                     templateColumns='repeat(2, 1fr)'
                     gap='5px 0px'
                     key={`rda-audits-table-${i}`}
                  >
                     <Flex
                        bg={i % 2 === 0 && 'grayRow'}
                        align='center'
                        p={cellPadding}
                     >
                        <Image
                           src={audit?.rda?.profile_photo?.[0]?.url}
                           size='30px'
                           objectFit='cover'
                           rounded='full'
                           mr='5px'
                        />

                        <Button
                           onClick={() =>
                              handleViewRdaAudits(
                                 audit?.rda?.id,
                                 audit?.rda?.name
                              )
                           }
                           variant='link'
                        >
                           {audit?.rda?.name}
                        </Button>
                     </Flex>
                     <Flex
                        bg={i % 2 === 0 && 'grayRow'}
                        py='10px'
                        px='10px'
                        align='center'
                        rounded='md'
                        zIndex='1'
                     >
                        <CustomProgress
                           value={(audit.audits / audit.target) * 100}
                           progressColor={
                              audit.audits / audit.target >= 0.66
                                 ? 'parfettsGreen'
                                 : audit.audits / audit.target <= 0.33
                                 ? 'parfettsRed'
                                 : 'parfettsBlue'
                           }
                           bg='transparent'
                           height='25px'
                           label={`${audit.audits} / ${audit.target ?? 0}`}
                        />
                     </Flex>
                  </Grid>
               )
            })}
      </ScrollContainer>
   )
})

export default UpcomingVisits

import { useHttp } from '.'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useAuditTemplates = debounce((inputValue, callback) => {
   Http.post(`/audit-templates`, { search: inputValue }).then(
      (res) => callback(res.data.data)
   )
}, 500)

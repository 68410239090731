import React, { useCallback, useEffect } from 'react'
import {
    IconButton,
    Stack,
    Icon,
    Text,
    Image,
    Modal,
    ModalButton,
    Table,
    PopConfirm,
    Box,
    Tooltip
} from '@builtbypixel/plasma'
import { usePost } from '../../hooks'
import { useHttp, useToast } from '../../hooks'
import { FiUploadCloud } from 'react-icons/fi'
import EditFile from './AddEditFile'


import { FiDownload, FiEye, FiTrash2 } from 'react-icons/fi'



import FileIcon from '../../components/common/FileIcon'
import PreviewFileModal from './PreviewFileModal'
import TableLoader from '../../components/common/TableLoader'
import mediaState, { triggerRefresh } from './utils/mediaState'

import {
    useRecoilState
} from 'recoil';



/* eslint-disable */
const List = ({ selectedFolder, retailer, retailerView, selectedCallback }) => {
    const [media, setMedia] = useRecoilState(mediaState);
    const [refreshCounter, setRefreshCounter] = useRecoilState(triggerRefresh)
    const { post, response, loading } = usePost()
    const Http = useHttp()
    const toast = useToast()
    const columns = [
        {
            Header: 'Preview',
            accessor: '',
            width: 50,
            Cell: ({ row }) => {
                if (
                    (row.original.type && row.original.type === 'image/jpg') ||
                    row.original.type === 'image/png' ||
                    row.original.type === 'image/gif' ||
                    row.original.type === 'image/jpeg'

                ) {
                    return (
                        <Image
                            src={row.original.url}
                            w='50px'
                            h='40px'
                            objectFit='cover'
                        />
                    )
                } else {
                    return null
                }
            }
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value, row }) => (
                <Stack isInline spacing='5px' align='center'>
                    <Icon color='global.text'>
                        <FileIcon fileType={row.original.type} />
                    </Icon>
                    <Text fontSize='1rem' fontWeight='semibold'>
                        {value}
                    </Text>
                </Stack>
            )
        },
        {
            Header: 'File size',
            accessor: 'filesize',
            Cell: ({ value }) => <Text>{value / 1000} kb</Text>
        },
        {
            Header: 'Actions',
            accessor: '',
            Cell: ({ row }) => (
                <Stack isInline spacing='0px'>

                    <Modal>
                        <ModalButton>
                            <IconButton
                                size='md'
                                variant='ghost'
                                icon={<FiEye fontSize='17px' />}
                            >
                                Preview
                       </IconButton>
                        </ModalButton>
                        <PreviewFileModal file={row.original.url} type={row.original.type} />
                    </Modal>


                    {!selectedCallback &&
                        <IconButton
                            size='md'
                            variant='ghost'
                            as='a'
                            href={row.original.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            download
                            icon={<FiDownload fontSize='17px' />}
                        >
                            Download
                 </IconButton>
                    }

                    <PopConfirm
                        title={
                            <>
                                <Text>Are you sure?</Text>{' '}
                                <Text fontWeight='semibold'>
                                    This will delete all files associated with this folder and
                                    cannot be undone.
                  </Text>
                            </>
                        }
                        onConfirm={() => handleDelete(row.original.id)}
                    >
                        <IconButton
                            size='md'
                            variant='ghost'
                            icon={<FiTrash2 fontSize='17px' />}
                        >
                            Delete
                 </IconButton>
                    </PopConfirm>

                </Stack>
            )
        }
    ]

    const refreshFiles = () => {
        post('/media-library/files', {
            filters: {
                folder_id: selectedFolder?.id
            }
        })
    }
    useEffect(() => {
        setMedia([])
        if (!retailerView) {
            refreshFiles()
        } else {
            selectedFolder?.id && refreshFiles()
        }

    }, [selectedFolder, retailer, refreshCounter])

    useEffect(() => {
        if (response && response.data) {
            setMedia(response.data)
        }
    }, [response])

    const handleDelete = (file) => {
        Http.delete(`/media-library/files`, { data: { id: [file] } })
            .then(() => {
                toast({
                    title: 'File Deleted',
                    status: 'success',
                    variant: 'plain'
                })
                setRefreshCounter(refreshCounter + 1)
            })
            .catch((err) => {
                toast({
                    title: 'Error Deleting File',
                    status: 'error',
                    variant: 'plain'
                })
            })


    }


    const UploadFile = ({ selectedFolder }) => {
        return (
            <Tooltip title='Upload File'>
                <Modal>
                    <ModalButton>
                        <Text cursor='pointer' textAlign='center'>This folder is empty</Text>
                        <IconButton
                            isFullWidth
                            opacity={0.6}
                            variant='ghost'
                            icon={<FiUploadCloud fontSize='24px' />}
                        />
                    </ModalButton>
                    <EditFile folder={selectedFolder} />
                </Modal>
            </Tooltip>

        )
    }

    const handleRowSelect = useCallback(
        (rows) => {
            if (selectedCallback) {
                selectedCallback(rows?.[0]?.original?.url)

            }
            else {
                return null
            }
        }
        , [selectedCallback])

    return (
        loading ? <TableLoader />
            :
            media?.data?.length > 0 ?
                <Table
                    columns={columns}
                    data={media}
                    loading={loading}
                    onRowSelect={handleRowSelect}
                />
                :
                <Box p='25px'>
                    {selectedFolder?.id ?
                        <UploadFile selectedFolder={selectedFolder} />
                        :
                        <Text>Select a folder to view files</Text>
                    }
                </Box>

    )
}
/* eslint-enable */

export default List;
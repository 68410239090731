import React, { useMemo } from 'react'
import { Text } from '@builtbypixel/plasma'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { useRDAS } from '../../hooks'

const EditForm = () => {
   const formatOptionLabel = (option) => (
      <Text>
         {option?.first_name} {option?.last_name}
      </Text>
   )
   const fields = useMemo(
      () => [
         {
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  rules: { required: true }
               },
               {
                  name: 'users',
                  component: 'select',
                  label: 'Members (RDA)',
                  placeholder: 'Search by name...',
                  isAsync: true,
                  loadOptions: useRDAS,
                  labelKey: 'full_name',
                  valueKey: 'id',
                  isMulti: true,
                  formatOptionLabel: { formatOptionLabel }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

import React, { useState, useEffect } from 'react'
import { Input } from '@builtbypixel/plasma'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp';
import {
   useWatch,
   useFormContext
} from 'react-hook-form'


const Geoloc = (props) => {
   const { setActive, onChange, onBlur, value, ...rest } = props
   const [fetchedPostcode, setFetchedPostcode] = useState(null)
   const { setValue } = useFormContext()

   const postcode = useWatch({ name: 'address.postcode' })

   useEffect(() => {
      if (postcode && postcode.length > 5) {
         axios({
            url: `https://api.postcodes.io/postcodes/${postcode}`,
            adapter: jsonpAdapter,
            //     callbackParamName: 'c' // optional, 'callback' by default
         }).then((res) => {

            setFetchedPostcode(res.data.result);
            setValue('geoloc', res.data.result)
         }).catch((err) => {
            console.log(`Error fetching postcode`);
         });
      }
   }, [postcode, setValue])

   return (
      <Input
         onChange={(e) => onChange(fetchedPostcode)}
         onBlur={() => {
            onBlur()
            setActive(false)
         }}
         type='text'
         onFocus={() => setActive(true)}
         value={fetchedPostcode?.latitude}
         defaultValue={fetchedPostcode?.latitude}
         required={false}
         isRequired={false}
         {...rest}
      />
   )
}

export default Geoloc

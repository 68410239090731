import React from 'react'
import { Button, Modal, ModalButton } from '@builtbypixel/plasma'

import { FiCheckCircle } from 'react-icons/fi'
import Content from './Content'

/**
 * A modal that prompt the user to confirm they are in the store,
 * and they can also add an optional note for the visit.
 */
const InStoreNow = () => {
   return (
      <Modal>
         <ModalButton>
            <Button
               variant='solid'
               variantColor='parfettsGreen'
               color='white'
               leftIcon={<FiCheckCircle />}
               mr='10px'
            >
               In Store Now
            </Button>
         </ModalButton>
         <Content />
      </Modal>
   )
}

export default InStoreNow

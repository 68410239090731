import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Box, Image } from '@builtbypixel/plasma'

const ImageModal = ({ images }) => {
   return (
      <Carousel
         showArrows={true}
         showStatus={false}
         centerMode={false}
         autoPlay={true}
         swipeable={true}
      >
         {images?.map((image) => (
            <Box maxHeight='80vh'>
               <Image
                  src={image.url}
                  height='80vh !important'
                  width='auto !important'
               />
            </Box>
         ))}
      </Carousel>
   )
}

export default ImageModal

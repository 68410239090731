import React, { Suspense, useState, useEffect } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Text,
   Box,
   Flex,
   Button,
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Table,
   Image,
   Modal,
   ModalButton,
   ModalContent,
   Input
} from '@builtbypixel/plasma'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { FiPlus, FiEdit } from 'react-icons/fi'
import BlockLoader from '../../components/common/BlockLoader'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import useSWR from 'swr'
import { useGet } from '../../hooks'
import LinkButton from '../../components/LinkButton'
import { Carousel } from 'react-responsive-carousel'

const Comp = ({ searchText }) => {
   const [filteredExecutions, setFilteredExecutions] = useState([])

   const { data } = useSWR(`/all-execution-lines`, useGet, {
      suspense: true
   })
   useEffect(() => {
      if (searchText) {
         setFilteredExecutions(
            data.data.filter((item) =>
               item?.price_promotion?.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            )
         )
      } else {
         setFilteredExecutions(data.data)
      }
   }, [searchText, data])
   return (
      <Box mt='25px'>
         <Accordion
            arrowAlign='left'
            collapsible
            defaultIndex={null}
            multiple={false}
            readOnly={false}
            size='lg'
            showArrow
         >
            {data &&
               filteredExecutions?.map((topExecution, i) => {
                  return (
                     <AccordionItem size='lg' key={`execution-accordion-${i}`}>
                        <AccordionButton
                           size='lg'
                           arrowAlign='left'
                           borderColor='red'
                           onClick={function noRefCheck() {}}
                           showArrow
                           backgroundColor='gray.200'
                           zIndex='9'
                        >
                           <Flex
                              align='center'
                              justify='space-between'
                              w='100%'
                              zIndex='99'
                           >
                              <Text fontWeight='bold'>
                                 {topExecution?.gl_period
                                    && topExecution.gl_period
                                 }

                                 {
                                       topExecution?.price_promotion?.name
                                          && ' - ' + topExecution.price_promotion.name
                                 }
                              </Text>

                              <LinkButton
                                 to={`/executions/edit/${topExecution?.id}`}
                              >
                                 <Button
                                    variantColor='parfettsGreen'
                                    color='white'
                                    leftIcon={<FiEdit />}
                                    rounded='md'
                                    size='sm'
                                    zIndex='999'
                                 >
                                    Edit
                                 </Button>
                              </LinkButton>
                           </Flex>
                        </AccordionButton>
                        <AccordionPanel mb={0} pb={3} px={5}>
                           {' '}
                           {topExecution?.executions?.length > 0 && (
                              <Box mt='15px'>
                                 <ExecutionProducts
                                    executions={topExecution?.executions}
                                    id={topExecution.id}
                                 />
                              </Box>
                           )}
                        </AccordionPanel>
                     </AccordionItem>
                  )
               })}
         </Accordion>
      </Box>
   )
}

const ExecutionProducts = ({ executions, id }) => {
   return (
      <ol style={{ listStyle: 'none' }}>
         {executions?.map((item, i) => (
            <Box as='li' key={`execution-brand-${i}`} bg='#E2E8F0'>
               <Text p='10px 5px' my='5px' fontWeight='700'>
                  {i + 1}. {item?.brand?.name}{' '}
                  {item?.supplier?.name ? ` - ${item?.supplier?.name}` : ''}
               </Text>
               <Flex align='center'>
                  <Text as='strong' pl='10px' pr='5px'>
                     Activation
                  </Text>
                  <Text bg='#E2E8F0' fontWeight='400'>
                     {item?.activation}
                  </Text>
               </Flex>
               <Flex align='center'>
                  <Text as='strong' pl='10px' pr='5px'>
                     Additional POS
                  </Text>
                  <Text bg='#E2E8F0' fontWeight='400'>
                     {item?.additional_pos}
                  </Text>
               </Flex>
            </Box>
         ))}
      </ol>
   )
}

const LinesTable = ({ lines }) => {
   const columns = [
      {
         Header: 'Retailer',
         accessor: 'retailer.business_name',
         Cell: ({ value, row }) => (
            <Box>
               <Text as='strong'>{value}</Text>
               <Text>
                  by {row.original.rda.first_name} {row.original.rda.last_name}
               </Text>
            </Box>
         )
      },
      {
         Header: 'Images',
         accessor: 'images',
         Cell: ({ value }) => (
            <Modal>
               <ModalButton>
                  <Flex position='relative' cursor='pointer'>
                     {value?.map((image) => (
                        <Image src={image.url} w='64px' mx='5px' />
                     ))}
                     <Button
                        size='small'
                        variant='solid'
                        variantColor='parfettsGreen'
                        color='white'
                        rounded='md'
                        w='100%'
                        p='5px'
                     >
                        view all
                     </Button>
                  </Flex>
               </ModalButton>
               <ModalContent>
                  <ImageModal images={value} />
               </ModalContent>
            </Modal>
         )
      },
      {
         Header: 'Products',
         accessor: 'products',
         Cell: ({ value }) =>
            value && value.length > 0 ? (
               value?.map((product) => <Text>{product.description}</Text>)
            ) : (
               <Text>n/a</Text>
            )
      }
   ]
   return (
      <Table data={{ data: lines }} columns={columns} manualPagination={2} />
   )
}

const ImageModal = ({ images }) => {
   return (
      <Carousel
         showArrows={true}
         showStatus={false}
         centerMode={false}
         autoPlay={true}
         swipeable={true}
      >
         {images?.map((image) => (
            <Image src={image.url} />
         ))}
      </Carousel>
   )
}
const RetailerExecutions = () => {
   const [searchText, setSearchText] = useState('')

   const handleChange = (e) => {
      setSearchText(e.target.value)
   }
   return (
      <Box>
         <Flex justifyContent='space-between'>
            <Box w='100%' mb='10px'>
               <ViewTitle title='Retailer Executions' />
            </Box>
            <Input
               placeholder='Filter by Go Local Period'
               value={searchText}
               onChange={(e) => handleChange(e)}
               mr='25px'
            />
            <Flex>
               <LinkButton to='/executions/create'>
                  <Button
                     variant='solid'
                     variantColor='parfettsRed'
                     color='white'
                     leftIcon={<FiPlus />}
                     rounded='md'
                  >
                     Add New
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>

         <ErrorBoundary>
            <Suspense fallback={<BlockLoader columns={1} />}>
               <Comp searchText={searchText} />
            </Suspense>
         </ErrorBoundary>
      </Box>
   )
}

export default RetailerExecutions

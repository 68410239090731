import React, { memo } from 'react'
import { useGet } from '../../hooks'
import useSWR from 'swr'

import { Flex, Grid, Text, Image, ScrollContainer } from '@builtbypixel/plasma'

const RetailerExecutionSubmissions = memo(() => {
   const { data } = useSWR('/retailer-execution-submittions', useGet, {
      suspense: true
   })
   const cellPadding = '8px 12px'

   return (
      <ScrollContainer>
         <Grid templateColumns='repeat(2, 1fr)' gap='5px 0px'>
            <Text mb='5px' fontWeight='semibold' p={cellPadding}>
               RDA Name
            </Text>
            <Text mb='5px' fontWeight='semibold' p={cellPadding}>
               Executions
            </Text>
         </Grid>

         {data.data?.length > 0 &&
            data.data?.slice(0, 8).map((execution, i) => {
               return (
                  <Grid
                     templateColumns='repeat(2, 1fr)'
                     gap='5px 0px'
                     key={`rda-audits-table-${i}`}
                  >
                     <Flex
                        bg={i % 2 === 0 && 'grayRow'}
                        align='center'
                        p={cellPadding}
                     >
                        <Image
                           src={execution?.rda?.profile_photo?.[0]?.url}
                           size='30px'
                           objectFit='cover'
                           rounded='full'
                           mr='5px'
                        />
                        {execution?.rda?.name}
                     </Flex>
                     <Flex
                        bg={i % 2 === 0 && 'grayRow'}
                        py='10px'
                        px='10px'
                        align='center'
                        rounded='md'
                     >
                        {execution.count}
                     </Flex>
                  </Grid>
               )
            })}
      </ScrollContainer>
   )
})

export default RetailerExecutionSubmissions

import React from 'react'
import { Flex } from '@builtbypixel/plasma'

import NavBackButton from '../components/NavBackButton'
import NavComponent from '../components/NavComponent'

/**
 * The navigation bar to navigate between the selectable options
 * @param {object} selectedGoLocalPeriod The selected GoLocalPeriod object
 * @param {function} setSelectedGoLocalPeriod The function to be called to make changes to the selectedGoLocalPeriod
 * @param {object} selectedRda The selected RDA object
 * @param {function} setSelectedRda The function to be called to make changes to the selectedRda
 * @param {object} selectedExecution The selected Execution object
 * @param {function} setSelectedExecution The function to be called to make changes to the selectedExecution
 */
const NavBar = ({
   selectedGoLocalPeriod,
   setSelectedGoLocalPeriod,
   selectedRda,
   setSelectedRda,
   selectedExecution,
   setSelectedExecution
}) => {
   const handleBack = () =>
      selectedExecution
         ? setSelectedExecution(null)
         : selectedRda
         ? setSelectedRda(null)
         : setSelectedGoLocalPeriod(null)

   const handleSelectedGoLocalPeriodClick = () => {
      setSelectedRda(null)
      setSelectedExecution(null)
   }

   return selectedGoLocalPeriod ? (
      <Flex align='center'>
         <NavBackButton onClick={handleBack} />
         <NavComponent
            item={selectedGoLocalPeriod}
            color='parfettsGreen'
            onClick={handleSelectedGoLocalPeriodClick}
            text={selectedGoLocalPeriod?.name?.name}
         />
         <NavComponent
            item={selectedRda}
            color='parfettsBlue'
            onClick={() => setSelectedExecution(null)}
            text={selectedRda?.first_name + ' ' + selectedRda?.last_name}
         />
         <NavComponent
            item={selectedExecution}
            color='parfettsOrange'
            text={selectedExecution?.brand?.name}
         />
      </Flex>
   ) : null
}

export default NavBar

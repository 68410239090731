import { useHttp } from './'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useRDAS = debounce((inputValue,callback) => {
        Http.post(`/users`, { search: inputValue }
        ).then((res) => {
            return callback(res.data.data)})
    }, 500)
import React, { useEffect, useState } from 'react'
import { Select, Box, Flex, DatePicker } from '@builtbypixel/plasma'
import { useRDAS } from '../../hooks'

const Filters = ({ showFilters, user, setUser, setDate }) => {
   const [selectValue, setSelectValue] = useState(null)
   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name
   const filterByUser = (el) => {
      let selected = el.map((item) => item.id)
      setUser(selected)
      setSelectValue(el)
   }
   const filterByDate = (el) => {
      setDate(el)
   }
   useEffect(() => {
      if (!showFilters) {
         setSelectValue(null)
      }
   }, [showFilters])

   return (
      <>
         {showFilters && (
            <Flex mb='20px'>
               <Box w='100%' mr='10px'>
                  <p>Posted by:</p>

                  <Select
                     name='user_id'
                     component='select'
                     placeholder='View notifications from'
                     isAsync={true}
                     loadOptions={useRDAS}
                     labelKey='first_name'
                     valueKey='id'
                     value={selectValue}
                     onChange={(value) => filterByUser(value)}
                     formatOptionLabel={formatOptionLabel}
                     variant='flushed'
                  />
               </Box>
               <Box w='100%' ml='10px'>
                  <p>Posted on:</p>
                  <DatePicker
                     autoOk
                     clearable
                     disableFuture
                     format='DD/MM/yyyy'
                     inputProps='outline'
                     onChange={(value) => filterByDate(value)}
                     placeholder='DD/MM/YYYY'
                  />
               </Box>
            </Flex>
         )}
      </>
   )
}

export default Filters

import React, { Suspense, useState } from 'react'

import { Grid, Flex, Box, Button } from '@builtbypixel/plasma'
import Card from '../../components/common/Card'
import SalesFigures from './SalesFigures'
import PendingLeadsByRDA from './PendingLeadsByRDA'
import AuditsByRDA from '../../components/AuditsByRda'
import CardLoader from '../../components/common/CardLoader'
import ChartLoader from '../../components/common/ChartLoader'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import CurrentGoLocalPeriod from './CurrentGoLocalPeriod'
import RetailerExecutionSubmissions from '../../components/RetailerExecutionSubmissions'
import LinkButton from '../../components/LinkButton'
import ChartFilters from './ChartFilters'
import Tasks from '../../containers/Planner/Tabs/Tasks'
import { BsArrowRightShort } from 'react-icons/bs'

const gridFirstRowHeight = 450 // The height of the cards in the first row in px.

const HeadOfficeDashboard = () => {
   const [financial, setFinancial] = useState(false)
   const [selectedBranch, setSelectedBranch] = useState(null)
   const [selectedUser, setSelectedUser] = useState(null)
   const [fascia, setFascia] = useState(false)
   const [sop, setSop] = useState(null)

   return (
      <Grid
         h={gridFirstRowHeight}
         isInline
         spacing='10px'
         templateColumns='repeat(12, 1fr)'
         gap='15px 15px'
      >
         <Card
            gridColumn='span 4'
            title='Current Go Local Period'
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/resources`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <Flex align='center'>
               <ErrorBoundary>
                  <Suspense fallback={<ChartLoader />}>
                     <CurrentGoLocalPeriod />
                  </Suspense>
               </ErrorBoundary>
            </Flex>
         </Card>
         <Card
            h={gridFirstRowHeight}
            gridColumn='span 4'
            title='Live Audit Results'
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/audits/list/go-local`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<ChartLoader />}>
                  <AuditsByRDA />
               </Suspense>
            </ErrorBoundary>
         </Card>
         <Card
            height={gridFirstRowHeight}
            gridColumn='span 4'
            title='Retailer Execution Submissions'
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/executions`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<ChartLoader />}>
                  <RetailerExecutionSubmissions />
               </Suspense>
            </ErrorBoundary>
         </Card>
         <Card
            flex={2}
            title='Sales Figures'
            position='relative'
            gridColumn={{ xs: 'span 12' }}
            extraMenu={
               <ChartFilters
                  setFascia={setFascia}
                  fascia={fascia}
                  setFinancial={setFinancial}
                  setSelectedBranch={setSelectedBranch}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                  selectedBranch={selectedBranch}
                  financial={financial}
                  sop={sop}
                  setSop={setSop}
               />
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<ChartLoader />}>
                  <>
                     <SalesFigures
                        mode={financial ? 'financial' : 'calendar'}
                        branch={selectedBranch?.id}
                        rda={selectedUser?.rda_id?.id}
                        fascia={fascia}
                        sop={sop}
                     />
                  </>
               </Suspense>
            </ErrorBoundary>
         </Card>

         <Card
            flex={1}
            title='Pending Customer Leads'
            gridColumn={{ xs: 'span 6', lg: 'span 6' }}
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/customer-leads`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<CardLoader />}>
                  <PendingLeadsByRDA />
               </Suspense>
            </ErrorBoundary>
         </Card>

         <Card
            flex={1}
            title='Your Tasks'
            gridColumn={{ xs: 'span 6', lg: 'span 6' }}
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/planner/tasks`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<CardLoader />}>
                  <Tasks limit='6' />
               </Suspense>
            </ErrorBoundary>
         </Card>
      </Grid>
   )
}

export default HeadOfficeDashboard

import React from 'react'
import {
   BiHomeAlt,
   BiStar,
   BiLineChart,
   BiCalendar,
   BiBookOpen,
   BiCloudDownload,
   BiNotification
} from 'react-icons/bi'
import { GiPodiumWinner } from 'react-icons/gi'
import {
   RiTruckLine,
   RiInformationLine,
   RiNotification3Line
} from 'react-icons/ri'
import { FiUsers, FiSettings } from 'react-icons/fi'
import { AiOutlineShop } from 'react-icons/ai'
import { MdPermMedia } from 'react-icons/md'
import { RiPlayListAddFill, RiWhatsappLine } from 'react-icons/ri'

const menuLinks = [
   {
      group: 'Dashboard',
      link: '/dashboard',
      icon: <BiHomeAlt />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Notifications',
      link: '/notifications',
      icon: <RiNotification3Line />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Customer Leads',
      link: '/customer-leads',
      icon: <BiStar />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Audits',
      link: '/audit-templates',
      icon: <RiPlayListAddFill />,
      items: [],
      permissions: ['Admin']
   },
   {
      group: 'Audits',
      link: '/audits/list/go-local',
      icon: <BiLineChart />,
      items: [],
      permissions: ['RDA']
   },
   {
      group: 'Push Notifications',
      link: '/push-notifications',
      icon: <BiNotification />,
      items: [],
      permissions: ['Admin']
   },
   {
      group: 'WhatsApp',
      link: '/whatsapp',
      icon: <RiWhatsappLine />,
      items: [
         {
            title: 'Templates',
            href: '/whatsapp-templates',
            items: [],
            permissions: ['Admin']
         },
         {
            title: 'Segments',
            href: '/whatsapp-segments',
            items: [],
            permissions: ['Admin']
         },
         {
            title: 'Messages',
            href: '/whatsapp-messages',
            items: [],
            permissions: ['Admin']
         }
      ],
      permissions: ['Admin']
   },
   {
      group: 'Competitions',
      link: '/competitions',
      icon: <GiPodiumWinner />,
      items: [],
      permissions: ['Admin']
   },
   {
      group: 'Planner',
      link: '/planner/calendar',
      icon: <BiCalendar />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Products',
      link: '/products',
      icon: <BiBookOpen />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Resources',
      link: '/resources',
      icon: <BiCloudDownload />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Retailers',
      link: '/retailers',
      icon: <RiTruckLine />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Executions',
      link: '/executions',
      icon: <RiInformationLine />,
      items: [],
      permissions: ['Admin']
   },
   {
      group: 'Reports',
      link: '/developments',
      icon: <AiOutlineShop />,
      items: [
         {
            title: 'Audits',
            href: '/audits/list/go-local',
            icon: <BiLineChart />,
            items: [],
            permissions: ['Admin']
         },
         {
            title: 'Supplier Report',
            href: '/report/execution',
            permissions: ['Admin'],
            icon: <RiInformationLine />
         },
         {
            title: 'RDA Report',
            href: '/report/rda',
            permissions: ['Admin'],
            icon: <RiInformationLine />
         }
      ],
      permissions: ['Admin']
   },

   // {
   //    group: 'Store Developments',
   //    link: '/developments',
   //    icon: <AiOutlineShop />,
   //    items: [],
   //    permissions: ['Admin']
   // },
   {
      group: 'Users',
      link: '/administrators',
      icon: <FiUsers />,
      items: [],
      permissions: ['Admin']
   },
   {
      group: 'Media Library',
      link: '/media-library',
      icon: <MdPermMedia />,
      items: [],
      permissions: ['RDA', 'Admin']
   },
   {
      group: 'Settings',
      link: '/settings',
      icon: <FiSettings />,
      permissions: ['RDA', 'Admin'],
      items: [
         {
            href: '/task-categories',
            title: 'Task Categories',
            permissions: ['RDA', 'Admin']
         },
         {
            href: '/teams',
            title: 'Teams',
            permissions: ['Admin']
         },
         {
            href: '/services',
            title: 'Services',
            permissions: ['Admin']
         }
      ]
   }
]

export default menuLinks

import React, { Suspense, useState } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Box,
   Grid,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   Select,
   Flex,
   Button
} from '@builtbypixel/plasma'
import { BiCalendar } from 'react-icons/bi'
import Card from '../../components/common/Card'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import ReturnButton from '../../components/common/ReturnButton'
import LinkButton from '../../components/LinkButton'
import CardLoader from '../../components/common/CardLoader'
import BlockLoader from '../../components/common/BlockLoader'
import ChartLoader from '../../components/common/ChartLoader'
import TableLoader from '../../components/common/TableLoader'
import InStoreNow from './InStoreNow/index'
import {
   Switch,
   Route,
   useRouteMatch,
   useParams,
   useHistory
} from 'react-router-dom'
import setup from './setup'
import useSWR from 'swr'
import { useGet } from '../../hooks'
import { MdPlaylistAdd } from 'react-icons/md'

const Invoices = React.lazy(() => import('./routes/Invoices'))
const Reports = React.lazy(() => import('./routes/Reports'))
const SalesSummary = React.lazy(() => import('./routes/SalesSummary'))
const HierarchySales = React.lazy(() => import('./routes/HierarchySales'))
const Notes = React.lazy(() => import('./routes/Notes'))
const Audits = React.lazy(() => import('./routes/Audits'))
//const Visits = React.lazy(() => import('../../components/VisitsTab'))
//const Assets = React.lazy(() => import('./routes/Assets'))
const Trolley = React.lazy(() => import('./routes/Trolley'))
const Execution = React.lazy(() => import('./routes/Execution'))
const Social = React.lazy(() => import('./routes/Social'))
const Compliance = React.lazy(() => import('./routes/Compliance'))

const RetailerOverview = React.lazy(() => import('./RetailerOverview'))
const RetailerSpending = React.lazy(() => import('./Spending'))
const RetailerScore = React.lazy(() => import('./RetailerScore'))
const ImageGallery = React.lazy(() => import('../../components/ImageGallery'))

const Dashboard = () => {
   const { branchId, id } = useParams()
   const { data } = useSWR(`/retailers/${branchId}/${id}`, useGet)
   const [financial, setFinancial] = useState(false)
   const [sop, setSop] = useState(null)

   const routes = [
      {
         name: 'Invoices',
         component: <Invoices retailer={id} />,
         path: 'invoices',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Images',
         component: (
            <ImageGallery id={id} endpoint='retailers-images' type='retailer' />
         ),
         path: 'images',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Reports',
         component: <Reports retailer={data} />,
         path: 'reports',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Sales Summary',
         component: <SalesSummary />,
         path: 'sales-summary',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Hierarchy Sales Split',
         component: <HierarchySales />,
         path: 'hierarchy-sales',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Notes',
         component: <Notes />,
         path: 'notes',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Audits',
         component: <Audits />,
         path: 'audits',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      // {
      //    name: 'Visits',
      //    component: <Visits id={id} type='retailer' />,
      //    path: 'visits',
      //    exact: false,
      //    permissions: ['Admin', 'RDA']
      // },
      {
         name: 'Compliance',
         component: <Compliance id={id} />,
         path: 'compliance',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Trolley',
         component: <Trolley id={id} />,
         path: 'trolley',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Social',
         component: (
            <Social
               facebook={data?.data?.attributes?.facebook}
               retailer={data?.data}
            />
         ),
         path: 'social',
         exact: false,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Execution',
         component: <Execution />,
         path: 'execution',
         exact: false,
         permissions: ['Admin', 'RDA']
      }
   ]
   let { path, url } = useRouteMatch()

   const history = useHistory()

   const getActiveTab = () => {
      const item = routes.find((r) =>
         history.location.pathname.split('/').includes(r.path)
      )
      return routes.indexOf(item)
   }

   return (
      <>
         <Box mb='20px'>
            <ReturnButton to={`/${setup.model}`}>Retailers</ReturnButton>
            <Flex align='center' justify='space-between'>
               <ViewTitle
                  title={data ? `${data.data.business_name} #${id}` : null}
               />

               <Flex>
                  <LinkButton to={`/audits/create/${id}`}>
                     <Button
                        variant='solid'
                        variantColor='parfettsBlue'
                        color='white'
                        mr='10px'
                        leftIcon={<MdPlaylistAdd />}
                     >
                        New Audit
                     </Button>
                  </LinkButton>
                  <InStoreNow />
                  <LinkButton to={`/retailers/edit/${branchId}/${id}`}>
                     <Button variantColor='parfettsRed' color='white'>
                        Edit Retailer
                     </Button>
                  </LinkButton>
               </Flex>
            </Flex>
         </Box>
         <Grid
            isInline
            spacing='10px'
            templateColumns='repeat(12, 1fr)'
            templateRows='repeat(auto, 1fr)'
            gap='15px 15px'
         >
            <Card
               title='Retailer Spending'
               gridColumn='span 12'
               extraMenu={
                  <Flex mb='1rem'>
                     <Select
                        labelKey='label'
                        options={[
                           { label: 'Only Cigarettes', value: true },
                           {
                              label: 'Everything less Cigrarettes',
                              value: false
                           }
                        ]}
                        onChange={(item) => setSop(item)}
                        value={sop}
                        valueKey='value'
                        placeholder='All'
                     />

                     <Button
                        onClick={() => setFinancial(!financial)}
                        leftIcon={<BiCalendar />}
                        variant='ghost'
                     >
                        View {financial ? 'Calendar Year' : 'Financial Year'}
                     </Button>
                  </Flex>
               }
            >
               <ErrorBoundary>
                  <Suspense fallback={<ChartLoader />}>
                     <RetailerSpending
                        mode={financial ? 'financial' : 'calendar'}
                        green={data?.data?.attributes?.max_spend_target}
                        amber={data?.data?.spend_target}
                        red={data?.data?.attributes?.min_spend_target}
                        sop={sop}
                     />
                  </Suspense>
               </ErrorBoundary>
            </Card>
            <Card title='Retailer Score' gridColumn='span 4'>
               <ErrorBoundary>
                  <Suspense fallback={<CardLoader />}>
                     <RetailerScore overview={data} />
                  </Suspense>
               </ErrorBoundary>
            </Card>

            <Card title='Retailer Overview' gridColumn='span 8'>
               <ErrorBoundary>
                  <Suspense fallback={<BlockLoader />}>
                     <RetailerOverview overview={data} />
                  </Suspense>
               </ErrorBoundary>
            </Card>
         </Grid>
         <Box mt='15px'>
            <Tabs
               mb={10}
               orientation='horizontal'
               variant='tab'
               size='md'
               index={getActiveTab()}
            >
               <TabList>
                  {routes.map((route, i) => (
                     <Tab
                        key={`tab-${i}`}
                        position='relative'
                        onClick={() => history.push(`${url}/${route.path}`)}
                     >
                        {route.name}
                     </Tab>
                  ))}
               </TabList>

               <TabPanels>
                  <Switch>
                     <Card borderTopLeftRadius={0}>
                        {routes.map((route, i) => (
                           <TabPanel key={'retailes-cust-tab-' + i}>
                              <Route
                                 key={`panel-${i}`}
                                 exact={route.exact}
                                 path={`${path}/${route.path}`}
                              >
                                 <ErrorBoundary>
                                    <Suspense fallback={<TableLoader />}>
                                       {route.component}
                                    </Suspense>
                                 </ErrorBoundary>
                              </Route>
                           </TabPanel>
                        ))}
                     </Card>
                  </Switch>
               </TabPanels>
            </Tabs>
         </Box>
      </>
   )
}

export default Dashboard

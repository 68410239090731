import React from 'react'
import { Skeleton, Card } from '@builtbypixel/plasma'

const NotificationLoader = () => {
   return (
      <Card>
         <Card p='25px 5px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' height='44px' />
            <Skeleton width='100%' height='44px' />
         </Card>
         <Card p='5px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' height='44px' />
            <Skeleton width='100%' height='44px' />
         </Card>
         <Card p='5px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' height='44px' />
            <Skeleton width='100%' height='44px' />
         </Card>
         <Card p='5px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' height='44px' />
            <Skeleton width='100%' height='44px' />
         </Card>

      </Card>
   )
}

export default NotificationLoader

import React, { useMemo } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { Tag } from '@builtbypixel/plasma'

const EditForm = () => {
   const formatOptionLabel = (option) => {
      return (
         <Tag variantColor={option.value} variant='solid' mr='10px'>
            {option.name}
         </Tag>
      )
   }
   const fields = useMemo(
      () => [
         {
            group: '',
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  rules: { required: true }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

import React from 'react'
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa'

const google_api = process.env.NEXT_PUBLIC_GOOGLE_MAPS;

const Map = ({ geoloc }) => {

   return (
      <GoogleMapReact
         bootstrapURLKeys={{ key: google_api }}
         defaultCenter={{
            lat: geoloc?.latitude,
            lng: geoloc?.longitude
         }}
         defaultZoom={14}
      >
         <FaMapMarkerAlt
            color='red'
            fontSize='1.5rem'
            lat={geoloc?.latitude}
            lng={geoloc?.longitude}
         />
      </GoogleMapReact>
   )
}


export default Map

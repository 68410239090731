import React, { Suspense } from 'react'
import {
   Text,
   Box,
   Flex,
   Image,
   Modal,
   ModalButton,
   ModalContent,
   Table
} from '@builtbypixel/plasma'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import BlockLoader from '../../../components/common/BlockLoader'
import ErrorBoundary from '../../../components/common/ErrorBoundary'

import ImageModal from '../components/ImageModal'

const columns = [
   {
      Header: 'Retailer',
      accessor: 'retailer_id'
   },
   {
      Header: 'Images',
      accessor: 'images',
      Cell: ({ value }) => (
         <Modal>
            <ModalButton>
               <Flex position='relative' cursor='pointer' align='flex-end'>
                  {value.length > 0 &&
                     value
                        ?.slice(0, 4)
                        ?.map((image) => (
                           <Image src={image.url} w='64px' mx='5px' />
                        ))}
                  {value.length > 4 && <Text>...</Text>}
               </Flex>
            </ModalButton>
            <ModalContent>
               <ImageModal images={value} />
            </ModalContent>
         </Modal>
      )
   },
   {
      Header: 'Products',
      accessor: 'products',
      Cell: ({ value }) =>
         value && value.length > 0 ? (
            value?.map((product, index) => (
               <Text key={index}>{product?.description}</Text>
            ))
         ) : (
            <Text>n/a</Text>
         )
   }
]

const LinesTable = ({ currentLine }) => {
   return (
      <Box>
         <ErrorBoundary>
            <Suspense fallback={<BlockLoader columns={1} />}>
               <Table
                  columns={columns}
                  size='sm'
                  data={{ data: currentLine.lines }}
               />
            </Suspense>
         </ErrorBoundary>
      </Box>
   )
}

export default LinesTable

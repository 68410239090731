const modelName = 'Competitions'
const modelNameSingular = 'Competition'
const endpoint = '/competitions'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: true,
   canAddNew: true,
   canSearch: true,
   endpoint,
   accessor: 'id',
   permissions: ['Admin']
}

export default setup

import Developments from './'


const routes = [{
  path: '/report/rda',
  title: 'Report',
  exact: true,
  auth: true,
  component: Developments,
  permissions: ['Admin']
}
]

export default routes


import Notifications from './'

const routes = [
    {
        path: '/notifications',
        title: 'Notifications',
        exact: true,
        auth: true,
        component: Notifications,
        permissions: ['Admin','RDA']
      }
]

export default routes
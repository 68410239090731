import React, {
   Suspense,
   useState,
   useContext,
   useEffect,
   useCallback
} from 'react'
import { Form, Field } from '../../components/Form'
import CardLoader from '../../components/common/CardLoader'
import {
   ModalBody,
   ModalHeader,
   ModalContent,
   ModalFooter,
   ModalContext,
   Box,
   Button,
   Flex,
   Text,
   Tag,
   Stack,
   useTheme
} from '@builtbypixel/plasma'
import { taskColour } from '../../containers/Planner/Tabs/Calendar/utils/taskColour'

import { usePost, useToast, useHttp } from '../../hooks'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

const debounce = require('lodash.debounce')

const SubmitButton = ({ loading }) => {
   const { submit } = useFormContext()
   return (
      <Button
         variantColor='parfettsGreen'
         color='white'
         mr='10px'
         isLoading={loading}
         loadingText='Saving File'
         onClick={() => submit()}
      >
         Save
      </Button>
   )
}

const EditCreateTask = ({ data, edit, successCallback, visit }) => {
   const [categories, setCategories] = useState([])
   const [selectedCategory, setSelectedCategory] = useState(null)
   const { close } = useContext(ModalContext)
   const history = useHistory()
   const toast = useToast()
   const { post, put, _delete, loading, response } = usePost()

   const Http = useHttp()

   useEffect(() => {
      Http.post(`/task-categories`).then((res) => setCategories(res.data.data))
   }, [Http])

   const deleteTask = () => {
      _delete(`/tasks`, { data: { id: [data.id] } })
   }

   const formatCategoryOptionLabel = (option) => (
      <Tag variantColor={option?.colour} variant='solid' size='sm' mr='10px'>
         {option?.name}
      </Tag>
   )

   const submitForm = (values) => {
      values.category = selectedCategory
      values.visit_id = visit
      if (edit) {
         put(`/tasks/${data.id}`, values)
      } else {
         post(`/tasks/create`, values)
      }
   }

   useEffect(() => {
      if (response) {
         close()
         toast({
            title: 'Task Saved',
            status: 'success'
         })
         successCallback()
         // Refresh the route to refresh the data
         //history.replace(window.location.pathname)
      }
   }, [close, history, response, successCallback, toast])

   return (
      <Form data={data} onSubmit={submitForm}>
         <ModalBody>
            {edit && (
               <Box px='25px'>
                  <Field
                     label='Complete Task'
                     component='switch'
                     size='lg'
                     name='complete'
                  />
               </Box>
            )}
            <Box px='25px'>
               <Field
                  label='Task'
                  component='textarea'
                  name='description'
                  rules={{ required: true }}
               />
            </Box>
            <Stack isInline spacing='10px' p='25px'>
               <Box>
                  <Field
                     label='Due date'
                     component='date'
                     name='due_date'
                     defaultValue={dayjs()}
                     rules={{ required: true }}
                  />
               </Box>
               <Box>
                  <Field
                     label='Category'
                     component='select'
                     name='category'
                     options={categories}
                     labelKey='name'
                     valueKey='id'
                     placeholder='Select Category'
                     formatOptionLabel={formatCategoryOptionLabel}
                     onChange={(e) => setSelectedCategory(e)}
                     defaultValue={data?.category && data?.category}
                     value={
                        selectedCategory ? selectedCategory : data?.category
                     }
                  />
               </Box>
            </Stack>

            {
               visit && (
                  <Field
                     component='text'
                     name='visit'
                     type='hidden'
                     value={visit}
                  />
               )
               //  : (
               //       <Box px='25px'>
               //          <Field
               //             label='Visit'
               //             component='select'
               //             name='visit'
               //             loadOptions={loadVisits}
               //             labelKey='retailer.business_name'
               //             isAsync
               //             valueKey='id'
               //             defaultValue={visit}
               //             placeholder='Search by retailer or RDA name'
               //             formatOptionLabel={formatVisitOptionLabel}
               //          />
               //       </Box>
               //    )
            }
         </ModalBody>
         <ModalFooter>
            {edit && (
               <Button
                  onClick={() => deleteTask()}
                  mr='10px'
                  variantColor='parfettsRed'
                  variant='outline'
                  isLoading={loading}
                  isDisabled={loading}
               >
                  Delete Task
               </Button>
            )}
            <SubmitButton loading={loading} />
         </ModalFooter>
      </Form>
   )
}

const SuspenseForm = (props) => {
   return (
      <ModalContent p='25px'>
         <ModalHeader>{props.edit ? 'Edit' : 'New'} Task</ModalHeader>
         <Suspense
            fallback={
               <Box p='25px'>
                  <CardLoader />
               </Box>
            }
         >
            <EditCreateTask {...props} />
         </Suspense>
      </ModalContent>
   )
}

export default SuspenseForm

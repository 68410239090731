import React, { useState } from 'react'
import { Button, Flex, PopConfirm } from '@builtbypixel/plasma'

/**
 * A button that toggle all the 'on_sale' or 'in_place' field
 * @param {number} totalItems The total number of items
 * @param {boolean} compliance Determine whether it is a compliance audit
 * @param {function} toggleAll function to be called when the button is clicked
 */
const ToggleAllButtons = ({ totalItems, compliance, toggleAll }) => {
   const [allOnSaleChecked, setAllOnSaleChecked] = useState(true)
   const [allInPlaceChecked, setAllInPlaceChecked] = useState(true)

   return totalItems() ? (
      <Flex justify='flex-end'>
         <PopConfirm
            onConfirm={() => {
               setAllOnSaleChecked(!allOnSaleChecked)
               toggleAll('on_sale', !allOnSaleChecked)
            }}
            title={`Are you sure you want to switch 'On Sale' ${
               allOnSaleChecked ? 'OFF' : 'ON'
            } for all ${totalItems()} Items?`}
         >
            <Button m='0 0.25rem' size='sm' variant='outline'>
               {`Switch All 'On Sale' ${allOnSaleChecked ? 'OFF' : 'ON'}`}
            </Button>
         </PopConfirm>

         {compliance && (
            <PopConfirm
               onConfirm={() => {
                  setAllInPlaceChecked(!allInPlaceChecked)
                  toggleAll('in_place', !allInPlaceChecked)
               }}
               title={`Are you sure you want to switch 'In Place' ${
                  allInPlaceChecked ? 'OFF' : 'ON'
               } for all ${totalItems()} Items?`}
            >
               <Button m='0 0.25rem' size='sm' variant='outline'>
                  {`Switch All 'In Place' ${allInPlaceChecked ? 'OFF' : 'ON'}`}
               </Button>
            </PopConfirm>
         )}
      </Flex>
   ) : null
}

export default ToggleAllButtons

import React, { useEffect, useState } from 'react'
import {
   Alert,
   Box,
   Button,
   Flex,
   Input,
   Modal,
   ModalButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   Select,
   Text,
   useToast
} from '@builtbypixel/plasma';

import { FiCheckCircle } from 'react-icons/fi'
import { useHttp, usePost, useRetailers } from '../../hooks'
import { useHistory } from 'react-router-dom'

const MarkAsCustomer = ({ customer }) => {
   const Http = useHttp();
   const { post, error, loading, response } = usePost()
   const history = useHistory()
   const toast = useToast()

   const [retailerId, setRetailerId] = useState('');
   const [retailer, setRetailer] = useState(null)

   const handleMarkAsCustomer = () => {
      post(`/customer-leads/convert`, {
         customerLeadId: customer?.id,
         retailerId: retailerId?.id
      })
   }

   useEffect(() => {
      if (retailerId) {
         Http.post(`/retailers`, { filters: { id: retailerId } }).then((res) =>
         setRetailer(res.data.data[0])
      )}
   }, [retailerId]);

   useEffect(() => {
      if (response) {
         toast({
            title: 'Marked as customer',
            status: 'success',
            position: 'top',
            variant: 'plain'
         })
         history.push('/customer-leads')
      }
   }, [response]);

   return (
      <Modal>
         <ModalButton>
            <Button
               variant='solid'
               variantColor='parfettsRed'
               color='white'
               leftIcon={<FiCheckCircle />}
               rounded='md'
               loading={loading}
               disabled={customer?.status !== 'Active'}
            >
               {customer?.status === 'Active'
                  ? 'Mark as customer'
                  : `Retailer status: ${customer?.status}`}
            </Button>
         </ModalButton>

         <ModalContent>
            <ModalHeader>{`Are you sure you want to mark '${customer?.contact_name}' as a customer?`}</ModalHeader>
            <Box m='1rem'>
               <Select
                  isAsync
                  size='lg'
                  loadOptions={useRetailers}
                  placeholder='Please enter retailer ID...'
                  labelKey='business_name'
                  valueKey='id'
                  onChange={(value) => setRetailerId(value)}
                  value={retailerId}
               />
               {error && (
                  <Alert
                     status='error'
                     title='Error marking as customer'
                     mt='20px'
                  >
                     Action not completed. Please try again.
                  </Alert>
               )}
            </Box>
            {retailer !== null && (
               <Flex
                  p={5}
                  direction="column"
               >
                  <Box>
                     <Text fontSize={14}>Business Name:</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.business_name}</Text>
                  </Box>
                  <Box mt={2}>
                     <Text fontSize={14}>Branch Name:</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.branch?.name}</Text>
                  </Box>
                  <Box mt={2}>
                     <Text fontSize={14}>Customer Name:</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.customer?.name}</Text>
                  </Box>
                  <Box mt={2}>
                     <Text fontSize={14}>Customer Address:</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.customer?.address.addr_1}</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.customer?.address.addr_2}</Text>
                     <Text fontWeight={600} fontSize={16}>{retailer?.customer?.address.post_code}</Text>
                  </Box>
                  <Box>
                     <a>
                        
                     </a>
                  </Box>
               </Flex>
            )}
            <ModalFooter showCloseIcon={false}>
               <Button
                  variantColor='parfettsGreen'
                  color='white'
                  onClick={handleMarkAsCustomer}
                  isLoading={loading}
                  isDisabled={!retailerId || loading}
                  loadingText='Submitting...'
               >
                  Confirm
               </Button>
            </ModalFooter>
         </ModalContent>
      </Modal>
   );
}

export default MarkAsCustomer

import React from 'react'
import { Skeleton, Stack, Flex } from '@builtbypixel/plasma'

const Item = () => (
   <Flex direction='column' width='100%' mr='20px'>
      <Skeleton width='100%' height='20px' mb='5px' />{' '}
      <Skeleton variant='rect' width='100%' height='50px' />
   </Flex>
)

const BlockLoader = ({ columns }) => {

   let items = [];
   for (let index = 0; index < columns; index++) {
      items.push(<Item key={`loader-${index}`} />)

   }
   return (
      <Stack justify='space-between' isInline>
         {items}
      </Stack>
   )
}

export default BlockLoader

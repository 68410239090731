import React, { useEffect, useState } from 'react'
import { Text, Button, Box, Spinner } from '@builtbypixel/plasma'
import { Select } from '@builtbypixel/plasma'

import { useHttp } from '../../../hooks'

const TestWhatsappMessage = ({ data }) => {
   const [segments, setSegments] = useState([])
   const [segmentOptions, setSegmentOptions] = useState([])

   const Http = useHttp()

   const [loading, setLoading] = useState(false)
   const [optionsLoading, setOptionsLoading] = useState(false)
   const [success, setSuccess] = useState(false)

   useEffect(() => {
      const getSegments = () => {
         setOptionsLoading(true)
         Http.post(`/whatsapp-segments`)
            .then((res) => {
               setOptionsLoading(false)
               setSegmentOptions(res.data.data)
            })
            .catch((err) => setOptionsLoading(false))
      }

      getSegments()
   }, [Http])

   const handleSubmit = () => {
      setLoading(true)
      setSuccess(false)

      Http.post('/whatsapp-messages-test', {
         ...data,
         test_segments: segments
      })
         .then((res) => {
            setLoading(false)
            setSuccess(true)
         })
         .catch((err) => {
            setLoading(false)
         })
   }

   // const formatFields = () => {
   //    return Object.keys(formFields).map((field) => {
   //       const value = () => {
   //          switch (field) {
   //             // case 'name':
   //             //    return (
   //             //       <>
   //             //          <Text mb='1' textTransform='capitalize'>
   //             //             Selected {field}
   //             //          </Text>
   //             //          <Text mb='3'>{formFields[field]}</Text>
   //             //       </>
   //             //    )
   //             //    break
   //             case 'template':
   //                return (
   //                   <>
   //                      <Text mb='1' textTransform='capitalize'>
   //                         Selected {field}
   //                      </Text>
   //                      <Text mb='3'>{formFields[field]}</Text>
   //                   </>
   //                )
   //                break
   //             // case 'branches':
   //             //    const branches = formFields?.branches;

   //             //    return <Select
   //             //       mb='3'
   //             //       name={field}
   //             //       isDisabled
   //             //       value={branches}
   //             //       options={branches}
   //             //       labelKey='name'
   //             //       valueKey='id'
   //             //       isMulti
   //             //    />
   //             // break;
   //             // case 'price_schemes':
   //             //    const price_schemes = formFields?.price_schemes;

   //             //    return <Select
   //             //       mb='10'
   //             //       name={field}
   //             //       isDisabled
   //             //       value={price_schemes}
   //             //       options={price_schemes}
   //             //       labelKey='scheme'
   //             //       valueKey='id'
   //             //       isMulti
   //             //    />
   //             // break;
   //             case 'products':
   //                const products = formFields?.products

   //                return (
   //                   <>
   //                      <Text mb='1' textTransform='capitalize'>
   //                         Selected {field}
   //                      </Text>
   //                      <Select
   //                         mb='10'
   //                         name={field}
   //                         isDisabled
   //                         value={products}
   //                         options={products}
   //                         labelKey='description'
   //                         valueKey='id'
   //                         isMulti
   //                      />
   //                   </>
   //                )
   //                break
   //             default:
   //                break
   //          }
   //       }

   //       return (
   //          <>
   //             <Box mb='5'>{value()}</Box>
   //          </>
   //       )
   //    })
   // }

   return (
      <Box p='2rem'>
         {/* {formatFields()} */}
         <Text mb='1' textTransform='capitalize'>
            Test Segments
         </Text>

         {optionsLoading ? (
            <Box py='2em'>
               <Spinner mt='10' />
            </Box>
         ) : (
            <Select
               value={segments}
               options={segmentOptions}
               onChange={(value) => setSegments(value)}
               labelKey='name'
               valueKey='id'
               isMulti
            />
         )}

         {loading ? (
            <Spinner mt='10' />
         ) : (
            <Button mt='10' onClick={() => handleSubmit()}>
               Send
            </Button>
         )}

         {success && (
            <Text color='green' mt='5'>
               Test WhatsApp Message Sent Successfully
            </Text>
         )}
      </Box>
   )
}

export default TestWhatsappMessage

import React, { Suspense } from 'react'
import {
   Box,
   Grid,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel
} from '@builtbypixel/plasma'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import TableLoader from '../../components/common/TableLoader'
import CalendarLoader from './Tabs/Calendar/parts/CalendarLoader'
import Card from '../../components/common/Card'

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import ViewTitle from '../../components/common/ViewTitle'

const Visits = React.lazy(() => import('./Tabs/Visits'))
const Milestones = React.lazy(() => import('./Tabs/Milestones'))
const Tasks = React.lazy(() => import('./Tabs/Tasks'))
const Calendar = React.lazy(() => import('./Tabs/Calendar'))

const Planner = () => {
   let { path, url } = useRouteMatch()

   const routes = [
      {
         name: 'Calendar',
         component: <Calendar />,
         path: 'calendar',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Tasks',
         component: <Tasks />,
         path: 'tasks',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Milestones',
         component: <Milestones />,
         path: 'milestones',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      {
         name: 'Visits',
         component: <Visits />,
         path: 'visits',
         exact: true,
         permissions: ['Admin', 'RDA']
      }
   ]

   const getActiveTab = () => {
      const item = routes.find(
         (r) => r.path === history.location.pathname.split('/').pop()
      )
      return routes.indexOf(item)
   }
   const history = useHistory()

   return (
      <>
         <Box mb='20px'>
            <ViewTitle title='Planner' />
         </Box>
         <Grid w='100%' templateColumns='repeat(2, 1fr)'>
            <Box gridColumn={{ xs: 'span 12' }}>
               <Tabs
                  mb={10}
                  orientation='horizontal'
                  size='lg'
                  index={getActiveTab()}
               >
                  <TabList>
                     {routes.map((route, i) => (
                        <Tab
                           key={`tab-${i}`}
                           position='relative'
                           onClick={() => history.push(`${url}/${route.path}`)}
                        >
                           {route.name}
                        </Tab>
                     ))}
                  </TabList>

                  <TabPanels>
                     <Switch>
                        <Card borderTopLeftRadius={0}>
                           {routes.map((route, i) => (
                              <TabPanel key={`planner-route-${i}`}>
                                 <Route
                                    key={`panel-${i}`}
                                    exact={route.exact}
                                    path={`${path}/${route.path}`}
                                 >
                                    <ErrorBoundary>
                                       <Suspense fallback={route.path === "calendar" ? <CalendarLoader /> : <TableLoader />}>
                                          {route.component}
                                       </Suspense>
                                    </ErrorBoundary>
                                 </Route>
                              </TabPanel>
                           ))}
                        </Card>
                     </Switch>
                  </TabPanels>
               </Tabs>
            </Box>
         </Grid>
      </>
   )
}

export default Planner

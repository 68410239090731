import React, { useEffect, useState } from 'react'
import { Field } from '../../../components/Form'
import { useHttp } from '../../../hooks'
import { useWatch } from 'react-hook-form'
import { Text } from '@builtbypixel/plasma'

const PriceSchemeSelect = () => {
   const Http = useHttp()
   const [priceScheme, setPriceScheme] = useState([])
   const selectedBranches = useWatch({ name: 'branches' }) || []
   const formatPriceSchemeLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.scheme}</span> {' - '}{' '}
         {option.branch?.name}
      </Text>
   )

   useEffect(() => {
      Http.get(`/price-schemes`).then((res) => setPriceScheme(res.data.data))
   }, [])

    useEffect(() => {
        const branches = selectedBranches?.map((item, index) => `branch${index}=${item.id}`)
  
        Http.get(`/price-schemes?${branches?.join('&')}`).then((res) =>
           setPriceScheme(res.data.data)
        )
     }, [selectedBranches?.length])

    return (
        <Field
            name='price_schemes'
            help='Please ignore this option if you wish to select all price schemes'
            component='select'
            placeholder='Price Scheme'
            label='Price Schemes'
            options={priceScheme}
            formatOptionLabel={formatPriceSchemeLabel}
            labelKey='scheme'
            valueKey='id'
            isMulti={true}
        />
    )
}

export default PriceSchemeSelect

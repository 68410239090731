import React, { useMemo, useState, useEffect } from 'react'
import { AutoFields, Field } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import ProductsContainer from '../../components/ProductsContainer'
import { Flex, Card, Box, PseudoBox, Icon, Text } from '@builtbypixel/plasma'
import { useFormContext, useWatch } from 'react-hook-form'
import { useHttp } from '../../hooks'

const EditForm = () => {
   return (
      <EditView setup={setup} hideAddNew autoHeight>
         <CreateAudit />
      </EditView>
   )
}

const CreateAudit = () => {
   const [auditType, setAuditType] = useState(null)
   const { setValue } = useFormContext()
   const [glPeriodName, setGlPeriodName] = useState(null)
   const [gl, setGl] = useState(null)

   const [showUplaoder, setShowUploader] = useState(false)
   const Http = useHttp()
   const products = useWatch({ name: 'products' })
   const formFields = useWatch({})

   useEffect(() => {
      if (formFields?.scheme) {
         setGl(formFields?.scheme?.name)
      }
      if (formFields?.audit_template_type_id) {
         setAuditType(
            formFields?.audit_template_type_id === 1 ? 'Supplier' : 'Go Local'
         )
      }
   }, [formFields])

   useEffect(() => {
      Http.get('/current-go-local-period').then((res) => {
         setGlPeriodName(res.data.data.name)
         setValue('gl_period', res.data.data.name)
      })
   }, [Http])

   const handleSetAuditType = (selected) => {
      // 1== supplier
      setAuditType(selected)
      setValue('audit_template_type_id', selected === 'Go Local' ? 2 : 1)
      setValue('name', selected === 'Supplier' ? 'Supplier' : 'Go Local')
      if (selected === 'Supplier') {
         setShowUploader(true)
      }
   }
   const handleSetGl = (gl) => {
      // 1== supplier
      setGl(gl)
      setValue('scheme', { name: gl })
      setShowUploader(true)
   }
   return (
      // <Field
      //    name='type_id'
      //    component='select'
      //    variant='filled'
      //    size='lg'
      //    placeholder='Select Audit type'
      //    options={[
      //       {
      //          id: 1,
      //          name: 'Stock Check Audit'
      //       },
      //       {
      //          id: 2,
      //          name: 'Compliance Audit'
      //       }
      //    ]}
      //    labelKey='name'
      //    valueKey='name'
      //    autoFocus
      //    mb={3}
      //    auto={false}
      //    rules={{ required: true }}
      // />

      <Flex align='flex-start'>
         <Card w='600px' ml='2rem'>
            <Box p='0 2rem 2rem'>
               <Flex w='100%' direction='row' my='25px'>
                  <NiceButton
                     onClick={() => handleSetAuditType('Go Local')}
                     text='Go Local'
                     selected={auditType}
                  />
                  <NiceButton
                     onClick={() => handleSetAuditType('Supplier')}
                     text='Supplier'
                     selected={auditType}
                  />
               </Flex>
            </Box>

            {auditType === 'Go Local' && (
               <Flex mb='2em'>
                  <NiceButton
                     onClick={() => handleSetGl('G1')}
                     text='G1'
                     selected={gl}
                  />
                  <NiceButton
                     onClick={() => handleSetGl('G2')}
                     text='G2'
                     selected={gl}
                  />
                  <NiceButton
                     onClick={() => handleSetGl('G3')}
                     text='G3'
                     selected={gl}
                  />
                  <NiceButton
                     onClick={() => handleSetGl('TL')}
                     text='TL'
                     selected={gl}
                  />
               </Flex>
            )}

            {auditType === 'Supplier' && (
               <>
                  <Field
                     name='name'
                     component='text'
                     size='lg'
                     label='Supplier Name'
                     placeholder='Enter supplier name'
                     autoFocus
                     mb={3}
                     auto={false}
                  />
               </>
            )}

            {auditType && (
               <>
                  <Field
                     name='gl_period'
                     component='text'
                     variant='filled'
                     size='lg'
                     label='Go Local Period'
                     placeholder='Select Audit type'
                     labelKey='name'
                     valueKey='name'
                     autoFocus
                     mb={3}
                     auto={false}
                  />
               </>
            )}
            {showUplaoder && (
               <>
                  <Field
                     name='template'
                     component='file'
                     variant='filled'
                     size='lg'
                     label='Upload products via CSV'
                     placeholder='Select CSV'
                     autoFocus
                     mb={3}
                     auto={false}
                  />
               </>
            )}

            {products?.length > 0 && <ProductsContainer />}
         </Card>
      </Flex>
   )
}

const NiceButton = ({ onClick, text, selected }) => (
   <PseudoBox
      rounded='lg'
      border='2px'
      borderColor='darkBorder'
      display='flex'
      alignItems='center'
      justifyCenter='center'
      flexDirection='column'
      p='20px'
      w='100%'
      mx='20px'
      _hover={{
         borderColor: 'parfettsBlue'
      }}
      cursor='pointer'
      onClick={onClick}
      bg={text === selected ? 'parfettsBlue' : 'parfettsGreen'}
      color={'white'}
   >
      <Text fontWeight='semibold' fontSize='1.2rem'>
         {text}
      </Text>
   </PseudoBox>
)
export default EditForm

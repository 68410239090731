import React from 'react'
import { Text, PseudoBox } from '@builtbypixel/plasma'

/**
 * A button that shows the selected option and allow the user to navigate to the item that they selected.
 * @param {object} item The item that is selected and to be displayed
 * @param {string} color The color the component will change to on hover
 * @param {function} onClick The function to be called when the component is clicked on
 * @param {string} text The text to be rendered
 */
const NavComponent = ({ item, color, onClick, text }) =>
   item ? (
      <>
         {'/'}
         <PseudoBox
            _hover={{
               color,
               textDecoration: 'underline',
               cursor: 'pointer'
            }}
            transition='color 0.15s'
            onClick={onClick}
         >
            <Text ml='0.5rem' mr='0.5rem' fontSize='1.3rem'>
               {text}
            </Text>
         </PseudoBox>
      </>
   ) : null

export default NavComponent

import React from 'react';
import { NativeSelect } from '@builtbypixel/plasma';

const NativeSelectField = (props) => {
    const {
        setActive,
        onChange,
        onBlur,
        value,
        labelKey,
        valueKey,
        options,
        ...rest
    } = props;
    return (
        <NativeSelect
            onChange={(e) => onChange(e.target.value)}
            isFullWidth
            onBlur={() => {
                onBlur();
                setActive(false);
            }}
            onFocus={() => setActive(true)}
            value={value}
            {...rest}
        >
            {options &&
                options.map((op, i) => (
                    <option value={op[valueKey]} key={`option-${i}`}>
                        {op[labelKey]}
                    </option>
                ))}
        </NativeSelect>
    );
};

export default NativeSelectField;

import { useEffect, useState } from 'react'

/**
 * Actively watch the viewport width and height
 * @returns viewport width
 */
const useViewport = () => {
   const [width, setWidth] = useState(window.innerWidth)
   const [height, setHeight] = useState(window.innerHeight)

   useEffect(() => {
      const handleWindowResize = () => {
         setWidth(window.innerWidth)
         setHeight(window.innerHeight)
      }
      window.addEventListener('resize', handleWindowResize)
      return () => window.removeEventListener('resize', handleWindowResize)
   }, [])

   // Return the width so we can use it in our components
   return { width, height }
}

export default useViewport

import { Box, Flex, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import Title from './Title'
import { FaPlus } from 'react-icons/fa'
import { Field } from '../../../../components/Form'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import VariablesPanel from './VariablesPanel'

const TemplateBody = () => {
   const { setValue, register, watch, control, getValues } = useFormContext()
   const [showVariablesPanel, setShowVariablesPanel] = useState(false)
   const repeaterFuncs = useFieldArray({
      name: 'content[1].variables'
   })
   const content = useWatch({ control, name: 'content[1]' })
   // console.log('content');
   const variablesBody = content?.variables
   const { fields, append, prepend, remove, swap, move, insert, replace } =
      repeaterFuncs

   useEffect(() => {
      if (variablesBody?.length === 0) {
         setShowVariablesPanel(false)
      }
   }, [variablesBody])

   return (
      <Flex gap={10} w='100%' direction='column'>
         <Title text='Body Details' />
         <Flex w='100%' align='center'>
            <Box width='100%' position='relative'>
               <Field
                  component='markdown'
                  name='content[1].text'
                  placeholder='Enter Body Text (Max 1024 character)'
                  rules={{ required: true }}
                  whatsapp={true}
                  maxLength={1024}
                  help={`${content?.text ? content?.text?.length : 0} / 1024`}
               />
               <Flex
                  maxWidth='150px'
                  align='center'
                  bg='#075E54'
                  px='1em'
                  h='25px'
                  justify='space-between'
                  top='-30px'
                  right='0'
                  position='absolute'
                  onClick={() => {
                     setShowVariablesPanel(true)
                     append('')
                     // append to text area also
                     setValue(
                        'content[1].text',
                        `${getValues('content[1].text')} {${fields.length + 1}}`
                     )
                  }}
               >
                  <FaPlus color='#25D366' />
                  <Text color='white' fontWeight='bold' ml='1em'>
                     Add Variable
                  </Text>
               </Flex>

               {showVariablesPanel && (
                  <VariablesPanel source='body' repeaterFuncs={repeaterFuncs} />
               )}
            </Box>
         </Flex>
      </Flex>
   )
}

export default TemplateBody

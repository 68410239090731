import React from 'react'
import { Modal, ModalButton, IconButton } from '@builtbypixel/plasma'
import EditNoteForm from './NoteForm'

import { FiEdit } from 'react-icons/fi'

const AddNewNote = ({ content, noteId, isEdit }) => {
   return (
      <Modal>
         <ModalButton>
            <IconButton variant='ghost' variantColor='primary' rounded='md'>
               <FiEdit fontSize={18} />
            </IconButton>
         </ModalButton>
         <EditNoteForm noteId={noteId} content={content} isEdit={isEdit} />
      </Modal>
   )
}

export default AddNewNote

import React, { Suspense, useEffect, useState, memo } from 'react'
import {
   Box,
   PseudoBox,
   Stack,
   Button,
   useToast,
   IconButton,
   Modal,
   ModalButton
} from '@builtbypixel/plasma'
import { useParams } from 'react-router-dom'
import { Field } from '../../components/Form'
import { BiTrashAlt } from 'react-icons/bi'
import { globalAtom } from '../../state/global'
import { useRecoilValue } from 'recoil'
import { useSWRPost } from '../../hooks'
import { useWatch, useFieldArray, useFormContext } from 'react-hook-form'
import useSWR, { mutate } from 'swr'
import SpinnerLoader from '../../components/common/SpinnerLoader'

import Item from '../../components/TaskItem'
import { MdAdd } from 'react-icons/md'

import AddEditTask from '../../components/AddEditTask'

const Tasks = memo((props) => {
   const { user } = useRecoilValue(globalAtom)
   const { control } = useFormContext()
   const { type, id } = useParams()
   const toast = useToast()

   const { data, response, errors } = useSWR('/tasks', (url) => useSWRPost(url, { relations: { "visit": id } }), { suspense: true })


   const { fields, append, remove } = useFieldArray({
      name: 'tasks',
      control
   })

   const noteObj = {
      content: '',
      visit: parseInt(id),
      type: 'Visit',
      user
   }

   const [newItem, setNewItem] = useState(noteObj)
   const [stateItems, setStateItems] = useState([])

   const retailer = useWatch({
      name: 'retailer'
   })

   useEffect(() => {
      if (data) {
         setStateItems(data.data)
      }
   }, [data])

   const refreshTasks = () => {
      mutate(`/tasks`)
   }
   /* eslint-disable */
   useEffect(() => {
      if (response) {
         refreshTasks()
         setNewItem({ ...newItem, content: '' })
      }
   }, [response])
   /* eslint-enable */

   useEffect(() => {
      if (errors) {
         toast({
            title: 'Action not completed',
            message: 'An error occurred',
            status: 'error',
            position: 'top',
            variant: 'plain'
         })
      }
   }, [errors, toast])

   return (
      <Box pt='25px' zIndex={0} position='relative'>
         {type === 'edit' ? (
            <>
               <Stack spacing='10px'>
                  {stateItems.map((item, i) => (
                     <Item
                        item={item}
                        key={`tasks-${i}`}
                        index={i}
                        successCallback={refreshTasks}
                        retailer={retailer}
                     />
                  ))}
               </Stack>
               <Box mt='25px'>
                  <Modal>
                     <ModalButton>
                        <Button
                           variant='solid'
                           variantColor='parfettsGreen'
                           color='white'
                           leftIcon={<MdAdd />}
                           rounded='md'
                        >
                           Add New Task
                        </Button>
                     </ModalButton>
                     <AddEditTask successCallback={refreshTasks} visit={id} />
                  </Modal>
               </Box>
               <Box></Box>
            </>
         ) : (
               <>
                  {fields &&
                     fields.map((field, i) => (
                        <PseudoBox
                           key={field.id}
                           rounded='lg'
                           p='10px'
                           mb='10px'
                           bg='grayBg'
                           position='relative'
                           zIndex={1}
                        >
                           <Field
                              component='text'
                              name={`tasks[${i}].description`}
                              defaultValue={field.description}
                              placeholder='Type your task'
                              w='100%'
                           />
                           <Stack isInline align='flex-start' spacing='10px'>
                              <Box>
                                 <Field
                                    component='date'
                                    name={`tasks[${i}].due_date`}
                                    placeholder='Due Date'
                                 />
                              </Box>
                              <Box>
                                 <Field
                                    component='select'
                                    name={`tasks[${i}].category`}
                                    placeholder='Category'
                                    size='sm'
                                 />
                              </Box>
                              <Box>
                                 <IconButton size='md' onClick={() => remove(i)}>
                                    <BiTrashAlt />
                                 </IconButton>
                              </Box>
                           </Stack>
                        </PseudoBox>
                     ))}
                  <Button onClick={() => append()}>Add New Task</Button>
               </>
            )}
      </Box>
   )
})

const SuspenseTasks = () => (
   <Suspense fallback={<SpinnerLoader />}>
      <Tasks />
   </Suspense>
)

export default SuspenseTasks

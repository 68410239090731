import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts'
import { useTheme } from '@builtbypixel/plasma'
import renderToolTip from '../../../../components/common/ChartElements/RenderCurrencyToolTip'


const Chart = ({ data }) => {
    const theme = useTheme()

    return (
        <ResponsiveContainer width='100%' height={300}>
            <BarChart
                width={500}
                height={300}
                data={data.data}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={renderToolTip} />
                <Legend />
                <Bar dataKey="0.week0" name="Week 0" fill={theme.colors.parfettsGreen} />
                <Bar dataKey="0.week1" name="Week 1" fill={theme.colors.parfettsOrange} />
                <Bar dataKey="0.week2" name="Week 2" fill={theme.colors.parfettsBlue} />
                <Bar dataKey="0.week3" name="Week 3" fill={theme.colors.parfettsRed} />
            </BarChart>

        </ResponsiveContainer>
    )
}

export default Chart

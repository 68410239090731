import React from 'react'
import { Skeleton, Card } from '@builtbypixel/plasma'

const NotificationLoader = () => {
   return (
      <Card>
         <Card p='50px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
         </Card>
         <Card p='50px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
         </Card>
         <Card p='50px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
         </Card>
         <Card p='50px' bg='global.lightBg'>
            <Skeleton width='20%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
         </Card>

      </Card>
   )
}

export default NotificationLoader

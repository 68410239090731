import React, { Suspense, useContext, useEffect, memo } from 'react'
import { Form, Field } from '../../../components/Form'
import CardLoader from '../../../components/common/CardLoader'
import {
   ModalBody,
   ModalHeader,
   ModalContent,
   ModalFooter,
   ModalContext,
   Box,
   Button,
   Text,
   Flex
} from '@builtbypixel/plasma'
import { useRetailers, usePost, useToast } from '../../../hooks'
import { useFormContext } from 'react-hook-form'
import { triggerRefresh } from '../utils/mediaState'

import { useRecoilState } from 'recoil'

const SubmitButton = ({ loading }) => {
   const { submit } = useFormContext()
   return (
      <Button
         variantColor='parfettsGreen'
         color='white'
         mr='10px'
         isLoading={loading}
         loadingText='Saving File'
         onClick={() => submit()}
      >
         Save
      </Button>
   )
}

const EditCreateFile = memo(({ edit, id, folder, retailer }) => {
   const { close } = useContext(ModalContext)
   const toast = useToast()
   const { post, put, loading, response } = usePost()
   const [refreshCounter, setRefreshCounter] = useRecoilState(triggerRefresh)

   const formatRetailerOptionLabel = (option) => (
      <Flex align='center'>
         <Text>{option.name} - </Text>
         <Text fontWeight='semibold'>#{option.id}</Text>
      </Flex>
   )

   const submitForm = (values) => {
      values.file = values.file[0]
      values.file.title = values.title

      if (edit) {
         put(`/media-library/file/${id}`, values)
      } else {
         post(`/media-library/files/create`, values)
      }
   }

   useEffect(() => {
      if (response) {
         close()
         setRefreshCounter(refreshCounter + 1)
         toast({
            title: 'File saved successfully',
            status: 'success'
         })
      }
   }, [close, folder, response, toast])

   return (
      <Form onSubmit={submitForm}>
         <ModalBody>
            {retailer && (
               <Box px='25px'>
                  <Text>Assigning file to Retailer #{retailer}</Text>
               </Box>
            )}
            <Box p='25px'>
               <Field
                  label='File Title'
                  component='text'
                  name='title'
                  rules={{ required: true }}
               />
               <Field
                  label='File'
                  max={1}
                  component='file'
                  name='file'
                  rules={{ required: true }}
               />
            </Box>
            <Field
               label={null}
               component='text'
               name='folder'
               defaultValue={folder}
               value={folder}
               type='hidden'
            />
            {!retailer && (
               <Box p='25px' mb='80px'>
                  <Text fontSize='1.1rem' fontWeight='semibold' mb='10px'>
                     Assign file to Retailer
                  </Text>
                  <Field
                     label='Retailer'
                     component='select'
                     name='retailer'
                     loadOptions={useRetailers}
                     labelKey='business_name'
                     isAsync
                     valueKey='id'
                     defaultValue={retailer}
                     placeholder='Search retailer name of ID'
                     formatOptionLabel={formatRetailerOptionLabel}
                  />
               </Box>
            )}
         </ModalBody>
         <ModalFooter>
            <SubmitButton loading={loading} />
         </ModalFooter>
      </Form>
   )
})

const SuspenseForm = (props) => {
   return (
      <ModalContent p='25px'>
         <ModalHeader>{props.edit ? 'Edit' : 'New'} File</ModalHeader>
         <Suspense
            fallback={
               <Box p='25px'>
                  <CardLoader />
               </Box>
            }
         >
            <EditCreateFile {...props} />
         </Suspense>
      </ModalContent>
   )
}

export default SuspenseForm

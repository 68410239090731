import React from 'react'
import { Switch } from '@builtbypixel/plasma'

const SwitchField = (props) => {
   const { setActive, onChange, onBlur, value, ...rest } = props
   return (
      <Switch
         onChange={(e) => onChange(e.target.checked)}
         onBlur={() => {
            onBlur && onBlur()
            setActive && setActive(false)
         }}
         onFocus={() => setActive && setActive(true)}
         value={value}
         isChecked={value}
         required={false}
         isRequired={false}
         {...rest}
      />
   )
}

export default SwitchField

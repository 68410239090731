import React from 'react'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import { IconButton, Text, Button, Flex } from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'
import ViewTitle from '../../components/common/ViewTitle'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
      {
         Header: 'Name',
         Cell: ({ row }) => (
            <Text>
               {row.original.first_name} {row.original.last_name}
            </Text>
         )
      },
      {
         Header: 'Email',
         accessor: 'email'
      },
      {
         Header: 'Account Type',
         Cell: ({ row }) => <Text>{row.original.type.name}</Text>
      },
      {
         Header: 'Join Date',
         Cell: ({ row }) => (
            <Text>{dayjs(row.original.join_date).format('DD/MM/YYYY')}</Text>
         )
      },
      {
         Header: 'Last Active',
         Cell: ({ row }) => (
            <Text>{dayjs(row.original.last_active).format('DD/MM/YYYY')}</Text>
         )
      },
      {
         Header: 'Edit',
         Cell: ({ row }) => (
            <LinkButton
               to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </LinkButton>
         )
      }
   ]

   return (
      <>
         <Flex mb='20px' align='center'>
            <ViewTitle title='Administrators' />
            <LinkButton to='/administrators/create'>
               <Button variantColor='success' color='white' ml='auto'>
                  Add New User
               </Button>
            </LinkButton>
         </Flex>
         <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
      </>
   )
}

export default List

import { createCrudRoutes } from '../../hooks'
import List from './List'
import Entry from './'

import setup from './setup'

const crud = createCrudRoutes({
   setup,
   name: setup.title,
   edit: Entry,
   list: List,
   view: null,
   permissions: ['Admin', 'RDA']
})

const otherRoutes = [
   {
      path: '/visits/:type(create)/:retailer',
      title: 'Create Visit',
      exact: false,
      auth: true,
      component: Entry,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/visits/:type(create)/:data_type/:retailer',
      title: 'Create Visit',
      exact: true,
      auth: true,
      component: Entry,
      permissions: ['Admin', 'RDA']
   }
]

const routes = [...crud, ...otherRoutes]

export default routes

import { useHttp } from '.'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useRetailersSearch = debounce((inputValue, callback) => {
   Http.post(`/retailers`, { search: inputValue }).then((res) =>
      callback(res.data.data)
   )
}, 500)

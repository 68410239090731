import React, { useEffect, useState, memo } from 'react'
import { useParams } from 'react-router-dom'
import {
   Box,
   Text,
   Button,
   Flex,
   Modal,
   ModalButton,
   ModalContent,
   ModalHeader,
   ModalBody,
   Grid,
   useToast
} from '@builtbypixel/plasma'
import useSWR from 'swr'
import { FiShoppingCart } from 'react-icons/fi'
import SubmitAudit from './SubmitAudit'
import { useGet, useHttp, usePost } from '../../../hooks'
import TrolleyContext from '../context'

import TableLoader from '../../../components/common/TableLoader'
import Product from './Product'
import Summary from './Summary'
import Trolley from './Trolley'
import ToggleAllButtons from './ToggleAllButtons'
import FilterByCategory from './FilterByCategory'

/**
 * The page for compliance audit or stock check audit.
 *
 * @param {Object} history The history interface from react for routing
 * @param {Boolean} compliance Determining the usage of the page. (true = compliance audit, false = stock check)
 */
const Comp = ({ history, compliance }) => {
   const { retailer, type, id, templateId, branchId } = useParams()
   const toast = useToast()
   const Http = useHttp()

   const [products, setProducts] = useState()
   const [filteredProducts, setFilteredProducts] = useState([])
   const [readyFlag, setReadyFlag] = useState(false)
   const [selectedCategory, setSelectedCategory] = useState('')
   const [selectAll, setSelectedAll] = useState(null)

   const [onSaleCount, setOnSaleCount] = useState(0)
   const [inPlaceCount, setInPlaceCount] = useState(0)
   const [complianceScore, setComplianceScore] = useState(0)
   const [notes, setNotes] = useState('')

   useEffect(() => {
      if (products?.length > 0) {
         reCalculateScore()
      }
   }, [products])

   const reCalculateScore = () => {
      setOnSaleCount(count('on_sale'))
      setInPlaceCount(count('in_place'))
      setComplianceScore(complianceRating)
   }
   const { data: selectedRetailer } = useSWR(
      `/retailers/${branchId}/${retailer}`,
      useGet
   )

   // const { data: retailerTrolley } = useSWR(`/trolley/${retailer}`, useGet)
   // const { data: purchaseRating } = useSWR(
   //    `/purchase-rating/${retailer}`,
   //    useGet
   // )

   /**
    *  Handles the data to be used in one of the situations below
    *  1. type = 'edit'
    *  2. type = 'create'
    *  3. using audit template
    */
   const getAudit = async () => {
      if (type === 'create' && !templateId) {
         // // create (pre-populates all 'on sale' and 'in place' fields with true)
         // const res = await Http.post(`/audits/products/${retailer}`)
         // const alteredData = res?.data?.map((product) => {
         //    for (let pack of product) {
         //       pack.on_sale = true
         //       pack.in_place = true
         //    }
         //    return product
         // })
         // setProducts(alteredData)
         // setReadyFlag(!readyFlag)
      } else if (type === 'edit') {
         // // edit
         const res = await Http.get(`/audits/${id}`)
         setProducts(res?.data?.data?.products)
         setNotes(res?.data?.data?.notes)
         setReadyFlag(!readyFlag)
      } else if (templateId) {
         // audit template
         const res = await Http.get(
            `audit-template-products/${retailer}/${branchId}/${templateId}`
         )
         setProducts(res?.data)
         setReadyFlag(!readyFlag)
      }
   }

   /* eslint-disable */
   useEffect(() => {
      getAudit()
   }, [])
   /* eslint-enable */

   /**
    * Filter the products to show only those with the specified category.
    * @param {string} category The category of the product name
    */
   const filterProducts = (category) => {
      const filtered = products?.filter(
         (product) =>
            product?.product?.category_product?.[0]?.category_id === category
      )

      if (filtered?.length) {
         setFilteredProducts(
            // Sort the product by 'name' in acending order
            filtered.sort((a, b) =>
               a.description < b.description
                  ? -1
                  : a.description > b.description
                  ? 1
                  : 0
            )
         )
         setSelectedCategory(category)
         setSelectedAll(false)
      }
   }

   const selectAllProducts = () => {
      setFilteredProducts(
         products.sort((a, b) =>
            a.description < b.description
               ? -1
               : a.description > b.description
               ? 1
               : 0
         )
      )
      setSelectedAll(true)
   }

   /**
    * Handles the change of state for 'on sale' and 'in place' switch
    * @param {string} status Specified the field to be changed. ('on_sale' or 'in_place')
    * @param {number} productId The product id
    * @param {number} packId The pack id
    * @param {boolean} value The value to change to.
    */
   const onChange = (status, productId, productProductId, value) => {
      const targetProductId = products?.findIndex(
         (product) => product.product_id === productProductId
      )
      // const targetPackId = products[targetProductId].findIndex(
      //    (pack) => pack.id === packId
      // )
      status === 'on_sale'
         ? (products[targetProductId].on_sale = value)
         : (products[targetProductId].in_place = value)

      setProducts([...products])
      reCalculateScore()
   }

   /**
    * Calculates the number of 'on sale' or 'in place' packs
    * @param {string} status Specified the field to be counted. ('on_sale' or 'in_place')
    * @returns the number of packs that is marked as true
    */
   const count = (status) => {
      const onSaleProducts = products?.filter((product) =>
         status === 'on_sale' ? product?.on_sale : product?.in_place
      ).length
      return onSaleProducts
   }

   /**
    * Calculates the total number of packs
    * @returns The total number of packs
    */
   const totalItems = () => {
      return products?.length
   }

   /**
    * Calculates the compliance rating.
    * 'on sale' and 'in place' each participate 50% weightage towards 'compliance rating'.
    * @returns The compliance rating.
    */
   const complianceRating = () =>
      compliance
         ? ((count('on_sale') + count('in_place')) / 2 / totalItems()) * 100
         : (count('on_sale') / totalItems()) * 100

   // Toggle all 'on_sale' or 'in_place' packs
   const toggleAll = (status, value) => {
      const toggled = products?.map((product) => {
         if (status === 'on_sale') {
            product.on_sale = value
         } else {
            product.in_place = value
         }
         return product
      })
      setProducts(toggled)
   }

   const { post, put, loading, response } = usePost()
   /**
    * Handles the submit audit process
    */
   const handleSubmit = (newNotes) => {
      const payload = {
         retailer: { id: retailer },
         type: { id: compliance ? 2 : 1 },
         compliance_rating: complianceRating(),
         audit_template: { id: templateId },
         spend_target: selectedRetailer?.data?.spend_target,
         purchase_target: selectedRetailer?.data?.purchase_target,
         on_sale: count('on_sale'),
         in_place: count('in_place'),
         products,
         notes: newNotes
      }
      if (type === 'create') {
         post(`/audits/create`, payload)
      } else {
         put(`/audits/${id}`, payload)
      }
   }

   // Render a toast after the submit is completed.
   useEffect(() => {
      if (response) {
         toast({
            title: 'Audit Submitted',
            status: 'success',
            position: 'top',
            variant: 'plain',
            isClosable: false
         })
         history.replace('/audits/list/go-local')
      }
   }, [history, response, toast])

   return (
      <>
         <Box bg='global.bg'>
            <Flex justify='space-between' align='center'>
               <Text fontWeight='semibold' fontSize='1.2rem'>
                  {selectedRetailer?.data?.business_name} - #
                  {selectedRetailer?.data?.id}
               </Text>
               <Flex>
                  <Modal size='xl'>
                     <ModalButton>
                        <Button leftIcon={<FiShoppingCart />}>Trolley</Button>
                     </ModalButton>
                     <ModalContent>
                        <ModalHeader>Trolley</ModalHeader>
                        <ModalBody p={3}>
                           <Trolley />
                        </ModalBody>
                     </ModalContent>
                  </Modal>
                  <SubmitAudit
                     handleSubmit={handleSubmit}
                     notes={notes}
                     isLoading={loading}
                     isDisabled={loading}
                  />
               </Flex>
            </Flex>
            <Grid
               m='1.5rem 1rem'
               templateColumns={
                  compliance ? 'repeat(5, 1fr)' : 'repeat(3, 1fr)'
               }
               gap='15px'
            >
               <Summary title='Total Items' value={totalItems()} bg='#EFA346' />
               <Summary title='On Sale' value={onSaleCount} bg='#85C96F' />
               {compliance && (
                  <Summary
                     title='POS In Place'
                     value={inPlaceCount}
                     bg='#13C6D0'
                  />
               )}
               <Summary
                  title='Compliance Rating'
                  value={products && `${complianceScore.toFixed(2)}%`}
                  bg='#8883F8'
               />
               <Summary
                  title='Purchase Compliance'
                  value={`${selectedRetailer?.data?.purchase_target}%`}
                  bg='#145DA0'
               />
            </Grid>
         </Box>

         <ToggleAllButtons
            totalItems={totalItems}
            compliance={compliance}
            toggleAll={toggleAll}
         />
         <FilterByCategory
            readyFlag={readyFlag}
            products={products}
            filterProducts={filterProducts}
            selectAll={selectAll}
            selectAllProducts={selectAllProducts}
            selectedCategory={selectedCategory}
         />

         <Box overflow='scroll' position='relative'>
            <TrolleyContext.Provider value={{ retailer }}>
               {!products ? (
                  <TableLoader />
               ) : (
                  filteredProducts?.map((product, index) => (
                     <Product
                        isEdit={type === 'edit'}
                        product={product}
                        productDetails={
                           type === 'edit' ? product.audit_product : product
                        }
                        onChange={onChange}
                        branchid={branchId}
                        scheme={selectedRetailer?.data?.groups?.[0]?.scheme}
                        compliance={compliance}
                        key={product?.id?.toString() + index}
                        selectedCategory={selectedCategory}
                     />
                  ))
               )}
            </TrolleyContext.Provider>
         </Box>
      </>
   )
}

export default memo(Comp)

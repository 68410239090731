import React, { useState, useContext, memo, useEffect } from 'react'
import {
   ModalContent,
   ModalFooter,
   Button,
   ModalHeader,
   TextArea,
   Box,
   ModalContext,
   useToast,
   Alert
} from '@builtbypixel/plasma'
import { useParams } from 'react-router-dom'
import { usePost } from '../../../hooks'
import { mutate } from 'swr'

const NoteForm = memo(({ noteId, content, type, isEdit = false }) => {
   const { id } = useParams()
   const [newNotification, setNewNotification] = useState(content)
   const [hasError, setError] = useState(false)
   const { toggle } = useContext(ModalContext)
   const toast = useToast()

   const { post, put, loading, error, response } = usePost()

   /* eslint-disable */
   useEffect(() => {
      if (response) {
         toggle()
         mutate(`/customer-leads/notes/${id}`)
         toast({
            title: 'Note saved',
            status: 'success',
            position: 'top',
            variant: 'plain',
            isClosable: false
         })
         setError(false)
      }
   }, [response])

   useEffect(() => {
      if (error) {
         setError(true)
      }
   }, [error])

   return (
      <ModalContent>
         <ModalHeader>{isEdit ? 'Edit' : 'Add'} Note</ModalHeader>
         <Box ml='20px' mr='20px'>
            <TextArea
               defaultValue={content}
               value={newNotification}
               onChange={(value) => {
                  setNewNotification(value.target.value)
               }}
               placeholder='Enter note text here...'
               rows={4}
               size='md'
               variant='outline'
               autoFocus
            />
            {hasError && (
               <Alert status='error' title='Error saving note' mt='20px'>
                  Your note was not saved. Please try again.
               </Alert>
            )}
         </Box>
         <ModalFooter showCloseIcon={false}>
            <Button
               variantColor='parfettsGreen'
               color='white'
               onClick={() =>
                  isEdit
                     ? put(`/customer-leads/notes/${noteId}`, {
                          description: newNotification,
                          note: noteId
                       })
                     : post(`/customer-leads/notes/${id}`, {
                          notes: {
                             description: newNotification,
                             type: {
                                id: 2,
                                name: 'Standard'
                             }
                          }
                       })
               }
               isLoading={loading}
               isDisabled={loading}
               loadingText='Submitting...'
            >
               {isEdit ? 'Update' : 'Add'} Note
            </Button>
         </ModalFooter>
      </ModalContent>
   )
})

export default NoteForm


import React, { useRef } from 'react'
import {
    Box,
} from '@builtbypixel/plasma'


const ParfettsAccordionPanel = (props) => {
    const { open, children } = props
    const content = useRef(null);


    return (
        <Box
            maxHeight={open ? content?.current?.scrollHeight : "0px"}
            overflowY={open ? 'visible' : 'hidden'}
            display={open ? 'inline' : 'block'}
            // transition='max-height 0.15s ease-out'
            ref={content}>
            {children}

        </Box>
    )
}
export default ParfettsAccordionPanel
import React from 'react'
import { Flex, Spinner } from '@builtbypixel/plasma'

const SpinnerLoader = ({ size }) => {
   return (
      <Flex w='inherit' h='inherit' justify='center' align='center'>
         <Spinner size={size} />
      </Flex>
   )
}

export default SpinnerLoader

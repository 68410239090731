import React from 'react'
import { Skeleton, Stack } from '@builtbypixel/plasma'

const TableLoader = () => {
   return (
      <>
         <Stack spacing='5px' isInline>
            <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
            <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
            <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
            <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
            <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
         </Stack>

         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
         <Skeleton width='100%' />
      </>
   )
}

export default TableLoader

import React, { useEffect, useState } from 'react'
import { Field } from '../../components/Form'
import {
   Text,
   Box,
   Button,
   Modal,
   ModalHeader,
   ModalButton,
   ModalContent,
   Input,
   Flex
} from '@builtbypixel/plasma'
import { useFormContext, useWatch } from 'react-hook-form'
import { FiCheckCircle } from 'react-icons/fi'

import { useHttp, useToast } from '../../hooks'
import { useParams } from 'react-router-dom'

const debounce = require('lodash.debounce')

const Form = () => {
   const [promotions, setPromotions] = useState(null)
   const [suppliers, setSuppliers] = useState([])
   const [hasDoneBrandSearch, setHasDoneBrandSearch] = useState(false)
   const [brandName, setBrandName] = useState(null)
   const [changePromotionPeriod, setPromotionPeriod] = useState(null);
   const toast = useToast()

   const handleCreateBrand = () => {
      Http.post('/brands/create', {
         name: brandName
      })
         .then((res) => {
            toast({
               title: 'Brand Created',
               status: 'success'
            })
            setHasDoneBrandSearch(false)
         })
         .catch((err) => {
            toast({
               title: 'There was an error creating your brand. Please try again',
               status: 'error'
            })
         })
   }
   // const [brands, getBrands] = useState([])

   const { type } = useParams()
   const price_promotion = useWatch({ name: 'price_promotion' })
   const gl_period = useWatch({ name: 'gl_period' }) 
   const { setValue, register } = useFormContext()

   const Http = useHttp()

   const getBrands = debounce((inputValue, callback) => {
      Http.get(`/product-suppliers?search=${inputValue}`).then((res) => {
         setHasDoneBrandSearch(true)
         return callback(res.data.data)
      })
   }, 500)

   const loadProducts = debounce((inputValue, callback) => {
      Http.post(`/products`, {
         filters: { id: inputValue }
      }).then((res) => callback(res.data.data))
   }, 500)

   useEffect(() => {
      Http.get(`/price-promotions`).then((res) => setPromotions(res.data.data))
      //Http.get(`/brands`).then((res) => setBrands(res.data.data))
      Http.get(`/suppliers`).then((res) => setSuppliers(res.data.data))
   }, [])

   // useEffect(() => {
   //    setTimeout(() => {
   //       if(type === 'create') {
   //          setValue('price_promotion', promotions?.[0]);
   //       }
   //    }, 500)
   // }, [promotions, type, setValue]);

   const updatePromotionPeriod = () => {
  //    if(type === 'create') {
         setValue('gl_period', promotions[0].name)
         setValue('price_promotion', promotions[0]);
         setPromotionPeriod(true);
    //  }
      //  else {
      //    setPromotionPeriod(true);
      //    setValue('price_promotion', gl_period);
      // }
   }

   const UpdateCurrentButton = () => (
      <Button
         size='md'
         variant='solid'
         onClick={() => updatePromotionPeriod()}
         leftIcon={changePromotionPeriod ? <FiCheckCircle /> : null}
         style={{ 
            marginTop: type === 'create' ? 0 : 15, 
            marginBottom: type === 'create' ? 15 : 0 
         }}
      >
         Set to Current Promotion
      </Button>
   )

   return (
      <>
         <Modal>
            {type === 'create' ? (
               <>
                  <UpdateCurrentButton />
                  <Field 
                     component="text"
                     name="gl_period"
                     label="Go Local Period"
                     placeholder="Enter Go Local Period"
                  />
                  {/* <Field
                     component='select'
                     name='price_promotion'
                     placeholder='Select promotion'
                     label='Promotions'
                     options={promotions}
                     labelKey='name'
                     valueKey='id'
                     size='lg'
                     rules={{
                        required: true,
                        message: 'You must select a promotion'
                     }}
                  /> */}
               </>
            ) : (
               <Flex justify="space-between" width="60%" mb='10px'>
                  <Box>
                     <Text>Promotion Period</Text>
                     <Text fontWeight='bold'>{price_promotion?.name}</Text>
                  </Box>
                  <Box>
                     <Text>Go Local Period</Text>
                     <Field 
                        component="text"
                        name="gl_period"
                        placeholder="Enter Go Local Period"
                     />
                  </Box>
                  <Box>
                     <UpdateCurrentButton />
                  </Box>
               </Flex>
            )}

            <Field
               component='repeater'
               name='execution'
               placeholder='Enter execution brand'
               label='Execution'
               fieldArray={[
                  {
                     name: 'supplier',
                     component: 'select',
                     loadOptions: getBrands,
                     isAsync: true,
                     placeholder: 'Select supplier name',
                     label: 'Supplier',
                     labelKey: 'name',
                     valueKey: 'id',
                     rules: { required: true }
                  },
                  {
                     name: 'activation',
                     component: 'text',
                     placeholder: 'Please enter activation',
                     label: 'Activation',
                     rules: { required: true }
                  },
                  {
                     name: 'additional_pos',
                     component: 'text',
                     placeholder: 'Please enter additional POS',
                     label: 'Additional POS'
                  },
                  {
                     name: 'products',
                     component: 'select',
                     label: 'Products',
                     placeholder: 'Search by id...',
                     isAsync: true,
                     loadOptions: loadProducts,
                     labelKey: 'description',
                     valueKey: 'id',
                     isMulti: true
                  },
                  {
                     name: 'id',
                     component: 'hidden',
                     rules: { required: true }
                  }
               ]}
               size='lg'
               rules={{
                  required: true,
                  message: 'You must enter a brand'
               }}
            />
            <ModalContent>
               <ModalHeader>
                  <Text>Create new brand</Text>
               </ModalHeader>
               <Flex p='0px 20px 20px 20px'>
                  <Input
                     placeholder='Enter brand name'
                     w='100%'
                     h='auto'
                     onChange={(e) => setBrandName(e.target.value)}
                     value={brandName}
                  />

                  <Button
                     variantColor='parfettsGreen'
                     color='white'
                     ml='10px'
                     onClick={() => handleCreateBrand()}
                  >
                     Create
                  </Button>
               </Flex>
            </ModalContent>
         </Modal>
      </>
   )
}

export default Form

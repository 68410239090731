import React, { Suspense, useState } from 'react'

import { Grid, Flex, Box, Button } from '@builtbypixel/plasma'
import Card from '../../components/common/Card'
import SalesFigures from './SalesFigures'
import PendingLeads from './PendingLeads'
import UpcomingVisits from './UpcomingVisits'
import CardLoader from '../../components/common/CardLoader'
import ChartLoader from '../../components/common/ChartLoader'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import CurrentGoLocalPeriod from './CurrentGoLocalPeriod'
import Tasks from '../../containers/Planner/Tabs/Tasks'
import { BsArrowRightShort } from 'react-icons/bs'
import LinkButton from '../../components/LinkButton'
import ChartFilters from './ChartFilters'
import { useRecoilValue } from 'recoil'
import { globalAtom } from '../../state/global'

const HeadOfficeDashboard = () => {
   const [financial, setFinancial] = useState(false)
   const [selectedBranch, setSelectedBranch] = useState(null)
   const [selectedUser, setSelectedUser] = useState(null)
   const [fascia, setFascia] = useState(false)
   const [sop, setSop] = useState(null)
   const { user } = useRecoilValue(globalAtom)

   console.log('useruseruseruseruser', user)
   return (
      <Grid
         isInline
         spacing='10px'
         templateColumns='repeat(12, 1fr)'
         gap='15px 15px'
      >
         <Card
            gridColumn='span 4'
            title='Current Go Local Period'
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/resources`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <Flex align='center'>
               <ErrorBoundary>
                  <Suspense fallback={<ChartLoader />}>
                     <CurrentGoLocalPeriod />
                  </Suspense>
               </ErrorBoundary>
            </Flex>
         </Card>

         <Card
            flex={2}
            title='Sales Figures'
            gridColumn={{ xs: 'span 12', lg: 'span 8' }}
            extraMenu={
               <ChartFilters
                  setFascia={setFascia}
                  fascia={fascia}
                  setFinancial={setFinancial}
                  setSelectedBranch={setSelectedBranch}
                  setSelectedUser={setSelectedUser}
                  selectedUser={user}
                  selectedBranch={selectedBranch}
                  financial={financial}
                  sop={sop}
                  setSop={setSop}
                  noEditRDA={true}
               />
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<ChartLoader />}>
                  <SalesFigures
                     mode={financial ? 'financial' : 'calendar'}
                     branch={selectedBranch?.id}
                     rda={user?.rda_id?.id}
                     fascia={fascia}
                     sop={sop}
                  />
               </Suspense>
            </ErrorBoundary>
         </Card>
         <Card
            flex={1}
            title='Your Tasks'
            gridColumn={{ xs: 'span 12', lg: 'span 4' }}
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/planner/tasks`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<CardLoader />}>
                  <Tasks />
               </Suspense>
            </ErrorBoundary>
         </Card>

         <Card
            flex={1}
            title='Pending Customer Leads'
            gridColumn={{ xs: 'span 12', lg: 'span 4' }}
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/customer-leads`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               {' '}
               <Suspense fallback={<CardLoader />}>
                  <PendingLeads />
               </Suspense>
            </ErrorBoundary>
         </Card>
         <Card
            flex={1}
            title='Upcoming Visits'
            gridColumn={{ xs: 'span 12', lg: 'span 4' }}
            extraMenu={
               <Box mt='auto'>
                  <LinkButton to={`/planner/visits`}>
                     <Button
                        size='sm'
                        variant='link'
                        rightIcon={<BsArrowRightShort />}
                     >
                        View all
                     </Button>
                  </LinkButton>
               </Box>
            }
         >
            <ErrorBoundary>
               <Suspense fallback={<CardLoader />}>
                  <UpcomingVisits />
               </Suspense>
            </ErrorBoundary>
         </Card>
      </Grid>
   )
}

export default HeadOfficeDashboard

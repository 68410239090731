import React, { useState } from 'react'
import {
   Grid,
   Flex,
   PseudoBox,
   Tooltip,
   Text,
   Stack,
   Checkbox,
   Box,
   Icon
} from '@builtbypixel/plasma'
import BlockData from '../../components/common/BlockData'
import { FaFacebookF, FaTwitter, FaInstagram, FaLink } from 'react-icons/fa'
import dayjs from 'dayjs'
const CustomerDetails = ({ customer }) => {
   const [copyToolTip, setCopyToolTip] = useState('click to copy')

   const saveToClipboard = (text) => {
      navigator.clipboard.writeText(text)
      setCopyToolTip('copied')
      setTimeout(() => {
         setCopyToolTip('click to copy')
      }, 300)
   }

   return (
      <Grid templateColumns='repeat(4, 1fr)' gap='15px'>
         <Box gridColumn='span 3'>
            <Grid templateColumns='repeat(3, 1fr)' gap='15px 15px'>
               <BlockData
                  title='Customer Name'
                  content={`${customer?.contact_name}`}
               />
               <BlockData
                  title='Current Fascia'
                  content={customer?.current_fascia}
               />
               <BlockData
                  title='Phone'
                  content={
                     <Tooltip title={copyToolTip}>
                        <PseudoBox
                           _hover={{ cursor: 'pointer' }}
                           onClick={() => saveToClipboard(customer.phone)}
                        >
                           {customer?.phone}
                        </PseudoBox>
                     </Tooltip>
                  }
               />
               <BlockData
                  title='Property Status'
                  content={
                     customer?.property_status?.name
                        ? customer?.property_status?.name
                        : '-'
                  }
               />
               <BlockData
                  title='Ownership'
                  content={
                     customer?.ownership?.name ? customer?.ownership?.name : '-'
                  }
               />
               <BlockData
                  title='Email'
                  content={
                     <Tooltip title={copyToolTip}>
                        <PseudoBox
                           _hover={{ cursor: 'pointer' }}
                           onClick={() => saveToClipboard(customer.email)}
                        >
                           {customer?.email}
                        </PseudoBox>
                     </Tooltip>
                  }
               />

               <BlockData
                  title='Contract'
                  content={dayjs(customer?.contract).format('MM/DD/YYYY')}
               />

               <BlockData
                  title='Opening Times'
                  content={customer?.opening_times}
               />
               <BlockData
                  title='Social Media'
                  content={
                     customer?.social ? (
                        <Flex>
                           {customer?.social?.map((item, i) => (
                              <a
                                 style={{ marginRight: '5px' }}
                                 target='_blank'
                                 rel='noopener noreferrer'
                                 href={item.url}
                                 key={`soc-it-` + i}
                              >
                                 <Icon color='lightGray' fontSize='1.5rem'>
                                    {item?.platform?.name === 'Facebook' ? (
                                       <FaFacebookF />
                                    ) : item?.platform?.name === 'Twitter' ? (
                                       <FaTwitter />
                                    ) : item?.platform?.name === 'Instagram' ? (
                                       <FaInstagram />
                                    ) : (
                                       <FaLink />
                                    )}
                                 </Icon>
                              </a>
                           ))}
                        </Flex>
                     ) : (
                        'N/A'
                     )
                  }
               />
               <BlockData
                  title='Potential'
                  content={
                     <Box
                        bg={
                           customer?.potential?.name === 'Medium'
                              ? 'parfettsOrange'
                              : customer?.potential?.name === 'Low'
                              ? 'parfettsGreen'
                              : 'parfettsRed'
                        }
                        color='white'
                        padding='1px 2px'
                        textAlign='center'
                        rounded='20px'
                     >
                        {customer?.potential?.name}
                     </Box>
                  }
               />

               <BlockData title='Service' content={customer?.service?.name} />
            </Grid>
         </Box>
         <Box gridColumn='span 1'>
            <BlockData
               title='Interests'
               content={
                  <Stack spacing='5px'>
                     <Box>
                        <Checkbox
                           label='Promotions'
                           checked={customer?.interests?.promotions}
                           isDisabled
                        />
                     </Box>
                     <Box>
                        <Checkbox
                           label='Fascia'
                           checked={customer?.interests?.fascia}
                           isDisabled
                        />{' '}
                     </Box>

                     <Box>
                        <Checkbox
                           label='Deliveries'
                           checked={customer?.interests?.deliveries}
                           isDisabled
                        />{' '}
                     </Box>

                     <Box>
                        <Checkbox
                           label='Social Media'
                           checked={customer?.interests?.social_media_support}
                           isDisabled
                        />
                     </Box>
                  </Stack>
               }
            />
         </Box>
      </Grid>
   )
}

export default CustomerDetails

import React, { Suspense, useState, useEffect } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Box,
   Text,
   Card,
   Flex,
   Button,
   Image,
   Tooltip,
   Modal,
   ModalContent,
   ModalButton,
   useToast
} from '@builtbypixel/plasma'
import { RiNotification3Line } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import useSWR from 'swr'
import { useRecoilState } from 'recoil'
import dayjs from 'dayjs'
import { Skeleton } from '@builtbypixel/plasma'

import { useGet } from '../../hooks'
import NotificationLoader from '../../components/common/NotificationLoader'
import ToggleReadButton from './ToggleReadButton'
import Filters from './Filters'
import { usePost } from '../../hooks'
import NewNotification from './NewNotification'
import { notificationsAtom } from '../../state/global'

var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const Comp = ({ notifications, setNotifications }) => {
   const { data } = useSWR(`/notifications`, useGet, {
      suspense: true
   })

   useEffect(() => {
      if (data) {
         setNotifications(data)
      }
   }, [data, setNotifications])

   return (
      <Card p='0 20px' bg='global.lightBg'>
         {Object.keys(notifications).map(
            (dayKey) =>
               notifications[dayKey].notifications.length > 0 && (
                  // notifications[dayKey].map((day) => (
                  <Card key={dayKey} pt='10px'>
                     <Text fontWeight='bold' fontSize='22px' ml='5px' mt='20px'>
                        {dayjs(dayKey).format('MMMM DD, YYYY')}
                     </Text>
                     {notifications[dayKey].notifications.map(
                        (notification, i) => (
                           <Flex
                              key={notification.id}
                              mt='20px'
                              pb='20px'
                              align='center'
                              justify='space-between'
                              borderBottom={
                                 notifications[dayKey].notifications.length -
                                    1 >
                                    i && '1px solid'
                              }
                              borderColor='global.borderColour'
                              position='relative'
                           >
                              <Flex align='center'>
                                 {notification?.created_by?.profile_image?.[0]
                                    .url ? (
                                    <Tooltip
                                       fontSize='14px'
                                       width='100%'
                                       color='gray.400'
                                       mb='10px'
                                       title={
                                          notification?.created_by?.first_name +
                                          ' ' +
                                          notification?.created_by?.last_name
                                       }
                                    >
                                       <Image
                                          src={
                                             notification?.created_by
                                                ?.profile_image?.[0].url
                                          }
                                          objectFit='cover'
                                          size='50px'
                                          rounded='full'
                                       />
                                    </Tooltip>
                                 ) : (
                                    <Tooltip
                                       fontSize='14px'
                                       width='100%'
                                       color='gray.400'
                                       mb='10px'
                                       title='Sent to all'
                                    >
                                       <Box
                                          p='10px'
                                          border='1px'
                                          borderColor='gray.200'
                                          borderRadius='5em'
                                       >
                                          <RiNotification3Line
                                             size='24px'
                                             color='gray'
                                          />
                                       </Box>
                                    </Tooltip>
                                 )}

                                 <Box pl='10px'>
                                    {notification.type === 'General' ? (
                                       <Text
                                          fontSize='14px'
                                          fontWeight={
                                             notification?.status?.id === 0
                                                ? 'bold'
                                                : 'normal'
                                          }
                                       >
                                          {notification.message}
                                       </Text>
                                    ) : (
                                       <Link
                                          to={
                                             notification.type === 'tasks'
                                                ? '/planner/tasks'
                                                : `/${notification.type}/edit/${notification.model_id}`
                                          }
                                       >
                                          <Text
                                             fontSize='14px'
                                             color='parfettsBlue'
                                             fontWeight={
                                                notification?.status?.id === 0
                                                   ? 'bold'
                                                   : 'normal'
                                             }
                                          >
                                             {notification.message}
                                          </Text>
                                       </Link>
                                    )}

                                    {notification.type !== 'General' &&
                                       notification?.due_date !==
                                          '0000-00-00 00:00:00' && (
                                          <Text
                                             fontSize='14px'
                                             width='100%'
                                             color='gray.400'
                                             mb='10px'
                                          >
                                             Due{' '}
                                             {dayjs(
                                                notification.due_date
                                             ).fromNow()}
                                          </Text>
                                       )}
                                 </Box>
                              </Flex>
                              <Flex
                                 width='160px'
                                 direction='column'
                                 align='flex-end'
                                 justify='space-between'
                              >
                                 <Tooltip
                                    title={
                                       notification?.read
                                          ? 'Mark as unread'
                                          : 'Mark as read'
                                    }
                                    placement='right'
                                 >
                                    <ToggleReadButton
                                       size='lg'
                                       notification={notification}
                                    />
                                 </Tooltip>
                                 <Text
                                    fontSize='12px'
                                    width='100%'
                                    color='gray.400'
                                    m='0'
                                    textAlign='right'
                                 >
                                    {dayjs(notification.created_at).format(
                                       'DD/MM/YYYY'
                                    )}
                                 </Text>
                                 <Text
                                    fontSize='12px'
                                    width='100%'
                                    color='gray.400'
                                    m='0'
                                    textAlign='right'
                                 >
                                    {dayjs(notification.created_at).format(
                                       '@ HH:mm'
                                    )}
                                 </Text>
                              </Flex>
                           </Flex>
                        )
                     )}
                  </Card>
               )
         )}
      </Card>
   )
}

const SuspenseNotifications = () => {
   const [notifications, setNotifications] = useRecoilState(notificationsAtom)
   const [user, setUser] = useState(null)
   const [date, setDate] = useState(null)
   const { response } = usePost()
   const toast = useToast()

   useEffect(() => {
      if (response) {
         toast({
            title: 'Notification sent',
            status: 'success',
            position: 'top-right',
            isClosable: false,
            variant: 'solid'
         })
      }
   }, [response, toast])

   return (
      <Box>
         <Flex justifyContent='space-between'>
            <Box w='100%' mb='10px'>
               <ViewTitle title='Notifications' />
            </Box>
            <Flex>
               <Modal>
                  <ModalButton>
                     <Button
                        variant='solid'
                        variantColor='parfettsRed'
                        color='white'
                        leftIcon={<FiPlus />}
                        rounded='md'
                     >
                        Add New
                     </Button>
                  </ModalButton>
                  <ModalContent>
                     <NewNotification />
                  </ModalContent>
               </Modal>
            </Flex>
         </Flex>
         <Suspense
            fallback={<Skeleton width='80px' height='50px' float='right' />}
         >
            <Filters
               setNotifications={setNotifications}
               user={user}
               setUser={setUser}
               setDate={setDate}
            />
         </Suspense>

         <Suspense fallback={<NotificationLoader />}>
            <Comp
               notifications={notifications}
               user={user}
               date={date}
               setNotifications={setNotifications}
            />
         </Suspense>
      </Box>
   )
}

export default SuspenseNotifications

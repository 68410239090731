import React from 'react'
import { Grid, Checkbox } from '@builtbypixel/plasma'

import BlockData from '../../../components/common/BlockData'
import moment from 'moment'

const Stock = ({ stock, branch }) => {
   const branchStock = stock.filter(item => item.branch_id === branch)


   return (

      <Grid templateColumns='repeat(2, 1fr)' gap='15px'>
         <BlockData title='Stock' content={branchStock?.[0]?.stock_qty ? branchStock?.[0]?.stock_qty : "-"} />

         <Checkbox
            label='In Stock'
            checked={branchStock?.[0]?.in_stock ? true : false}
            isDisabled
         />


         <BlockData title='Priority' content={branchStock?.[0]?.priority ? branchStock?.[0]?.priority : "-"} />


         <Checkbox
            label='Delisted'
            checked={branchStock?.[0]?.delisted ? true : false}
            isDisabled
         />
         <BlockData title='Popularity' content={branchStock?.[0]?.popularity ? branchStock?.[0]?.popularity : "-"} />
         <Checkbox
            label='On Order'
            checked={branchStock?.[0]?.on_order ? true : false}
            isDisabled
         />



         {/* <BlockData
            title='Last Sold'
            content={moment(branchStock?.[0]?.last_sold).format('ll')}
         /> 
         <BlockData title='Stock Turn' content={branchStock?.[0]?.turn} />
         <BlockData title='Cost' content={branchStock?.[0]?.cost} />
         <BlockData title='Sell' content={branchStock?.[0]?.sell} />
         */}
      </Grid>
   )
}

export default Stock

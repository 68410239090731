import { createCrudRoutes } from '../../hooks'
import Entry from './Edit'
import List from './'
import setup from './setup'

const crud = createCrudRoutes({
   setup,
   name: setup.title,
   edit: Entry,
   list: List,
   view: null,
   permissions: ['Admin']
})

const routes = [...crud]

export default routes

import React from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { Box, Flex, Button, IconButton } from '@builtbypixel/plasma'
import { Link } from 'react-router-dom'
import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'

const columns = [
   {
      Header: 'Name',
      accessor: 'name'
   },
   {
      Header: 'Customers',
      accessor: 'customer_count'
   },
   {
      Header: 'Edit',
      accessor: 'id',
      Cell: ({ row }) => {
         return (
            <Link to={`whatsapp-segments/edit/${row.original.id}`}>
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </Link>
         )
      }
   }
]

const Comp = () => {
   const filters = []

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='WhatsApp Segments' />
            </Box>
            <Flex>
               <LinkButton to={`/${setup.model}/create`}>
                  <Button ml='5px' bg='parfettsRed' color='white'>
                     Add New Segment
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Flex justify='space-between'>
            <Box flex='6'>
               <Table
                  columns={columns}
                  setup={setup}
                  onRowSelect={null}
                  filters={filters}
               />
            </Box>
         </Flex>
      </>
   )
}

export default Comp

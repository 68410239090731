import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@builtbypixel/plasma'
import { useFormContext, useWatch } from 'react-hook-form'
import { FiExternalLink } from 'react-icons/fi'
import MDEditor from '@uiw/react-md-editor'
import TemplateMediaPreview from './TemplateMediaPreview'

const TemplatePreview = ({ source }) => {
   const { control } = useFormContext()

   const allFields = useWatch({ control })

   const headerFormat = allFields?.content?.[0]?.format
   const headerText = allFields?.content?.[0]?.text
   const bodyText = allFields?.content?.[1]?.text
   const footerText = allFields?.content?.[2]?.text
   const buttons = allFields?.content?.[3]?.buttons

   const variablesHeader = allFields?.content?.[0]?.variables
   const variablesBody = allFields?.content?.[1]?.variables

   const [header, setHeader] = useState(null)
   const [body, setBody] = useState(null)
   //   const { setValue } = useFormContext();

   useEffect(() => {
      setBody(bodyText)
   }, [bodyText])

   useEffect(() => {
      setHeader(headerText)
   }, [headerText])

   useEffect(() => {
      if (variablesHeader) {
         const replacedHeader = headerText?.replaceAll(
            `{${1}}`,
            variablesHeader ? variablesHeader : `{${1}}`
         )

         setHeader(replacedHeader)
      }
   }, [headerText, variablesHeader])

   useEffect(() => {
      if (variablesBody?.length > 0) {
         const replacedBody = variablesBody.reduce(
            (body, variable, i) =>
               body.replaceAll(
                  `{${i + 1}}`,
                  variable ? variable : `{${i + 1}}`
               ),
            bodyText
         )

         setBody(replacedBody)
      }
   }, [bodyText, variablesBody])

   return (
      <Box
         backgroundImage={'url("/images/whatsappBg.jpeg")'}
         backgroundSize='cover'
         backgroundRepeat='no-repeat'
         h='800px'
         w='300px'
      >
         <Box
            mt='2rem'
            ml='1rem'
            p={
               headerFormat !== 'TEXT' || headerFormat?.value !== 'TEXT'
                  ? '0.2rem'
                  : '1rem'
            }
            w='90%'
            style={{
               backgroundColor: 'white',
               diplay: 'flex',
               flexDirection: 'row'
            }}
         >
            <Box
               mt='2rem'
               ml='1rem'
               p={
                  headerFormat !== 'TEXT' || headerFormat?.value !== 'TEXT'
                     ? '0.2rem'
                     : '1rem'
               }
               w='90%'
               style={{
                  backgroundColor: 'white',
                  diplay: 'flex',
                  flexDirection: 'row'
               }}
            >
               <Box
                  style={{
                     backgroundColor: 'white',
                     diplay: 'flex',
                     flexDirection: 'row',
                     fontSize: '15px'
                  }}
               >
                  {/* {headerFormat === 'TEXT' || headerFormat?.value === 'TEXT' ? (
                     <Text as='strong'>{header}</Text>
                  ) : headerFormat ? (
                     allFields?.content?.[0]?.example?.header_handle?.[0]
                        ?.secure_url ? (
                        <Image
                           src={`${allFields?.content?.[0]?.example?.header_handle?.[0]?.url}`}
                        />
                     ) : (
                        <Flex
                           background='#ccd0d5'
                           width='100%'
                           justify='center'
                           py='1em'
                        >
                           <Image
                              src={
                                 headerFormat === 'IMAGE' ||
                                 headerFormat?.value === 'IMAGE'
                                    ? '/images/wa_img_placeholder.png'
                                    : headerFormat === 'VIDEO' ||
                                      headerFormat?.value === 'VIDEO'
                                    ? '/images/wa_vid_placeholder.png'
                                    : '/images/wa_doc_placeholder.png'
                              }
                           />
                        </Flex>
                     )
                  ) : null} */}
                  {headerFormat === 'TEXT' || headerFormat?.value === 'TEXT' ? (
                     <Text as='strong'>{header}</Text>
                  ) : headerFormat ? (
                     <TemplateMediaPreview headerFormat={headerFormat} />
                  ) : null}
               </Box>
               <MDEditor.Markdown
                  source={body}
                  style={{
                     backgroundColor: 'white',
                     diplay: 'flex',
                     flexDirection: 'row',
                     whiteSpace: 'pre-line',
                     fontSize: '15px'
                  }}
               />
               <Box
                  style={{
                     backgroundColor: 'white',
                     diplay: 'flex',
                     flexDirection: 'row',
                     whiteSpace: 'pre-line',
                     color: 'rgba(0, 0, 0, 0.45)',
                     fontSize: '13px',
                     marginBottom: '0.8em'
                  }}
               >
                  {footerText}
               </Box>
               {buttons?.[0]?.url && (
                  <Flex
                     background='white'
                     borderTop='1px solid #dadde1'
                     justify='center'
                  >
                     <a href={buttons[0].url}>
                        <Flex align='center' marginTop='0.2em'>
                           <FiExternalLink color='#00a5f4' />
                           <Text ml='0.2em' color='#00a5f4'>
                              {buttons[0].text}
                           </Text>
                        </Flex>
                     </a>
                  </Flex>
               )}
            </Box>
         </Box>
      </Box>
   )
}

export default TemplatePreview

import React, { useEffect, useState } from 'react'
import MDEditor, { commands } from '@uiw/react-md-editor'
import { Picker } from 'emoji-picker-element'
import Swal from 'sweetalert2'
import './index.css'

const picker = new Picker()

const Markdown = ({ label, value, onChange, maxLength }) => {
   const [text, setText] = useState(value)
   const [mdData, setMdData] = useState({ api: null, state: null })

   const eventHandler = function (event) {
      handleEmojiClick(event, mdData?.api, mdData?.state)
   }

   const handleEmojiClick = (event, api, state) => {
      // console.log('emoji-click', event.detail);

      let modifyText = `${event.detail.unicode}`

      if (!state?.selectedText) {
         modifyText = ` ${event.detail.unicode}`
      }

      api?.replaceSelection && api.replaceSelection(modifyText)

      Swal.close()
   }

   const emojis = {
      name: 'emojis',
      keyCommand: 'emojis',
      buttonProps: { 'aria-label': 'Insert Emoji' },
      icon: (
         <svg
            className='emoji-button'
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='12'
            viewBox='0 0 24 24'
         >
            <path d='M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm0 20a9 9 0 1 1 9-9 9.011 9.011 0 0 1-9 9zm6-8a6 6 0 0 1-12 0 1 1 0 0 1 2 0 4 4 0 0 0 8 0 1 1 0 0 1 2 0zM8 10V9a1 1 0 0 1 2 0v1a1 1 0 0 1-2 0zm6 0V9a1 1 0 0 1 2 0v1a1 1 0 0 1-2 0z' />
         </svg>
      ),
      execute: (state, api) => {
         Swal.fire({
            html: '<div id="emoji-picker" class="light" />',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false
         })

         var theDiv = document.getElementById('emoji-picker')
         theDiv.appendChild(picker)

         setMdData({ state, api })
      }
   }

   useEffect(() => {
      picker.addEventListener('emoji-click', eventHandler, false)
      return () =>
         picker.removeEventListener('emoji-click', eventHandler, false)
   }, [mdData])

   useEffect(() => {
      onChange(text)
   }, [text, onChange])

   useEffect(() => {
      if (value === text) return
      setText(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value])

   return (
      <div className='container' data-color-mode='light'>
         <MDEditor
            value={text}
            onChange={setText}
            fullscreen={false}
            preview='edit'
            textareaProps={{
               placeholder: label
            }}
            commands={[
               commands.group(
                  [
                     commands.title1,
                     commands.title2,
                     commands.title3,
                     commands.title4,
                     commands.title5,
                     commands.title6
                  ],
                  {
                     name: 'title',
                     groupName: 'title',
                     buttonProps: { 'aria-label': 'Insert title' }
                  }
               ),
               commands.bold,
               commands.italic,
               commands.strikethrough,
               commands.divider,
               emojis
            ]}
            extraCommands={[commands.codeEdit, commands.codePreview]}
         />
      </div>
   )
}

export default Markdown

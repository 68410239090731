import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import {
   IconButton,
   Flex,
   Text,
   Image,
   Button,
   Box
} from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { tableAtom } from '../../state/global'
import { useRecoilState } from 'recoil'


// Extra Menu appears next to the create button
const extraMenu = () => {
   return (
      <LinkButton to={`/${setup.model}/create`}>
         <Button variantColor='parfettsGreen' color='white'>
            New Store Development
         </Button>
      </LinkButton>
   )
}

const List = () => {
   const { completed } = useParams()
   const [tableState, setTableState] = useRecoilState(tableAtom)
   const [noFetch, setNoFetch] = useState(true);

   const columns = [
      {
         Header: () => null,
         id: 'edit',
         width: '50px',
         Cell: ({ row }) => (
            <Link to={`/developments/edit/${row.original.id}`}>
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </Link>
         )
      },
      {
         Header: 'Customer',
         accessor: 'customer',
         Cell: ({ value }) => (
            <Flex align='center'>
               <Text>
                  {value?.name}
               </Text>
            </Flex>
         )
      },
      {
         Header: 'RDA',
         accessor: 'rda',
         Cell: ({ value }) => (
            <Flex align='center'>
               <Image
                  src={value?.profile_image?.[0]?.url}
                  objectFit='cover'
                  size='30px'
                  rounded='full'
                  mr='10px'
               />
               <Box minWidth='250px'>
                  <Text>
                     {value?.first_name} {value?.last_name}
                  </Text>
                  <Text color='gray.400'>
                     {dayjs(value?.created_at).format('DD/MM/YYYY')}
                  </Text>
               </Box>
            </Flex>
         )
      },

      {
         Header: 'Signage',
         accessor: 'signage',
         Cell: ({ value }) => (
            <Box minWidth='50px'>
               <Text>{dayjs(value.date).format('DD/MM/YYYY')}</Text>
               <Text color='gray.400'>{value.status.name}</Text>
            </Box>
         )
      },
      {
         Header: 'Fitting',
         accessor: 'fitting',
         Cell: ({ value }) => (
            <Box minWidth='50px'>
               <Text>{dayjs(value.date).format('DD/MM/YYYY')}</Text>
               <Text color='gray.400'>{value.status.name}</Text>
            </Box>
         )
      },
      {
         Header: 'Artwork',
         accessor: 'artwork',
         Cell: ({ value }) => (
            <Box minWidth='50px'>
               <Text>{dayjs(value.date).format('DD/MM/YYYY')}</Text>
               <Text color='gray.400'>{value.status.name}</Text>
            </Box>
         )
      },
   ]



   useEffect(() => {
      if (completed) {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: {
                  completed: parseInt(completed)
               }
            }
         }))
         setNoFetch(false);
      }

   }, [])

   const filters = useMemo(
      () => [
         {
            name: 'from',
            component: 'date',
            placeholder: 'From',
            group: 'due_date'
         },
         {
            name: 'to',
            component: 'date',
            placeholder: 'To',
            group: 'due_date'
         }
      ],
      []
   )

   return (
      <ListWrapper
         setup={setup}
         columns={columns}
         filters={filters}
         extraMenu={extraMenu}
         noInitialFetch={noFetch}
      />
   )
}

export default List

import { atom } from 'recoil'



const mediaState = atom({
   key: 'mediaState',
   default: {
      media:[],
   }
})
export const triggerRefresh =  atom({
   key:'triggerRefresh',
   default:{
     refreshCounter: 1
   }
})
 export default mediaState
import React from 'react'
import { Button, Modal, ModalButton } from '@builtbypixel/plasma'

import Content from './Content'

/**
 * A button that shows the modal that handles Sending Execution Reports By Email
 * @param {Object} selectedExecution The selected execution object
 */
const SendReports = ({ selectedExecution }) => {
   return (
      <Modal>
         <ModalButton>
            <Button>Send All Execution Reports By Email</Button>
         </ModalButton>
         <Content selectedExecution={selectedExecution} />
      </Modal>
   )
}

export default SendReports

export const taskColour = (type, theme) => {
   switch (type) {
      case 'task':
         return theme.colors.parfettsGreen
      case 'milestone':
         return theme.colors.parfettsBlue
      case 'visit':
         return theme.colors.parfettsOrange
      default:
         return theme.colors.parfettsGreen
   }
}

import React, { Suspense } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Box,
   Grid,
   Button,
   Flex,
   TabPanel,
   Tab,
   TabPanels,
   Tabs,
   TabList,
   Text
} from '@builtbypixel/plasma'
import Card from '../../components/common/Card'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import BlockLoader from '../../components/common/BlockLoader'
import ReturnButton from '../../components/common/ReturnButton'
import CardLoader from '../../components/common/CardLoader'
import TableLoader from '../../components/common/TableLoader'
import { useParams } from 'react-router-dom'
import setup from './setup'
import useSWR from 'swr'
import { useGet } from '../../hooks'
import Map from './Map'
import ImageGallery from '../../components/ImageGallery'
import Visits from '../../components/VisitsTab'

import CustomerDetails from './CustomerDetails'
import Notes from './Notes'
import AddNewNote from './AddNewNote'
import MarkAsCustomer from './MarkAsCustomer'
import InStoreNow from './InStoreNow'

import LinkButton from '../../components/LinkButton'
import { FiEye } from 'react-icons/fi'

const CustomerLeads = () => {
   const { id } = useParams()

   const { data } = useSWR(`/customer-leads/${id}`, useGet)

   return (
      <>
         <Grid w='100%' templateColumns='repeat(2, 1fr)'>
            <Box mb='20px'>
               <ReturnButton to={`/${setup.model}`}>
                  Customer Leads
               </ReturnButton>
               <ViewTitle
                  title={
                     data?.data?.name
                        ? `${data?.data?.name}`
                        : `Customer #${id}`
                  }
               />
               <Text>
                  RDA: {data?.data?.rda?.first_name}{' '}
                  {data?.data?.rda?.last_name}
               </Text>
            </Box>
            <Flex justify='flex-end' flexWrap='wrap'>
               <InStoreNow id={data?.data?.id} />

               <MarkAsCustomer customer={data?.data} />

               <LinkButton to={`/customer-leads/edit/${id}`}>
                  <Button
                     variant='solid'
                     variantColor='parfettsRed'
                     color='white'
                     leftIcon={<FiEye />}
                     rounded='md'
                     ml='10px'
                  >
                     Edit details
                  </Button>
               </LinkButton>
            </Flex>
         </Grid>

         <Grid
            templateColumns='repeat(12, 1fr)'
            templateRows='repeat(2, auto)'
            gap={4}
            w='100%'
         >
            <Card gridColumn={{ xs: 'span 12', md: 'span 4', lg: 'span 3' }}>
               <Suspense fallback={<CardLoader />}>
                  <Box minHeight='250px'>
                     {data && <Map geoloc={data?.data?.geoloc} />}
                  </Box>
               </Suspense>
            </Card>

            <Card gridColumn={{ xs: 'span 12', md: 'span 8', lg: 'span 9' }}>
               {data ? (
                  <CustomerDetails customer={data.data} />
               ) : (
                  <>
                     <BlockLoader columns={4} />
                     <BlockLoader columns={4} />
                  </>
               )}
            </Card>
            <Grid gridColumn='span 12'>
               <Tabs
                  mb={10}
                  orientation='horizontal'
                  variant='tab'
                  size='md'
                  title='Notes'
                  gridColumn='span 12'
               >
                  <TabList>
                     <Tab position='relative'>Notes</Tab>
                     <Tab position='relative'>Images</Tab>
                     <Tab position='relative'>Visits</Tab>
                  </TabList>
                  <TabPanels>
                     <TabPanel>
                        <ErrorBoundary>
                           <Suspense fallback={<TableLoader />}>
                              <Card extraMenu={<AddNewNote id={id} />}>
                                 <Notes id={id} />
                              </Card>
                           </Suspense>
                        </ErrorBoundary>
                     </TabPanel>
                     <TabPanel>
                        {data && (
                           <ImageGallery
                              id={id}
                              endpoint='customer-leads-images'
                              type='customer'
                           />
                        )}
                     </TabPanel>
                     <TabPanel>
                        <ErrorBoundary>
                           <Suspense fallback={<TableLoader />}>
                              <Card>
                                 <Visits id={id} type='customer_lead' />
                              </Card>
                           </Suspense>
                        </ErrorBoundary>
                     </TabPanel>
                  </TabPanels>
               </Tabs>
            </Grid>
         </Grid>
      </>
   )
}

export default CustomerLeads

/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import FroalaEditorComponent from 'react-froala-wysiwyg'
import { useTheme, useColorMode } from '@builtbypixel/plasma'
import { useHttp } from '../../hooks'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import FroalaEditor from 'froala-editor'
import { Picker } from 'emoji-picker-element'

const picker = new Picker()

// Define popup template.
FroalaEditor.POPUP_TEMPLATES['customPlugin.popup'] = '[_CUSTOM_LAYER_]'

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
   popupButtons: ['popupClose', '|', 'popupButton1', 'popupButton2']
})

// The custom popup is defined inside a plugin (new or existing).
FroalaEditor.PLUGINS.customPlugin = function (editor) {
   // Create custom popup.
   function initPopup() {
      // Load popup template.
      var template = {
         custom_layer:
            '<div class="custom-layer" id="custom-layer" style="z-index:999"></div>'
      }

      // Create popup.
      var $popup = editor.popups.create('customPlugin.popup', template)

      return $popup
   }

   // Show the popup
   function showPopup() {
      // Get the popup object defined above.
      var $popup = editor.popups.get('customPlugin.popup')

      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      if (!$popup) $popup = initPopup()

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('customPlugin.popup', editor.$tb)

      // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('customPlugin.popup');

      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.
      var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]')

      // Set the popup's position.
      var left = $btn.offset().left + $btn.outerWidth() / 2
      var top =
         $btn.offset().top +
         (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

      // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.
      editor.popups.show('customPlugin.popup', left, top, $btn.outerHeight())
   }

   // Hide the custom popup.
   function hidePopup() {
      editor.popups.hide('customPlugin.popup')
   }

   // Methods visible outside the plugin.
   return {
      showPopup: showPopup,
      hidePopup: hidePopup
   }
}

FroalaEditor.DefineIcon('buttonIcon', { NAME: 'star', SVG_KEY: 'star' })
FroalaEditor.RegisterCommand('myButton', {
   title: 'Emojis',
   icon: 'buttonIcon',
   undo: false,
   focus: false,
   plugin: 'customPlugin',
   callback: function () {
      this.customPlugin.showPopup()

      var theDiv = document.getElementById('custom-layer')
      theDiv.appendChild(picker)

      picker.addEventListener('emoji-click', (event) => {
         console.log('emoji-click', event.detail)
         this.html.insert(event.detail.unicode)
      })
   }
})

const RichTextEditor = ({
   onChange,
   value,
   placeholder,
   setActive,
   maxLength
}) => {
   const theme = useTheme()
   const { colorMode } = useColorMode()
   const Http = useHttp()

   const [editor, setEditor] = useState(value ? value : '')

   const handleChange = (v) => {
      setEditor(v)
      onChange(v)
   }

   return (
      <div
         css={{
            color: theme.colors.global.text,
            zIndex: 0,
            position: 'relative',
            '.fr-toolbar': {
               backgroundColor: 'transparent',
               borderRadius: theme.radii.lg,
               color: theme.colors.global.text,
               padding: '3px 0px',
               border: 0,
               '.fr-newline': {
                  backgroundColor: theme.colors.global.elementBg
               },
               '.fr-btn-grp': {
                  marginLeft: '0px'
               },

               '.fr-command.fr-btn': {
                  margin: 0,
                  color: theme.colors.global.text,
                  '&:hover': {
                     backgroundColor: theme.colors.global.elementBg
                  },
                  svg: {
                     height: '20px'
                  },
                  '&.fr-active svg path': {
                     fill: theme.colors.secondary
                  },
                  '&:not(.fr-active) svg path': {
                     fill: theme.colors.global.text
                  },

                  '&.fr-btn-active-popup': {
                     background: theme.colors.secondary,
                     'svg path': {
                        fill: 'white'
                     }
                  }
               }
            },
            '.fr-second-toolbar': {
               display: 'none'
            },
            '.fr-popup': {
               backgroundColor: theme.colors.global.elementBgAlt,
               color: theme.colors.global.text,
               '.fr-buttons': {
                  backgroundColor: theme.colors.global.elementBgAlt
               },
               '.fr-input-line': {
                  padding: '8px 0px',
                  label: {
                     top: '23px',
                     background: 'transparent',
                     color: theme.colors.global.text
                  },
                  input: {
                     background: theme.colors.whiteAlpha[100],
                     borderColor: theme.colors.inputs.borderColour,
                     color: theme.colors.global.text,
                     borderWidth: '1px',
                     paddingBottom: '0px',
                     paddingTop: '6px',
                     '&:focus': {
                        borderColor: theme.colors.primary,
                        borderWidth: '1px'
                     }
                  }
               },
               '.fr-checkbox input:checked + span': {
                  background: theme.colors.primary,
                  borderColor: theme.colors.primary,
                  'svg path': {
                     fill: colorMode === 'light' ? 'white' : 'black'
                  }
               }
            },
            '.fr-box': {
               '.fr-wrapper': {
                  borderRadius: '5px',
                  background: theme.colors.richText.editorBg,
                  color: theme.colors.global.text,
                  border: `1px solid ${theme.colors.inputs.borderColour}`,
                  borderTop: `1px solid ${theme.colors.inputs.borderColour} !important`,
                  cursor: 'text'
               },
               '.fr-element': {
                  color: theme.colors.global.text,
                  padding: '7px 10px',
                  a: {
                     textDecoration: 'underline',
                     color: theme.colors.secondary
                  }
               },
               '.fr-placeholder': {
                  color: theme.colors.gray[300],
                  opacity: 0.9
               }
            }
         }}
      >
         <FroalaEditorComponent
            model={editor}
            onModelChange={handleChange}
            config={{
               placeholderText: placeholder,
               videoUpload: false,
               events: {
                  focus: () => {
                     setActive(true)
                  },
                  blur: () => {
                     setActive(false)
                  },
                  'image.beforeUpload': function (images, editorInst) {
                     // Before image is uploaded
                     const data = new FormData()
                     data.append('file', images[0])

                     Http.post('/file-upload', data, {
                        headers: {
                           accept: 'application/json',
                           'Accept-Language': 'en-US,en;q=0.8',
                           'Content-Type': `multipart/form-data; boundary=${data._boundary}`
                        }
                     })
                        .then((res) => {
                           this.image.insert(
                              res.data.url,
                              null,
                              null,
                              this.image.get()
                           )
                        })
                        .catch((err) => {
                           console.log(err)
                        })
                     return false
                  }
               },
               quickInsertEnabled: false,
               toolbarButtons: {
                  moreText: {
                     buttons: ['bold', 'italic', 'underline', 'myButton'], //'emoticons'

                     buttonsVisible: 4
                  },
                  moreRich: {
                     buttons: [
                        'insertLink',
                        'insertImage',
                        'insertVideo',
                        'insertTable'
                     ]
                  },
                  pluginsEnabled: ['emoticons', 'charCounter', 'myButton'],
                  charCounterCount: maxLength && true,
                  charCounterMax: maxLength && maxLength
               },
               linkInsertButtons: ['linkBack']
            }}
         />
      </div>
   )
}

export default RichTextEditor

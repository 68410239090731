import React from 'react'
import {
   Stack,
   Text,
   Box,
   Flex,
   Tooltip,
   PseudoBox
} from '@builtbypixel/plasma'

const TrafficLights = ({
   title,
   audit_compliance,
   spend_target,
   purchase_target,
   isOverall
}) => {
   const range = Math.round(parseInt(title) / 33)
   return (
      <Flex align='center'>
         <Tooltip
            title={
               <Flex direction='column'>
                  <Text>Overall: {parseFloat(title).toFixed(2)}</Text>
                  <Text>
                     Audit:{' '}
                     {audit_compliance !== 0 && audit_compliance !== null
                        ? parseFloat(audit_compliance).toFixed(2)
                        : parseFloat(0).toFixed(2)}
                  </Text>
                  <Text>
                     Spend:{' '}
                     {spend_target !== 0 && spend_target !== null
                        ? parseFloat(spend_target).toFixed(2)
                        : parseFloat(0).toFixed(2)}
                  </Text>
                  <Text>
                     Purchase:{' '}
                     {purchase_target !== 0 && purchase_target !== null
                        ? parseFloat(purchase_target).toFixed(2)
                        : parseFloat(0).toFixed(2)}
                  </Text>
               </Flex>
            }
            interactive
         >
            <PseudoBox _hover={{ bg: 'grayBg' }} rounded='md' p='5px'>
               <Stack isInline spacing='3px'>
                  <Box
                     rounded='full'
                     size={isOverall ? '20px' : '15px'}
                     bg='parfettsGreen'
                     border={range > 2 ? '2px' : '0px'}
                     opacity={range > 2 ? 1 : 0.3}
                  ></Box>

                  <Box
                     rounded='full'
                     size={isOverall ? '20px' : '15px'}
                     bg='parfettsOrange'
                     border={range === 2 ? '2px' : '0px'}
                     opacity={range === 2 ? 1 : 0.3}
                  ></Box>

                  <Box
                     rounded='full'
                     size={isOverall ? '20px' : '15px'}
                     bg='parfettsRed'
                     border={range <= 1 ? '2px' : '0px'}
                     opacity={range <= 1 ? 1 : 0.3}
                  ></Box>
               </Stack>
            </PseudoBox>
         </Tooltip>
         <Text
            ml={5}
            fontWeight={'600'}
            color={
               range > 2
                  ? 'parfettsGreen'
                  : range === 2
                  ? 'parfettsOrange'
                  : 'parfettsRed'
            }
         >
            {title}
         </Text>
      </Flex>
   )
}

export default TrafficLights

import React from 'react'
import NucleusCore from './Core'
import routes from './routes/routes'
import config from './config.json'
import links from './links'
import './index.css'

function App() {
   return <NucleusCore routes={routes} config={config} links={links} />
}

export default App

import { Box, Flex, Input, Text } from '@builtbypixel/plasma'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Field } from '../../../components/Form'
import { useParams } from 'react-router-dom'

const EditMessageVariables = ({
   template,
   messageSent,
   setContentHeader,
   contentHeader,
   setContentBody,
   contentBody,
   contentFooter,
   setContentFooter
}) => {
   const headerVars = template?.content?.[0]?.example?.header_text?.[0]
   const bodyVars = template?.content?.[1]?.example?.body_text?.[0]
   const [inputHeader, setInputHeader] = useState('')
   const [inputBody, setInputBody] = useState(null)
   const { type } = useParams()


   useEffect(() => {
 setInputBody(bodyVars)
 
   }, [bodyVars])
   
   useEffect(() => {
      if (inputHeader)
         setContentHeader((old) => {
            return {
               ...old,
               example: {
                  ...old.example,
                  header_text: [inputHeader]
               }
            }
         })
   }, [inputHeader])

   useEffect(() => {
      if (inputBody?.length > 0)
         setContentBody((old) => {
            return {
               ...old,
               example: {
                  ...old.example,
                  body_text: [inputBody]
               }
            }
         })
   }, [inputBody])

   return (
      <Flex w='100%' direction='column'>
         {headerVars !== undefined && (
            <Flex direction='column' p='1em'>
               <Text>Header Variables</Text>
               <Flex align='center'>
                  <Box
                     bg='#075E54'
                     color='white'
                     mr='1em'
                     w='6ch'
                     textAlign='center'
                     fontWeight='bold'
                  >{`{{${1}}}`}</Box>

                  <Input
                     disabled={messageSent}
                     onChange={(e) => {
                        setInputHeader(e.target.value)
                     }}
                     value={inputHeader ? inputHeader : headerVars}
                  />
               </Flex>
            </Flex>
         )}
         {bodyVars?.length > 0 && (
            <Flex direction='column' p='1em'>
               <Text>Body Variables</Text>
               <Flex align='center' w='100%' direction='column'>
                  {bodyVars.map((el, index) => {
                     return (
                        <Flex align='center' w='100%'>
                           <Box
                              bg='#075E54'
                              color='white'
                              mr='1em'
                              w='6ch'
                              textAlign='center'
                              fontWeight='bold'
                           >{`{{${index + 1}}}`}</Box>

                           <Input
                              disabled={messageSent}
                              my='0.5em'
                              value={inputBody?.[index]}
                              // value={inputBody?.[index] ? inputBody[index] : el}
                              defaultValue={el}
                              onChange={(e) => {
                                 let arr =
                                    inputBody?.length > 0
                                       ? [...inputBody]
                                       : [...bodyVars]
                                 arr[index] = e.target.value

                                 setInputBody(arr)
                              }}
                           />
                        </Flex>
                     )
                  })}
               </Flex>
            </Flex>
         )}
      </Flex>
   )
}

export default EditMessageVariables

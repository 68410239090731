import Login from '../containers/Auth/Login'
import ForgotPassword from '../containers/Auth/ForgotPassword'
import ResetPassword from '../containers/Auth/ResetPassword'

import adminUsers from '../containers/AdminUsers/routes'

import audits from '../containers/Audits/routes'
import auditTemplates from '../containers/AuditTemplates/routes'
import dashboard from '../containers/Dashboard/routes'
import developments from '../containers/StoreDevelopments/routes'
import executionsReport from '../containers/Reports/Execution/routes'
import leads from '../containers/CustomerLeads/routes'
import mediaLibrary from '../containers/MediaLibrary/routes'
import milestones from '../containers/Milestones/routes'
import notifications from '../containers/Notifications/routes'
import planner from '../containers/Planner/routes'
import products from '../containers/Products/routes'
import rdaReport from '../containers/Reports/Rda/routes'
import resources from '../containers/Resources/routes'
import retailerExecutions from '../containers/RetailerExecutions/routes'
import retailers from '../containers/Retailers/routes'
import report from '../containers/Reports/Report/routes'
import taskCategories from '../containers/TaskCategories/routes'
import teams from '../containers/Teams/routes'
import visits from '../containers/Visits/routes'
import visitTypes from '../containers/VisitTypes/routes'
import services from '../containers/Services/routes'
import appNotifications from '../containers/AppNotifications/routes'
import competitions from '../containers/Competitions/routes'
import whatsapp from '../containers/WhatsAppTemplates/routes'
import whatsappSegments from '../containers/WhatsAppSegments/routes'
import whatsappMessages from '../containers/WhatsAppMessages/routes'

const routes = [
   {
      path: '/',
      title: 'Login',
      exact: true,
      auth: false,
      component: Login,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/forgot-password',
      title: 'Forgot Password',
      exact: true,
      auth: false,
      component: ForgotPassword,
      permissions: ['Admin', 'RDA']
   },
   {
      path: '/reset-password',
      title: 'Reset Password',
      exact: true,
      auth: false,
      component: ResetPassword,
      permissions: ['Admin', 'RDA']
   },
   ...adminUsers,
   ...audits,
   ...auditTemplates,
   ...dashboard,
   ...developments,
   ...executionsReport,
   ...leads,
   ...mediaLibrary,
   ...milestones,
   ...notifications,
   ...planner,
   ...products,
   ...rdaReport,
   ...report,
   ...resources,
   ...retailerExecutions,
   ...retailers,
   ...taskCategories,
   ...teams,
   ...visits,
   ...visitTypes,
   ...services,
   ...appNotifications,
   ...competitions,
   ...whatsapp,
   ...whatsappSegments,
   ...whatsappMessages
]

export default routes

import { useHttp } from '.'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useTeams = debounce((inputValue, callback) => {
   Http.post(`/teams`, { search: inputValue }).then((res) => {
      return callback(res.data.data)
   })
}, 500)

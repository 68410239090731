import React, { useState, useEffect, useContext } from 'react'
import {
   Box,
   Button,
   TextArea,
   ModalHeader,
   ModalContext,
   ModalFooter,
   useToast,
   Select,
   Text,
   Flex
} from '@builtbypixel/plasma'
import { useRDAS, useTeams } from '../../hooks'

import dayjs from 'dayjs'

import { usePost } from '../../hooks'
var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const NewNotification = () => {
   const [newNotification, setNewNotification] = useState('')
   const [target, setTarget] = useState('RDAs')
   const [selectedRDAs, setSelectedRDAs] = useState(null)
   const [selectedTeams, setSelectedTeams] = useState(null)

   const { loading, post, response } = usePost()
   const toast = useToast()
   const { close } = useContext(ModalContext)

   const formatOptionLabel = (option) =>
      target === 'RDAs'
         ? option.first_name + ' ' + option.last_name + ' - ' + option.email
         : option.name

   const handleSendNotification = () => {
      const payload =
         target === 'RDAs'
            ? { message: newNotification, user_id: selectedRDAs }
            : { message: newNotification, team_id: selectedTeams }

      if (newNotification) {
         post('/notifications', payload)
      }
   }
   useEffect(() => {
      if (response) {
         setNewNotification('')
         toast({
            title: 'Notification sent',
            status: 'success',
            position: 'top-right',
            isClosable: false,
            variant: 'solid'
         })
         close()
      }
   }, [response, toast])

   return (
      <>
         <ModalHeader>Add Notification</ModalHeader>
         <Box mx='1rem'>
            <Flex align='center' mb='1rem'>
               <Text>Post to:</Text>
               <Button
                  color={target === 'RDAs' ? 'black' : 'grey'}
                  mx='1rem'
                  onClick={() => setTarget('RDAs')}
               >
                  RDAs
               </Button>
               <Button
                  color={target === 'Teams' ? 'black' : 'grey'}
                  onClick={() => setTarget('Teams')}
               >
                  Teams
               </Button>
            </Flex>
            <Select
               name={target === 'RDAs' ? 'user_id' : 'team_id'}
               component='select'
               placeholder={`Send notifications to... (Type to search ${target})`}
               isAsync={true}
               loadOptions={target === 'RDAs' ? useRDAS : useTeams}
               valueKey='id'
               value={target === 'RDAs' ? selectedRDAs : selectedTeams}
               onChange={(value) =>
                  target === 'RDAs'
                     ? setSelectedRDAs(value)
                     : setSelectedTeams(value)
               }
               formatOptionLabel={formatOptionLabel}
               variant='flushed'
               rules={{ required: true }}
               isMulti={true}
            />

            <TextArea
               defaultValue=''
               value={newNotification}
               onChange={(value) => {
                  setNewNotification(value.target.value)
               }}
               placeholder='Enter notification text here...'
               rows={4}
               size='md'
               variant='outline'
               isLoading={loading}
               mt='2rem'
            />
         </Box>
         <ModalFooter showCloseIcon={false}>
            <Button
               variant='solid'
               variantColor='parfettsGreen'
               color='white'
               onClick={handleSendNotification}
            >
               Post Notification
            </Button>
         </ModalFooter>
      </>
   )
}

export default NewNotification

import React, { memo } from 'react'
import { Card, Flex, Text, Box } from '@builtbypixel/plasma'

const CardTitle = memo(({ children }) => (
   <Text
      fontWeight='semibold'
      px='15px'
      pt='15px'
      pb='5px'
      fontSize={{ xs: '14px', lg: '16px', xl: '18px' }}
   >
      {children}
   </Text>
))

const CardComponent = ({ children, title, extraMenu, ...rest }) => {
   return (
      <Card
         display='flex'
         flexDirection='column'
         justifyContent='stretch'
         h='100%'
         {...rest}
      >
         <Flex align='center'>
            {title && <CardTitle>{title}</CardTitle>}
            {extraMenu && (
               <Box ml='auto' p='15px'>
                  {extraMenu}
               </Box>
            )}
         </Flex>
         <Flex
            p='15px'
            h='auto'
            overflow='hidden'
            direction='column'
            justify='stretch'
         >
            {children}
         </Flex>
      </Card>
   )
}

export default CardComponent

import React from 'react'
import useSWR from 'swr'
import { Table, Text } from '@builtbypixel/plasma'
import { useGet } from '../../../hooks'
import EditNote from './EditNote'
import dayjs from 'dayjs'

const Notes = ({ id }) => {
   const { data } = useSWR(`/customer-leads/notes/${id}`, useGet, {
      suspense: true
   })

   const columns = [
      {
         Header: () => null,
         id: 'edit',
         width: '50px',
         Cell: ({ row }) => (
            <EditNote
               noteId={row.original.id}
               content={row.original.description}
               type={row.original.type}
               isEdit={true}
            />
         )
      },
      {
         Header: 'Note',
         width: '400px',
         accessor: 'description',
         Cell: ({ value }) => <Text fontSize='1.1rem'>{value}</Text>
      },
      {
         Header: 'RDA',
         accessor: 'user',
         Cell: ({ row }) => {
            return (
               row.original.user.first_name + ' ' + row.original.user.last_name
            )
         }
      },
      {
         Header: 'Date',
         accessor: 'date',
         Cell: ({ value }) => {
            return dayjs(value).format('MM/DD/YYYY')
         }
      }
   ]
   return (
      <Table
         columns={columns}
         data={{ data: data ? data.data : [] }}
         hidePagination
      />
   )
}

export default Notes

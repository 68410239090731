import React, { memo } from 'react'
import { useSWRPost } from '../../hooks'
import useSWR from 'swr'
import dayjs from 'dayjs'
import { Stack, Flex, Button, Text, Box } from '@builtbypixel/plasma'
import LinkButton from '../../components/LinkButton'
import { MdAdd } from 'react-icons/md'

const UpcomingVisits = memo(() => {
   const { data } = useSWR(
      '/visits',
      (url) => useSWRPost(url, { filters: {} }),
      { suspense: true }
   )

   return (
      <>
         <Stack spacing='2px'>
            {data?.data?.length > 0 ? (
               data?.data?.slice(0, 2).map((visit, i) => (
                  <Flex
                     key={`visit=${i}`}
                     bg={i % 2 === 0 && 'grayRow'}
                     py='10px'
                     px='10px'
                     align='center'
                     rounded='md'
                  >
                     <Text fontWeight='semibold'>
                        {dayjs(visit.due_date).format('DD/MM/YYYY')}
                     </Text>
                     <Flex ml='auto' align='center'>
                        <Text mr='15px'>{visit.retailer?.business_name}</Text>
                     </Flex>
                  </Flex>
               ))
            ) : (
               <Box mt='auto' pl='10px'>
                  <Text>No visits scheduled</Text>
                  <LinkButton to={`/visits/create`}>
                     <Button
                        variant='solid'
                        variantColor='parfettsGreen'
                        color='white'
                        leftIcon={<MdAdd />}
                        rounded='md'
                     >
                        Add a new visit
                     </Button>
                  </LinkButton>
               </Box>
            )}
         </Stack>
      </>
   )
})

export default UpcomingVisits

import React, { useCallback, useEffect, useRef, useState, memo } from 'react'
import { useHttp } from '../../hooks'
import { FiTrash } from 'react-icons/fi'
import { BiSearch } from 'react-icons/bi'
import TableLoader from '../common/TableLoader'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { tableAtom, defaultValues } from '../../state/global'
import DebouncedSearch from './DebouncedSearch'

import {
   Table,
   Text,
   Box,
   Stack,
   Flex,
   Button,
   useToast,
   PopConfirm,
   DatePicker,
   Select,
   ScrollContainer,
   Switch
} from '@builtbypixel/plasma'
import LinkButton from '../../components/LinkButton'
import { FiDatabase } from 'react-icons/fi'
const MemoTable = memo(
   ({
      setup,
      columns,
      data = null,
      loading,
      onChangePageSize,
      onChangePage,
      onRowSelect,
      onSortSelect,
   }) => {
      return (
         <Box bg='global.elementBg' h='auto' maxHeight='100%'>
            <ScrollContainer>
               {loading ? (
                  <Box p='20px'>
                     <TableLoader />
                  </Box>
               ) : data?.data.length === 0 ? (
                  <Flex p='20px' justify='center' align='center'>
                     <FiDatabase color='gray' />
                     <Text color='gray.500' ml='5px' mt='5px'>
                        No data{' '}
                     </Text>
                  </Flex>
               ) : (
                  <Table
                     accessor={setup.accessor}
                     columns={columns}
                     data={data}
                     nextPage={() => console.log('next page')}
                     loading={loading}
                     onChangePageSize={onChangePageSize}
                     onChangePage={onChangePage}
                     onRowSelect={onRowSelect}
                     onSortSelect={onSortSelect}
                     size='sm'
                  />
               )}
            </ScrollContainer>
         </Box>
      )
   }
)

MemoTable.displayName = 'MemoTable'

const TableComp = ({
   setup,
   columns,
   extraMenu = null,
   filters,
   relations,
   noInitialFetch,
   summary = false
}) => {
   const resetFilters = useResetRecoilState(tableAtom)
   const boxRef = useRef(null)

   //const [cancelToken, setCancelToken] = useState(null)
   const [requestSource, setRequestSource] = useState(null)

   const [tableState, setTableState] = useRecoilState(tableAtom)

   const Http = useHttp()
   const toast = useToast()
   const ExtraComponent = extraMenu

   const [loading, setLoading] = useState(true)
   const [data, setData] = useState({
      data: []
   })

   const [selected, setSelected] = useState([])

   const setFilter = useCallback(
      (name, value) => {
         const _filters = { ...tableState.params.filters }
         
         // the rda needs to come in as an object rather than value.id
         name === 'rda_id' ? _filters[name] = value?.rda_id : _filters[name] = value?.id ? value.id : value

         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: _filters
            }
         }))
      },
      [setTableState, tableState.params.filters]
   )
   const setDateRange = useCallback(
      (group, name, value) => {
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               date_range: {
                  ...old.params.date_range,
                  [group]: {
                     ...old?.params?.date_range?.[group],
                     [name]: value
                  }
               }
            }
         }))
      },
      [setTableState]
   )
   const setRelations = useCallback(
      (name, value) => {
         const _relations = { ...tableState.params.relations }
         _relations[name] = value?.id
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               relations: _relations
            }
         }))
      },
      [setTableState, tableState.params.relations]
   )

   const getField = (field) => {
      switch (field.component) {
         case 'switch':
            return (
               <Flex>
                  <Text color='gray.500'>{field.label}</Text>
                  <Switch
                     ml='5px'
                     value={
                        field.name && [tableState.params?.filters[field.name]]
                     }
                     onChange={(e) => setFilter(field.name, e.target.checked)}
                     {...field}
                  />
               </Flex>
            )
         case 'date':
            return (
               <DatePicker
                  {...field}
                  variant='unstyled'
                  value={
                     tableState.params?.date_range &&
                     tableState.params?.date_range[field.group][field.name]
                  }
                  onChange={(date) =>
                     setDateRange(field.group, field.name, date)
                  }
                  readOnly={false}
               />
            )
         case 'select':
            return (
               <Select
                  //value={tableState.params?.filters[field.name]}
                  value={
                     field.name &&
                     field.options?.find(
                        (item) =>
                           item.id === [tableState.params?.filters[field.name]]
                     )
                  }
                  options={field.options}
                  onChange={(value) => setFilter(field.name, value)}
                  {...field}
               />
            )
         case 'relation_select':
            return (
               <Select
                  value={
                     field.name &&
                     field.options?.find(
                        (item) =>
                           item.id ===
                           [tableState.params?.relations?.[field.name]]
                     )
                  }
                  options={field.options}
                  onChange={(value) => setRelations(field.name, value)}
                  {...field}
               />
            )
         case 'text':
            return (
               <DebouncedSearch
                  defaultSearch=''
                  onSearch={(e) => onTermSearch(e, field.name)}
                  placeholder={field.placeholder}
                  noInitialFetch={true}
                  requestSource={requestSource}
                  setRequestSource={setRequestSource}
               />
            )
         default:
            return null
      }
   }

   function countProperties(obj) {
      return Object.keys(obj).length
   }
   /* eslint-disable  */
   const fetchData = () => {
      if (tableState.params && tableState.params.date_range) {
         let first =
            tableState.params.date_range[
               Object.keys(tableState.params.date_range)[0]
            ]
         if (countProperties(first) == 1) {
            return false
         }
      }
      setLoading(true)
      Http.post(setup.endpoint, tableState.params, {
         cancelToken: requestSource?.token
      })
         .then((res) => {
            setData(res.data)
            setLoading(false)
         })
         .catch((err) => {
            if (err.message !== 'Operation canceled due to new request.') {
               toast({
                  title: 'Error fetching data',
                  message: 'Please contact support',
                  status: 'error',
                  position: 'top',
                  variant: 'plain'
               })
            }

            setLoading(false)
         })
   }
   useEffect(() => {
      resetFilters()
      setTableState(defaultValues)
      return () => {
         resetFilters()
         setTableState(defaultValues)
      }
   }, [resetFilters, setTableState])

   useEffect(() => {
      if (boxRef.current && !noInitialFetch) {
         fetchData()
      }
   }, [
      tableState.params.pagination,
      tableState.params.page,
      tableState.params.sort,
      tableState.params.order,
      tableState.params.relations,
      tableState.params.filters,
      tableState.params.search,
      tableState.params.date_range
   ])

   useEffect(() => {
      if(summary)
      {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, pagination: 100 }
         }))
      }
   }, [setTableState])

   const onChangePageSize = useCallback(
      (size) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, pagination: size }
         }))
      },
      [setTableState]
   )

   const onChangePage = useCallback(
      (page) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, page }
         }))
      },
      [setTableState]
   )

   const handleRowSelect = useCallback(
      (rows) => {
         let selectedIDs = []
         rows.map((each) => selectedIDs.push(each.original.id))
         setSelected(selectedIDs)
         setTableState((old) => ({
            ...old,
            selected: selectedIDs
         }))
      },
      [setTableState]
   )

   const onSortSelect = useCallback(
      (filter) => {
         if (filter[0]) {
            setTableState((old) => ({
               ...old,
               params: {
                  ...old.params,
                  sort: filter[0].id,
                  order: filter[0].desc ? 'DESC' : 'ASC'
               }
            }))
         }
      },
      [setTableState]
   )

   const onDelete = useCallback(() => {
      setLoading(true)

      Http.delete(setup.endpoint, { data: { id: selected } })
         .then(() => {
            fetchData()
         })
         .catch(() => {
            toast({
               title: 'Error deleting data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
            setLoading(false)
         })
   }, [Http, setup.endpoint, selected, fetchData, toast])

   /* eslint-enable */

   const onSearch = useCallback(
      (value) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, search: value }
         }))
      },
      [setTableState]
   )

   const onTermSearch = useCallback(
      (value, term) => {
         const _filters = { ...tableState.params.filters }
         _filters[term] = value
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: _filters
            }
         }))
      },
      [setTableState]
   )
   return (
      <Flex ref={boxRef} height='auto' direction='column'>
         <Flex
            borderBottom='1px'
            borderColor='global.borderColour'
            align='center'
            bg='global.bg'
            wrap="wrap"
         >
            {/* <Stack
               isInline
               py='8px'
               align='center'
               spacing='3px'
               //minHeight='46px'
               pl='8px'
            > */}
               {setup.canSearch && (
                  <Flex
                  maxWidth='190px'
                  align='center'
                  mb='10px'
                  ml='5px'
               >
                  <DebouncedSearch
                     defaultSearch={tableState.params.search}
                     onSearch={onSearch}
                     term='search'
                     requestSource={requestSource}
                     setRequestSource={setRequestSource}
                  />
               </Flex>
               )}
               {filters && (
                  <>
                      {/* <Stack spacing='3px' isInline> */}
                        {filters.map((filter, i) => (
                           <Flex
                              key={`filter-${i}`}
                              maxWidth='190px'
                              align='center'
                              mb='10px'
                              ml='5px'
                           >
                              {getField(filter)}
                           </Flex>
                        ))}
                        </>
                    
               )}
               {relations && (
                  <Stack spacing='3px' isInline>
                     {relations.map((filter, i) => (
                        <Flex
                           key={`filter-${i}`}
                           maxWidth='190px'
                           align='center'
                        >
                           {getField(filter)}
                        </Flex>
                     ))}
                  </Stack>
               )}

               {setup.canSearch && (
                  <Box>
                     <Button
                        variant='outline'
                        mb='10px'
                        leftIcon={<BiSearch />}
                        isLoading={data && loading}
                        isDisabled={loading}
                        loadingText='Searching...'
                        onClick={() => fetchData()}
                        ml='1'
                     >
                        Search
                     </Button>
                  </Box>
               )}

               {(setup.canSelect === undefined || setup.canSelect) && (
                  <Box fontWeight='medium' fontSize='sm' pl='10px'>
                     {selected.length} items selected
                  </Box>
               )}

               <Box>
                  {setup.canDelete && selected.length !== 0 && (
                     <PopConfirm
                        okText='Delete'
                        onConfirm={() => onDelete()}
                        okButtonProps={{ variantColor: 'error' }}
                        cancelButtonProps={{ variantColor: 'gray' }}
                        title='Are you sure you want to delete these entries?  This cannot be undone.'
                     >
                        <Button
                           leftIcon={<FiTrash />}
                           variant='ghost'
                           size='sm'
                           variantColor='error'
                        >
                           Delete
                        </Button>
                     </PopConfirm>
                  )}
               </Box>
            {/* </Stack> */}
            <Box ml='auto' pr='15px'>
               {extraMenu && <ExtraComponent />}
            </Box>
            { setup.isRetailer && (
               <LinkButton to={`/${setup.model}/create`}>
                  <Button variant='outline' color='gray' size='md' mx='5px' mb='10px'>
                     + Add New {setup.singular}
                  </Button>
               </LinkButton>
            )}
         </Flex>
         {summary ?
         <Flex justify="space-between">
            <Box flex="6">
               <MemoTable
                  accessor={setup.accessor}
                  columns={columns}
                  data={setup.dataKey ? data?.data[setup.dataKey] : data}
                  setup={setup}
                  loading={loading}
                  onChangePageSize={onChangePageSize}
                  onChangePage={onChangePage}
                  onRowSelect={
                     setup.canSelect === undefined
                        ? handleRowSelect
                        : setup.canSelect
                        ? handleRowSelect
                        : null
                  }
                  onSortSelect={onSortSelect}
                  size='sm'
               />
               
            </Box>
            <Box bg='white' ml='5' flex="1" px='5' py='2'>
               <Text fontWeight='bold' mb='3'>Summary</Text>

               <Box border='1px solid #f0f2f5'>
                  <Flex justify='space-between' bg='#f0f2f5' p='2' borderBottom='1px solid #f0f2f5' wrap="wrap">
                     <Text textAlign='center'>Total Sent</Text>
                     <Text textAlign='center' fontWeight='bold'></Text>
                     <Text textAlign='center' fontWeight='bold'>{data?.total}</Text>
                  </Flex>

                  <Flex justify='space-between' bg='white' p='2' wrap="wrap">
                     <Text textAlign='center'>Opened</Text>
                     <Text textAlign='center' fontWeight='bold'>{data?.opened_percent}</Text>
                     <Text textAlign='center' fontWeight='bold'>{data?.opened}</Text>
                  </Flex>

                  <Flex justify='space-between' bg='#f0f2f5' p='2' borderBottom='1px solid #f0f2f5' wrap="wrap">
                     <Text textAlign='center'>Delivered</Text>
                     <Text textAlign='center' fontWeight='bold'>{data?.delivered_percent}</Text>
                     <Text textAlign='center' fontWeight='bold'>{data?.delivered}</Text>
                  </Flex>
               </Box>
            </Box>
         </Flex>
         : 
            <MemoTable
               accessor={setup.accessor}
               columns={columns}
               data={setup.dataKey ? data?.data[setup.dataKey] : data}
               setup={setup}
               loading={loading}
               onChangePageSize={onChangePageSize}
               onChangePage={onChangePage}
               onRowSelect={
                  setup.canSelect === undefined
                     ? handleRowSelect
                     : setup.canSelect
                     ? handleRowSelect
                     : null
               }
               onSortSelect={onSortSelect}
               size='sm'
            />
         }
      </Flex>
   )
}

TableComp.displayName = 'ListView'

export default TableComp

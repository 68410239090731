import React, { useCallback, useEffect, useState, memo } from 'react'
import { useHttp } from '../../../../hooks'
import { FiTrash } from 'react-icons/fi'
import { BiSearch } from 'react-icons/bi'
import { useRecoilState } from 'recoil'
import { tableAtom } from '../../../../state/global'
import {
   Table,
   Box,
   Stack,
   Flex,
   Button,
   useToast,
   PopConfirm,
   Input,
   DatePicker,
   Select,
   ScrollContainer,
   Text
} from '@builtbypixel/plasma'
import TotalsBlock from './TotalsBlock'
import TableLoader from '../../../../components/common/TableLoader'

const MemoTable = memo(
   ({
      id,
      setup,
      columns,
      data = null,
      loading,
      onChangePageSize,
      onChangePage,
      onRowSelect,
      onSortSelect
   }) => {
      return (
         <Box bg='global.elementBg' h='auto' maxHeight='100%'>
            <ScrollContainer>
               {data?.length ? (
                  <Table
                     id={id}
                     accessor={setup.accessor}
                     columns={columns}
                     data={{ data: data }}
                     loading={loading}
                     onChangePageSize={onChangePageSize}
                     onChangePage={onChangePage}
                     onRowSelect={onRowSelect}
                     onSortSelect={onSortSelect}
                     size='sm'
                  />
               ) : !loading ? (
                  <Text m='2rem'>Your trolley is empty.</Text>
               ) : (
                  <TableLoader />
               )}
            </ScrollContainer>
         </Box>
      )
   }
)

MemoTable.displayName = 'MemoTable'

const TableComp = ({
   id,
   setup,
   fetch,
   columns,
   extraMenu = null,
   filters,
   retailer
}) => {
   const [tableState, setTableState] = useRecoilState(tableAtom)

   const Http = useHttp()
   const toast = useToast()
   const ExtraComponent = extraMenu

   const [loading, setLoading] = useState(true)
   const [data, setData] = useState({
      data: []
   })

   const [selected, setSelected] = useState([])
   const setFilter = useCallback(
      (name, value) => {
         const _filters = { ...tableState.params.filters }
         _filters[name] = value
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: _filters
            }
         }))
      },
      [setTableState, tableState.params.filters]
   )

   const getField = (field) => {
      switch (field.component) {
         case 'date':
            return (
               <DatePicker
                  {...field}
                  variant='unstyled'
                  value={tableState.params?.filters[field.name]}
                  onChange={(date) => setFilter(field.name, date)}
               />
            )
         case 'select':
            return (
               <Select
                  value={tableState.params?.filters[field.name]}
                  options={field.options}
                  onChange={(value) => setFilter(field.name, value)}
                  {...field}
               />
            )
         default:
            return null
      }
   }

   /* eslint-disable  */
   const fetchData = () => {
      setLoading(true)

      Http.get(setup.endpoint)
         .then((res) => {
            setData(res.data)
            setLoading(false)
         })
         .catch(() => {
            toast({
               title: 'Error fetching data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
            setLoading(false)
         })
   }

   useEffect(() => {
      fetchData()
   }, [
      tableState.params.pagination,
      tableState.params.page,
      tableState.params.sort,
      tableState.params.order,
      fetch
   ])

   const onChangePageSize = useCallback(
      (size) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, pagination: size }
         }))
      },
      [setTableState]
   )

   const onChangePage = useCallback(
      (page) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, page }
         }))
      },
      [setTableState]
   )

   const handleRowSelect = useCallback(
      (rows) => {
         let selectedIDs = []
         rows.map((each) => selectedIDs.push(each.original.pack_id))
         setSelected(selectedIDs)
         setTableState((old) => ({
            ...old,
            selected: selectedIDs
         }))
      },
      [setTableState]
   )

   const onSortSelect = useCallback(
      (filter) => {
         if (filter[0]) {
            setTableState((old) => ({
               ...old,
               params: {
                  ...old.params,
                  sort: filter[0].id,
                  order: filter[0].desc ? 'DESC' : 'ASC'
               }
            }))
         }
      },
      [setTableState]
   )

   const onDelete = useCallback(() => {
      setLoading(true)
      //console.log('selected', selected)
      Http.delete(`trolley/${retailer}/remove`, { data: { id: selected } })
         .then(() => {
            fetchData()
         })
         .catch(() => {
            toast({
               title: 'Error deleting data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
            setLoading(false)
         })
   }, [Http, setup.endpoint, selected, fetchData, toast])

   /* eslint-enable */

   const onSearch = useCallback(
      (value) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, search: value }
         }))
      },
      [setTableState]
   )
   const handleProcessOrder = (data) => {
      data.retailer = { id: id }
      Http.post('/order', data)
         .then((res) => {
            fetchData()
            toast({
               title: 'Order placed',
               status: 'success',
               position: 'top',
               variant: 'plain'
            })
         })
         .catch((err) => {
            toast({
               title: 'Error processing data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
         })
   }

   const TrolleyTotals = () => {
      return (
         <Stack spacing='10px' isInline mb='10px'>
            <TotalsBlock
               label='Total'
               value={`£${data?.data?.total?.toFixed(2) ?? 0}`}
            />
            <TotalsBlock
               label='Sub Total'
               value={`£${data?.data?.sub_total?.toFixed(2) ?? 0}`}
            />
            <TotalsBlock
               label='VAT'
               value={`£${
                  data?.data?.vat ? parseFloat(data?.data?.vat).toFixed(2) : 0
               }`}
            />
            <TotalsBlock
               label='Savings'
               value={`£${data?.data?.savings?.toFixed(2) ?? 0}`}
            />
            <TotalsBlock
               label='Items'
               value={data?.data?.products?.length ?? 0}
            />
         </Stack>
      )
   }

   return (
      <Flex height='auto' direction='column'>
         <TrolleyTotals id={id} />
         <Flex
            borderBottom='1px'
            borderColor='global.borderColour'
            align='center'
            bg='global.bg'
         >
            <Stack isInline py='8px' align='center' spacing='3px' pl='8px'>
               {setup.canSearch && (
                  <Box>
                     <Input
                        minWidth={240}
                        placeholder='Search...'
                        value={tableState.params.search}
                        px='15px'
                        onChange={(e) => onSearch(e.target.value)}
                     />
                  </Box>
               )}
               {filters && (
                  <Stack spacing='3px' isInline>
                     {filters.map((filter, i) => (
                        <Box key={`filter-${i}`} maxWidth='190px'>
                           {getField(filter)}
                        </Box>
                     ))}
                  </Stack>
               )}
               {setup.canSearch && (
                  <Box>
                     <Button
                        variant='outline'
                        leftIcon={<BiSearch />}
                        isLoading={data && loading}
                        isDisabled={loading}
                        loadingText='Searching...'
                        onClick={() => fetchData()}
                     >
                        Search
                     </Button>
                  </Box>
               )}

               {(setup.canSelect === undefined || setup.canSelect) && (
                  <Box fontWeight='medium' fontSize='sm' pl='10px'>
                     {selected.length} items selected
                  </Box>
               )}

               <Box>
                  {setup.canDelete && selected.length !== 0 && (
                     <PopConfirm
                        okText='Delete'
                        onConfirm={() => onDelete()}
                        okButtonProps={{ variantColor: 'error' }}
                        cancelButtonProps={{ variantColor: 'gray' }}
                        title='Are you sure you want to delete these entries?  This cannot be undone.'
                     >
                        <Button
                           leftIcon={<FiTrash />}
                           variant='ghost'
                           size='sm'
                           variantColor='error'
                        >
                           Delete
                        </Button>
                     </PopConfirm>
                  )}
               </Box>
            </Stack>
            <Box ml='auto' pr='15px'>
               {extraMenu && <ExtraComponent />}
            </Box>
         </Flex>

         <MemoTable
            fetchData={fetchData}
            accessor={setup.accessor}
            columns={columns}
            data={data?.data?.products}
            setup={setup}
            loading={loading}
            onChangePageSize={onChangePageSize}
            onChangePage={onChangePage}
            onRowSelect={
               setup.canSelect === undefined
                  ? handleRowSelect
                  : setup.canSelect
                  ? handleRowSelect
                  : null
            }
            onSortSelect={onSortSelect}
            size='sm'
         />
      </Flex>
   )
}

TableComp.displayName = 'ListView'

export default TableComp

import React from 'react'
import LinkButton from '../../components/LinkButton'
import ListWrapper from '../../components/ListView'
import { IconButton, Text, Button, Flex } from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import dayjs from 'dayjs'
import ViewTitle from '../../components/common/ViewTitle'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
      {
         Header: () => null,
         id: 'edit',
         width: '50px',
         Cell: ({ row }) => (
            <LinkButton
               to={`${setup.model}/edit/${row.original[setup.accessor]}`}
            >
               <IconButton variant='ghost' variantColor='primary' rounded='md'>
                  <FiEdit fontSize={18} />
               </IconButton>
            </LinkButton>
         )
      },
      {
         Header: 'Name',
         accessor: 'name'
      },
      {
         Header: 'Tasks in Category',
         accessor: 'tasks_in_category'
      }
   ]

   return (
      <>
         <Flex mb='20px' align='center'>
            <ViewTitle title={setup.title} />
            <LinkButton to={`/${setup.model}/create`}>
               <Button variantColor='success' color='white' ml='auto'>
                  Add New {setup.singular}
               </Button>
            </LinkButton>
         </Flex>
         <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
      </>
   )
}

export default List

import { useHttp } from '.'

const debounce = require('lodash.debounce')

const Http = useHttp()

export const useProducts = debounce((inputValue, callback) => {
   Http.post('products', { filters: { product: inputValue } }).then((res) => {
      return callback(res.data.data)
   })
}, 500)

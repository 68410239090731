import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { Grid, Box, Flex } from '@builtbypixel/plasma'

import { useGet } from '../../../hooks'

import LinesTable from './LinesTable'
import NavBar from './NavBar'
import SelectableItem from '../components/SelectableItem'
import SendReports from '../components/SendReports'

/**
 * The page that renders the RDA reports
 * @param {string} searchText The text searching for GoLocalPeriods
 */
const Comp = ({ searchText }) => {
   const [goLocalPeriods, setGoLocalPeriods] = useState([])
   const [selectedGoLocalPeriod, setSelectedGoLocalPeriod] = useState(null)
   const [rdas, setRdas] = useState(null)
   const [selectedRda, setSelectedRda] = useState(null)
   const [executions, setExecutions] = useState(null)
   const [selectedExecution, setSelectedExecution] = useState(null)

   const { data } = useSWR(`/rda-executions`, useGet, {
      suspense: true
   })

   useEffect(() => {
      if (data) {
         setGoLocalPeriods(data.data)
      }
   }, [data])

   useEffect(() => {
      if (searchText) {
         setGoLocalPeriods(
            data.data.filter((item) =>
               item?.name?.name.toLowerCase().includes(searchText.toLowerCase())
            )
         )
      } else {
         setGoLocalPeriods(data.data)
      }
   }, [searchText, data])

   const handleGoLocalPeriodClick = (goLocalPeriod) => {
      setSelectedGoLocalPeriod(goLocalPeriod)
      setRdas(goLocalPeriod?.rdas)
   }

   const handleRdaClick = (rda) => {
      setSelectedRda(rda)
      setExecutions(rda?.executions)
   }

   return (
      <>
         <Flex justify='space-between'>
            <NavBar
               selectedGoLocalPeriod={selectedGoLocalPeriod}
               setSelectedGoLocalPeriod={setSelectedGoLocalPeriod}
               selectedRda={selectedRda}
               setSelectedRda={setSelectedRda}
               selectedExecution={selectedExecution}
               setSelectedExecution={setSelectedExecution}
            />
            {selectedExecution && (
               <SendReports selectedExecution={selectedExecution} />
            )}
         </Flex>

         <Box mt='25px'>
            <Grid templateColumns='repeat(3, 1fr)' gap='15px 15px'>
               {!selectedGoLocalPeriod
                  ? goLocalPeriods?.map((goLocalPeriod, index) => (
                       <SelectableItem
                          backgroundColor='parfettsGreen'
                          onClick={() =>
                             handleGoLocalPeriodClick(goLocalPeriod)
                          }
                          textLeft={goLocalPeriod?.name?.name}
                          key={index}
                       />
                    ))
                  : !selectedRda
                  ? rdas?.map((rda, index) => (
                       <SelectableItem
                          backgroundColor='parfettsBlue'
                          onClick={() => handleRdaClick(rda)}
                          textLeft={rda?.first_name + ' ' + rda?.last_name}
                          textRight={rda?.total_lines}
                          key={index}
                       />
                    ))
                  : !selectedExecution
                  ? executions?.map((execution, index) => (
                       <SelectableItem
                          backgroundColor='parfettsOrange'
                          onClick={() => setSelectedExecution(execution)}
                          textLeft={execution?.brand?.name}
                          textRight={execution?.lines?.length}
                          key={index}
                       />
                    ))
                  : null}
            </Grid>

            {selectedExecution && (
               <LinesTable currentLine={selectedExecution} />
            )}
         </Box>
      </>
   )
}

export default Comp

import React, { useMemo, useState, useEffect } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { useHttp } from '../../hooks'
const EditForm = () => {

   const [status, setStatus] = useState([]);
   const Http = useHttp()

   useEffect(() => {
      Http.get(`/competition-statuses`).then((res) =>
         setStatus(res.data.data)
      )
   }, [Http])

   const fields = useMemo(
      () => [
         {
            group: 'Competition Details',
            tab: 'Details',
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'entries_amount',
                  component: 'text',
                  type: 'number',
                  placeholder: 'Entries Amount',
                  label: 'Entries Amount',
                  help: 'Amount of entries before there is a winner',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'status',
                  component: 'select',
                  placeholder: 'Select Status',
                  label: 'Status',
                  options: status,
                  labelKey: 'name',
                  labelValue: 'id',
                  isRequired: true,
                  rules: { required: true }
               }
            ]
         }
      ],
      [status]
   )

   return (
      <>
         <EditView
            fields={fields}
            setup={setup}
            noPadding
         >
            <AutoFields fields={fields} />
         </EditView>
      </>
   )
}

export default EditForm

import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@builtbypixel/plasma'
import { FiExternalLink } from 'react-icons/fi'
import EditMediaPreview from './EditMediaPreview'

const EditMessagePreview = ({ source, data }) => {
   const headerText = data?.[0]?.text
   const bodyText = data?.[1]?.text
   const footerText = data?.[2]?.text
   const buttons = data?.[3]?.buttons

   const variablesHeader = data?.[0]?.example?.header_text
   const variablesBody = data?.[1]?.example?.body_text

   const headerFormat = data?.[0]?.format

   const [header, setHeader] = useState(null)
   const [body, setBody] = useState(null)

   useEffect(() => {
      setBody(bodyText)
   }, [bodyText])

   useEffect(() => {
      setHeader(headerText)
   }, [headerText])

   useEffect(() => {
      if (variablesHeader) {
         const replacedHeader = headerText?.replaceAll(
            `{{${1}}}`,
            variablesHeader ? variablesHeader : `{${1}}`
         )

         setHeader(replacedHeader)
      }
   }, [headerText, variablesHeader])

   useEffect(() => {
      if (variablesBody?.[0]?.length > 0) {
         const replacedBody = variablesBody?.[0]?.reduce(
            (body, variable, i) =>
               body.replaceAll(
                  `{{${i + 1}}}`,
                  variable ? variable : `{{${i + 1}}}`
               ),
            bodyText
         )
         setBody(replacedBody)
      }
   }, [bodyText, variablesBody])

   return (
      <Box
         backgroundImage={'url("/images/whatsappBg.jpeg")'}
         backgroundSize='cover'
         backgroundRepeat='no-repeat'
         h='600px'
         w='300px'
      >
         <Box
            mt='2rem'
            ml='1rem'
            p='1rem'
            w='90%'
            style={{
               backgroundColor: 'white',
               diplay: 'flex',
               flexDirection: 'row'
            }}
         >
            <Box
               style={{
                  backgroundColor: 'white',
                  diplay: 'flex',
                  flexDirection: 'row',
                  fontSize: '15px'
               }}
            >
               {headerFormat === 'TEXT' || headerFormat?.value === 'TEXT' ? (
                  <Text as='strong'>{header}</Text>
               ) : headerFormat ? (
                  <EditMediaPreview headerFormat={headerFormat} data={data} />
               ) : null}
            </Box>
            <Box
               style={{
                  backgroundColor: 'white',
                  diplay: 'flex',
                  flexDirection: 'row',
                  whiteSpace: 'pre-line',
                  fontSize: '15px'
               }}
            >
               {body}
            </Box>
            <Box
               style={{
                  backgroundColor: 'white',
                  diplay: 'flex',
                  flexDirection: 'row',
                  whiteSpace: 'pre-line',
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontSize: '13px',
                  marginBottom: '0.8em'
               }}
            >
               {footerText}
            </Box>
            {buttons?.length > 0 && (
               <Flex
                  background='white'
                  borderTop='1px solid #dadde1'
                  justify='center'
               >
                  <a href={buttons[0].url}>
                     <Flex align='center' marginTop='0.2em'>
                        <FiExternalLink color='#00a5f4' />
                        <Text ml='0.2em' color='#00a5f4'>
                           {buttons[0].text}
                        </Text>
                     </Flex>
                  </a>
               </Flex>
            )}
         </Box>
      </Box>
   )
}

export default EditMessagePreview

import React, { useMemo } from 'react'
import { AutoFields } from '../../components/Form'
import EditView from '../../components/EditView'
import setup from './setup'
import { Tag } from '@builtbypixel/plasma'

const EditForm = () => {
   const formatOptionLabel = (option) => {
      return (
         <Tag variantColor={option.value} variant='solid' mr='10px'>
            {option.name}
         </Tag>
      )
   }
   const fields = useMemo(
      () => [
         {
            group: '',
            fields: [
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Name',
                  label: 'Name',
                  rules: { required: true }
               },
               {
                  name: 'colour',
                  component: 'select',
                  placeholder: 'Select colour',
                  labelKey: 'name',
                  valueKey: 'value',
                  options: [
                     {
                        id: 1,
                        name: 'Green',
                        value: 'parfettsGreen'
                     },

                     {
                        id: 2,
                        name: 'Red',
                        value: 'parfettsRed'
                     },
                     {
                        id: 3,
                        name: 'Orange',
                        value: 'parfettsOrange'
                     },
                     {
                        id: 4,
                        name: 'Blue',
                        value: 'parfettsBlue'
                     },
                     {
                        id: 5,
                        name: 'Light Green',
                        value: 'parfettsGreenSublte'
                     },
                     {
                        id: 6,
                        name: 'Light Blue',
                        value: 'parfettsBlueHighlight'
                     },
                     {
                        id: 7,
                        name: 'Gray',
                        value: 'parfettsGrayText'
                     }
                  ],
                  formatOptionLabel: formatOptionLabel,
                  rules: { required: true }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup} noPadding>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm

import React from 'react'
import { Box, Text, Flex } from '@builtbypixel/plasma'

const renderToolTip = function ({ active, payload }) {
   if (!active) return null
   return (
      <Box
         bg='global.elementBg'
         p='10px'
         border='solid 1px'
         borderColor={payload?.[0]?.color}
      >
         {payload?.map((each, i) => (
            <Flex key={'tool-tip-three' + i}>
               <Text color={each?.color} fontWeight='semibold'>
                  {each.name}:
               </Text>{' '}
               <Text ml='2px'>
                  {' '}
                  £
                  {parseFloat(each?.value).toLocaleString(undefined, {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2
                  })}{' '}
               </Text>
            </Flex>
         ))}
      </Box>
   )
}

export default renderToolTip

import React, {useState} from 'react';
import { Field } from '../../../components/Form';
import {Flex, Switch, Text} from '@builtbypixel/plasma'

const ProofOfId = () => {

    const [showComponent, setShowComponent] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const showIdField = (e) => {
        setSelectedValue(e.target.checked);
        setShowComponent(e.target.checked);
    }

    const options = [
        {
            id: 1,
            name: "Yes"
        },
        {
            id: 2,
            name: "No"
        }
    ];

    return (
        <Flex style={{gap: '10px'}} direction="column">
            <Switch
                placeholder='ID Required'
                label='ID Required'
                value={selectedValue}
                size='sm'
                onChange={(e) => console.log('dskfnwek', e.target.checked)  || showIdField(e) }
            >Photo ID Required?</Switch>

            {showComponent && (
                <Field
                    component='file'
                    name='photo_id'
                    max={1}
                    placeholder='Photo ID'
                    label='Photo ID'
                    size='lg'
                    rules={{
                        required: true,
                        message: 'You must upload a photo ID'
                    }}
                />
            )}
        </Flex>
    )
}

export default ProofOfId;
import React, { useMemo, useState, useEffect } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { useHttp, useRetailers } from '../../hooks'

import { Box, Flex, Button, Text } from '@builtbypixel/plasma'

import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import setup from './setup'

const columns = [
   {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row, value }) => (
         <a href={`/whatsapp-templates/edit/${row.original.id}`}>{value}</a>
      )
   },
   {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ value }) => value
   },
   {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => value
   }
]

const Comp = () => {
   const Http = useHttp()
   const [branches, setBranches] = useState([])
   const [priceSchemes, setPriceSchemes] = useState([])

   const formatRetailerLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.id}</span>{' '}
         {' - ' + option.branch?.name}
      </Text>
   )

   const formatBranchLabel = (option) => <Text>{option.name}</Text>

   const formatPriceSchemeLabel = (option) => (
      <Text>
         <span style={{ fontWeight: 'bold' }}>{option.scheme}</span> {' - '}{' '}
         {option?.branch?.name}
      </Text>
   )

   useEffect(() => {
      Http.get('/branches').then((res) => setBranches(res?.data?.data))
   }, [Http])

   useEffect(() => {
      Http.get('/price-schemes').then((res) => setPriceSchemes(res?.data?.data))
   }, [Http])

   const filters = useMemo(
      () => [
         {
            name: 'customer_id',
            component: 'select',
            placeholder: 'Filter by Retailer',
            isAsync: true,
            loadOptions: useRetailers,
            labelKey: 'first_name',
            valueKey: 'id',
            formatOptionLabel: formatRetailerLabel
         },
         {
            name: 'branch_id',
            component: 'select',
            placeholder: 'Filter by Branch',
            options: branches,
            labelKey: 'name',
            valueKey: 'id',
            formatOptionLabel: formatBranchLabel
         },
         {
            name: 'is_ordering',
            component: 'select',
            placeholder: 'Retailer Type',
            isAsync: false,
            options: [
               {
                  name: 'Ordering',
                  value: true
               },
               {
                  name: 'Non-Ordering',
                  value: false
               }
            ],
            labelKey: 'name',
            valueKey: 'value'
         },
         {
            name: 'price_scheme',
            component: 'select',
            placeholder: 'Filter by Price Scheme',
            options: priceSchemes,
            labelKey: 'scheme',
            valueKey: 'id',
            formatOptionLabel: formatPriceSchemeLabel
         },
         {
            name: 'from_date',
            component: 'date',
            placeholder: 'From Date'
         },
         {
            name: 'to_date',
            component: 'date',
            placeholder: 'To Date'
         }
      ],
      [branches, priceSchemes]
   )

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='WhatsApp Templates' />
            </Box>
            <Flex>
               <LinkButton to={`/${setup.model}/create`}>
                  <Button ml='5px' bg='parfettsRed' color='white'>
                     Add New Template
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Flex justify='space-between'>
            <Box flex='6'>
               <Table
                  columns={columns}
                  setup={setup}
                  onRowSelect={null}
                  filters={filters}
               />
            </Box>
         </Flex>
      </>
   )
}

export default Comp

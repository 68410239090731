const modelName = 'Products'
const modelNameSingular = 'Product'
const endpoint = '/products'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id',
  permissions: ['Admin','RDA']
}

export default setup

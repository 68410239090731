import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Page from '../components/Page'
import { hasAuthToken, checkPermissions } from '../helpers'
import { useRecoilValue } from 'recoil'
import { globalAtom } from '../state/global'
import UnAuthorised from '../components/common/UnAuthorised'
const PrivateRoute = ({
   component: Component,
   title,
   padding,
   permissions,
   ...rest
}) => {
   const { user } = useRecoilValue(globalAtom)
   return (
      <Route
         {...rest}
         render={(props) =>
            hasAuthToken() ? (
               <Page title={title} padding={padding} auth>
                  {user &&
                  checkPermissions({
                     has: user?.role?.name,
                     required: permissions
                  }) ? (
                     <Component {...props} />
                  ) : (
                     <UnAuthorised />
                  )}
               </Page>
            ) : (
               <Redirect
                  to={{
                     pathname: '/',
                     state: { from: props.location }
                  }}
               />
            )
         }
      />
   )
}

export default PrivateRoute

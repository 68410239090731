import React, { useMemo, useState, useEffect, useRef } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import { useHttp, useRetailers } from '../../hooks'

import {
   Box,
   IconButton,
   Flex,
   Button,
   Text,
   useToast,
   Progress,
   Image
} from '@builtbypixel/plasma'

import Table from '../../components/ListView'
import LinkButton from '../../components/LinkButton'
import setup from './setup'

const columns = [
   {
      Header: '',
      accessor: 'id',
      Cell: ({ row }) => (
         <Image
            alt="Image"
            fallbackSrc="https://via.placeholder.com/150"
            mr="20px"
            objectFit="cover"
            size="50px"
            src={row?.original?.qr_code?.url}
         />
      )
   },
   {
      Header: 'Name',
      accessor: 'name'
   },
   {
      Header: 'Winner Limit',
      accessor: 'entries_amount'
   },
   {
      Header: 'Total Entries',
      accessor: 'total_entries'
   },
   {
      Header: 'Total Winners',
      accessor: 'total_winners'
   },
   {
      Header: 'Status',
      accessor: 'status'
   }
]

const Comp = () => {
   
   const filters = [];

   return (
      <>
         <Flex mb='20px' align='center' justify='space-between'>
            <Box>
               <ViewTitle title='Competitions' />
            </Box>
            <Flex>
               <LinkButton to={`/${setup.model}/create`}>
                  <Button ml='5px' bg='parfettsRed' color='white'>
                     Create New Competition
                  </Button>
               </LinkButton>
            </Flex>
         </Flex>
         <Flex justify='space-between'>
            <Box flex="6">
               <Table
                  columns={columns}
                  setup={setup}
                  onRowSelect={null}
                  filters={filters}
               />
            </Box>
         </Flex>
      </>
   )
}

export default Comp

import { createCrudRoutes } from '../../hooks'
import Developments from './'
import Edit from './Edit'
import setup from './setup'
import List from './List'


const crud = createCrudRoutes({
  setup,
  name: setup.title,
  edit: Edit,
  list: Developments,
  permissions: ['Admin','RDA'],
  view: null,
})


const otherRoutes = [
  {
     path: '/developments/create',
     title: 'New Store Development',
     exact: false,
     auth: true,
     component: Edit,
     permissions: ['Admin','RDA']
  },

 {
  path: '/developments/list/:completed',
  title: 'View Developments',
  exact: false,
  auth: true,
  padding: false,
  component: List,
  permissions: ['Admin','RDA']
}
]

const routes = [...crud, ...otherRoutes]


export default routes
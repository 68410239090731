import React, { useEffect, useState } from 'react'
import { Field } from '../../../components/Form'

import { useFormContext, useWatch } from 'react-hook-form'
import { Box, Card, Flex, Grid, Select, Text } from '@builtbypixel/plasma'
import TemplateHeader from './TemplateComponents/TemplateHeader'
import TemplateBody from './TemplateComponents/TemplateBody'
import TemplateFooter from './TemplateComponents/TemplateFooter'
import TemplateHiddenFields from './TemplateComponents/TemplateHiddenFields'
import Title from './TemplateComponents/Title'
import TemplateButtons from './TemplateComponents/TemplateButtons'

const CreateTemplateForm = () => {
   const { setValue, register, watch, control, getValues } = useFormContext()

   useEffect(() => {
      register('content')
   }, [])

   return (
      <>
         <Flex
            direction='column'
            align='center'
            gap={2}
            maxWidth='50%'
            width='100%'
         >
            <TemplateHiddenFields />
            <Flex w='100%' direction='column'>
               <Title text='Template Name' />
               <Field
                  component='text'
                  name='name'
                  placeholder='Enter Template Name'
                  rules={{ required: true }}
                  width='100%'
                  defaultValue={() => getValues('name')}
               />
            </Flex>
            <TemplateHeader />
            <TemplateBody />
            <TemplateFooter />
            <TemplateButtons />
         </Flex>
      </>
   )
}

export default CreateTemplateForm

import React, { useEffect, useState } from 'react'
import { Field } from '../../../components/Form'
import { Box, Flex, Input, Text } from '@builtbypixel/plasma'
import { useFormContext, useWatch } from 'react-hook-form'
import File from '../../../Fields/File'

const EditMessageUploads = ({
   media,
   fileName,
   messageSent,
   setContentHeader
}) => {
   const [filename, setFilename] = useState('')
   const [file, setFile] = useState(null)


   useEffect(() => {
      if ( filename)
         setContentHeader((old) => {
            return {
               ...old,
               example: {
                  ...old.example,
                  file_name: filename
               }
            }
         })
   }, [ filename])

   useEffect(() => {
      if (file)
         setContentHeader((old) => {
            return {
               ...old,
               example: {
                  ...old.example,
                  header_handle: file ? file : null,
               }
            }
         })
   }, [file])

   return (
      <Flex w='100%' direction='column' p='1em'>
         <Text>Media Upload</Text>
         <Box width='100%'>
            <File
               max={messageSent === true ? 0 : 1}
               value={file ? file : media ? [media] : []}
               onChange={(file) => setFile(file)}
            />
            <Input
               placeholder='Enter Filename'
               onChange={(e) => setFilename(e.target.value)}
               value={filename ? filename : fileName}
               disabled={messageSent}
            />
         </Box>
      </Flex>
   )
}

export default EditMessageUploads

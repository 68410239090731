import React from 'react'
import {
    Box,
    useTheme,
    Text
} from '@builtbypixel/plasma'
import {
    PieChart,
    Pie,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Cell
} from 'recharts'
const RADIAN = Math.PI / 180;


const UpcomingChart = ({ data }) => {
    const { current_year, last_year } = data;
    const theme = useTheme()
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, value, index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                Current Year: {value}
            </text>
        );
    };
    let renderInnerLabel = function (entry) {
        const radius = entry.innerRadius + (entry.outerRadius - entry.innerRadius) * 0.5;
        const x = entry.cx + radius * Math.cos(-entry.midAngle * RADIAN);
        const y = entry.cy + radius * Math.sin(-entry.midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > entry.cx ? 'start' : 'end'} dominantBaseline="central">
                {(entry.percent * 100).toFixed(0)}%
            </text>

        )
    }
    let renderLabel = function (entry) {
        return entry.name + ": " + (entry.percent * 100).toFixed(0) + "%"; //(" + entry.value + ")";
    }
    const COLORS = [theme.colors.parfettsOrange, theme.colors.parfettsGreen, theme.colors.parfettsRed, theme.colors.parfettsBlue];

    return (
        <Box>
            <ResponsiveContainer width='100%' height={300}>
                <PieChart height={300} data={data} margin={0}>
                    <Tooltip />
                    <Legend />
                    <Pie
                        data={last_year}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={50}
                        fill={theme.colors.parfettsOrange}
                        legendType="none"
                        label={renderInnerLabel}
                    >
                        {
                            current_year.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Pie
                        data={current_year}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={80}
                        fill={theme.colors.parfettsGreen}
                        label={renderLabel}
                        legendType='circle'
                    >
                        {
                            current_year.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>

                </PieChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default UpcomingChart

import React from 'react'
import { Button, Modal, ModalButton } from '@builtbypixel/plasma'

import Content from './Content'

/**
 * A modal that prompt the user to confirm they are in the store,
 * and they can also add an optional note for the visit.
 */
const SubmitAudit = (props) => {
   return (
      <Modal>
         <ModalButton>
            <Button
               variant='solid'
               variantColor='green'
               color='white'
               mr='10px'
               mt='auto'
            >
               Submit Audit
            </Button>
         </ModalButton>
         <Content {...props} />
      </Modal>
   )
}

export default SubmitAudit

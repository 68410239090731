import { atom } from 'recoil'

export const facebookUserAtom = atom({
   key: 'facebookUserAtom',
   default: {
      userID: false,
   }
})

export const facebookPageAtom = atom({
    key: 'facebookPageAtom',
    default: {
       pages:[]
    }
 })
import React, {useEffect} from 'react'
import { Text } from '@builtbypixel/plasma'
import { Field } from '../../../components/Form';
import { useFormContext } from 'react-hook-form';

const SelectRDA = ({rdas, currentRda}) => {

    const {setValue} = useFormContext();

    useEffect(() => {

        setValue('representative_rda', currentRda)

    }, [currentRda])

    const formatOptionLabel = (option) => (
        <>
        <Text display='inline'>{option?.branch?.name} -{' '}</Text>
        <Text fontWeight='bold' display='inline'>
            {option?.name}
        </Text>
        </>
    )

    return (
        <>
            <Field
                name='representative_rda'
                component='select'
                placeholder='Representative RDA'
                label='Representative RDA'
                options={rdas}
                labelKey='name'
                formatOptionLabel={formatOptionLabel}
                isRequired={true}
            />
        </>
    )
}

export default SelectRDA

import React, { useState } from 'react'
import {
   Box,
   Text,
   IconButton,
   Flex,
   Button,
   useTheme,
   Select
} from '@builtbypixel/plasma'
import { useRecoilState } from 'recoil'
import calendarState from '../utils/calendarState'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { taskColour } from '../utils/taskColour'
import { useRDAS, useHasAdminPrivileges } from '../../../../../hooks'

const dayjs = require('dayjs')

const CalendarHeader = (props) => {
   const [calendar, setCalendar] = useRecoilState(calendarState)
   const [currentUser, setCurrentUser] = useState(null)
   const theme = useTheme()

   const formatOptionLabel = (option) =>
      option.first_name + ' ' + option.last_name

   const handleChangeUser = (value, triggeredAction) => {
      setCurrentUser(value)
      if (triggeredAction.action === 'clear') {
         setCurrentUser(null)
         props.setFilterUser(null)
      } else {
         setCurrentUser(value)
         props.setFilterUser(value)
      }
   }
   return (
      <Box mb='10px'>
         <Flex m='20px 0' alignItems='center' w='100%' justify='space-between'>
            <Text
               fontWeight='semibold'
               fontSize='20px'
               as='h1'
               minWidth='150px'
            >
               {calendar.mode === 'month' &&
                  dayjs(calendar.selectedDate).format('MMMM YYYY')}
               {calendar.mode === 'week' &&
                  dayjs(calendar.selectedDate)
                     .startOf('week')
                     .format('DD MMMM') +
                     ' - ' +
                     dayjs(calendar.selectedDate)
                        .endOf('week')
                        .format('DD MMMM YYYY')}
               {calendar.mode === 'day' &&
                  dayjs(calendar.selectedDate).format('dddd, DD MMMM YYYY')}
            </Text>
            {useHasAdminPrivileges() && (
               <Box w='250px'>
                  <Select
                     name='user_id'
                     component='select'
                     placeholder='View calendar for...'
                     isAsync={true}
                     loadOptions={useRDAS}
                     labelKey='first_name'
                     valueKey='id'
                     value={currentUser}
                     onChange={handleChangeUser}
                     formatOptionLabel={formatOptionLabel}
                     variant='flushed'
                  />
               </Box>
            )}
         </Flex>

         <Flex justify='space-between'>
            <Flex>
               <IconButton
                  variant='outline'
                  roundedLeft='md'
                  onClick={() =>
                     setCalendar((cal) => ({
                        ...cal,
                        selectedDate: dayjs(cal.selectedDate).subtract(
                           1,
                           calendar.mode
                        )
                     }))
                  }
                  icon={<MdKeyboardArrowLeft />}
               />
               <Button
                  variant='outline'
                  rounded='none'
                  borderLeft='0px'
                  borderRight='0px'
                  onClick={() =>
                     setCalendar((cal) => ({ ...cal, selectedDate: dayjs() }))
                  }
               >
                  Today
               </Button>
               <IconButton
                  variant='outline'
                  roundedRight='md'
                  onClick={() =>
                     setCalendar((cal) => ({
                        ...cal,
                        selectedDate: dayjs(cal.selectedDate).add(
                           1,
                           calendar.mode
                        )
                     }))
                  }
                  icon={<MdKeyboardArrowRight />}
               />
            </Flex>
            <Flex>
               <Flex
                  mr='20px'
                  alignItems='center'
                  cursor='pointer'
                  onClick={() =>
                     setCalendar((cal) => ({
                        ...cal,
                        showTasks: !cal.showTasks
                     }))
                  }
               >
                  <Box
                     rounded='full'
                     size='15px'
                     bg={calendar.showTasks && taskColour('task', theme)}
                     border='solid 1px'
                     borderColor={taskColour('task', theme)}
                     minWidth='15px'
                     mr='2px'
                  ></Box>
                  <Text>Tasks</Text>
               </Flex>

               <Flex
                  alignItems='center'
                  cursor='pointer'
                  onClick={() =>
                     setCalendar((cal) => ({
                        ...cal,
                        showMilestones: !cal.showMilestones
                     }))
                  }
               >
                  <Box
                     rounded='full'
                     size='15px'
                     bg={
                        calendar.showMilestones &&
                        taskColour('milestone', theme)
                     }
                     border='solid 1px'
                     borderColor={taskColour('milestone', theme)}
                     minWidth='15px'
                     mr='2px'
                  ></Box>
                  <Text>Milestones</Text>
               </Flex>
               <Flex
                  ml='20px'
                  alignItems='center'
                  cursor='pointer'
                  onClick={() =>
                     setCalendar((cal) => ({
                        ...cal,
                        showVisits: !cal.showVisits
                     }))
                  }
               >
                  <Box
                     rounded='full'
                     size='15px'
                     bg={calendar.showVisits && taskColour('visit', theme)}
                     border='solid 1px'
                     borderColor={taskColour('visit', theme)}
                     minWidth='15px'
                     mr='2px'
                  ></Box>
                  <Text>Visits</Text>
               </Flex>
            </Flex>
            <Flex>
               <Button
                  variant={calendar.mode === 'month' ? 'outline' : 'solid'}
                  rounded='none'
                  borderRight='0px'
                  boxShaddow='none'
                  onClick={() =>
                     setCalendar((cal) => ({ ...cal, mode: 'month' }))
                  }
               >
                  Month
               </Button>
               <Button
                  variant={calendar.mode === 'week' ? 'outline' : 'solid'}
                  rounded='none'
                  borderLeft='0px'
                  onClick={() =>
                     setCalendar((cal) => ({ ...cal, mode: 'week' }))
                  }
               >
                  Week
               </Button>
               <Button
                  variant={calendar.mode === 'day' ? 'outline' : 'solid'}
                  rounded='none'
                  borderLeft='0px'
                  onClick={() =>
                     setCalendar((cal) => ({ ...cal, mode: 'day' }))
                  }
               >
                  Day
               </Button>
            </Flex>
         </Flex>
      </Box>
   )
}

export default CalendarHeader

import React from 'react'
import { Field } from '../../../../components/Form'
import { Flex } from '@builtbypixel/plasma'
import { useWatch } from 'react-hook-form'

const TemplateHiddenFields = () => {
   return (
      <Flex h='1em'>
         <Field
            component='text'
            name='content[0].type'
            rules={{ required: true }}
            defaultValue='HEADER'
            hidden
         />

         <Field
            component='text'
            name='content[1].format'
            rules={{ required: true }}
            defaultValue='TEXT'
            hidden
         />
         <Field
            component='text'
            name='content[1].type'
            rules={{ required: true }}
            defaultValue='BODY'
            hidden
         />

         <Field
            component='text'
            name='content[2].type'
            rules={{ required: true }}
            defaultValue='FOOTER'
            hidden
         />
         <Field
            component='text'
            name='content[2].format'
            rules={{ required: true }}
            defaultValue='TEXT'
            hidden
         />
         <Field
            component='text'
            name='content[3].type'
            rules={{ required: true }}
            defaultValue='BUTTONS'
            hidden
         />
         <Field
            component='text'
            name='content[3].buttons[0].type'
            rules={{ required: true }}
            defaultValue='URL'
            hidden
         />

         {/* <Field
            component='text'
            name='content[3].buttons[1].type'
            rules={{ required: true }}
            defaultValue='PHONE_NUMBER'
            hidden
         /> */}
      </Flex>
   )
}

export default TemplateHiddenFields

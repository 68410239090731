import React, { useEffect, Suspense, useState } from 'react'
import ViewTitle from '../../components/common/ViewTitle'
import {
   Box,
   Grid,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   Select,
   Divider
} from '@builtbypixel/plasma'
import { useHttp } from '../../hooks'

import ErrorBoundary from '../../components/common/ErrorBoundary'
import TableLoader from '../../components/common/TableLoader'
import Card from '../../components/common/Card'
import ReturnButton from '../../components/common/ReturnButton'
import Stock from './Details/Stock'
import BlockData from '../../components/common/BlockData'

import {
   Switch,
   Route,
   useRouteMatch,
   useParams,
   useHistory
} from 'react-router-dom'
import setup from './setup'
import useSWR from 'swr'
import { useGet } from '../../hooks'
import Product from './Tabs/Product'
import BlockLoader from '../../components/common/BlockLoader'
import WeeklySales from './Tabs/WeeklySales'
import AnnualSales from './Details/AnnualSales'

const Products = () => {
   const { id } = useParams()
   const [branches, setBranches] = useState([])


   const [currentBranch, setCurrentBranch] = useState(null)


   const { data } = useSWR(`/products/${id}`, useGet)
   const Http = useHttp()

   let { path, url } = useRouteMatch()

   useEffect(() => {
      Http.get(`/branches`).then((res) => {
         setBranches(res.data.data)
         setCurrentBranch(res.data.data[0])
      })

   }, [Http])


   const routes = [
      {
         name: 'Pack Details',
         component: <Product options={data?.data?.subRows} branches={branches} />,
         path: 'pack',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      // {
      //    name: 'Price',
      //    component: <Price />,
      //    path: 'price',
      //    exact: true
      // },
      // {
      //    name: 'Annual Sales',
      //    component: <AnnualSales />,
      //    path: 'annual-sales',
      //    exact: true
      // },
      {
         name: 'Weekly Sales',
         component: <WeeklySales />,
         path: 'weekly-sales',
         exact: true,
         permissions: ['Admin', 'RDA']
      },
      // {
      //    name: 'Promotion',
      //    component: <Promotion />,
      //    path: 'promotion',
      //    exact: true
      // },
      // {
      //    name: 'History',
      //    component: <History />,
      //    path: 'history',
      //    exact: true
      // },
      // {
      //    name: 'Barcode',
      //    component: <Barcode />,
      //    path: 'barcode',
      //    exact: true
      // }
   ]

   const getActiveTab = () => {
      const item = routes.find(
         (r) => r.path === history.location.pathname.split('/').pop()
      )
      return routes.indexOf(item)
   }
   const history = useHistory()



   return (
      <>
         <Grid
            w='100%'
            templateColumns='repeat(2, 1fr)'
            gap={4}
            mb="15px"
         >
            <Box mb='20px'>
               <ReturnButton to={`/${setup.model}`}>Products</ReturnButton>
               <ViewTitle title={data?.data?.description ? data?.data?.description : `Product #${id}`} />
            </Box>

         </Grid>
         <Grid
            templateColumns='repeat(12, 1fr)'
            templateRows='repeat(2, auto)'
            gap={4}
            w='100%'
         >

            <Card
               title='Annual Sales'
               gridColumn={{ xs: 'span 12', md: 'span 8' }}>
               {data ?

                  <Box mb="20px">

                     <AnnualSales />

                  </Box>

                  :
                  <>
                     <BlockLoader columns={2} />
                     <BlockLoader columns={2} />
                     <BlockLoader columns={2} />
                  </>
               }
            </Card>
            <Card
               title='Stock Control'
               gridColumn={{ xs: 'span 12', md: 'span 4' }}
            >

               {data ?

                  <Box>
                     <Box mb="20px">
                        <Select
                           placeholder="Select branch"
                           value={currentBranch}
                           labelKey="name"

                           valueKey="id"
                           onChange={(val) => setCurrentBranch(val)}
                           options={branches}

                        />
                     </Box>

                     <Stock stock={data?.data?.stock} branch={currentBranch?.id} />
                  </Box>
                  :
                  <>
                     <BlockLoader columns={2} />
                     <BlockLoader columns={2} />
                     <BlockLoader columns={2} />
                  </>
               }
               <Divider my='20px' />
               {data?.data?.hierarchy &&

                  data?.data?.hierarchy.map((item, i) => (
                     <BlockData
                        key={`hierarchy-block-${i}`}
                        title={`Hierarchy ${i + 1}`}
                        content=
                        {item?.category} />
                  ))

               }




            </Card>
            <Box gridColumn={{ xs: 'span 12' }}>
               <Tabs
                  mb={10}
                  orientation='horizontal'
                  variant='tab'
                  size='md'
                  index={getActiveTab()}
               >
                  <TabList>
                     {routes.map((route, i) => (
                        <Tab
                           key={`tab-${i}`}
                           position='relative'
                           onClick={() => history.push(`${url}/${route.path}`)}
                        >
                           {route.name}
                        </Tab>
                     ))}
                  </TabList>

                  <TabPanels>
                     <Switch>
                        <Card borderTopLeftRadius={0}>
                           {routes.map((route, i) => (
                              <TabPanel key={`tab-panel-${i}`}>
                                 <Route
                                    key={`panel-${i}`}
                                    exact={route.exact}
                                    path={`${path}/${route.path}`}
                                 >
                                    <ErrorBoundary>
                                       <Suspense fallback={<TableLoader />}>
                                          {route.component}
                                       </Suspense>
                                    </ErrorBoundary>
                                 </Route>
                              </TabPanel>
                           ))}
                        </Card>
                     </Switch>
                  </TabPanels>
               </Tabs>
            </Box>
         </Grid>
      </>
   )
}

export default Products

const FB_APP_ID = process.env.REACT_APP_FB_APP_ID


const useLoadFBApp= () => {
  window.fbAsyncInit = function () {
    window.FB.init({
       appId: FB_APP_ID,
       autoLogAppEvents: true,
       xfbml: true,
       version: 'v9.0'
    });
 };
};
export default useLoadFBApp;
import React, { Suspense, useContext, useEffect, memo } from 'react'
import { Form, Field } from '../../components/Form'
import CardLoader from '../../components/common/CardLoader'
import {
   ModalBody,
   ModalHeader,
   ModalContent,
   ModalFooter,
   ModalContext,
   Box,
   Button,
   Flex,
   Text
} from '@builtbypixel/plasma'
import { usePost, useToast, useRetailers } from '../../hooks'
import { useFormContext } from 'react-hook-form'

const SubmitButton = ({ loading }) => {
   const { submit } = useFormContext()
   return (
      <Button
         variantColor='parfettsGreen'
         color='white'
         mr='10px'
         isLoading={loading}
         loadingText='Saving File'
         onClick={() => submit()}
      >
         Save
      </Button>
   )
}

const AddFolderModal = memo(({ edit, folder, refreshData, retailer }) => {
   const { close } = useContext(ModalContext)
   const toast = useToast()
   // const { folder } = useRecoilValue(mediaAtom)

   const { post, put, loading, response } = usePost()

   const formatRetailerOptionLabel = (option) => (
      <Flex align='center'>
         <Text>{option.name} - </Text>
         <Text fontWeight='semibold'>#{option.id}</Text>
      </Flex>
   )
   const submitForm = (values) => {
      if (retailer) {
         values.retailer = retailer
      }
      if (edit) {
         put(`/media-library/folders/${folder?.id}`, values)
      } else {
         values.parent = folder
         post(`/media-library/folders/create`, values)
      }
   }

   useEffect(() => {
      if (response) {
         close()
         toast({
            title: 'Folder created successfully',
            status: 'success'
         })
         refreshData()
      }
   }, [close, response, toast])

   return (
      <Form data={edit && folder} onSubmit={submitForm}>
         <ModalBody>
            <Box px='25px'>
               <Field
                  label='Folder Name'
                  component='text'
                  size='lg'
                  autoFocus
                  name='name'
                  rules={{ required: true }}
               />
               {!retailer && (
                  <Field
                     label='Retailer'
                     component='select'
                     name='retailer'
                     loadOptions={useRetailers}
                     labelKey='business_name'
                     isAsync
                     valueKey='id'
                     defaultValue={retailer}
                     placeholder='Search retailer name of ID'
                     formatOptionLabel={formatRetailerOptionLabel}
                  />
               )}
            </Box>
         </ModalBody>
         <ModalFooter>
            <SubmitButton loading={loading} />
         </ModalFooter>
      </Form>
   )
})

const SuspenseAddFolderModal = (props) => {
   return (
      <ModalContent p='25px'>
         <ModalHeader>
            {props.edit ? 'Edit' : 'Add'} Folder{' '}
            {props?.folder?.name && ` to ${props?.folder?.name}`}
         </ModalHeader>
         <Suspense
            fallback={
               <Box p='25px'>
                  <CardLoader />
               </Box>
            }
         >
            <AddFolderModal {...props} />
         </Suspense>
      </ModalContent>
   )
}

export default SuspenseAddFolderModal
